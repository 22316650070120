import React, { useEffect, useState } from 'react';
import './FacetDropdown.scss';
import { flatMap, find, mapValues, groupBy, pick, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useDeepEffect, useMaterialCategory, useRegions } from '../../../hooks';
import { DropdownSelect } from '../index';
import { useClients, useServiceProviders } from '../../../hooks/useCustomers';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 12,
  fontFamily: 'font-semibold',
  letterSpacing: '1px',
  borderBottom: '1px solid #dcdcdc',
  paddingBottom: '7px',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

/**
 * Helper function to format Data into Select Object shape
 * @param data
 * @param type
 * @param keys
 * @return {{label: *, type: *, title: *, value: *}}
 * @private
 */
function _formatData(data = {}, type, keys = ['id', 'name', 'name']) {
  return {
    type,
    value: data && data[keys[0]],
    label: data && data[keys[1]],
    title: data && data[keys[2]],
  };
}

/**
 * return already selected value in facet
 * @param data - array of all facet values
 * @param value - selected value from props
 * @param filters - current applied filters
 * @param FACET_KEYS - keys of params
 * @return {[]|*}
 */
function getSelectedFacets(data, value, filters, FACET_KEYS) {
  if (value) return value;
  const allFacets = flatMap(data, (v, k) => data[k])?.map((data) => ({
    ...data,
    value: data?.value?.toString(),
  }));
  const selectedValues = pick(filters, FACET_KEYS);
  const selectedFacets = [];

  Object.keys(selectedValues).forEach((ObjKey) => {
    const facetValue = selectedValues[ObjKey] || [];
    facetValue.forEach((val) => {
      selectedFacets.push(find(allFacets, { type: ObjKey, value: val?.toString() }));
    });
  });
  return selectedFacets;
}

const INIT_OPTIONS = {
  categoryOptions: [],
  itemOptions: [],
  stateOptions: [],
  districtOptions: [],
};

/**
 * Component for facet Material Category, Items, States, Districtss
 * @param onChange
 * @param filters
 * @param value
 * @param restProps
 * @return {JSX.Element}
 * @constructor
 */
const FacetDropdown = ({ onChange, filters, value, fromDeliveryPage, ...restProps }) => {
  const categories = useMaterialCategory(true);
  const states = useRegions(true);
  const [options, setOptions] = useState();
  const [data, setData] = useState(INIT_OPTIONS);

  const FACET_KEYS = ['stateIds', 'districtIds', 'itemIds', 'browseNodeIds'];

  useDeepEffect(() => {
    prepareData();
  }, [categories, states]);

  useEffect(() => {
    const _options = [
      { label: 'MATERIAL CATEGORIES', options: data.categoryOptions },
      {
        label: 'MATERIAL ITEMS',
        options: data.itemOptions?.filter((data) => data?.label !== 'Any'),
      },
      { label: 'STATES', options: data.stateOptions },
      { label: 'DISTRICTS', options: data.districtOptions },
    ];
    setOptions(_options);
  }, [data]);

  /**
   * function called on facet value change
   * @param _values
   */
  function handleChange(_values) {
    const facetFilters = {};
    FACET_KEYS.forEach((facetKey) => {
      facetFilters[facetKey] = undefined;
    });
    const values = mapValues(groupBy(_values, 'type'), (v) => v.map((d) => d.value));
    Object.keys(values).map((e) => {
      facetFilters[e] = values[e];
    });
    onChange(facetFilters);
  }

  /**
   * Function to prepare group options in required shape
   */
  function prepareData() {
    const _sort = (a, b) => a.label?.localeCompare(b.label);
    const stateOptions = states.map((s) => _formatData(s, 'stateIds')).sort(_sort);

    const districtOptions = flatMap(states, 'districts')
      .map((d) => _formatData(d, 'districtIds'))
      .sort(_sort);
    const categoryOptions = categories
      .map((s) => _formatData(s, 'browseNodeIds', ['id', 'title', 'title']))
      .sort(_sort);
    const itemOptions = flatMap(categories, 'items')
      .map((i) => _formatData(i, 'itemIds'))
      .sort(_sort);
    setData({ categoryOptions, itemOptions, stateOptions, districtOptions });
  }

  return (
    <div className="FacetDropdown">
      <DropdownSelect
        isMulti
        options={options}
        formatGroupLabel={formatGroupLabel}
        onChange={handleChange}
        value={getSelectedFacets(data, value, filters, FACET_KEYS)}
      />
    </div>
  );
};

FacetDropdown.prototype = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  filters: PropTypes.object,
  className: PropTypes.string,
};

const INIT_OPTIONS_CUSTOMER = {
  client: [],
  serviceProvider: [],
};

/**
 * @param onChange - function will call on change of facet
 * @param filters - all active filters
 * @param value - selected value if null will be taken from filters
 * @param filterKeys [client, serviceProvider] - this are keys will be set to URL on filter,
 * the keys set to here only those customer list will be shown in dropdown
 * @param restProps
 * @return {JSX.Element}
 */
const CustomerFacet = ({
  onChange,
  filters,
  value,
  filterKeys,
  recyclerNameList,
  recyklersLoading,
  isUserServiceProvider,
  fromDeliveryPage,
  ...restProps
}) => {
  const clients = useClients(true);
  const serviceProviders = useServiceProviders(true);
  const [options, setOptions] = useState();
  const [data, setData] = useState(INIT_OPTIONS_CUSTOMER);

  const FACET_KEYS = Object.values(filterKeys);

  /**
   * called only when something change in deps,
   * will not called on ref. change with same data
   */
  useDeepEffect(() => {
    prepareData();
  }, [serviceProviders, recyclerNameList?.length]);

  useEffect(() => {
    const enableFacetFor = Object.keys(filterKeys);
    const _options = enableFacetFor.map((k) => ({ label: startCase(k), options: data[k] }));
    setOptions(_options);
  }, [data]);

  /**
   * function called on facet value change
   * @param _values
   */
  function handleChange(_values) {
    const facetFilters = {};
    FACET_KEYS.forEach((facetKey) => {
      facetFilters[facetKey] = undefined;
    });
    const values = mapValues(groupBy(_values, 'type'), (v) => v.map((d) => d.value));
    Object.keys(values).map((e) => {
      facetFilters[e] = values[e];
    });
    onChange(facetFilters);
  }

  /**
   * Function to prepare group options in required shape
   */
  function prepareData() {
    const _sort = (a, b) => a.label?.localeCompare(b.label);
    const clientOptions = clients.map((s) => _formatData(s, filterKeys['client'])).sort(_sort);
    const recyclerOptions = recyclerNameList?.map((s) => ({
      label: s?.label,
      title: s?.label,
      type: s?.type,
      value: s?.id,
    }));

    const spOptions =
      isUserServiceProvider && fromDeliveryPage
        ? serviceProviders
            ?.filter((sp) => sp?.vendorType === 'SERVICE_PROVIDER')
            ?.map((s) => _formatData(s, filterKeys['serviceProvider']))
            .sort(_sort)
        : serviceProviders?.map((s) => _formatData(s, filterKeys['serviceProvider'])).sort(_sort);
    setData({ client: clientOptions, serviceProvider: spOptions, recycler: recyclerOptions });
  }

  return (
    <div className="FacetDropdown">
      <DropdownSelect
        isMulti
        options={recyklersLoading ? [] : options}
        noOptionsMessage={() => (recyklersLoading ? 'Loading...' : 'Options not found...')}
        formatGroupLabel={formatGroupLabel}
        onChange={handleChange}
        value={getSelectedFacets(data, value, filters, FACET_KEYS)}
      />
    </div>
  );
};

CustomerFacet.propTypes = {
  filterKeys: PropTypes.objectOf(
    PropTypes.shape({ clientKey: PropTypes.string, serviceProviderKey: PropTypes.string })
  ),
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.object,
  value: PropTypes.any,
};

export { CustomerFacet, FacetDropdown };
