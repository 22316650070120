import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mapHeight: { height: '68vh' },
  locationCard: { padding: 14 },
  address: { marginLeft: 5, marginTop: 3 },
  bottomCard: {
    width: '-webkit-fill-available',
    position: 'absolute',
    bottom: 0,
  },
  searchBox: {
    position: 'absolute',
    zIndex: 999,
    width: '-webkit-fill-available',
    margin: 10,
  },
}));
