import React from 'react';
import { Card, CardContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  boxShadow: { boxShadow: 'none' },
  card: {
    height: '100%',
    borderRadius: 10,
  },
}));

const ActiveCard = ({ cardName, qty, child, bgColor, to }) => {
  const history = useHistory();
  const classes = useStyles();
  function navigate() {
    history.push(to);
  }
  return (
    <Card onClick={navigate} elevation={0} className={classes.card}>
      <CardContent className={classes.content}>
        <div className={classes.root}>
          <Typography color={'secondary'}>{cardName}</Typography>
          <IconButton
            style={{ backgroundColor: bgColor, width: '35px', height: '35px' }}
            edge="start"
            aria-label="account of current user"
            aria-haspopup="true"
            size={'small'}
            color="inherit">
            {child}
          </IconButton>
        </div>
        <Typography variant="h2">{qty}</Typography>
      </CardContent>
    </Card>
  );
};

export default ActiveCard;
