import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, TextField } from '@material-ui/core';
import FormLabel from './FormLabel';

const FormField = (props) => {
  const {
    FormControlClassName,
    label,
    fullWidth,
    children,
    required,
    value,
    error,
    type,
    isAutoComplete,
    ...restProps
  } = props;
  return children ? (
    <FormControl fullWidth>
      <FormLabel label={label} />
      {children}
    </FormControl>
  ) : (
    <FormControl fullWidth className={FormControlClassName}>
      <FormLabel label={label} />
      <TextField
        margin="dense"
        className="m-0"
        type={type}
        error={error}
        id="outlined-error-helper-text"
        fullWidth
        value={value}
        variant="outlined"
        autoComplete={isAutoComplete ? 'off' : 'on'}
        {...restProps}
      />
    </FormControl>
  );
};

FormField.propTypes = {
  label: PropTypes.element,
  required: PropTypes.bool,
  value: PropTypes.any,
  error: PropTypes.any,
};

export default FormField;
