import React, { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './GooglePlaceAutocomplete.scss';
import { useRegions } from '../../../hooks';
import { FormControl, InputGroup } from 'react-bootstrap';
import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, includes } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
const GooglePlaceAutocomplete = (props) => {
  const ref = useRef(null);
  const states = useRegions();

  const [inputValue, setInputValue] = useState(props.value);
  const [defaultAddresses, setDefaultAddresses] = useState([]);
  const [openList, setOpenList] = useState(false);

  useEffect(() => {
    if (props.defaultSuggetion) {
      const addr = props.defaultSuggetion
        .map((ad) => ({
          address: ad.pickUpAddress || ad.dropOffAddress,
          street: ad.pickUpAddress || ad.dropOffAddress,
          zipCode: ad.pickUpPincode || ad.dropOffPincode,
          latLng: {
            lat: ad.pickUpLatitude || ad.dropOffLatitude,
            lng: ad.pickUpLongitude || ad.dropOffLongitude,
          },
          city: ad.pickUpCity || ad.dropOffCity,
          state: ad.pickUpState || ad.dropOffState,
          sourceType: ad.sourceType,
          sourceName: ad.sourceName,
        }))
        .filter((a) => !isEmpty(a.street));
      setDefaultAddresses(addr);
    }
  }, [props.defaultSuggetion]);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  let addressObj = {
    street: '',
    zipCode: '',
    latLng: { lat: '', lng: '' },
    city: '',
    state: '',
  };
  const parseResult = (result) => {
    function isEqual(a, b) {
      return a?.trim()?.toLocaleString() === b?.trim()?.toLocaleString();
    }
    //extract data from google object
    const addrComponent = result.address_components;
    const cityName = addrComponent.find((comp) => comp.types.indexOf('locality') >= 0)?.long_name;
    const zipCode = addrComponent.find((comp) => comp.types.indexOf('postal_code') >= 0)?.long_name;
    const stateName = addrComponent.find(
      (comp) => comp.types.indexOf('administrative_area_level_1') >= 0
    )?.long_name;

    //get city state object from our data
    addressObj.state = states.find((s) => isEqual(s.name, stateName));
    addressObj.street = result.formatted_address;
    // setInputValue(addressObj.street);

    if (!!addressObj.state) {
      addressObj.city = addressObj.state.districts.find((d) => isEqual(d.name, cityName));
    }
    addressObj.zipCode = isEmpty(zipCode) ? '' : zipCode;
  };
  const handleSelect = (address, zipCode, city, sourceType, sourceName) => {
    setInputValue(address);
    geocodeByAddress(address)
      .then((results) => {
        parseResult(results[0]);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        addressObj.latLng = latLng;
        addressObj.street = address;
        addressObj.sourceType = sourceType;
        addressObj.cityName = typeof city === 'string' ? city : addressObj.city?.name;
        addressObj.city = typeof city === 'string' ? city : addressObj.city?.id;
        addressObj.sourceName = sourceName;
        if (addressObj.zipCode === undefined) {
          addressObj.zipCode = isEmpty(zipCode) ? '' : zipCode;
        }
      })
      .finally((e) => {
        props.handleSelect && props.handleSelect(addressObj);
      })
      .catch((error) => console.error('Error', error));
  };

  const handleChange = (input) => {
    setInputValue(input);
    props.handleChange && props.handleChange(input);
  };

  const handleDefaultSelect = (addr) => {
    setInputValue(addr.street);

    const latLng = addr.latLng;
    // if latlng empty in predefined address parse address from google
    if (
      (latLng && (isEmpty(latLng.lat) || isEmpty(latLng.lng))) ||
      isEmpty(addr.city) ||
      isEmpty(addr.state) ||
      isEmpty(addr.zipCode)
    ) {
      handleSelect(addr.street, addr.zipCode, addr.city, addr.sourceType, addr.sourceName);
    } else {
      props.handleSelect && props.handleSelect(addr);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenList(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div style={props.disabled ? { pointerEvents: 'none' } : null}>
      <PlacesAutocomplete value={inputValue} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <InputGroup ref={ref}>
            <FormControl
              {...getInputProps({
                placeholder: props.placeholder || 'Search Places ...',
                className: 'w-100' + props.className,
              })}
              disabled={props.disabled}
              isInvalid={props.isInvalid}
              onClick={() => setOpenList(true)}
            />
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
              </InputGroup.Text>
            </InputGroup.Append>
            {/*{suggestions?.length > 0 &&*/}
            {openList && (
              <PerfectScrollbar
                style={{ maxHeight: '200px', height: 'auto' }}
                className="w-100 GooglePlaceAutocomplete p-0 autocomplete-dropdown-container">
                {isEmpty(inputValue) &&
                  defaultAddresses &&
                  defaultAddresses
                    ?.filter((a) => includes(a.street, inputValue))
                    .map((addr) => (
                      <div
                        className="result-row pointer-event animated fadeIn"
                        onClick={() => handleDefaultSelect(addr)}
                        style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          className="text-muted ml-1"
                          style={{ fontSize: '1.5rem' }}
                          icon={faClock}
                        />
                        <div className="pl-3">
                          <div className="font-medium">{addr.street}</div>
                          {/*<div className="text-muted">dad</div>*/}
                        </div>
                      </div>
                    ))}
                {suggestions?.slice(0, 5)?.map((suggestion) => {
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#e3edff', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: 'result-row border-bottom',
                        style,
                      })}>
                      <FontAwesomeIcon
                        className="text-muted ml-1"
                        style={{ fontSize: '1.5rem' }}
                        icon={faMapMarkerAlt}
                      />
                      <div className="pl-3">
                        <div className="font-medium">
                          {suggestion.formattedSuggestion?.mainText}
                        </div>
                        <div className="text-muted">
                          {suggestion.formattedSuggestion?.secondaryText}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </PerfectScrollbar>
            )}
            {/*}*/}
          </InputGroup>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default GooglePlaceAutocomplete;
