import { DOCUMENT_STATUS_LIST, DOCUMENT_STATUSES } from '../Constants/Constants';
import * as moment from 'moment';

import { publish } from '../Events/AppEvents';
import { APP_EVENTS } from '../Constants/Constants';
import { keyBy, map, uniqBy, isEmpty } from 'lodash';
import errorExtractor from '../../shared/Utils/ErrorExtractor';

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function numberWithCommas(x) {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const getDocumentByType = (documents, type) => {
  return documents.filter(({ document }) => document?.type === type);
};

const getColorByDocType = (documents, type) => {
  let { color } = DOCUMENT_STATUS_LIST.find((ds) => ds.id === DOCUMENT_STATUSES.NO_DOC);

  if (documents && documents.length > 0) {
    const delDocuments = getDocumentByType(documents, type);
    let status = DOCUMENT_STATUSES.NO_DOC;
    delDocuments.forEach(({ status: docStatus }) => {
      if (docStatus === DOCUMENT_STATUSES.APPROVED) return (status = docStatus);
      else if (docStatus === DOCUMENT_STATUSES.IN_REVIEW) return (status = docStatus);
      else return (status = docStatus);
    });
    const { color: newColor } = DOCUMENT_STATUS_LIST.find((ds) => ds.id === status) || {};
    color = newColor || color;
  }
  return color;
};

/**
 * convert server date to local date format
 * @param dateString
 * @param format
 * @return {string}
 */
const toLocalDate = (dateString, format = 'DD-MMM-YYYY') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

const toLocalDisplayDate = (dateString, format = 'MMMM YYYY') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

export const toFinYear = (dateString1, dateString2) => {
  if (!dateString1 || !dateString2) return '';
  return new Date(dateString1).getFullYear() + '-' + new Date(dateString2).getFullYear();
};

/**
 * convert server date to local date format
 * @param dateString
 * @param format
 * @return {string}
 */
const toLocalDateTime = (dateString, format = 'DD-MMM-YYYY . hh:mm A') => {
  if (!dateString) return '';
  return moment(dateString).format(format);
};

/**
 * convert local date to Server required format
 * @param date
 * @param onlyDate
 * @param format
 * @return {string}
 */
const toServerDate = (date, onlyDate, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') => {
  const _date = moment(date);
  return onlyDate ? _date.startOf('day').utc().format(format) : _date.utc().format(format);
};
function toCapitalize(str) {
  if (!str) return str;
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)?.toLowerCase();
  }
  return frags.join(' ');
}

const parseQueryParams = (queryString) => {
  const queryParams = {};
  const query = new URLSearchParams(queryString);
  for (let param of query.entries()) {
    queryParams[param[0]] = param[1];
  }
  return queryParams;
};

/**
 *
 * @param {*} month
 * @returns
 * To get the start and end date for
 *  current year(jan 1 to December 31 ) of a finanicial Year(April 1 to March 31)===> April 1 to december 31
 */
const getCurrentYearStartEndDates = (selectedFinancialYear, month) => {
  return {
    fromDate: moment
      .utc(moment(selectedFinancialYear?.fromDate).month(month).startOf('month'))
      .format(),
    toDate: moment
      .utc(moment(selectedFinancialYear?.fromDate).month(month).endOf('month'))
      .format(),
  };
};

/**
 *
 * @param {*} month
 * @returns
 * To get the start and end date for
 *  next year of a finanicial Year(april 1 to March 31)===> jan 1 to march 31
 */
const getNextYearStartEndDates = (selectedFinancialYear, month) => {
  return {
    fromDate: moment
      .utc(moment(selectedFinancialYear?.toDate).month(month).startOf('month'))
      .format(),
    toDate: moment.utc(moment(selectedFinancialYear?.toDate).month(month).endOf('month')).format(),
  };
};

/**
 *
 * @param {month} key
 * @returns an object of start and end date of a month
 */
const startAndEndDateOfMonth = (month) => {
  return {
    fromDate: moment.utc(moment().month(month).startOf(month)).format(),
    toDate: moment.utc(moment().month(month).endOf(month)).format(),
  };
};

const removeFieldFromUrlParam = (key, history) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.delete(key);
  history.replace({
    pathname: window.location.pathname,
    search: currentUrlParams.toString(),
  });
};

const setObjectToUrlParam = (obj, history) => {
  if (obj && Object.keys(obj).length) {
    let currentUrlParams = new URLSearchParams(window.location.search);
    Object.keys(obj).forEach((keyItem) => {
      if (obj[keyItem] === null || obj[keyItem] === undefined) {
        currentUrlParams.delete(keyItem);
      } else {
        currentUrlParams.set(keyItem, obj[keyItem]);
      }
    });
    history.replace({
      pathname: window.location.pathname,
      search: currentUrlParams.toString(),
    });
  }
};

const setFilterToUrl = (obj, history) => {
  if (obj && Object.keys(obj).length) {
    let currentUrlParams = new URLSearchParams();
    Object.keys(obj).forEach((keyItem) => {
      if (obj[keyItem] === null || obj[keyItem] === undefined) {
        currentUrlParams.delete(keyItem);
      } else {
        currentUrlParams.set(keyItem, obj[keyItem]);
      }
    });
    history.replace({
      pathname: window.location.pathname,
      search: currentUrlParams.toString(),
    });
  }
};

const reloadParentDelivery = () => {
  publish(APP_EVENTS.topic.DELIVERY, APP_EVENTS.event.CHANGE_DELIVERY, null);
};

function deepKeyBy(arr, key = 'id') {
  return keyBy(
    map(arr, (o) => ({ ...o, districts: keyBy(o.districts, key) })),
    key
  );
}

function hasError(response) {
  if (response?.status >= 400) {
    const error = errorExtractor(response);
    return error;
  }
  return false;
}

function isSpecialCharacter(character) {
  const specialCharacterRegex = /[^A-Za-z 0-9]/g;
  return specialCharacterRegex.test(character);
}

function isEquals(from, to) {
  return from.replace(' ', '').toLowerCase() === to.replace(' ', '').toLowerCase();
}
function percentage(percent, total) {
  return Math.round(Number((percent / 100) * total) || 0);
}
function getSearchParams(param) {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
}
export const getArialDistance = function (p1, p2) {
  const R = 6378137;
  const rad = function (x) {
    return (x * Math.PI) / 180;
  };
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  const dInKm = d / 1000;
  return Math.ceil(dInKm + percentage(18, dInKm));
};
export const getHSNCode = (browseNodes) => {
  const hsnCodeArray = [];
  for (let item of browseNodes?.data) {
    for (let itemHSN of item?.items) {
      hsnCodeArray.push({
        id: Number(itemHSN?.hsnCode?.id),
        tax: 5,
      });
    }
  }
  return uniqBy(hsnCodeArray, 'id');
};
export const getTaxableValue = (qty, pricePerUnit) => qty * pricePerUnit;
export const getGstRate = (materials, itemId) =>
  materials?.find((material) => material?.id === itemId)?.hsnCode?.tax;
export const getHsnCode = (materials, itemId) =>
  materials?.find((material) => material?.id === itemId)?.hsnCode?.id;
export const getGstAmount = (taxableValue, gstRate) => taxableValue * (gstRate / 100);
export const getLineAmount = (taxableValue, gstAmount) => taxableValue + gstAmount;

export const convertToMt = (value, uptoDigit = 2) =>
  value ? parseFloat((value / 1000)?.toFixed(uptoDigit)) : 0;

export const browseNodesToCategory = (categoryBrowseNodeId) => {
  switch (categoryBrowseNodeId) {
    case '1':
      return '1';
    case '2':
      return '3';
    case '3':
      return 'E-waste';
    case '4':
      return '2';
    case '5':
      return 'Batteries';
    case '6':
      return '4';
    case '7':
      return 'Tyres';
    default:
      return 'error parsing browse node';
  }
};

export const checkAmountType = (value) => {
  return value?.toFixed()?.length <= 5
    ? `${Number(Math.round(value / 1000 + 'e3') + 'e-3')} K`
    : value?.toFixed()?.length <= 7
    ? `${Number(Math.round(value / 100000 + 'e2') + 'e-2')} Lac`
    : `${Number(Math.round(value / 10000000 + 'e2') + 'e-2')} Cr`;
};

export function findPercentage(planned, total) {
  return Math.round(Number((planned * 100) / total) || 0);
}

export function findDeliverables(deliverablesArray, customerId) {
  const deliverablesArrayByCustomerId = deliverablesArray[customerId];
  const specialDeliverablesArray = isEmpty(deliverablesArrayByCustomerId)
    ? []
    : deliverablesArrayByCustomerId?.filter((data) => data?.special);

  return {
    deliverablesArrayByCustomerId: deliverablesArrayByCustomerId,
    specialDeliverablesArray: specialDeliverablesArray,
    docType: isEmpty(specialDeliverablesArray)
      ? []
      : specialDeliverablesArray?.map((data) => data?.docType),
    isSpecial: isEmpty(deliverablesArrayByCustomerId) ? false : !isEmpty(specialDeliverablesArray),
  };
}

export const convertToKG = (value) => value * 1000;
export const convertToMT = (value) => value / 1000;

export const convertToRupee = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumSignificantDigits: 3,
  }).format(+value);

export {
  groupBy,
  toLocalDate,
  toLocalDateTime,
  toServerDate,
  getColorByDocType,
  toLocalDisplayDate,
  toCapitalize,
  getDocumentByType,
  parseQueryParams,
  removeFieldFromUrlParam,
  setObjectToUrlParam,
  numberWithCommas,
  setFilterToUrl,
  reloadParentDelivery,
  deepKeyBy,
  hasError,
  isEquals,
  isSpecialCharacter,
  getSearchParams,
  getCurrentYearStartEndDates,
  getNextYearStartEndDates,
  startAndEndDateOfMonth,
};
