import React from 'react';
import { Row } from 'react-bootstrap';
import './Planning.scss';

export default function PlanningCard({ label, value, unit, isBorderHighlighted, isMpCard, count }) {
  return (
    <div
      className="planningCardStyle"
      style={{ border: isBorderHighlighted && 'solid 1px #005dff' }}>
      <div className="planningCardSection">
        {isMpCard ? (
          <div>
            <Row>
              <p className="mpQuantityText" style={{ color: isBorderHighlighted && '#005dff' }}>
                {value}
                <span className="text-style-2">{unit}</span>
              </p>
            </Row>
            <Row>
              <p>({count} deliveries)</p>
            </Row>
          </div>
        ) : (
          <p className="planningQuantityText" style={{ color: isBorderHighlighted && '#005dff' }}>
            {value} <span className="mtText">{unit}</span>
          </p>
        )}

        <p className="verticalCardLine"></p>
        <p
          className={isMpCard ? 'mpPlanningLabelText' : 'planningLabelText'}
          style={{ color: isBorderHighlighted && '#005dff' }}>
          {label}
        </p>
      </div>
    </div>
  );
}
