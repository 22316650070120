import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import AppModal from '../../../../shared/Components/_App/AppModal';
import docsuccess from '../../../../assets/img/_App/docgeneratedimg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { toCapitalize } from '../../../../shared/Utils/Helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  heading: {
    color: '#2e374d',
    fontFamily: 'font-semibold',
  },
  buttonstyl: {
    backgroundColor: '#f7faff',
    color: '#2e374d',
    border: '1px dotted #e0e0e0',
    width: '69%',
    fontSize: '15px',
  },
  linkstyl: {
    color: '#33ab2e',
    fontSize: '15px',
  },
}));

const DownloadFile = (list) => {
  list.map((e) => {
    fetch(e.document.viewUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', e.document.fileName); //or any other extension
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
        // window.open(e.document.viewUrl);
      });
  });
};

const DeliveryDocGeneratedSuccessView = (props) => {
  const { delivery } = { ...props };
  const classes = useStyles();
  const [showDocModal, setShowDocMoadal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(false);
  const ewaybillandInvoiceDocs = () => {
    const ewaybilldocs = delivery.deliveryDocuments.filter(
      (e) => e.document.type === 'PICKUP_E_WAY_BILL'
    );
    const invoiceDocs = delivery.deliveryDocuments.filter(
      (e) => e.document.type === 'PICKUP_INVOICE'
    );

    return (
      <>
        <Grid>
          <Button
            variant="contained"
            size="large"
            className={`${classes.buttonstyl} mt-3`}
            onClick={() => {
              setShowDocMoadal(true);
              setSelectedDoc(ewaybilldocs[0]);
            }}
            disabled={ewaybilldocs.length === 0}
            startIcon={
              <FontAwesomeIcon
                className={classes.approve}
                color="green"
                size="sm"
                icon={faCheckCircle}
              />
            }>
            View E-Way Bill{' '}
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            size="large"
            className={`${classes.buttonstyl} mt-3`}
            onClick={() => {
              setShowDocMoadal(true);
              setSelectedDoc(invoiceDocs[0]);
            }}
            disabled={invoiceDocs.length === 0}
            startIcon={
              <FontAwesomeIcon
                className={classes.approve}
                color="green"
                size="sm"
                icon={faCheckCircle}
              />
            }>
            View E-Invoice{' '}
          </Button>
        </Grid>

        <Grid className="mt-4">
          <Button
            className={`${classes.linkstyl} `}
            disabled={invoiceDocs.length === 0 && ewaybilldocs.length === 0}
            onClick={() => DownloadFile([...ewaybilldocs, ...invoiceDocs])}>
            <FontAwesomeIcon color="#33ab2e" className="mr-2" size="sm" icon={faDownload} />
            Download Files
          </Button>
        </Grid>
      </>
    );
  };
  const getEncodedUrl = (url) => {
    var encodedUrl = encodeURIComponent(url);
    return encodedUrl;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <img
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                width: '125px',
                height: '125px',
              }}
              src={docsuccess}
            />
            <Typography variant="h4" className={classes.heading}>
              Successfully Generated <br /> EWB & E-Invoice
            </Typography>
            {ewaybillandInvoiceDocs()}
          </Paper>
        </Grid>
      </Grid>
      <AppModal
        title={toCapitalize(selectedDoc?.document?.type)}
        visible={showDocModal}
        setVisible={setShowDocMoadal}>
        <TransformWrapper
          className="h-100"
          defaultScale={1}
          defaultPositionX={200}
          defaultPositionY={100}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            //   <React.Fragment>
            <TransformComponent>
              <div style={{ height: '90vh', width: '100vw' }}>
                {selectedDoc?.document?.contentType === 'application/pdf' ? (
                  // <Image src={pdfIcon} />
                  /*<object
                    type="application/pdf"
                    data={selectedDoc?.document?.viewUrl}
                    width="100%"
                    height="90%"
                  target="_self"></object>*/
                  <iframe
                    type="application/pdf"
                    src={`https://docs.google.com/viewer?url=${getEncodedUrl(
                      selectedDoc?.document?.viewUrl
                    )}&embedded=true`}
                    // src={`${tile.document.viewUrl}`}
                    style={{ pointerEvents: 'none', width: '100%', height: '100%' }}></iframe>
                ) : (
                  <img
                    className="h-100"
                    width="100%"
                    height="100%"
                    src={selectedDoc?.document?.viewUrl}
                    alt="test"
                  />
                )}
              </div>
            </TransformComponent>
          )}
        </TransformWrapper>
      </AppModal>
    </div>
  );
};

export default DeliveryDocGeneratedSuccessView;
