import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TargetStepCard.scss';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import tickSign from '../../../assets/img/ticksign.png';
import Row from 'react-bootstrap/Row';

const TargetStepCard = (props) => {
  return (
    <Card body className="TargetStepCard ">
      <Row>
        <Col xs={12}>
          <img
            src={require(`../../../assets/img/${props.data.icon}`)}
            className="download-button mb-3"
            width="32"
            height="30"
          />
          {props.data.status ? (
            <img src={tickSign} className={'float-right'} height={25} width={25} />
          ) : null}
          <p>
            {props.data.id}. {props.data.title}
          </p>
          <Button variant="link" style={!props.data.status ? { opacity: 0.5 } : {}}>
            <FontAwesomeIcon onClick={props.action} icon={faChevronCircleRight} size="lg" />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default TargetStepCard;
