import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listTitle: { width: '35%', height: '150px' },
  img: {
    width: '100%',
    borderRadius: '20px',
    padding: '5px',
    height: '100%',
  },
  tileBar: {
    background: 'transparent',
  },
  icon: { backgroundColor: 'white', opacity: '80%', margin: '5px' },
  rejectedImg: {
    width: '100%',
    borderRadius: '20px',
    height: '88%',
    borderColor: 'red',
    borderStyle: 'solid',
  },
  approveAvtar: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
    backgroundColor: 'rgb(240, 255, 237)',
    position: 'absolute',
    right: '10px',
    top: '8px',
  },
  deleteAvatar: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
    backgroundColor: 'rgb(240, 255, 237)',
    fontSize: '12px',
    position: 'absolute',
    right: '33px',
    top: '8px',
  },
  deleteproAvatar: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
    backgroundColor: 'rgb(240, 255, 237)',
    fontSize: '12px',
    position: 'absolute',
    right: '11px',
    top: '8px',
  },

  rejectAvatar: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
    backgroundColor: '#fff5f5',
    position: 'absolute',
    right: '31px',
    top: '8px',
  },
  approveBadgeStyl: {
    border: '1px solid #00b9ba',
    padding: '1px 3px',
    color: 'white',
    position: 'absolute',
    right: '17px',
    bottom: '10px',
    backgroundColor: '#00b9ba',
    borderRadius: '10px',
    fontFamily: 'font-semibold',
  },
  reviewBadgeStyl: {
    border: '1px solid #3a3232 ',
    padding: '1px 3px',
    color: 'white',
    position: 'absolute',
    right: '17px',
    bottom: '10px',
    backgroundColor: '#3a3232',
    borderRadius: '10px',
    fontFamily: 'font-semibold',
  },
  rejectBadgeStyl: {
    border: '1px solid #ff7272',
    padding: '1px 3px',
    color: 'white',
    position: 'absolute',
    right: '17px',
    bottom: '10px',
    backgroundColor: '#ff7272',
    borderRadius: '10px',
    fontFamily: 'font-semibold',
  },
  accordianMain: {
    width: 'auto',
  },
  accordionHeader: {
    width: 'auto',
    height: '50px',
    margin: '0 0 11px',
    padding: '12px 18px 8px 15px',
    borderBottom: 'none !important',
  },
  trackingCard: {
    marginBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125) !important',
  },
}));
