import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppModal from '../../../../shared/Components/_App/AppModal';
import SupervisorForm from './DeliverySuperVisorForm';
import SelectDropdown from '../../../../shared/Components/_App/SelectDropdown/SelectDropdown';
import FormLabel from '../../../../shared/Components/_App/FormLabel';
import Section from '../../../../shared/Components/_App/Section';
import FormField from '../../../../shared/Components/_App/FormField';
import { SupervisorListView } from './SupervisorItems';
import { useAsyncEffect } from '../../../../hooks';
import { useSupervisor } from '../../../../hooks/hooks';
import { hasError } from '../../../../../src/shared/Utils/Helper';
import { toast } from 'react-toastify';

const DeliverySupervisor = (props) => {
  const { createDeliveryForm, user, displayMyself, isPRO } = { ...props };
  const [
    {
      svError,
      svLoading,
      svValue,
      createError,
      createValue,
      creating,
      updateError,
      updateValue,
      updating,
      deleteError,
      deleteValue,
      deleting,
    },
    { dofetchSuperviosr, doCreateSupervisor, doUpdateSupervisor, doDeleteSupervisor },
  ] = useSupervisor();
  const [showAddSupervisorModal, setShowSupervisorModal] = useState(false);
  const [showPickupSupervisorList, setShowPickupSupervisorList] = useState(false);
  const [showDeliverySupervisorList, setShowDeliverySupervisorList] = useState(false);
  const [svlist, setList] = useState([]);
  const [deliverySupervisorList, setDeliverySupervisorList] = useState([]);

  const [type, setType] = useState();
  const MOBILE_NUMBER_REGEX = '^[6789][0-9]{9}$';

  const supervisorForm = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: () => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          phoneNumber: Yup.string()
            .matches(MOBILE_NUMBER_REGEX, 'Mobile Number is required')
            .required('Mobile Number is required'),
        });
      });
    },
    validateOnChange: true,
    onSubmit: (values) => {
      createOrUpdateSupervisor(values);
    },
  });

  useAsyncEffect(async () => {
    await dofetchSuperviosr();
  }, [showAddSupervisorModal]);

  useEffect(() => {
    if (svValue) {
      const obj = { name: user?.firstName, phoneNumber: user?.mobile };
      const list = svValue;
      list.unshift(obj);
      setList(list);
      setDeliverySupervisorList(list);
    }
  }, [svValue]);

  useEffect(() => {
    if (!showPickupSupervisorList) setList(svValue);
  }, [showPickupSupervisorList]);

  useEffect(() => {
    if (!showDeliverySupervisorList) setDeliverySupervisorList(svValue);
  }, [showDeliverySupervisorList]);

  /**
   * On selecting row this will directly set values to main formik and close the modals
   * @param {*} svObj
   * @param {*} svtype
   */
  const supervisorHandler = (svObj, svtype) => {
    setMainFormik(svObj, svtype);
    closeAllModals();
  };

  /**
   * This will set name and number to main formik
   * @param {*} obj
   * @param {*} mainType
   */
  const setMainFormik = (obj, mainType) => {
    if (mainType === 'Pickup') {
      createDeliveryForm.setFieldValue('pickUpSupervisorName', obj.name);
      createDeliveryForm.setFieldValue('pickUpSupervisorMobile', obj.phoneNumber);
    } else {
      createDeliveryForm.setFieldValue('dropSupervisorName', obj.name);
      createDeliveryForm.setFieldValue('dropSupervisorMobile', obj.phoneNumber);
    }
  };
  const addPickUpSV = () => {
    setType('Pickup');
    addSVhandler();
  };
  const addDropSV = () => {
    setType('Delivery');
    addSVhandler();
  };
  /**
   * On adding supervisor this will reset supervisor formik values
   */
  const addSVhandler = () => {
    setShowSupervisorModal(true);
    supervisorForm.resetForm();
    // createDeliveryForm.resetForm();
  };

  /**
   * On clicking edit this will set row data to formik
   * @param {*} rowData
   * @param {*} editType
   */

  const editHandler = (rowData, editType) => {
    setType(editType);
    supervisorForm.setValues(rowData);
    setShowSupervisorModal(true);
  };

  /**
   * This api call is used to create or update the supervisor
   * @param {*} payload
   */

  const createOrUpdateSupervisor = async (payload) => {
    const result = payload.id
      ? await doUpdateSupervisor(payload)
      : await doCreateSupervisor(payload);
    const error = hasError(result);
    if (error) {
      return;
    }
    toast.success(`Supervisor ${payload.id ? 'Updated' : 'Added'} successfully.`);
    setMainFormik(payload, type);
    closeAllModals();
  };

  /**
   * This api call is used to delete the supervisor
   */

  const deleteSupervisorHandler = async (deleteType, deleteName) => {
    const pickUpSupervisorName = createDeliveryForm.values.pickUpSupervisorName;
    const dropSupervisorName = createDeliveryForm.values.dropSupervisorName;
    const result = await doDeleteSupervisor(supervisorForm?.values?.id);
    const error = hasError(result);
    if (error) {
      return;
    }

    if (
      deleteType === 'Pickup' &&
      deleteName.toUpperCase() === pickUpSupervisorName.toUpperCase()
    ) {
      if (pickUpSupervisorName.toUpperCase() === dropSupervisorName.toUpperCase()) {
        createDeliveryForm.setFieldValue('pickUpSupervisorName', '');
        createDeliveryForm.setFieldValue('pickUpSupervisorMobile', '');
        createDeliveryForm.setFieldValue('dropSupervisorName', '');
        createDeliveryForm.setFieldValue('dropSupervisorMobile', '');
      } else {
        createDeliveryForm.setFieldValue('pickUpSupervisorName', '');
        createDeliveryForm.setFieldValue('pickUpSupervisorMobile', '');
      }
    }

    if (
      deleteType === 'Delivery' &&
      deleteName.toUpperCase() === dropSupervisorName.toUpperCase()
    ) {
      if (dropSupervisorName.toUpperCase() === pickUpSupervisorName.toUpperCase()) {
        createDeliveryForm.setFieldValue('pickUpSupervisorName', '');
        createDeliveryForm.setFieldValue('pickUpSupervisorMobile', '');
        createDeliveryForm.setFieldValue('dropSupervisorName', '');
        createDeliveryForm.setFieldValue('dropSupervisorMobile', '');
      } else {
        createDeliveryForm.setFieldValue('dropSupervisorName', '');
        createDeliveryForm.setFieldValue('dropSupervisorMobile', '');
      }
    }

    toast.success('Supervisor Deleted Successfully.');
    closeAllModals();
  };

  const closeAllModals = () => {
    setShowPickupSupervisorList(false);
    setShowDeliverySupervisorList(false);
    setShowSupervisorModal(false);
  };

  const handlePickupSupervisorSearch = (Value) => {
    const val = Value?.target?.value?.toUpperCase()?.trim();
    if (val) {
      const originalValue = svValue;
      const filteredTransporterValue = originalValue?.filter((data) =>
        data?.name?.toUpperCase()?.trim()?.includes(val)
      );
      setList(filteredTransporterValue);
    } else setList(svValue);
  };

  const handleDeliverySupervisorSearch = (Value) => {
    const val = Value?.target?.value?.toUpperCase()?.trim();
    if (val) {
      const originalValue = svValue;
      const filteredTransporterValue = originalValue?.filter((data) =>
        data?.name?.toUpperCase()?.trim()?.includes(val)
      );
      setDeliverySupervisorList(filteredTransporterValue);
    } else setDeliverySupervisorList(svValue);
  };

  return (
    <Section title="SELECT/ADD SUPERVISOR">
      <FormField label="PICKUP SUPERVISOR">
        <SelectDropdown
          visible={showPickupSupervisorList}
          setVisible={setShowPickupSupervisorList}
          onClick={() => (isPRO ? '' : setShowPickupSupervisorList(true))}
          listData={svlist}
          onSearch={handlePickupSupervisorSearch}
          modalTitle="Select Pickup Supervisor"
          searchPlaceHolderName="Search for Pickup Supervisor"
          value={displayMyself(
            user?.mobile,
            createDeliveryForm.values.pickUpSupervisorMobile,
            createDeliveryForm.values.pickUpSupervisorName
          )}
          addHandler={addPickUpSV}
          actions
          renderItem={(e, idx) => (
            <SupervisorListView
              onClick={() => supervisorHandler(e, 'Pickup')}
              svObj={e}
              onEdit={() => editHandler(e, 'Pickup')}
              key={idx}
              user={user}
              displayMyself={displayMyself}
            />
          )}
        />
      </FormField>

      <FormLabel label="DELIVERY SUPERVISOR" />
      <SelectDropdown
        visible={showDeliverySupervisorList}
        setVisible={setShowDeliverySupervisorList}
        onClick={() => (isPRO ? '' : setShowDeliverySupervisorList(true))}
        listData={deliverySupervisorList}
        modalTitle="Select Delivery Supervisor"
        searchPlaceHolderName="Search for Delivery Supervisor"
        onSearch={handleDeliverySupervisorSearch}
        value={displayMyself(
          user?.mobile,
          createDeliveryForm.values.dropSupervisorMobile,
          createDeliveryForm.values.dropSupervisorName
        )}
        addHandler={addDropSV}
        actions
        renderItem={(e, idx) => (
          <SupervisorListView
            onClick={() => supervisorHandler(e, 'Delivery')}
            svObj={e}
            onEdit={() => editHandler(e, 'Delivery')}
            key={idx}
            user={user}
            displayMyself={displayMyself}
          />
        )}
      />
      <AppModal
        title={`${supervisorForm?.values?.id ? 'Edit' : 'Add'} ${type} Supervisor`}
        backArrowDisplay={true}
        editMode={supervisorForm?.values?.id}
        deleteHandler={deleteSupervisorHandler}
        visible={showAddSupervisorModal}
        setVisible={setShowSupervisorModal}
        editType={type}
        editName={supervisorForm?.values?.name}>
        <SupervisorForm supervisorForm={supervisorForm} />
      </AppModal>
    </Section>
  );
};

export default DeliverySupervisor;
