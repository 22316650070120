import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Tabs,
  Tab,
  Box,
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from '../../../../assets/img/_App/cross.svg';
import { useStyles } from './FilterModal.styles';

const FilterModal = ({ open, handleClose, onClose, onClear, onDone, Tabs }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <AppBar className={classes.header}>
              <Toolbar>
                <div>
                  <Button color="primary" onClick={() => onClear()}>
                    Clear All
                  </Button>
                </div>
                <div className={classes.grow} />
                <Typography className={classes.title} variant="h6" noWrap>
                  Filter By
                </Typography>
                <div className={classes.grow} />
                <div>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={onClose}>
                    <CrossIcon />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
            <Container className={classes.container}>
              <div className={classes.root}>
                <StyledTabs
                  orientation="vertical"
                  variant="scrollable"
                  indicatorColor="white"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}>
                  {Tabs?.map((tab, index) => (
                    <Tab label={tab?.tabName} {...a11yProps(index)} />
                  ))}
                </StyledTabs>
                {Tabs?.map((tab, index) => (
                  <TabPanel value={value} index={index}>
                    {tab?.component}
                  </TabPanel>
                ))}
              </div>
            </Container>
            <Button
              fullWidth
              onClick={() => onDone()}
              variant="contained"
              color="primary"
              className={classes.button}>
              Done
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ overflow: 'scroll', width: '100%' }}
      {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default FilterModal;
