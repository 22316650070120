import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AccordianArrow } from '../../../assets/img/AccordianArrow.svg';
import { Loader } from '../../../shared/Components';
import { CheckboxCell } from '../../../shared/Components/GenericComponent';
import { toLocalDate } from '../../../shared/Utils/Helper';
import PlanningTable from '../SharedComponents/PlanningTable';
import './MarketPlaceCredits.scss';

export default function SupplyFromVendors(props) {
  const {
    supplyData,
    handleSupplyGenerateWoRow,
    setGenerateWoDeliveries,
    setAllocateWoDelivery,
    allocateWoDelivery,
    isInnerArrowActive,
    setIsInnerArrowActive,
    tabKey,
    generateWoDeliveries,
    handleGenerateWoAgainstDeliveries,
  } = props;

  const [loading, setLoading] = useState(false);

  const tablecolumns = [
    tabKey === 'generateWO'
      ? {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <CheckboxCell getToggleSelectionProps={getToggleAllRowsSelectedProps} />
          ),
          Cell: ({ row }) => (
            <CheckboxCell getToggleSelectionProps={row.getToggleRowSelectedProps} />
          ),
        }
      : {
          id: 'selection',
          Header: 'Select',
          Cell: ({ row }) => {
            const isRowEnabled = isEmpty(allocateWoDelivery)
              ? false
              : allocateWoDelivery?.some((data) => data?.index !== row?.index);
            return (
              <div>
                <CheckboxCell
                  getToggleSelectionProps={row.getToggleRowSelectedProps}
                  disabled={isRowEnabled}
                />
              </div>
            );
          },
        },

    {
      Header: 'Delivery Id',
      accessor: 'id',
      disableSortBy: true,
      Cell: (cellInfo) => {
        const history = useHistory();

        return (
          <Button
            className={'pl-3'}
            onClick={() => history.push(`/executions/deliveries/${cellInfo?.row?.original?.id}`)}
            variant="link">
            {cellInfo?.row?.original?.id}
          </Button>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'deliveryStatus',
      sortKey: 'status',
    },
    {
      Header: 'Qunatity (KGs)',
      accessor: 'qty',
      disableSortBy: true,
    },

    {
      Header: 'DropOff Unload Date',
      disableSortBy: 'basic',
      Cell: (cellInfo) => {
        return toLocalDate(cellInfo?.row?.original?.deliveryDetails?.dropOffUnloadDate);
      },
    },
  ];

  const handleDeliveries = (value) => {
    setLoading(true);
    setGenerateWoDeliveries([]);
    setAllocateWoDelivery([]);
    setIsInnerArrowActive((prevState) => ({
      ...prevState,
      isMarketPlaceAccordionActive:
        isInnerArrowActive?.isMarketPlaceAccordionActive === value?.accordionId ? false : true,
      activeMarketPlaceAccordionValue:
        isInnerArrowActive?.activeMarketPlaceAccordionValue === value?.accordionId?.toString()
          ? null
          : value?.accordionId?.toString(),
      vendorAccordianId: value?.accordionId,
    }));
    setTimeout(() => setLoading(false), [1000]);
  };

  return (
    <>
      {isInnerArrowActive?.isArrowActive &&
        isInnerArrowActive?.activeArrowValue === supplyData?.id &&
        supplyData?.recyclerList?.map((value) => (
          <div>
            <Accordion
              defaultActiveKey={false}
              activeKey={
                isInnerArrowActive?.isMarketPlaceAccordionActive
                  ? isInnerArrowActive?.activeMarketPlaceAccordionValue
                  : null
              }>
              <Card className="Card">
                <div>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={value?.accordionId?.toString()}
                    className="supplyVendorToggle"
                    onClick={() => handleDeliveries(value)}
                    activeKey={
                      isInnerArrowActive?.isMarketPlaceAccordionActive
                        ? isInnerArrowActive?.activeMarketPlaceAccordionValue
                        : null
                    }>
                    <div className="subHeading">
                      <p>
                        <span>
                          {' '}
                          Vendor: {
                            value?.recyclerData[0]?.deliveryDetails?.dropOffRecyclerName
                          } |{' '}
                        </span>
                        <span> No of Deliveries: {value?.generateWoData?.length} </span>

                        <AccordianArrow
                          style={{
                            transform:
                              isInnerArrowActive?.isArrowActive &&
                              isInnerArrowActive?.activeMarketPlaceAccordionValue &&
                              isInnerArrowActive?.vendorAccordianId === value?.accordionId &&
                              `rotate(-180deg)`,
                            float: 'right',
                          }}
                        />
                      </p>
                    </div>
                  </Accordion.Toggle>
                </div>

                <Accordion.Collapse
                  eventKey={value?.accordionId?.toString()}
                  activeKey={
                    isInnerArrowActive?.isMarketPlaceAccordionActive
                      ? isInnerArrowActive?.activeMarketPlaceAccordionValue
                      : null
                  }>
                  <Card.Body>
                    <div className="accordianBody">
                      <div>
                        {loading ? (
                          <Loader />
                        ) : (
                          <PlanningTable
                            HeaderColor={true}
                            tableColumns={tablecolumns}
                            tableData={value?.generateWoData}
                            handleRowSelection={handleSupplyGenerateWoRow}
                            isRowSelectionRequired={true}
                            selectedRowIds={{}}
                            totalCount={value?.generateWoData.length}
                          />
                        )}
                      </div>
                      {generateWoDeliveries &&
                        tabKey === 'generateWO' &&
                        !isEmpty(generateWoDeliveries) && (
                          <Button
                            className="generateWOButton"
                            disabled={
                              generateWoDeliveries.length === 0 ||
                              !generateWoDeliveries.every(
                                (delivery) => delivery?.deliveryStatus === 'Allocated'
                              )
                            }
                            onClick={handleGenerateWoAgainstDeliveries}>
                            Generate Wo against deliveries
                          </Button>
                        )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        ))}
    </>
  );
}
