import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listTitle: { width: '35%', height: '150px' },
  img: {
    maxWidth: '100%',
    borderRadius: '20px',
    padding: '5px',
    height: '100%',
  },
  tileBar: {
    background: 'transparent',
  },
  icon: { backgroundColor: 'white', opacity: '80%', margin: '5px' },
  takePicture: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    border: 'dotted',
    borderColor: '#005dff',
    borderWidth: '1px',
    marginTop: '5px',
    marginBottom: '15px',
  },
  headerText: {
    lineHeight: '12px',
  },
}));
