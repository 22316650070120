import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    bottom: 73,
    width: '95%',
    position: 'absolute',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  span: { color: '#8797ad', paddingTop: '4px' },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    borderRadius: '50%',
    backgroundColor: '#33ab2e',
    backgroundImage: 'linear-gradient(90deg, #33ab2e,#33ab2e)',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  horizontalDivider: {
    backgroundColor: '#fafafa',
    marginLeft: -20,
    marginRight: -20,
    alignSelf: 'stretch',
    marginTop: 18,
  },
  verticalDivider: { backgroundColor: '#fafafa' },
  buttonGroup: { width: '111.4%', marginLeft: -20, display: 'flex' },
  closeButton: { width: '100%' },
  selectButton: { backgroundColor: '#eaf7e9', color: '#33ab2e', borderBottomRightRadius: 20 },
}));
