import React, { useEffect } from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormField from '../../../../shared/Components/_App/FormField';
import Section from '../../../../shared/Components/_App/Section';
import { isEmpty } from 'lodash';
const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: 'blue',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
}));

const DeliveryDriverForm = (props) => {
  const classes = styles();
  const { driverForm, transporterId, transporterValue } = { ...props };

  return (
    <Box>
      <Section>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField
              label="DRIVER NAME"
              value={driverForm?.values?.name}
              error={!isEmpty(driverForm.errors?.name)}
              isValid={!driverForm.errors?.name && driverForm.dirty}
              onChange={driverForm.handleChange('name')}
              onBlur={driverForm.handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="MOBILE NUMBER"
              value={driverForm.values.phoneNumber}
              error={!isEmpty(driverForm.errors?.phoneNumber)}
              isValid={!driverForm.errors?.phoneNumber && driverForm.dirty}
              onChange={driverForm.handleChange('phoneNumber')}
              onBlur={driverForm.handleBlur('phoneNumber')}
            />
          </Grid>

          <Grid item xs={12}>
            <FormField
              disabled
              label="TRANSPORTER"
              value={transporterValue?.find((e) => e.id === transporterId)?.name}
            />
          </Grid>
        </Grid>
      </Section>
      <Button
        disabled={!driverForm.isValid || !driverForm.dirty}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => driverForm.handleSubmit()}>
        {' '}
        Save & Select
      </Button>
    </Box>
  );
};

export default DeliveryDriverForm;
