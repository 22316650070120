import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const PromptModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      className="modal-small"
      size={props.size ? props.size : 'sm'}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{props?.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.text}</p>
        <p>{props.subText}</p>
      </Modal.Body>

      {!props.hideFooter && (
        <Modal.Footer>
          {!props.hideDismissBtn && (
            <Button variant="secondary" onClick={props.onDismiss}>
              {props?.dismissBtnText ? props?.dismissBtnText : 'No'}
            </Button>
          )}
          {!props.hideSuccessBtn && (
            <Button variant="primary" onClick={props.onSuccess}>
              {props?.successBtnText ? props?.successBtnText : 'Yes'}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default PromptModal;
