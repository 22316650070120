import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'font-medium',
    letterSpacing: 1.8,
    fontSize: '0.84rem',
    lineHeight: '1.5rem',
  },
}));
const FormLabel = (props) => {
  const { label, size } = { ...props };
  const classes = useStyles();
  return (
    <Typography
      style={size === 'small' ? { fontSize: '0.75rem' } : null}
      color={'secondary'}
      className={classes.label}>
      {label?.toUpperCase()}
    </Typography>
  );
};

export default FormLabel;
