import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyle } from './WoStateListPage.app.styles';
import { useRegionsMap, useAccount } from '../../../hooks';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { Container } from '@material-ui/core';
import { groupBy, isEmpty, sumBy } from 'lodash';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import PageView from '../../../shared/Components/_App/PageView';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const WoStateListPage = () => {
  const classes = useStyle();
  const history = useHistory();
  const regionsMap = useRegionsMap();
  const [stateName, setStateName] = useState();
  const [workOrders, setWorkOrders] = useState([]);
  const [masterWorkOrders, setMasterWorkOrders] = useState([]);
  const [fetchingWorkOrders, setFetchingWorkOrders] = useState();
  const { myCustomerId } = useAccount();
  const { selectedFinancialYear } = useFinancialYear();
  const [filter, setFilter] = useState({
    size: 500,
    page: 0,
    targetFromDate: selectedFinancialYear?.fromDate,
    targetToDate: selectedFinancialYear?.toDate,
    itemIds: appMaterialItems,
    'isNeeded.equals': true,
    'toCustomerId.equals': myCustomerId,
  });

  useEffect(() => {
    getAllWorkOrders({ ...filter });
  }, [filter]);

  const modifyWorkOrderData = (woData) => {
    let setArray = [];

    let woStateGrouping = groupBy(woData, function (value) {
      return value?.stateId;
    });
    Object.keys(woStateGrouping).map((list) => {
      const childs = woStateGrouping[list];
      if (list !== 'null') {
        setArray.push({
          stateId: list,
          stateName: regionsMap[list]?.name,
          locationsCount: childs.length,
          workOrderCount: childs.length,
          qty: sumBy(childs, 'qty') - sumBy(childs, 'fulfilledQty'),
        });
      }
      setWorkOrders(setArray);
      setMasterWorkOrders(setArray);
    });
  };

  const getAllWorkOrders = async (params) => {
    setFetchingWorkOrders(true);
    try {
      const response = await WorkOrderService.fetchWorkOrderItems(params);
      if (response?.data) {
        modifyWorkOrderData(response?.data);
        setFetchingWorkOrders(false);
      }
    } catch (e) {
      setFetchingWorkOrders(false);
    }
  };

  const searchFilterFunction = (text) => {
    if (text?.length >= 3) {
      const newData = masterWorkOrders.filter(function (state) {
        const itemData = state.stateName ? state.stateName.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setWorkOrders(newData);
      setStateName(text);
    } else {
      setWorkOrders(masterWorkOrders);
      setStateName(text);
    }
  };
  return (
    <Container disableGutters>
      <PageView loading={fetchingWorkOrders}>
        <Paper component="form" className={classes.root} elevation={0}>
          <InputBase
            className={classes.input}
            placeholder="Search with state name"
            inputProps={{ 'aria-label': 'search state name' }}
            onChange={(e) => searchFilterFunction(e.target.value)}
            value={stateName}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => setFilter({ ...filter, 'stateId.equals': stateName })}>
            <SearchIcon />
          </IconButton>
        </Paper>
        <List className={classes.demo}>
          <Grid container>
            {workOrders?.length === 0 ? (
              <EmptyView
                hasNoData={isEmpty(workOrders)}
                className={'p-5'}
                message={'No data for selected criteria'}
              />
            ) : (
              workOrders.map((e, idx) => (
                <Grid item xs={12} md={6} sm={6} key={idx}>
                  <ListItem onClick={() => history.push('/open-wo', e)}>
                    <ListItemAvatar>
                      <Avatar className={classes.locationAvtar}>
                        <LocationOnIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={e?.stateName || 'NA'}
                      secondary={`${e?.locationsCount || 0} locations`}
                    />
                    <ListItemText
                      className={classes.lastCol}
                      primary={`${e?.workOrderCount || 0} PO's`}
                      secondary={`${e?.qty / 1000 || 0} MT`}
                    />
                    <ListItemSecondaryAction>
                      <ArrowForwardIosIcon fontSize={'small'} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Grid>
              ))
            )}
          </Grid>
        </List>
      </PageView>
    </Container>
  );
};

export default WoStateListPage;
