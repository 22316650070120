import React, { useEffect } from 'react';
import { Avatar, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Paper, Typography } from '@material-ui/core';
import { useStyles } from './DocListModal.styles';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import LoadingButton from '../../../../../shared/Components/_App/LoadingButton/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import RejectedDocList from './RejectedDocList';
import { DOCUMENT_STATUSES } from '../../../../../shared/Constants/Constants';
import { isEmpty } from 'lodash';

const GenerateDocModal = ({
  open,
  onClose,
  docConfigList,
  delivery,
  setVisible,
  doGenerateEwaybillEInvoice,
  setGenerateDocModal,
  showModal,
}) => {
  const classes = useStyles();
  const isDocsApprovedRejectedView = () => {
    const pickupDocs = delivery?.deliveryDocuments?.filter((doc) =>
      doc.document?.type?.includes('PICKUP_')
    );
    const inReviewDocs = pickupDocs?.find((doc) => doc.status === DOCUMENT_STATUSES.IN_REVIEW);
    if (isEmpty(inReviewDocs)) {
      const rejectedList = pickupDocs?.filter((doc) => doc.status === DOCUMENT_STATUSES.REJECTED);
      if (rejectedList?.length === 0) {
        return (
          <div className="ml-3 pt-5 pb-3" style={{ textAlign: 'center' }}>
            <Avatar className={classes.approveAvtar}>
              <FontAwesomeIcon size="sm" color="green" icon={faCheck} />
            </Avatar>
            <Typography variant="h4" className="mb-3 mt-3">
              YAY! You have approved all the documents for this delivery..!
            </Typography>
          </div>
        );
      } else {
        return (
          <div className="ml-3 pt-5 pb-3" style={{ textAlign: 'center' }}>
            <Paper elevation={0} className={classes.generateDocPaperRejected}>
              <div style={{ display: 'flex' }}>
                <Typography variant="subtitle1">
                  {pickupDocs?.length - rejectedList?.length} out of {pickupDocs?.length} documents
                  are approved !
                </Typography>
                <Avatar className={classes.alertAvtar}>
                  <FontAwesomeIcon size="sm" color="yellow" icon={faExclamationTriangle} />
                </Avatar>
              </div>

              <RejectedDocList
                docConfigList={docConfigList}
                deliveryDocuments={delivery?.deliveryDocuments.filter(
                  (e) => e.status === 'REJECTED'
                )}
                delivery={delivery}
              />
            </Paper>
          </div>
        );
      }
    } else {
      return (
        <div className="ml-3 pt-5 pb-3" style={{ textAlign: 'center' }}>
          <Typography variant="h4" className="mb-3 mt-3">
            Some documents are still in review state.
          </Typography>
        </div>
      );
    }
  };

  const showHideGenerateInvoiceModal = () => {
    const isInreview = delivery?.deliveryDocuments?.find(
      (doc) => doc.document?.type?.includes('PICKUP_') && doc.status === DOCUMENT_STATUSES.IN_REVIEW
    );
    if (!isEmpty(isInreview)) {
      return true;
    } else {
      return false;
    }
  };

  const isPickupWbPresent = delivery?.deliveryDocuments?.some(
    (doc) => doc.document?.type?.trim() === 'PICKUP_WB'
  );
  const isPickupVehicleDocPresent = delivery?.deliveryDocuments?.some(
    (doc) => doc.document?.type?.trim() === 'PICKUP_PHOTOS'
  );

  const isDocewayeinvoicePresent = () => {
    const pickUpewaybillEinvoice = delivery?.deliveryDocuments?.find(
      (doc) =>
        doc.document.type.includes('PICKUP_INVOICE') ||
        doc.document.type.includes('PICKUP_E_WAY_BILL')
    );
    if (!isEmpty(pickUpewaybillEinvoice)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (showModal) {
      if (!showHideGenerateInvoiceModal() && !isDocewayeinvoicePresent()) {
        setGenerateDocModal(true);
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [showModal]);

  const doGoBack = () => {
    onClose();
    setVisible(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.generateDoc}>
          {isDocsApprovedRejectedView()}
          <LoadingButton
            className={classes.submitButton}
            disabled={
              showHideGenerateInvoiceModal() || !isPickupWbPresent || !isPickupVehicleDocPresent
            }
            loadingText={'Loading..'}
            onClick={() => doGenerateEwaybillEInvoice()}>
            Generate Invoice
          </LoadingButton>
          <Button className={classes.skipButton} onClick={() => doGoBack()}>
            Skip & Go back
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default GenerateDocModal;
