import { serviceProviderAPI } from './serviceProviderAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchServiceProviders = createAsyncThunk(
  'serviceProvider/fetchServiceProviders',
  async () => {
    const response = await serviceProviderAPI.fetchServiceProviders();
    return response.data;
  }
);
