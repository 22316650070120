import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { API_BASE_URL } from '../../../../shared/Config/Api';
import AppModal from '../../../../shared/Components/_App/AppModal';
import generateInvoiceSuccess from '../../../../assets/img/_App/invoicegenerate.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import APIEndpoints from '../../../../shared/Config/ApiEndpoints.const';
import { usePluginImages } from '../../../../shared/Utils/UsePluginImages';
import JwtUtils from '../../../../shared/Utils/jwt.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
  heading: {
    color: '#2e374d',
    fontFamily: 'font-semibold',
  },
  buttonstyl: {
    backgroundColor: '#f7faff',
    color: '#2e374d',
    border: '1px dotted #e0e0e0',
    width: '69%',
    fontSize: '15px',
  },
  linkstyl: {
    color: '#33ab2e',
    fontSize: '15px',
  },
}));

const DeliveryInvoiceDocGeneratedSuccessView = (props) => {
  const { delivery } = { ...props };
  const classes = useStyles();
  const [showDocModal, setShowDocModal] = useState(false);
  const [docUrl, setDocUrl] = useState();

  /**
   * This api call is used to view invoice
   * @return {Promise<*>}
   */
  const doViewInvoice = () => {
    axios({
      url: API_BASE_URL + APIEndpoints.VIEW_INVOICE,
      method: 'GET',
      params: {
        id: delivery.id,
        isImage: true,
      },
      headers: {
        Authorization: 'Bearer ' + JwtUtils().getToken(),
      },
      responseType: 'blob',
    }).then((res) => {
      //const file = new Blob([res.data], { type: 'application/pdf' });
      // const fileURL = URL.createObjectURL(file);
      //window.open(url);

      const blob = new Blob([res.data], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);
      setDocUrl(url);
      setShowDocModal(true);
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper}>
            <img
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: '15px',
              }}
              src={generateInvoiceSuccess}
            />
            <Typography variant="h4" className={classes.heading}>
              Invoice has been Generated
            </Typography>
            <Grid>
              <Button
                variant="contained"
                size="large"
                className={`${classes.buttonstyl} mt-3`}
                onClick={() => doViewInvoice()}
                startIcon={
                  <FontAwesomeIcon
                    className={classes.approve}
                    color="green"
                    size="sm"
                    icon={faCheckCircle}
                  />
                }>
                View Invoice{' '}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <AppModal title={'Invoice'} visible={showDocModal} setVisible={setShowDocModal}>
        <>
          <TransformWrapper
            className="h-100"
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <TransformComponent>
                <div style={{ height: '80vh', width: '100vw' }}>
                  <img
                    className="h-100"
                    width="100%"
                    height="100%"
                    crossOrigin="anonymous"
                    src={docUrl}
                  />
                </div>
              </TransformComponent>
            )}
          </TransformWrapper>
        </>
        {/* <iframe type="application/pdf" src={docUrl} width="100%" height="100%"></iframe>*/}
      </AppModal>
    </div>
  );
};

export default DeliveryInvoiceDocGeneratedSuccessView;
