import React from 'react';
import QuantityOverviewCard from './QuantityOverviewCard';
import { Col, Row } from 'react-bootstrap';
import './QuantityOverview.scss';
import { AccountSelectors } from '../../../state/Account';
import { useAccount } from '../../../hooks/useAccount';

const QuantityOverview = (props) => {
  const account = useAccount();
  const isUserServiceProvider = AccountSelectors.isUserServiceProvider(account);
  const {
    currentOrderQtyReceived,
    currentOrderQtyGiven,
    deliveredQtyReceived,
    acceptedReceived,
    inReviewReceived,
    deliveredQtyGiven,
    orderQtyReceived,
    pendingReceived,
    unallocatedReceived,
    disputedReceived,
    acceptedGiven,
    inReviewGiven,
    disputedGiven,
  } = props.data || {};
  const { browseNodeIds, itemIds } = props?.filters;
  const pendingQty = orderQtyReceived - (acceptedGiven + inReviewGiven + disputedGiven);

  const bulidPath = (status) => {
    let currentUrlParam = new URLSearchParams();
    browseNodeIds !== null &&
      itemIds === null &&
      currentUrlParam.set('browseNodeIds', browseNodeIds);
    itemIds !== null && currentUrlParam.set('itemIds', itemIds);
    status !== '' && currentUrlParam.set('status', status);
    return currentUrlParam;
  };
  return (
    <div className="QuantityOverview">
      {!isUserServiceProvider ? (
        <Row>
          <Col>
            <QuantityOverviewCard
              title={'Target'}
              value={currentOrderQtyGiven || 0}
              path={'target'}></QuantityOverviewCard>
          </Col>
          <Col>
            <QuantityOverviewCard
              title={'Achieved'}
              value={acceptedReceived || 0}
              path={`deliveries?${bulidPath('APPROVED')}`}
              percent={(acceptedReceived / currentOrderQtyGiven) * 100 || 0}></QuantityOverviewCard>
          </Col>
          <Col>
            <QuantityOverviewCard
              title={'For Review'}
              path={`deliveries?${bulidPath('IN_REVIEW')}`}
              value={inReviewReceived || 0}></QuantityOverviewCard>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <QuantityOverviewCard
              title={'Target Received'}
              value={currentOrderQtyReceived || 0}
              path={`work-orders-incoming?${bulidPath('')}`}></QuantityOverviewCard>
          </Col>
          <Col>
            <QuantityOverviewCard
              title={'Client Pending fullfilment'}
              path={`work-orders-incoming?${bulidPath('')}`}
              value={currentOrderQtyReceived - deliveredQtyGiven || 0}
              percent={
                (deliveredQtyGiven / currentOrderQtyReceived) * 100 || 0
              }></QuantityOverviewCard>
          </Col>
          {unallocatedReceived > 0 && (
            <Col>
              <QuantityOverviewCard
                title={'Available for allocation'}
                path={`deliveries?${bulidPath('')}`}
                value={unallocatedReceived || 0}></QuantityOverviewCard>
            </Col>
          )}
          {disputedReceived > 0 && (
            <Col>
              <QuantityOverviewCard
                title={'disputed by client'}
                path={`deliveries?${bulidPath('DISPUTED')}`}
                value={disputedReceived || 0}></QuantityOverviewCard>
            </Col>
          )}
          <Col>
            <QuantityOverviewCard
              title={'Received for review'}
              path={`deliveries?${bulidPath('IN_REVIEW')}`}
              value={inReviewReceived || 0}></QuantityOverviewCard>
          </Col>
        </Row>
      )}
    </div>
  );
};

QuantityOverview.propTypes = {};

export default QuantityOverview;
