import React from 'react';
import { Button, Card, Modal, Row } from 'react-bootstrap';
import './PlanningModule.scss';
import { useHistory } from 'react-router-dom';

export default function PlanningCloseWorkOrderCreationModal(props) {
  const {
    showCloseWorkOrderModal,
    handleCloseWorkOrderModal,
    handleCloseWorkOrder,
    closeWorkOrderLoading,
    workOrderId,
  } = props;
  const history = useHistory();

  return (
    <div>
      <Modal show={showCloseWorkOrderModal} onHide={handleCloseWorkOrderModal} centered size="md">
        <Modal.Body>
          <Card className="border-0">
            <Modal.Header closeButton className="border-0">
              <Modal.Title>
                <div className="modalHeader">
                  <p>WO-{workOrderId}</p>
                  <div className="headerBrandNameLine">|</div>
                  <p className="woCreationStyling">WO Creation</p>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <p style={{ fontSize: '18px', color: 'black' }}>
                  Are you sure you want to Close Work Order?
                </p>
                <p style={{ fontSize: '15px' }}>
                  <span style={{ fontSize: '18px', color: 'black' }}>Note: </span>Once the work
                  order is closed the service provider will not be able to assign any deliveries to
                  the work order
                </p>
              </Row>
            </Modal.Body>
            <Card.Footer className="bg-white cardFooter">
              <div className="buttonDivStyle">
                <Button
                  className="cancelButton"
                  variant="light"
                  onClick={handleCloseWorkOrderModal}
                  disabled={closeWorkOrderLoading}>
                  Do not Close
                </Button>
                <Button onClick={handleCloseWorkOrder} disabled={closeWorkOrderLoading}>
                  Confirm WO Cancellation
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
}
