import React from 'react';
import TickIcon from '../../../assets/img/tick_icon.svg';
import TargetIcon from '../../../assets/img/target_icon.svg';
import TargetEmptyIcon from '../../../assets/img/target_empty_icon.svg';

const Indicator = ({ icon, index }) => {
  const OBJ = {
    0: 'File Extracted Successfully',
    1: 'Organise Folder Structures',
    2: 'Uploaded to Database',
  };

  return (
    <div className="stepper-indicator">
      <img
        className="stepper-image"
        style={{ zIndex: '2', height: '2rem', width: '2rem' }}
        src={icon}
        alt="indicator_status_icon"
      />
      <p
        className="font-bold"
        style={{
          fontSize: '1.15rem',
          textAlign: 'center',
          color: icon === TargetIcon ? '#175CD3' : '#344054',
        }}>
        {OBJ[index]}
      </p>
    </div>
  );
};

function StepperStatus({ actionDel }) {
  const status = actionDel.toLowerCase();

  const getStatusCode = () => {
    switch (status) {
      case 'uploading':
        return 1;
      case 'uploaded':
        return 2;
      case 'extracted':
        return 3;
      case 'initiated':
        return 3;
      case 'in_process':
        return 4;
      default:
        return 0;
    }
  };

  const CODE = getStatusCode();

  const OBJ = {
    1: [TargetIcon, TargetEmptyIcon, TargetEmptyIcon],
    2: [TargetIcon, TargetEmptyIcon, TargetEmptyIcon],
    3: [TickIcon, TargetIcon, TargetEmptyIcon],
    4: [TickIcon, TickIcon, TargetIcon],
  };

  return (
    <div className="stepper w-75 mx-auto">
      <div className="stepper-wrapper">
        <Indicator index={0} icon={OBJ[CODE][0]} />
        <div
          className="stepper-connector-1"
          data-active={OBJ[CODE][1] === TargetIcon || OBJ[CODE][1] === TickIcon}
        />
        <Indicator index={1} icon={OBJ[CODE][1]} />
        <div className="stepper-connector-2" data-active={OBJ[CODE][2] === TargetIcon} />
        <Indicator index={2} icon={OBJ[CODE][2]} />
      </div>
    </div>
  );
}

export default StepperStatus;
