import React, { useRef } from 'react';
import JSZip from 'jszip';
import { sumBy, throttle } from 'lodash';

export function usePluginImages() {
  const MAX_CONCURRENT_DOWNLOADS = 10;
  const [progress, setProgress] = React.useState(null);
  const throttledSetProgress = React.useCallback(throttle(setProgress, 500), [setProgress]);
  const abortControllerRef = useRef(new AbortController());

  const workerRef = useRef();

  async function pluginImages(docx) {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    const doc = await JSZip.loadAsync(docx);
    const files = doc.folder('word/media').filter(() => true);
    const images = (
      await Promise.all(
        files.map(async (file) => {
          const text = await file.async('string');
          if (!text.startsWith('http')) {
            return;
          }
          let [url, pageNo, fileSize] = text.split('|');
          return { url, pageNo, fileSize: parseInt(fileSize), name: file.name };
        })
      )
    ).filter((file) => !!file);
    const totalSize = sumBy(images, 'fileSize');
    let downloadedSize = 0;
    setProgress({ totalSize, downloadedSize });
    console.log('docx.size = ', docx.size);
    console.log('totalSize = ', totalSize);

    async function downloadDocs() {
      while (true) {
        const file = images.pop();
        if (!file) return;
        let { url, pageNo, name } = file;
        let opts = null;
        let blob1 = null;
        pageNo = parseInt(pageNo) + 1;
        try {
          if (name.includes('.emf') || url.includes('.pdf')) {
            // Loaded via <script> tag, create shortcut to access PDF.js exports.
            let pdfjsLib = window['pdfjs-dist/build/pdf'];
            // The workerSrc property shall be specified.
            pdfjsLib.GlobalWorkerOptions.workerSrc =
              '//mozilla.github.io/pdf.js/build/pdf.worker.js';
            //https://github.com/mozilla/pdf.js/issues/7333#issuecomment-219532850
            const pdfLoadingTask = pdfjsLib.getDocument({ url, worker: workerRef.current });
            workerRef.current = workerRef.current || pdfLoadingTask._worker;
            const pdfFile = await pdfLoadingTask.promise;
            if (pdfFile?._pdfInfo?.numPages >= pageNo) blob1 = await getPage(pageNo, pdfFile);
          } else {
            const response = await fetch(url, {
              ...opts,
              signal: abortControllerRef.current.signal,
            });
            blob1 = await response.blob();
          }
          doc.file(file.name, blob1, { binary: true });
          downloadedSize += file.fileSize;
          throttledSetProgress({ totalSize, downloadedSize });
        } catch (e) {
          console.log(`Failed to download ${url}, err = ${e}`);
        }
      }
    }

    // read pdf page and convert to blob
    const getPage = (num, pdf) => {
      return new Promise(async (resolve, reject) => {
        const page = await pdf.getPage(num);
        const scale = '1.5';
        const viewport = page.getViewport({ scale: scale });
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderPdf = page.render({ canvasContext: canvasContext, viewport: viewport });
        renderPdf.promise.then(function () {
          canvas.toBlob(
            function (blob) {
              resolve(blob);
            },
            'image/jpeg',
            0.43
          );
        });
      });
    };

    const promises = [];
    for (let i = 0; i < MAX_CONCURRENT_DOWNLOADS; i++) {
      const promise = downloadDocs();
      promises.push(promise);
    }
    await Promise.all(promises);
    throttledSetProgress(null);
    throttledSetProgress.flush();
    if (abortControllerRef.current.signal.aborted) return null;
    return await doc.generateAsync({ type: 'blob' });
  }

  return {
    pluginImages,
    progress,
    abort: () => abortControllerRef.current.abort(),
  };
}
