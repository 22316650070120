import React, { Component } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Card, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import tiicksign from '../../assets/img/ticksign.png';
import './Blank.scss';
import brand from '../../assets/img/brand.png';
import serviceprovider from '../../assets/img/service-provider.png';
import back from '../../assets/img/back.png';
import upload from '../../assets/img/upload.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Jumbotron from 'react-bootstrap/Jumbotron';
import attachments from '../../assets/img/attachments.svg';
import file from '../../assets/img/file.svg';

import deleteicon from '../../assets/img/delete.svg';
import floaticon from '../../assets/img/float-label.svg';

const Blank = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="Blank">
        <Card className="main-card">
          <Card.Header className="bg-white border-0  py-0">
            <Row className="padding-aligned align-self-center">
              <Col className="d-flex align-self-center">
                <NavLink to={'/dashboard'}>
                  <Button className="back-btn">
                    <FontAwesomeIcon className={''} icon={faChevronLeft} />
                  </Button>
                </NavLink>
                <h4>Nav Back</h4>
              </Col>

              <Col className="text-right" md={8}>
                <ButtonToolbar className="float-right">
                  <Button
                    className={'text-dark font-regular'}
                    onClick={() => {
                      history.goBack();
                    }}
                    variant={'outline-secondary'}>
                    Cancel
                  </Button>

                  <Button
                    onClick={() => {
                      console.log('goNext');
                    }}>
                    Next
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="border-top">
            {/* <p>  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
              ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.</p>
            <Button>Go somewhere</Button> */}
            <DocumentHistory />
            <FloatLabel />
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <h5>Buttons</h5>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <h1>Text Style h1 </h1>
                <h2>Text Style h2 </h2>
                <h3>Text Style h3 </h3>
                <h4>Text Style h4 </h4>
                <h5>Text Style h5 </h5>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Form Elements</Card.Header>
          <Card.Body>
            <Row>
              <Col className="py-3"></Col>
            </Row>
            <p className="card-subtitle mb-2">Back button</p>
            <Row>
              <Col className="d-flex align-self-center" md={4}>
                <Button
                  className="back-btn"
                  onClick={() => {
                    history.goBack();
                  }}>
                  <FontAwesomeIcon className={''} icon={faChevronLeft} />
                </Button>
                <h4>Click Back</h4>
              </Col>
              <Col className="d-flex align-self-center" md={4}>
                <NavLink to={'/dashboard'}>
                  <Button className="back-btn">
                    <FontAwesomeIcon className={''} icon={faChevronLeft} />
                  </Button>
                </NavLink>
                <h4>Nav Back</h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Buttons</Card.Header>
          <Card.Body>
            <Row>
              <Col className="py-3">
                <Button variant="primary px-4">Primary</Button>
                <br />
                <br />
                <Button>Download Deliveries</Button>
                <br />
                <br />
                <Button variant="success">+ Success</Button>
                <br />
                <br />
                <Button variant="danger">Danger</Button>
                <br />
                <br />
                <Button variant="info">Info Reject All</Button>
                <br />
                <br />
                <Button variant="over-due">Over due</Button>
              </Col>
            </Row>
            <p className="card-subtitle mb-2">Back button</p>
            <Row>
              <Col className="d-flex align-self-center" md={4}>
                <Button
                  className="back-btn"
                  onClick={() => {
                    history.goBack();
                  }}>
                  <FontAwesomeIcon className={''} icon={faChevronLeft} />
                </Button>
                <h4>Click Back</h4>
              </Col>
              <Col className="d-flex align-self-center" md={4}>
                <NavLink to={'/dashboard'}>
                  <Button className="back-btn">
                    <FontAwesomeIcon className={''} icon={faChevronLeft} />
                  </Button>
                </NavLink>
                <h4>Nav Back</h4>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Dropdowns</Card.Header>
          <Card.Body>
            <Row>
              <Col className="py-3">
                <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Tab</Card.Header>
          <Card.Body>
            <Row>
              <Col className="py-3"></Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Modal section</Card.Header>
          <Card.Body>
            <Row>
              <Col className="py-3">
                <Modals />
                <br />
                <br />
                <Partnermodal />
                <br />
                <br />
                <Registermodal />
                <br />
                <br />
                <Agreementmodal />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Tabsection />
        <br />
        <h6>tab-ver-3</h6>
        <Tabtwo />
        <br />
        <br />
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

class Tabsection extends Component {
  render() {
    return (
      <div>
        <h4>Tab section</h4>
        <Tabs
          className="tab-two"
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example">
          <Tab eventKey="home" title="Work orders" className="pt-4 p-4">
            one What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell
            within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to
            win! What wretched errors hath my heart committed, Whilst it hath thought itself so
            blessed never! How have mine eyes out of their spheres been fitted, In the distraction
            of this madding fever! O benefit of ill! now I find true That better is, by evil still
            made better;
          </Tab>
          <Tab eventKey="profile" title="Allocated work orders" className="pt-4 p-4">
            two What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell
            within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to
            win! What wretched errors hath my heart committed, Whilst it hath thought itself so
            blessed never! How have mine eyes out of their spheres been fitted, In the distraction
            of this madding fever! O benefit of ill! now I find true That better is, by evil still
            made better;
          </Tab>
          {/* <Tab eventKey="contact" title="Contact" className="pt-4" >
          three What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to win! What wretched errors hath my heart committed, Whilst it hath thought itself so blessed never! How have mine eyes out of their spheres been fitted, In the distraction of this madding fever! O benefit of ill! now I find true That better is, by evil still made better;
        </Tab> */}
        </Tabs>
      </div>
    );
  }
}
//

class Tabtwo extends Component {
  render() {
    return (
      <div className="tab-ver-3">
        <Tabs className="tab-v3" defaultActiveKey="home" transition={false} id="noanim-tab-example">
          <Tab eventKey="home" title="Recylable plastic" className="pt-4 p-4">
            one What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell
            within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to
            win! What wretched errors hath my heart committed, Whilst it hath thought itself so
            blessed never! How have mine eyes out of their spheres been fitted, In the distraction
            of this madding fever! O benefit of ill! now I find true That better is, by evil still
            made better;
          </Tab>
          <Tab eventKey="profile" title="Non - Recylable plastic" className="pt-4 p-4">
            two What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell
            within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to
            win! What wretched errors hath my heart committed, Whilst it hath thought itself so
            blessed never! How have mine eyes out of their spheres been fitted, In the distraction
            of this madding fever! O benefit of ill! now I find true That better is, by evil still
            made better;
          </Tab>
          <Tab eventKey="contact" title="E-waste" className="pt-4 p-4">
            three What potions have I drunk of Siren tears, Distill'd from limbecks foul as hell
            within, Applying fears to hopes, and hopes to fears, Still losing when I saw myself to
            win! What wretched errors hath my heart committed, Whilst it hath thought itself so
            blessed never! How have mine eyes out of their spheres been fitted, In the distraction
            of this madding fever! O benefit of ill! now I find true That better is, by evil still
            made better;
          </Tab>
        </Tabs>
      </div>
    );
  }
}

//
const Modals = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="primary px-4" onClick={showModal}>
        Target Modal
      </Button>

      <Modal show={isOpen} onHide={hideModal} size="sm" className="modal-small ">
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title id="example-modal-sizes-title-sm "></Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-5">
          <img alt="tick_icon" src={tiicksign} />
          <p class="pt-5 mb-2">
            Your have successfully set <br />
            targets by states.
          </p>
          <h5 class="py-4 ">
            Would you like to define service providers who help you meet these targets?
          </h5>
          <div>
            <Button variant="primary  px-5 py-2">No</Button>
            <Button variant="primary px-5 py-2" className="small-mdl-btn">
              Yes
            </Button>
            <br />
            <br />
            <a className="pt-5 blue-color" href="#">
              Skip districts and define partners
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Partnermodal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="primary px-4" onClick={showModal}>
        Partner Modal
      </Button>

      <Modal show={isOpen} onHide={hideModal} size="sm" className="modal-small">
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title id="example-modal-sizes-title-sm "></Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-center mt-5">
          <img alt="tick_icon" src={tiicksign} />
          <p class="py-2 success-service">
            Service provider <br />
            onboarded successfully.
          </p>
          <Button variant="primary px-5 mt-3" className="fw-600">
            Create Work Order
          </Button>
          <div>
            <h5 className="py-4 or">OR</h5>
            <a className="blue-color  add-another" href="#">
              Add another <br /> service provider
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Registermodal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="primary px-4" onClick={showModal}>
        Register Modal
      </Button>

      <Modal show={isOpen} onHide={hideModal} size="sm" className="register-modal">
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title id="example-modal-sizes-title-sm" className=" p-4">
            <h3>Add Service Provider</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="  modal-register-page">
          <Card className=" mx-auto border-0">
            <Card.Body className="sign-up pt-0">
              <div className="">
                <label>Register as</label>
                <Form>
                  <Form.Group className="mb-4">
                    <div className="d-flex">
                      <div className="position-relative text-center">
                        <div className="check-bg ">
                          <img alt="brand_icon" src={brand} />
                          {['checkbox'].map((type) => (
                            <div key={`custom-${type}`} className="mb-3 check-box-position">
                              <Form.Check
                                custom
                                checked
                                type={type}
                                id={`custom-${type}-1`}
                                label={` `}
                              />
                            </div>
                          ))}
                        </div>
                        <label>Brand</label>
                      </div>
                      <div className="ml-4 position-relative text-center">
                        <div className="check-bg">
                          <img alt="serviceProvider_image" src={serviceprovider} />
                        </div>
                        <label>Service Privider</label>
                        {['checkbox'].map((type) => (
                          <div key={`custom-${type}`} className="mb-3 check-box-position">
                            <Form.Check
                              custom
                              inline
                              label=""
                              type={type}
                              id={`custom-inline-${type}-2`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="mb-5">
                    <Form.Label className="mb-0">Company PAN number</Form.Label>
                    <Form.Control className="p-0" type="text" value="123456789" required />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="mb-5">
                    <Form.Label className="mb-0">Name</Form.Label>
                    <Form.Control className="p-0" type="text" placeholder="Ex: Smarthan" required />
                  </Form.Group>
                  <Button variant="primary float-right px-4 my-4 login">Next</Button>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Agreementmodal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="primary px-4" onClick={showModal}>
        Agreement Modal
      </Button>

      <Modal show={isOpen} onHide={hideModal} size="sm" className="register-modal">
        <Modal.Header closeButton className="border-bottom-0">
          <img alt="back_icon" src={back} />
        </Modal.Header>
        <Modal.Body className="  modal-register-page px-5 pt-0">
          <h5 className="heading">Agreement</h5>
          <Card className=" mx-auto mb-4 mt-3 border-0">
            <Card.Body className="sign-up p-0">
              <div>
                <div className="dashed-border">
                  <div className="text-center py-5">
                    <img alt="ipload_icon" src={upload} />
                    <h5 className="aggrement-text">Upload your agreement here</h5>
                    <p class="file-size">
                      Only one page .pdf files <br />
                      allowed, max 10Mb
                    </p>
                  </div>
                </div>
                <div class="py-4">
                  <Form.Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="mb-0">Start Date</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="10-01-2020"
                        className="p-0 datepicker"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="mb-0">End Date</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="20-01-2020"
                        className="p-0 datepicker"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label className="mb-0">State</Form.Label>
                      <Form.Control as="select" className="p-0">
                        <option>Choose...</option>
                        <option>...</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label className="mb-0">Invoicing cycle</Form.Label>
                      <Form.Control type="text" placeholder="3 months" className="p-0" />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="mb-1 pl-2">
                    <Form.Group column="sm" md={6} controlId="formGridEmail">
                      <Form.Label className="mb-0">Payment term</Form.Label>
                      <Form.Control type="text" className="p-0" />
                      <p className="invoice">
                        days after raising the
                        <br /> invoice
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Button variant="primary  px-5 py-2 float-right">Continue</Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
//DocumentHistoryCommentBox
class DocumentHistory extends Component {
  render() {
    return (
      <div>
        <div className="col-md-5 pl-0">
          <div className="documenthistory p-4 mb-4">
            <Modal.Header closeButton className="border-bottom-0 pt-0">
              <Modal.Title id="example-modal-sizes-title-sm "></Modal.Title>
            </Modal.Header>
            <DocumentHistoryComment />
            <DocumentHistoryComment />
            <DocumentHistoryCommentBox />
            <DocumentHistoryCommentBox />
          </div>
          <div className="documenthistory p-4 mb-4">
            <Modal.Header closeButton className="border-bottom-0 pt-0">
              <Modal.Title id="example-modal-sizes-title-sm "></Modal.Title>
            </Modal.Header>

            <DocumentHistoryComment />
            <DocumentHistoryFileTextArea />
          </div>
        </div>
      </div>
    );
  }
}

class DocumentHistoryComment extends Component {
  render() {
    return (
      <div className="documenthistory-comment mb-3">
        <div className="row">
          <div className=" documentheading col-8 p-0">
            <div className="ml-4">
              <div className="title">
                <img alt="file_image" src={file} width="16" height="26" className="pr-1" />
                Way Bridge Document <span className="rejected">-Rejected</span>
              </div>
              <span className="view-docu">View Document</span>
            </div>
          </div>
          <div className=" author col-4 p-0">
            <div className="ml-4">
              <label className="mb-0">by Samarthanam</label>
              <p>30 AUG, 2019 4:30 PM</p>
            </div>
          </div>
        </div>
        <Jumbotron className="p-4 mb-3 jumbo">
          <p className="mb-0">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the
          </p>
        </Jumbotron>
        <div className="text-right">
          <Button variant="primary" className=" ">
            Reply
          </Button>
          <div className="border-dashed my-3"></div>
        </div>
      </div>
    );
  }
}

class DocumentHistoryCommentBox extends Component {
  render() {
    return (
      <div className="documenthistory-comment-box">
        <Card className="p-0">
          <Card.Header className="px-4 p-3">
            <p className="mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
            <DocumentHistoryFile />
            <DocumentHistoryFile />
          </Card.Header>
          <Card.Body className="px-3 jumbo p-2">
            <div className="file-attachments">
              <img
                alt="attachment_image"
                className="img-fluid pr-2 attachments"
                src={attachments}
              />
              Attachments
            </div>
          </Card.Body>
        </Card>
        <div className=" d-flex pt-3 ">
          <div className="offset-7">
            {['checkbox'].map((type) => (
              <div key={`custom-${type}`} className="mb-0 align-self-center mt-2">
                <Form.Check custom type={type} id={`custom-${type}`} label={`Resolve `} />
              </div>
            ))}
          </div>
          <Button variant="primary" className="ml-3">
            Accept
          </Button>
        </div>
      </div>
    );
  }
}

class DocumentHistoryFile extends Component {
  render() {
    return (
      <div className="documenthistory-comment-file mb-3">
        <div className="doc-file p-2">
          <div className="d-flex">
            <img alt="back_icon" src={back} className="challan-doc" />
            <div className="challen-jpg col-md-8">
              Challana_document.jpg
              <div className="file-size">JPEG • 1.3MB</div>
            </div>
            <Button variant={'outline-secondary ml-2 btn-icon download-bg'} />
            <img alt="delete_icon" src={deleteicon} className="ml-3" />
          </div>
        </div>
      </div>
    );
  }
}

class DocumentHistoryFileTextArea extends Component {
  render() {
    return (
      <div className="documenthistory-comment-file-textarea mb-4">
        <Card className="p-0">
          <Card.Header className="p-0">
            <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0">
              <Form.Control as="textarea" rows="2" placeholder="Write your comment here" />
            </Form.Group>
          </Card.Header>
          <Card.Body className="px-3 jumbo py-1">
            <div className="file-attachments d-flex">
              <img alt="attachment_image" className="img-fluid attachments" src={attachments} />
              <Dropdown>
                <Dropdown.Toggle variant="success" className="attach-file-btn">
                  Attachments
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-icon">
                  <Dropdown.Item href="#/action-1" className="first-label">
                    Type of Document
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Way Bridge</Dropdown.Item>
                  <Dropdown.Item href="#/action-3"> E-Way Bill</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">LR</Dropdown.Item>
                  <Dropdown.Item href="#/action-3"> DC </Dropdown.Item>
                  <Dropdown.Item href="#/action-3"> DC Photos </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Body>
        </Card>
        <div></div>
      </div>
    );
  }
}

class FloatLabel extends Component {
  render() {
    return (
      <div className="float-label-section">
        <div className="float-icon">
          <img alt="float_icon" src={floaticon} width="20" />
        </div>
      </div>
    );
  }
}

export default Blank;
