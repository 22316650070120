import { deviceDetect } from 'react-device-detect';
import { DeviceUUID } from 'device-uuid';
import { useDispatch } from 'react-redux';
import { merge } from 'lodash';
import { updateDevice } from '../state/Account/accountThunks';

const appVersion = process.env.REACT_APP_VERSION;
export const useDevice = () => {
  const dispatch = useDispatch();
  const dinfo = deviceDetect();
  let runCount = localStorage.getItem('runCount') || '0';

  let device = {
    uuid: new DeviceUUID().get(),
    appVersion: appVersion,
    model: dinfo.osName,
    referrer: getReferral(),
    // session_count: null,
    runCount: runCount,
    manufacturer: dinfo.browserName,
    platform: 'Browser',
    serial: dinfo.osVersion,
    version: dinfo.browserFullVersion,
  };
  const sendDeviceInfo = (deviceInfo) => {
    device = merge(device, deviceInfo);
    dispatch(updateDevice(device));
  };
  function getReferral() {
    let url = '';
    let utm_source = getUrlParameter('utm_source');
    let utm_medium = getUrlParameter('utm_medium');
    let utm_campaign = getUrlParameter('utm_campaign');
    let utm_content = getUrlParameter('utm_content');
    let utm_term = getUrlParameter('utm_term');

    if (utm_source) {
      url += 'utm_source=' + utm_source;
      if (utm_medium) url += 'utm_medium=' + utm_medium;
      if (utm_campaign) url += 'utm_medium=' + utm_medium;
      if (utm_content) url += 'utm_medium=' + utm_medium;
      if (utm_term) url += 'utm_medium=' + utm_medium;
      return url;
    } else {
      return null;
    }
  }

  function getUrlParameter(sParam) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  }

  return { sendDeviceInfo, device };
};
