import { AccountAPI } from './accountAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const getAccount = createAsyncThunk('account/getAccount', async () => {
  const response = await AccountAPI.getAccount();
  return response.data;
});

export const updateDevice = createAsyncThunk('account/updateDevice', async (payload) => {
  const response = await AccountAPI.updateDevice(payload);
  return response.data;
});

export const fetchMyClients = createAsyncThunk('account/fetchMyClients', async () => {
  const response = await AccountAPI.fetchMyClients();
  return response.data;
});

export const updateProfileInfo = createAsyncThunk('account/updateProfileInfo', async (payload) => {
  const response = await AccountAPI.updateProfileInfo(payload);
  return response.status;
});

export const updateContactPerson = createAsyncThunk(
  'account/updateContactPerson',
  async (payload) => {
    return await AccountAPI.updateContactPerson(payload);
  }
);
export const uploadLogo = createAsyncThunk('account/uploadLogo', async (payload) => {
  const response = await AccountAPI.uploadLogo(payload);
  return response.data;
});
export const changePassword = createAsyncThunk('account/changePassword', async (payload) => {
  return await AccountAPI.changePassword(payload);
});
export const updateProfile = createAsyncThunk('account/updateProfile', async (payload) => {
  const response = await AccountAPI.updateProfile(payload);
  if (response.status === 200) {
    toast.success('Updated Successfully.');
  }
  return response.status;
});
export const getGlobalNotifications = createAsyncThunk(
  'account/getGlobalNotifications',
  async () => {
    const response = await AccountAPI.getGlobalNotifications();
    return response.data;
  }
);
export const setNotificationTapped = createAsyncThunk(
  'account/setNotificationTapped',
  async (payload) => {
    const response = await AccountAPI.setNotificationTapped(payload);
    return response.data;
  }
);
export const getTimelineLogs = createAsyncThunk('account/getTimelineLogs', async () => {
  const response = await AccountAPI.getTimelineLogs();
  return response.data;
});
