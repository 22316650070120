import React, { useEffect, useState } from 'react';
import UploadIconM from '../../../assets/imageComponents/UploadIconM';
import './ScrollableUpload.css';
import { Card } from 'react-bootstrap';
import { Link } from '@material-ui/core';
import { toast } from 'react-toastify';
import { uploadDocument } from '../../Utils/FileUpload';
import DeleteICon from '../../../assets/imageComponents/DeleteICon';
import ArrowUp from '../../../assets/imageComponents/ArrowUp';

function MultiUploadCard(props) {
  const { label, required, docs, deletDoc, type, keyInd, isEdit, FILE_NAME } = props;

  const [documents, setDocuments] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [inproDoc, setInProDoc] = useState([]);
  const [noOfDocUploaded, setNoOfDocUploaded] = useState(0);
  // const purchaseOrder = props?.documents?.filter((e) => e.type === 'WO_PURCHASE_ORDER');
  // const otherOrder = props?.documents?.filter((e) => e.type === 'WO_OTHERS');

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  useEffect(() => {
    if (!!inproDoc && inproDoc.length > 0 && inproDoc.length === noOfDocUploaded) {
      const docs = inproDoc.filter((e) => e.document.status === 'DONE');
      props.handleDocChange(docs);
      setInProDoc([]);
      setNoOfDocUploaded(0);
    }
  }, [inproDoc]);

  const uploadDocumentToS3 = (file, delDocId, type, count, index) => {
    const fileDetails = {
      active: true,
      entity: 'WORK_ORDER',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setDocuments((oldDocs) => [...(oldDocs || []), docTobeUploaded]);
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded.document?.filaeName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: 0,
        },
      });
    };

    const successCallback = (signedURLResponse) => {
      const oldUploadStatus = { ...uploadStatus };
      setUploadStatus({
        ...oldUploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'DONE',
          uploading: false,
        },
      });
      docTobeUploaded.document.status = 'DONE';
      setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
    };
    const progressCallback = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      const newStatus = {
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: percentCompleted,
        },
      };
      setUploadStatus(newStatus);
    };

    const failureCallback = (error) => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
      docTobeUploaded.document.status = 'FAIL';
      setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
      toast.error('Failed to upload the document');
    };

    uploadDocument(
      fileDetails,
      file,
      signedURLCallback,
      successCallback,
      failureCallback,
      progressCallback
    );
  };

  const handleFileSelected = (files, docId, type) => {
    setNoOfDocUploaded(files.length);

    const requests = Object.keys(files)?.map((file, index) =>
      uploadDocumentToS3(files[file], docId, type, files.length, index)
    );
  };

  return (
    <Card className="multiUploadCard" style={{ background: !isEdit && 'var(--Gray-50, #F9FAFB)' }}>
      <Card.Header
        style={{
          padding: 3,
          margin: 0,
          border: !docs?.length && 'none',
          background: !isEdit && 'var(--Gray-50, #F9FAFB)',
        }}
        className="d-flex just-space-betw">
        <span className="uploadCardHead">
          {label} {required && <span style={{ color: 'red' }}>*</span>}{' '}
        </span>

        {isEdit ? (
          <div style={{ cursor: 'pointer' }}>
            <label htmlFor={`file-upload-${keyInd}`} style={{ marginRight: '5px' }}>
              <UploadIconM />
            </label>

            <input
              accept="application/pdf,image/png,image/jpeg"
              type="file"
              id={`file-upload-${keyInd}`}
              style={{ display: 'none' }}
              hidden
              multiple
              onChange={(e) => handleFileSelected(e.target.files, null, type)}
            />
          </div>
        ) : (
          <ArrowUp />
        )}
      </Card.Header>
      {docs?.length ? (
        <Card.Body style={{ minHeight: '10px' }}>
          {docs?.map((doc_i, ind) => (
            <div key={ind + 444} className="d-flex align-i-center">
              {isEdit && (
                <div style={{ marginRight: '6px' }} onClick={() => deletDoc(doc_i)}>
                  <DeleteICon />
                </div>
              )}
              <div>
                <Link key={ind + 222} target="_blank" href={`${doc_i?.viewUrl}`} underline="always">
                  {`${FILE_NAME}_${ind + 1}`}
                </Link>
              </div>
            </div>
          ))}
        </Card.Body>
      ) : null}
    </Card>
  );
}

export default MultiUploadCard;
