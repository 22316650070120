import React from 'react';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import { FormControlLabel, Paper, Radio, RadioGroup } from '@material-ui/core';
import StyledRadio from '../../../../shared/Components/_App/StyledRadio/StyledRadio';

const FactoryLocationItem = ({ listData }) => {
  const [selection, setSelection] = React.useState('1');
  const updateSelection = (event, value) => {
    setSelection(value);
  };
  const ItemView = (location) => (
    <List>
      <ListItem>
        <ListItemText primary={location?.name} secondary={location?.street} />
        <ListItemSecondaryAction>
          <ListItemText primary={location?.km} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
  return (
    <RadioGroup name="value" value={selection} onChange={updateSelection}>
      {listData?.map((location) => (
        <Paper style={{ margin: 10, paddingLeft: 10 }} elevation={1}>
          <FormControlLabel
            value={location?.id.toString()}
            key={location?.id}
            control={<StyledRadio />}
            label={ItemView(location)}
          />
        </Paper>
      ))}
    </RadioGroup>
  );
};

export default FactoryLocationItem;
