import { AppBar, Button, Divider, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FilterModal from './FilterModal';
import SortByModel from './SortByModel';
import { ReactComponent as FilterIcon } from '../../../../assets/img/_App/filter.svg';
import { ReactComponent as SortbyIcon } from '../../../../assets/img/_App/sortby.svg';
import { useStyles } from './Filter.styles';

const Filter = ({
  bottomMargin,
  Tabs,
  openFilter,
  openSortBy,
  handleClose,
  handleClear,
  onDone,
  handleOpen,
  sortByFilter,
  setSortByFilter,
  handleOpenSortBy,
  handleCloseSortBy,
  onSelect,
  sortByDate,
}) => {
  const classes = useStyles();
  return (
    <div>
      <AppBar
        elevation={0}
        position="fixed"
        color="primary"
        className={classes.appBar}
        style={bottomMargin}>
        <Toolbar className={classes.toolBar}>
          <Button className={classes.button} startIcon={<SortbyIcon />} onClick={handleOpenSortBy}>
            SORT BY
          </Button>
          <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
          <div className={classes.grow} />
          <Button className={classes.button} startIcon={<FilterIcon />} onClick={handleOpen}>
            FILTER
          </Button>
        </Toolbar>
      </AppBar>

      <FilterModal
        open={openFilter}
        Tabs={Tabs}
        handleClose={handleClose}
        onClear={() => handleClear()}
        onClose={handleClose}
        onDone={() => onDone()}
      />
      <SortByModel
        open={openSortBy}
        onSelect={onSelect}
        onClose={handleCloseSortBy}
        sortByFilter={sortByFilter}
        setSortByFilter={setSortByFilter}
        sortByDate={sortByDate}
      />
    </div>
  );
};

export default Filter;
