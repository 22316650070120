import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backArrowIcon: {
    margin: '10px',
  },
  submitBottom: {
    width: '100%',
    textAlign: 'centre',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
  goHomeBottom: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: '#33ab2e',
    position: 'fixed',
    bottom: 0,
    borderRadius: 0,
  },
  approvePaper: {
    backgroundColor: '#e6fff9',
    border: '2px dashed #00bf8f',
    color: '#00bf8f',
  },
  alertPaper: {
    backgroundColor: '#ffffe9',
    border: '2px dashed #ffc918',
    color: '#ffc918',
  },
  approveAvtar: {
    width: '30px',
    height: '30px',
    fontSize: '18px',
    backgroundColor: '#33ab2e',
    margin: 'auto',
  },
  alertAvtar: {
    width: '30px',
    height: '30px',
    fontSize: '18px',
    backgroundColor: '#ffffe9',
    margin: 'auto',
  },
  deliveryMain: {
    maxHeight: '80vh',
    overflow: 'auto',
  },
  headerText: {
    lineHeight: '12px',
  },
  proButtonContainer: {
    width: '100%',
    height: '10%',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fff',
  },
  proDisputeButton: {
    width: '45%',
    backgroundColor: '#FF0000',
    color: '#fff',
    fontSize: 'large',
    fontFamily: 'font-bold',
    margin: 'auto',
  },
  proApproveButton: {
    width: '45%',
    fontSize: 'large',
    fontFamily: 'font-bold',
    margin: 'auto',
  },
}));
