import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    bottom: 10,
    width: '95%',
    position: 'absolute',
  },
  span: { color: '#8797ad' },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  buttonGroup: { display: 'flex' },
  goHomeButton: {
    width: '100%',
    borderBottomLeftRadius: 20,
    backgroundColor: '#eff5ff',
    color: '#005dff',
  },
  continueButton: {
    width: '100%',
    backgroundColor: '#eaf7e9',
    color: '#33ab2e',
    borderBottomRightRadius: 20,
  },
}));
