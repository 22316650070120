import React, { useEffect, useRef, useState } from 'react';
import './DocumentHistoryItem.scss';
import Jumbotron from 'react-bootstrap/Jumbotron';
import file from '../../../../assets/img/file.svg';
import van from '../../../../assets/img/van.svg';
import { Badge, Button, Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import attachments from '../../../../assets/img/attachments.svg';
import DocumentHistoryFile from '../DocumentHistoryFile/DocumentHistoryFile';
import Form from 'react-bootstrap/Form';
import { uploadDocument } from '../../../../shared/Utils/FileUpload';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { publish } from '../../../../shared/Events/AppEvents';
import _clone from 'lodash/clone';
import _escapeRegExp from 'lodash/escapeRegExp';
import ReactHtmlParser from 'react-html-parser';
import { DeliveryService } from '../../delivery.service';

const DocumentHistoryItem = (props) => {
  const { delivery, ticket, docTypeList, fetchAllTickets, doFetchDelivery } = props;
  const TICKET_KINDS = {
    DOC_TICKET: 'DOC_TICKET',
    DEL_TICKET: 'DEL_TICKET',
  };

  const inputRef = useRef(null);

  // Setup State
  const [ticketKind, setTicketKind] = useState(TICKET_KINDS.DOC_TICKET);
  const [disputedDelivery, setDisputedDelivery] = useState(null);
  const [disputedDeliveryDocument, setDisputedDeliveryDocument] = useState(null);
  const [documentTypesList, setDocumentTypesList] = useState([]);
  const [documentList, setDocsList] = useState([]);

  // UI State
  const [displayTextArea, setDisplayTextArea] = useState(false);

  // Message State
  const [messageText, setMessageText] = useState('');
  const [docType, setDocType] = useState(null);
  const [newAttachments, setNewAttachments] = useState([]);
  const [isResolving, setIsResolving] = useState(false);

  const { account } = useSelector(({ account }) => ({
    account,
  }));

  const ownerId =
    disputedDeliveryDocument?.fromCustomerId || disputedDeliveryDocument?.toCustomerId;
  const isOwner = ownerId === account.user.profile?.id;
  const canResolve = isOwner && disputedDeliveryDocument?.status === 'REJECTED';

  useEffect(() => {
    const list = [];
    docTypeList.map((docKindList) => {
      docKindList.documents.map((docType) => {
        list.push(docType);
      });
    });
    setDocumentTypesList(list);

    const docsList = [];
    docTypeList.map((docList) => {
      if (docList.id === 'pickupDocs') {
        docList.documents.map((finalDocs) => {
          docsList.push({
            _id: finalDocs.type,
            name: 'Pickup ' + '' + finalDocs.title,
          });
        });
      } else if (docList.id === 'dropDocs') {
        docList.documents.map((finalDocs) => {
          docsList.push({
            _id: finalDocs.type,
            name:
              finalDocs.type === 'DROP_INWARD_TYPE'
                ? finalDocs.title
                : 'Drop off ' + '' + finalDocs.title,
          });
        });
      }
    });
    setDocsList(docsList);
  }, [docTypeList]);

  useEffect(() => {
    const kindOfTicket =
      ticket.deliveryDocuments && ticket.deliveryDocuments.length === 1
        ? TICKET_KINDS.DOC_TICKET
        : TICKET_KINDS.DEL_TICKET;
    setTicketKind(kindOfTicket);
    if (delivery?.id) {
      let disputedDel = null;
      if (delivery.id === ticket.deliveryId) {
        disputedDel = delivery;
      } else {
        disputedDel =
          delivery.assignedDeliveries &&
          delivery.assignedDeliveries.find((assignment) => {
            return assignment.id === ticket.deliveryId;
          });
      }
      setDisputedDelivery(disputedDel);
      if (disputedDel) {
        if (kindOfTicket === TICKET_KINDS.DOC_TICKET) {
          const disputedDoc =
            disputedDel.deliveryDocuments &&
            disputedDel.deliveryDocuments.find((deliveryDoc) => {
              return deliveryDoc.id === ticket.deliveryDocuments[0].id;
            });
          setDisputedDeliveryDocument(disputedDoc);
        }
      }
    }
  }, [ticket, delivery]);

  const onAttachmentSelect = (eventObject) => {
    if (ticketKind === TICKET_KINDS.DEL_TICKET) {
      setDocType(eventObject.target.id);
    } else {
      setDocType(disputedDeliveryDocument.document.type);
    }
    inputRef.current.click();
  };

  const handleFileInput = (e) => {
    //TODO: Check validity
    const file = e.target.files[0];
    if (true) {
      uploadDocumentToS3(file, null, docType);
    } else {
      toast.error('Not a valid file');
    }
  };

  /**
   * upload document to s3 server
   * @param file
   * @param delDocId
   * @param type
   */
  const uploadDocumentToS3 = (file, delDocId, type) => {
    const fileDetails = {
      active: true,
      entity: 'DELIVERY',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
    };
    const signedURLCallback = (doc) => {};

    const successCallback = (signedURLResponse) => {
      const uploadDetails = signedURLResponse.data;
      setNewAttachments([
        ...newAttachments,
        {
          id: null,
          document: uploadDetails,
        },
      ]);
    };
    const progressCallback = (progressEvent) => {};

    const failureCallback = (error) => {
      toast.error('Failed to upload the document');
    };

    uploadDocument(
      fileDetails,
      file,
      signedURLCallback,
      successCallback,
      failureCallback,
      progressCallback
    );
  };

  // The attachment which gets the DeliveryDocumentID will replace the disputed document
  const setReplaceLogic = (newAttachments) => {
    if (!newAttachments || newAttachments.length === 0) {
      return newAttachments;
    }

    if (ticketKind === TICKET_KINDS.DOC_TICKET) {
      newAttachments[0].id = disputedDeliveryDocument.id;
    } else if (ticketKind === TICKET_KINDS.DEL_TICKET) {
      ticket.deliveryDocuments &&
        ticket.deliveryDocuments.map((deliveryDocument) => {
          let isReplaceSet = false;
          newAttachments = newAttachments.map((attachment) => {
            if (attachment.document.type === deliveryDocument.document.type && !isReplaceSet) {
              attachment.id = deliveryDocument.id;
            }
            return attachment;
          });
        });
    }
    return newAttachments;
  };

  const addMessage = async () => {
    const messageData = {
      ticketId: ticket.id,
      text: messageText,
      createdOn: Date.now(),
      replyToMessageId: null,
      resolve: isResolving,
      attachments: isResolving ? setReplaceLogic(newAttachments) : newAttachments,
    };

    const response = await DeliveryService.replyTicket(messageData);
    if (response?.data) {
      setDisplayTextArea(false);
      doFetchDelivery();
      setNewAttachments([]);
      const replacedDocumentID = response?.data.replacedId;
      const attachments = response?.data.attachments || [];

      let replacingAttachment = null;
      const remainingAttachments = [];
      for (let attachment of attachments) {
        if (attachment.id === replacedDocumentID) {
          replacingAttachment = attachment;
        } else {
          remainingAttachments.push(attachment);
        }
      }
      if (replacingAttachment) {
        setDisputedDeliveryDocument(replacingAttachment);
      }
      fetchAllTickets(delivery.id);
    }
  };

  const deleteFile = (fileIndex) => {
    const newArr = newAttachments.filter((file, index) => {
      return index !== fileIndex;
    });
    setNewAttachments([...newArr]);
  };

  const viewDocument = () => {
    if (disputedDelivery && disputedDeliveryDocument) {
      publish('SELECTED_ASSIGNMENT', 'CHANGE_ASSIGNMENT', disputedDelivery.id);
      publish(`DELIVERY_DOC_${disputedDeliveryDocument.id}`, 'OPEN_MODAL', null);
    }
  };

  const viewDelivery = () => {
    if (disputedDelivery) {
      publish('SELECTED_ASSIGNMENT', 'CHANGE_ASSIGNMENT', disputedDelivery.id);
    }
  };

  const messageTextConvertor = (text) => {
    let displayText = _clone(text);

    const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
    tags.map((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split('||');
      const tagDisplayValue = '<span>' + tagDataArray[2] + '</span>';

      displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue);
    });
    return <p>{ReactHtmlParser(displayText)}</p>;
  };

  function documentTypeName(disputedDeliveryDocument, documentTypesList) {
    return (
      (disputedDeliveryDocument?.document?.type &&
        documentTypesList.find((docType) => {
          return docType.type === disputedDeliveryDocument.document.type;
        })?.title) ||
      'Document type not set'
    );
  }

  return (
    <div className="DocumentHistoryItem">
      <div className="documenthistory-comment mb-3">
        <div className="row px-4">
          <div className=" documentheading col-8 p-0">
            <div className="">
              <div className="title">
                {ticketKind === TICKET_KINDS.DOC_TICKET && (
                  <>
                    <img src={file} width="16" height="26" className="pr-1" />
                    <span>{documentTypeName(disputedDeliveryDocument, documentTypesList)}</span>
                    <span>
                      {disputedDeliveryDocument?.status === 'REJECTED' && (
                        <Badge className="font-regular text-light ml-2" variant={'danger'}>
                          REJECTED
                        </Badge>
                      )}
                      {disputedDeliveryDocument?.status === 'APPROVED' && (
                        <Badge className="font-regular text-light ml-2" variant={'success'}>
                          APPROVED
                        </Badge>
                      )}
                    </span>
                  </>
                )}
                {ticketKind === TICKET_KINDS.DEL_TICKET && (
                  <>
                    <img src={van} width="16" height="26" className="pr-1" alt="van_icon" />
                    <span>{disputedDelivery && `Delivery : ${disputedDelivery.id}`}</span>
                    <span>
                      <Badge className="font-regular text-light ml-2" variant={'danger'}>
                        {disputedDelivery && disputedDelivery.status}
                      </Badge>
                    </span>
                  </>
                )}
              </div>
              {ticketKind === TICKET_KINDS.DOC_TICKET && (
                <span className="view-docu" onClick={() => viewDocument()}>
                  View Document
                </span>
              )}
              {ticketKind === TICKET_KINDS.DEL_TICKET && (
                <span className="view-docu" onClick={() => viewDelivery()}>
                  View Delivery
                </span>
              )}
            </div>
          </div>
          <div className=" author col-4 p-0 text-right">
            <div>
              <label className="mb-0">by {ticket?.raisedBy}</label>
              <p>
                {ticket?.ticketDate && moment(ticket?.ticketDate).format('DD MMM, YYYY h:mm A')}
              </p>
            </div>
          </div>
        </div>
        {ticket?.commentList?.length > 0 &&
          ticket?.commentList.map((message, index) => {
            return (
              <Jumbotron className="p-4 mb-3 jumbo" key={`MESSAGE-${index}`}>
                {messageTextConvertor(message.text ?? '')}
                {message.attachments &&
                  message.attachments.map(({ document }, docIndex) => {
                    return (
                      <DocumentHistoryFile
                        key={`DOCUMENT_ATTACHMENT-${docIndex}`}
                        fileType={document.contentType}
                        fileName={document.fileName}
                        fileSize={document.fileSize}
                        fileURL={document.viewUrl}
                        canDelete={false}
                      />
                    );
                  })}
              </Jumbotron>
            );
          })}
        {ticket.status === 'OPEN' && !displayTextArea && (
          <div className="text-right">
            <Button variant="primary" className="" onClick={() => setDisplayTextArea(true)}>
              Reply
            </Button>
            <div className="border-dashed my-3"></div>
          </div>
        )}
        {displayTextArea && (
          <div className="documenthistory-comment-file-textarea mb-4">
            <Card className="p-0">
              <Card.Header className="p-0">
                <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0 p-3">
                  <Form.Control
                    as="textarea"
                    rows="2"
                    placeholder="Write your comment here"
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  {newAttachments.map(({ document }, index) => {
                    return (
                      <DocumentHistoryFile
                        key={`NEW_ATTACHMENT-${index}`}
                        fileType={document.contentType}
                        fileName={document.fileName}
                        fileSize={document.fileSize}
                        fileURL={document.viewUrl}
                        canDelete={true}
                        onDelete={() => deleteFile(index)}
                        docType={
                          ticketKind === TICKET_KINDS.DOC_TICKET
                            ? documentTypesList.find((docType) => {
                                return docType.type === disputedDeliveryDocument.document.type;
                              })?.title || null
                            : null
                        }
                      />
                    );
                  })}
                </Form.Group>
              </Card.Header>
              <Card.Body className="px-3 jumbo py-1">
                {delivery?.providerId === account?.user?.profile.id && (
                  <div className="file-attachments d-flex">
                    <input
                      ref={inputRef}
                      id="file-input"
                      type="file"
                      name="name"
                      className="hidden"
                      onChange={handleFileInput}
                    />
                    <img
                      className="img-fluid attachments"
                      alt="attachment_icon"
                      src={attachments}
                    />
                    {ticketKind === TICKET_KINDS.DOC_TICKET && (
                      <div onClick={() => onAttachmentSelect()}>Attachments</div>
                    )}
                    {ticketKind === TICKET_KINDS.DEL_TICKET && (
                      <Dropdown
                        drop={'down'}
                        onSelect={(eventKey, eventObject) => onAttachmentSelect(eventObject)}>
                        <Dropdown.Toggle variant="success" className="attach-file-btn">
                          Attachments
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-icon">
                          <Dropdown.Header className="first-label">
                            Type of Document
                          </Dropdown.Header>
                          {documentList.map((docStat, idx) => (
                            <Dropdown.Item id={docStat._id} key={idx}>
                              {docStat.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                )}
              </Card.Body>
            </Card>
            <div className="py-3 d-flex align-items-center justify-content-end">
              {((ticketKind === TICKET_KINDS.DOC_TICKET && canResolve) ||
                (ticketKind === TICKET_KINDS.DEL_TICKET &&
                  delivery?.providerId === account.user?.profile.id &&
                  disputedDelivery.status === 'DISPUTED')) &&
                ['checkbox'].map((type) => (
                  <div key={`custom-${type}`} className="mb-0 mr-4 align-items-center">
                    <Form.Check
                      custom
                      type={type}
                      id={`custom-${type}`}
                      label={`Resolve `}
                      size={'lg'}
                      checked={isResolving}
                      onChange={(e) => setIsResolving(!isResolving)}
                    />
                  </div>
                ))}
              <Button
                variant="primary"
                disabled={messageText.length <= 0}
                className=" my-2"
                onClick={(e) => addMessage()}>
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentHistoryItem;
