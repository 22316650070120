import React, { memo, useEffect, useState } from 'react';
import { Alert, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './DashboardMaterialItemsFilter.scss';
import { useDispatch } from 'react-redux';
import { useCombinedMaterialCategory } from '../../hooks';
import { compact, isEmpty } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { applySummaryFilter } from '../../state/Execution/executionSlice';
const DashboardMaterialItemsFilters = (props) => {
  const { facetList, filters } = props;
  const [itemList, setItemList] = useState([]);
  const categories = useCombinedMaterialCategory();
  const dispatch = useDispatch();

  //set default browsenodeid
  useEffect(() => {
    if (facetList?.length > 0) {
      if (filters && isEmpty(filters.browseNodeIds)) {
        for (const cat of categories) {
          const filteredItems = getItemListByCatId(cat.id);
          if (filteredItems.length > 0) {
            handleCategoryChange(cat.id);
            setItemList(filteredItems);
            break;
          }
        }
      } else {
        setItemList(
          getItemListByCatId(filters?.browseNodeIds.length === 4 ? '4' : filters?.browseNodeIds[0])
        );
      }
    }
  }, [facetList]);

  const getItemListByCatId = (val) => {
    const ids = compact(facetList?.map((f) => (f.valueKey === 'itemId' ? f.value : null)));
    let itemsArray = categories?.find((f) => f.id === val);
    const _items = itemsArray?.items;
    return _items?.filter((i) => ids?.indexOf(i.id) >= 0);
  };

  //handle material category changes
  const handleCategoryChange = (val) => {
    dispatch(
      applySummaryFilter({
        ...filters,
        browseNodeIds: val === '4' ? ['1', '2', '4', '6'] : [val],
        itemIds: null,
      })
    );
  };

  //to set item when the value changes
  const handleItemChange = (val) => {
    val = isEmpty(val) ? null : isEmpty(filters?.itemIds) ? [val] : [...filters?.itemIds, val];
    // dispatch(executionActions.applySummaryFilter({ ...filters, itemIds: val }));
    // val = isEmpty(val) ? null : [val];
    // dispatch(executionActions.applySummaryFilter({ ...filters, itemIds: val }));
    dispatch(applySummaryFilter({ ...filters, itemIds: val }));
  };

  const handleItemDeselection = (itemValue) => {
    if (!isEmpty(itemValue?.target?.value)) {
      const isItemPresent = filters?.itemIds?.find(
        (data) => data?.trim() === itemValue?.target?.value?.trim()
      );

      if (!isEmpty(isItemPresent)) {
        const filteredItemIds = filters?.itemIds?.filter(
          (data) => data?.trim() !== isItemPresent?.trim()
        );
        dispatch(
          applySummaryFilter({
            ...filters,
            itemIds: isEmpty(filteredItemIds) ? null : filteredItemIds,
          })
        );
      }
    }
    // val = isEmpty(val) ? null : [val];
    // // dispatch(executionActions.applySummaryFilter({ ...filters, itemIds: val }));
    // dispatch(applySummaryFilter({ ...filters, itemIds: val }));
  };

  return (
    <div>
      <Row noGutters>
        <Col md={12}>
          <div className="dashboard-main-toggle">
            <ToggleButtonGroup
              type="radio"
              className="w-100"
              name="filter"
              value={
                filters?.browseNodeIds
                  ? filters?.browseNodeIds.length === 4
                    ? '4'
                    : filters?.browseNodeIds[0]
                  : '4'
              }
              onChange={handleCategoryChange}>
              {categories?.map((facet, idx) => (
                <ToggleButton className=" browseNodeToggle float-right" key={idx} value={facet.id}>
                  {facet.title}
                  {/*<span className="font-bold pl-1">MET {0} %</span>*/}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </Col>
      </Row>

      <div className="dashboard-inner-toggle">
        <div className="DashboardMaterialFilter">
          {props.financialYearNew && (
            <Alert className="animated slideInDown m-0 text-center mb-2" variant={'info'}>
              To check the Fulfilment status of FY 20-21, kindly change the financial year at the
              top right corner.
            </Alert>
          )}
          {itemList?.length > 0 ? (
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <ToggleButtonGroup
                type="radio"
                name="filter"
                value={filters.itemIds ? filters.itemIds : ''}
                onChange={handleItemChange}>
                <ToggleButton key="ALL" variant={'none'} value={''}>
                  <span className="doc-type-label">ALL </span>
                </ToggleButton>
                {itemList?.map((item, idx) => (
                  <ToggleButton
                    variant={'none'}
                    key={idx}
                    value={item.id}
                    onClick={handleItemDeselection}>
                    <span className="doc-type-label">{item.name}</span>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </PerfectScrollbar>
          ) : (
            <Alert className="animated slideInDown m-0 text-center" variant={'info'}>
              There is no delivery or target data available for selected category.
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardMaterialItemsFilters);
