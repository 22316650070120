import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './Planning.scss';

export default function PlanningTabs({ tabArray, handleTabSelect, activeKey }) {
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={activeKey}
      onSelect={(eventKey) => handleTabSelect(eventKey)}
      className="planningTabs">
      {tabArray?.map((data) => (
        <Tab key={data?.id} eventKey={data?.value} title={data?.label} />
      ))}
    </Tabs>
  );
}
