import { Box, Grid, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import FormField from '../../../../shared/Components/_App/FormField';
import Section from '../../../../shared/Components/_App/Section';
import { changePassword } from '../../../../state/Account/accountThunks';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: 'blue',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
}));

const ProfileChangePasswordForm = (props) => {
  const { setShowChangePassModal } = { ...props };
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().min(4, 'Should be Min. 4 Char').required('Password required.'),
      newPassword: Yup.string().min(4, 'Should be Min. 4 Char').required('Password required.'),
      confirmPassword: Yup.string()
        .min(4, 'Should be Min. 4 Char')
        .required('Confirm password required.')
        .test('passwords-match', 'Passwords must match', function (value) {
          return formik.values.newPassword === value;
        }),
    }),
    isInitialValid: false,
    onSubmit: async (values, action) => {
      try {
        const originalPromiseResult = await dispatch(
          changePassword({
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          })
        ).unwrap();
        if (originalPromiseResult?.status === 200) {
          toast.success('Password updated successfully!!!');
          setShowChangePassModal(false);
          action.resetForm();
        }
      } catch (rejectedValueOrSerializedError) {
        toast.error(rejectedValueOrSerializedError);
      }
    },
  });

  const handleBlur = () => {
    if (
      formik.values.confirmPassword &&
      formik.values.newPassword.toUpperCase() !== formik.values.confirmPassword.toUpperCase()
    ) {
      toast.error('New password and Confirm New Password should be same');
    }
  };

  const handleClickShowPassword = (type) => {
    if (type === 'currentPassword')
      setShowPassword({ ...showPassword, currentPassword: !showPassword.currentPassword });
    if (type === 'newPassword')
      setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword });
    if (type === 'confirmPassword')
      setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword });
  };

  return (
    <Box>
      <Section>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField
              label="CURRENT PASSWORD"
              value={formik.values.currentPassword}
              error={formik.errors?.currentPassword}
              isValid={!formik.errors?.currentPassword && formik.dirty}
              onChange={formik.handleChange('currentPassword')}
              onBlur={formik.handleBlur('currentPassword')}
              touched={formik.touched?.currentPassword}
              type={showPassword.currentPassword ? 'text' : 'password'}
              placeholder="*******"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('currentPassword')}>
                      {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="ENTER NEW PASSWORD"
              value={formik.values.newPassword}
              error={formik.errors?.newPassword}
              isValid={!formik.errors?.newPassword && formik.dirty}
              onChange={formik.handleChange('newPassword')}
              onBlur={formik.handleBlur('newPassword')}
              touched={formik.touched?.newPassword}
              type={showPassword.newPassword ? 'text' : 'password'}
              placeholder="*******"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('newPassword')}>
                      {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="CONFIRM NEW PASSWORD"
              value={formik.values.confirmPassword}
              error={formik.errors?.confirmPassword}
              isValid={!formik.errors?.confirmPassword && formik.dirty}
              onChange={formik.handleChange('confirmPassword')}
              onBlur={handleBlur}
              touched={formik.touched?.confirmPassword}
              type={showPassword.confirmPassword ? 'text' : 'password'}
              placeholder="*******"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('confirmPassword')}>
                      {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Section>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={!formik.isValid || !formik.dirty}
        onClick={() => formik.handleSubmit()}>
        {' '}
        Confirm
      </Button>
    </Box>
  );
};

export default ProfileChangePasswordForm;
