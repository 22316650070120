export const ChatService = () => {
  return {
    /*
     * Freshchat Widget Init Method - Set the payload here
     */
    init: function (profile = {}) {
      if (window.fcWidget && profile) {
        if (window.fcWidget.isLoaded()) {
          this.loadUser(profile);
          return;
        }
        window.fcWidget.init({
          token: 'b2034893-6df5-445a-9bb2-416603c06386',
          host: 'https://wchat.freshchat.com',
          firstName: profile.businessName,
          externalId: profile.formattedId,
          // restoreId: profile.formattedId,
          email: profile.email,
          phone: profile.mobile,
          phoneCountryCode: '+91',
          tags: ['epr'],
          sideId: 'epr.recykal.com',
          // restoreId: 'RESTORE_ID',
          onInit: this.loadWidget(profile),
        });
      } else {
        window.fcSettings = {
          token: 'b2034893-6df5-445a-9bb2-416603c06386',
          host: 'https://wchat.freshchat.com',
          tags: ['epr'],
          sideId: 'epr.recykal.com',
          onInit: () => {
            this.loadWidget(profile);
          },
        };
      }
    },
    /*
     * @method loadWidget - Load the widget
     */
    loadWidget: function (profile) {
      var _self = this;
      if (window.fcWidget && window.fcWidget.isLoaded()) {
        _self.loadUser(profile);
      } else {
        window.fcWidget.on(
          'widget:loaded',
          function () {
            _self.loadUser(profile);
          },
          function () {
            // Error Loading Widget
          }
        );
      }
    },
    /*
     * @method loadUser - Load the widget user
     * @param Object user - site user Object
     */
    loadUser: function (user) {
      try {
        if (window.fcWidget && !window.fcWidget.isInitialized()) {
          this.init(user);
          return;
        }
        const _self = this;
        // Check the user
        window.fcWidget.user.get().then(
          function (response) {
            _self.validateUser(response, user);
          },
          function (error) {
            _self.validateUser(error, user);
          }
        );
      } catch (e) {}
    },
    /*
     * @method validateUser - Validate the Loaded User
     * @param Object response - widget user Object
     * @param Object user - site user Object
     */
    validateUser: function (response, profile) {
      var status = response && response.status,
        // rstId = user && user.restoreId,
        widget = window.fcWidget,
        _self = this;
      if (status === 200) {
        this.updateUser(response, profile);
      }
      if ([401, 403, 404].includes(status)) {
        /*
         * User doesn't exist
         * Use this block only if you want to create the user on load
         * Otherwise comment this
         */
        widget.user.create().then(
          function (resp) {
            _self.updateUser(resp, profile);
          },
          (err) => {
            console.log('Error creating user');
          }
        );
      }
    },
    /*
     * Freshchat Widget Update Method
     * @param Object response - widget user Object
     * @param Object user - site user Object
     */
    updateUser: function (response, profile) {
      var data = response && response.data,
        externalId = profile && profile.formattedId;
      // restoreId = user && user.restoreId;
      if (data) {
        let rstId = data.restoreId;
        let extId = data.externalId || data.identifier;
        if (externalId && externalId === extId) {
          /*
           * Update widget user custom parameters here
           */
          window.fcWidget.user.update({
            firstName: profile.businessName,
            email: profile.email,
            phone: profile.mobile,
            meta: {
              Actor: profile.productSubscriptions && profile.productSubscriptions[0]?.actor,
              'Contact Person': profile?.contactPerson?.name,
              Mobile: profile?.contactPerson?.mobile,
            },
          });
        }
      }
    },

    openChat: async (channelName = '') => {
      // if (!!window.fcWidget) return;
      window.fcWidget.open({ name: channelName });
      window.fcWidget.show();
    },

    hideChat: (channelName = '') => {
      window.fcWidget && window.fcWidget.close();
      window.fcWidget && window.fcWidget.hide();
    },

    clearUserData: async () => {
      if (window.fcWidget && window.fcWidget.isLoaded()) {
        return await window.fcWidget.user.clear();
      }
      return true;
    },
  };
};
