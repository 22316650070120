import React, { useEffect, useState } from 'react';
import {
  InputBase,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  Typography,
  Container,
  Modal,
  Button,
} from '@material-ui/core';
import * as _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useHistory } from 'react-router-dom';
import { useStyles } from './DeliveryPage.app.styles';
import { DeliveryStatus, Region } from '../../../shared/Components/GenericComponent';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { useAsyncEffect } from '../../../hooks';
import PageView from '../../../shared/Components/_App/PageView';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { isEmpty } from 'lodash';
import AppHeader from '../../../layout/AppHeader';
import { ReactComponent as NotificationIcon } from '../../../assets/img/_App/notification.svg';
import { ReactComponent as PikupIcon } from '../../../assets/img/_App/pickup.svg';
import { ReactComponent as DropIcon } from '../../../assets/img/_App/dropoff.svg';
import Filter from '../../../shared/Components/_App/Modal/Filter';
import DeliveryStatusTab from '../../../shared/Components/_App/Modal/DeliveryStatusTab';
import PushNotification from '../../../shared/Config/push-notification';
import { ChatService } from '../../../shared/Services/ChatService';
import AppAnalytics from '../../../shared/Config/AppAnalytics';
import { ReactComponent as LogoutIcon } from '../../../assets/img/_App/logout.svg';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import FinancialYearModal from '../../../features/_App/Home/FinancialYearModal';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const DeliveryFulfilmentExecutivePage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  // const [filter, setFilter] = useState();
  const { selectedFinancialYear } = useFinancialYear();
  const searchParams = new URLSearchParams(window.location.search);
  const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
  const [deliverylist, setDeliveryList] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [masterdeliverylist, setMasterDeliveryList] = useState([]);
  const [filterdeliverylist, setFilterDeliveryList] = useState([]);
  const [openFilter, setOpenfilter] = useState(false);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [materialList, setMaterialList] = useState([]);
  const [woFilter, setWofilter] = useState({
    deliveryStatus: null,
    material: null,
    LatestonTop: searchParams.get('sort')
      ? searchParams.get('sort') === 'createdOn,desc'
        ? true
        : false
      : true,
    OlderonTop: false,
  });
  const [previousFilter, setPreviousFilter] = useState({
    deliveryStatus: null,
    material: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({
    page: 0,
    pageSize: 15,
    isFetchMore: true,
    isFilterSortFetch: false,
    isNormalFetch: true,
    normalFetchPage: 0,
    filterFetchPage: 0,
    deliveryCount: 0,
    isSearch: false,
    isFilterApplied: searchParams.get('isFilterApplied') || 'false',
    isFilter: false,
  });
  const [count, setCount] = useState(0);
  const [deliveryParams, setDeliveryParams] = useState({
    deliveryDateFrom: selectedFinancialYear?.fromDate,
    deliveryDateTo: selectedFinancialYear?.toDate,
    page: 0,
    sort: searchParams.get('sort') || 'createdOn,desc',
    direction: 'RECEIVED',
    itemIds: appMaterialItems,
    size: deliveryDetails.pageSize,
  });

  const setUpdatedFinancialYear = (financialYear) => {
    setDeliveryParams({
      ...deliveryParams,
      deliveryDateFrom: financialYear?.fromDate,
      deliveryDateTo: financialYear?.toDate,
    });
    setDeliveryList([]);
    setMasterDeliveryList([]);
    setDeliveryDetails({
      ...deliveryDetails,
      page: 0,
      isFetchMore: true,
      isFilterSortFetch: false,
      isNormalFetch: true,
      normalFetchPage: 0,
      filterFetchPage: 0,
      deliveryCount: 0,
    });
  };

  useAsyncEffect(async () => {
    if (deliveryDetails.isFetchMore) {
      setDeliveryLoading(true);
      let delParams = { ...deliveryParams };
      if (deliveryDetails.isFilterApplied === 'true') {
        if (searchParams.get('isSearchApplied'))
          delParams = {
            ...delParams,
            ids: searchValue,
          };
        else
          delParams = {
            ...delParams,
            status: searchParams.get('status'),
          };
      }
      const response = await DeliveryService.fetchDeliveries(delParams);
      if (response?.deliveries) {
        if (isEmpty(deliverylist)) {
          setCount(response?.totalCount);
          setDeliveryList(response?.deliveries?.received);
          setDeliveryLoading(false);
          groupByMaterials(response?.deliveries?.received);
          if (deliveryDetails.isNormalFetch && !deliveryDetails.isSearch) {
            setMasterDeliveryList(response?.deliveries?.received);
            setDeliveryDetails({ ...deliveryDetails, deliveryCount: response?.totalCount });
          } else if (!deliveryDetails.isSearch) {
            setFilterDeliveryList(response?.deliveries?.received);
          }
        } else {
          const updatedData = [...deliverylist, ...response?.deliveries?.received];
          setDeliveryList(updatedData);
          groupByMaterials(updatedData);
          if (deliveryDetails.isNormalFetch) setMasterDeliveryList(updatedData);
          else setFilterDeliveryList(updatedData);
        }
      }
      setDeliveryLoading(false);
    }
  }, [deliveryDetails.isFetchMore, deliveryDetails.page, selectedFinancialYear]);

  useEffect(() => {
    if (searchParams.get('isFilterApplied') === 'true') {
      if (searchParams.get('status') === 'IN_PROGRESS') {
        const status = {
          name: 'In Progress',
          select: true,
          status: 'IN_PROGRESS',
          internalStatus: 'IN_PROGRESS',
        };
        setWofilter({
          ...woFilter,
          deliveryStatus: status,
        });
      } else if (searchParams.get('status') === 'IN_TRANSIT') {
        const status = {
          name: 'In Transit',
          select: true,
          status: 'IN_TRANSIT',
          internalStatus: 'IN_TRANSIT',
        };
        setWofilter({
          ...woFilter,
          deliveryStatus: status,
        });
      } else if (searchParams.get('status') === 'IN_REVIEW') {
        const status = {
          name: 'In Review',
          select: true,
          status: 'IN_REVIEW',
          internalStatus: 'IN_REVIEW',
        };
        setWofilter({
          ...woFilter,
          deliveryStatus: status,
        });
      } else if (searchParams.get('status') === 'APPROVED') {
        const status = {
          name: 'Approved',
          select: true,
          status: 'APPROVED',
          internalStatus: 'APPROVED',
        };
        setWofilter({
          ...woFilter,
          deliveryStatus: status,
        });
      } else if (searchParams.get('status') === 'DISPUTED') {
        const status = {
          name: 'Disputed',
          select: true,
          status: 'DISPUTED',
          internalStatus: 'REJECTED',
        };
        setWofilter({
          ...woFilter,
          deliveryStatus: status,
        });
      }
    }
  }, []);

  const handleClick = (e) => {
    const deliveryData = e.deliveryDetails;
    if (
      // deliveryData.pickUpSupervisorMobile &&
      // deliveryData.dropSupervisorMobile &&
      // deliveryData.transporterId &&
      // deliveryData.driverName &&
      deliveryData.pickUpVehicleNumber
    ) {
      if (deliveryDetails?.isFilter) {
        const searchedQuery = window.location.search.slice(1);
        searchParams.get('delivery') === 'true'
          ? history.push({
              pathname: `/delivery-view/${e.id}`,
              search: `${searchedQuery}`,
            })
          : history.push({
              pathname: `/delivery-view/${e.id}`,
              search: `${searchedQuery}&delivery=true`,
            });
      } else history.push(`/delivery-view/${e.id}`);
    } else {
      if (deliveryDetails?.isFilter) {
        const searchedQuery = window.location.search.slice(1);
        searchParams.get('delivery') === 'true'
          ? history.push({
              pathname: `/update-delivery/${e.id}`,
              search: `${searchedQuery}`,
            })
          : history.push({
              pathname: `/update-delivery/${e.id}`,
              search: `${searchedQuery}&delivery=true`,
            });
      } else history.push(`/update-delivery/${e.id}`);
    }
  };
  // const fromTabView = props.location.pathname?.includes('/tabs/');

  const filterDelivery = (id) => {
    setSearchValue(id);
    if (id.length > 3) {
      const updatedDelivery = {
        deliveryDateFrom: deliveryParams?.deliveryDateFrom,
        deliveryDateTo: deliveryParams?.deliveryDateTo,
        page: 0,
        sort: deliveryParams?.sort,
        direction: deliveryParams?.direction,
        itemIds: appMaterialItems,
        size: deliveryParams?.size,
        ids: id,
      };
      setDeliveryList([]);
      setDeliveryParams({ ...updatedDelivery });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        isSearch: true,
        isFilter: true,
      });
      if (searchParams.get('isSearch') !== 'true')
        history.push({
          search: `search=${id}&isSearch=true&isFilterApplied=true`,
        });
      else {
        const searchedQuey = window.location.search.slice(1);
        const newSearchedQuery = searchedQuey.replace(searchParams.get('search'), id);
        history.push({
          search: newSearchedQuery,
        });
      }
    } else {
      if (woFilter?.deliveryStatus && searchParams.get('isSearchApplied') !== 'true')
        setDeliveryList(filterdeliverylist);
      if (deliveryDetails.isNormalFetch && searchParams.get('isSearchApplied') !== 'true')
        setDeliveryList(masterdeliverylist);
      setDeliveryDetails({ ...deliveryDetails, isSearch: false });
      if (id.length === 0) {
        if (searchParams.get('isSearchApplied') === 'true') {
          const updatedDelivery = {
            deliveryDateFrom: deliveryParams?.deliveryDateFrom,
            deliveryDateTo: deliveryParams?.deliveryDateTo,
            page: 0,
            sort: deliveryParams?.sort,
            direction: deliveryParams?.direction,
            browseNodeIds: deliveryParams?.browseNodeIds,
            size: deliveryParams?.size,
          };
          setDeliveryParams({ ...updatedDelivery });
          setDeliveryDetails({
            ...deliveryDetails,
            isNormalFetch: true,
            isFetchMore: true,
            page: deliveryDetails.page + 1,
            isFilter: false,
          });
        }
        history.push({
          search: '',
        });
      }
    }
  };
  const onSelect = () => {
    setDeliveryList([]);
    if (woFilter?.deliveryStatus) {
      setDeliveryParams({
        ...deliveryParams,
        sort: woFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc',
        page: 0,
      });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        filterFetchPage: 0,
      });
      const searchedQuey = window.location.search.slice(1);
      const newSearchedQuery = searchedQuey.replace(
        searchParams.get('sort'),
        woFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc'
      );
      history.push({
        search: `${newSearchedQuery}`,
      });
    } else if (deliveryDetails.isNormalFetch) {
      setDeliveryParams({
        ...deliveryParams,
        sort: woFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc',
        page: 0,
      });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        normalFetchPage: 0,
        deliveryCount: 0,
      });
      history.push({
        search: `sort=${woFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc'}`,
      });
    }
    setOpenSortBy(false);
  };
  const groupByMaterials = (woData) => {
    let uniqueItemId = _.uniqBy(woData, 'itemId');
    // setMaterialList(
    //   uniqueItemId.map((item) => {
    //     return { itemId: item.itemId, itemName: item.itemName, select: false };
    //   })
    // );
  };
  const handleOpenSortBy = () => {
    setOpenSortBy(true);
  };
  const handleCloseSortBy = () => {
    setWofilter({ ...woFilter, LatestonTop: false, OlderonTop: false });
    setOpenSortBy(false);
  };
  const handleOpen = () => {
    setOpenfilter(true);
  };
  const closeFilterModal = () => {
    setOpenfilter(false);
  };
  const handleClose = () => {
    setWofilter(previousFilter);
    closeFilterModal();
  };
  const clearAll = () => {
    setPreviousFilter({ ...previousFilter, deliveryStatus: null, material: null });
    setWofilter({ ...woFilter, deliveryStatus: null, material: null });
    if (searchParams.get('isFilterApplied') !== 'true') setDeliveryList(masterdeliverylist);
    const updatedDelivery = {
      deliveryDateFrom: deliveryParams?.deliveryDateFrom,
      deliveryDateTo: deliveryParams?.deliveryDateTo,
      page: deliveryParams?.page,
      sort: deliveryParams?.sort,
      direction: deliveryParams?.direction,
      browseNodeIds: deliveryParams?.browseNodeIds,
      size: deliveryParams?.size,
    };
    if (searchParams.get('isFilterApplied') !== 'true')
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
      });
    else
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
      });
    history.push({
      search: '',
    });
    setDeliveryParams({ ...updatedDelivery });
    closeFilterModal();
  };

  const applyFilter = () => {
    if (woFilter?.deliveryStatus) {
      setDeliveryList([]);
      const updatedDelivery = {
        deliveryDateFrom: deliveryParams?.deliveryDateFrom,
        deliveryDateTo: deliveryParams?.deliveryDateTo,
        page: 0,
        sort: deliveryParams?.sort,
        direction: deliveryParams?.direction,
        browseNodeIds: deliveryParams?.browseNodeIds,
        size: deliveryParams?.size,
        status: woFilter?.deliveryStatus?.status,
      };
      setDeliveryParams({ ...updatedDelivery });
      searchParams.get('isFilterApplied') === 'true'
        ? history.push({
            search: `status=${woFilter?.deliveryStatus?.status}&sort=${deliveryParams?.sort}&isFilterApplied=true`,
          })
        : history.push({
            search: `status=${woFilter?.deliveryStatus?.status}&sort=${deliveryParams?.sort}`,
          });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        isNormalFetch: false,
        filterFetchPage: 0,
        isFilter: true,
      });
    } else {
      const updatedDelivery = {
        deliveryDateFrom: deliveryParams?.deliveryDateFrom,
        deliveryDateTo: deliveryParams?.deliveryDateTo,
        page: deliveryParams?.page,
        sort: deliveryParams?.sort,
        direction: deliveryParams?.direction,
        browseNodeIds: deliveryParams?.browseNodeIds,
        size: deliveryParams?.size,
      };
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
        isFilter: false,
        page: deliveryDetails?.page + 1,
        isFilterSortFetch: false,
        normalFetchPage: 0,
        filterFetchPage: 0,
        deliveryCount: 0,
        isSearch: false,
        isFilterApplied: searchParams.get('isFilterApplied') || 'false',
      });
      setMasterDeliveryList([]);
      setDeliveryList([]);
      setDeliveryParams({ ...updatedDelivery });
      history.push({
        search: '',
      });
    }
    // setPreviousFilter(woFilter);
    closeFilterModal();
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
      AppAnalytics.clearUserProperties();
      await PushNotification.deleteToken();
      await ChatService().clearUserData();
      setIsModalVisible(false);
    } catch (e) {
    } finally {
      window.location.reload();
    }
  };

  const fetchMoreData = () => {
    if (deliveryDetails.isNormalFetch) {
      if (deliverylist.length !== deliveryDetails.deliveryCount) {
        setDeliveryParams({ ...deliveryParams, page: deliveryDetails.normalFetchPage + 1 });
        setDeliveryDetails({
          ...deliveryDetails,
          isFetchMore: true,
          page: deliveryDetails.page + 1,
          normalFetchPage: deliveryDetails.normalFetchPage + 1,
        });
      } else setDeliveryDetails({ ...deliveryDetails, isFetchMore: false });
    } else if (woFilter?.deliveryStatus) {
      if (deliverylist.length !== count) {
        setDeliveryParams({ ...deliveryParams, page: deliveryDetails.filterFetchPage + 1 });
        setDeliveryDetails({
          ...deliveryDetails,
          isFetchMore: true,
          page: deliveryDetails.page + 1,
          filterFetchPage: deliveryDetails.filterFetchPage + 1,
        });
      } else setDeliveryDetails({ ...deliveryDetails, isFetchMore: false });
    }
  };

  return (
    <Container className={classes.deliveryMain}>
      <AppHeader
        titleRender={(route) => (
          <div className={classes.headerText}>
            <Typography variant={'subtitle2'} noWrap>
              Deliveries
            </Typography>
          </div>
        )}
        leftRender={null}
        rightRender={(route) => (
          <>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit">
              <NotificationIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={() => setOpenModal(true)}>
              <CalendarTodayIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={() => setIsModalVisible(true)}>
              <LogoutIcon style={{ width: '18.462', height: '24' }} />
            </IconButton>
            <Modal
              open={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className={classes.logoutModal}>
              <Paper className={classes.modalBody}>
                <Typography className={classes.modalText}>
                  Are you sure you want to logout?
                </Typography>
                <div className={classes.modalButtonContainer}>
                  <Button
                    variant="contained"
                    className={classes.modalButton}
                    onClick={() => setIsModalVisible(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.modalButton}
                    onClick={handleLogout}>
                    OK
                  </Button>
                </div>
              </Paper>
            </Modal>
          </>
        )}
      />
      <PageView>
        <Grid item xs={12} md={12} sm={6}>
          <Paper className={classes.root} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder="Search open deliveries by work order id, delivery id, po number"
              inputProps={{ 'aria-label': 'search open deliveries' }}
              value={searchValue}
              onChange={(e) => filterDelivery(e.target.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* <List> */}
        <Grid style={{ paddingBottom: '10px' }} spacing={1}>
          {!deliveryLoading && deliverylist?.length === 0 ? (
            <Paper>
              <EmptyView
                hasNoData={isEmpty(deliverylist)}
                className={'p-5'}
                message={'No data for selected criteria'}
              />
            </Paper>
          ) : (
            <div id="scrollableDiv" style={{ maxHeight: '74vh', overflow: 'auto' }}>
              <InfiniteScroll
                dataLength={deliverylist?.length}
                next={fetchMoreData}
                hasMore={deliveryDetails.isFetchMore}
                loader={deliveryLoading ? <h4 style={{ textAlign: 'center' }}>Loading...</h4> : ''}
                scrollableTarget="scrollableDiv">
                {deliverylist?.map((e) => (
                  <Grid
                    key={e.id}
                    className={classes.greyBg}
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    style={{ marginBottom: '10px' }}>
                    <ListItem className={classes.whiteBg} button onClick={() => handleClick(e)}>
                      <Grid container>
                        <ListItemText
                          primary={e.id}
                          secondary={toLocalDate(e.deliveryDate, 'DD MMM hh:mm A')}
                        />
                      </Grid>

                      <Grid container>
                        <ListItemText
                          primary={`${Number(e?.qty) / 1000} MT's`}
                          secondary={<DeliveryStatus status={e?.status} />}
                        />
                      </Grid>
                      <Grid container>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: '11px' }}>
                              <DropIcon style={{ paddingRight: '5px', width: '15px' }} />

                              <Region
                                stateId={e?.deliveryDetails?.pickUpState}
                                districtId={e?.deliveryDetails?.pickUpCity}
                              />
                            </Typography>
                          }
                          secondary={
                            <Typography style={{ fontSize: '11px' }}>
                              <PikupIcon style={{ paddingRight: '5px', width: '15px' }} />

                              {(
                                <Region
                                  stateId={e?.deliveryDetails?.dropOffState}
                                  districtId={e?.deliveryDetails?.dropOffCity}
                                />
                              ) || 'NA'}
                            </Typography>
                          }
                        />
                      </Grid>
                    </ListItem>
                  </Grid>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Grid>
        <Filter
          bottomMargin={{ bottom: 0 }}
          openFilter={openFilter}
          openSortBy={openSortBy}
          onSelect={() => onSelect()}
          handleOpenSortBy={() => handleOpenSortBy()}
          handleCloseSortBy={() => handleCloseSortBy()}
          handleClose={handleClose}
          handleClear={() => clearAll()}
          onDone={() => applyFilter()}
          handleOpen={handleOpen}
          sortByFilter={woFilter}
          setSortByFilter={setWofilter}
          sortByDate={'Delivery Date'}
          Tabs={[
            {
              tabName: 'Delivery Status',
              component: (
                <DeliveryStatusTab selectedStatus={woFilter} setSelectedStatus={setWofilter} />
              ),
            },
            {
              /* tabName: 'Material',
              component: (
                <MaterialTab
                  materialList={materialList}
                  setMaterialList={setMaterialList}
                  selectedStatus={woFilter}
                  setSelectedStatus={setWofilter}
                />
              ),*/
            },
          ]}
        />
        <FinancialYearModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setUpdatedFinancialYear={setUpdatedFinancialYear}
        />
      </PageView>
    </Container>
  );
};

export default DeliveryFulfilmentExecutivePage;
