import types from './types';

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // service worker
    case types.SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case types.SW_UPDATE:
      console.log('update process...', state.serviceWorkerUpdated);
      console.log('update process registration...', action.payload);
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
