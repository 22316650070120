import { useSelector } from 'react-redux';
import { flatMap, keyBy } from 'lodash';
import { useAccount } from './useAccount';

export const useMaterialCategory = () => {
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  return browseNodes;
};

export const useItemCategory = () => {
  const browseNodeItemsData = [];
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  for (let item of browseNodes) {
    const itemData = item?.items?.map((data) => data);
    browseNodeItemsData.push(...itemData);
  }
  return browseNodeItemsData;
};

export const useCombinedMaterialCategory = () => {
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  const newBrowseNodes = [];
  const plasticObj = { id: '4', title: 'Plastic', items: [] };
  browseNodes.forEach((element) => {
    if (['1', '2', '4', '6']?.includes(element?.id)) {
      plasticObj.items = [...element.items, ...plasticObj.items];
    } else {
      newBrowseNodes.push(element);
    }
  });
  newBrowseNodes.unshift(plasticObj);

  return newBrowseNodes;
};

export const useBrowseNodeMap = () => {
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  let categoriesById = keyBy(browseNodes, 'id');
  let itemsById = keyBy(flatMap(browseNodes, 'items'), 'id');
  return { categoriesById, itemsById };
};

export const useCategoryByItemId = () => {
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  const items = keyBy(
    flatMap(
      browseNodes?.map((browseNode) => {
        return browseNode?.items?.map((item) => {
          return { browseNodeId: browseNode?.id, ...item };
        });
      })
    ),
    'id'
  );
  return items;
};

export const useBrowseNodesByVertical = () => {
  const browseNodes = useSelector(({ browseNodes }) => browseNodes.data);
  const account = useAccount();
  return browseNodes?.filter((node) => account?.user?.businessVerticals.includes(node?.vertical));
};
