import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  OutlinedInput,
  Typography,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Section from '../../../../shared/Components/_App/Section';
import SelectDropdown from '../../../../shared/Components/_App/SelectDropdown/SelectDropdown';
import { DriverListView, TransporterListView } from './ListView';
import FormField from '../../../../shared/Components/_App/FormField';
import AppModal from '../../../../shared/Components/_App/AppModal';
import DeliveryTransporterForm from './DeliveryTransporterForm';
import DeliveryDriverForm from './DeliveryDriverForm';
import { ReactComponent as CheckedIcon } from '../../../../assets/img/_App/checked.svg';
import { useTransporter, useDriver } from '../../../../hooks/hooks';
import { hasError, isSpecialCharacter } from '../../../../../src/shared/Utils/Helper';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAsyncEffect } from '../../../../hooks';
import { isEmpty } from 'lodash';

const DeliveryTransporter = (props) => {
  const { createTrasnporterForm, dofetchTransporter, transporterValue, isPRO } = { ...props };
  const [showTranporterModal, setTranporterModal] = useState(false);
  const [showDriverModal, setDriverModal] = useState(false);
  const [driverlist, setDriverList] = useState([]);
  const [showTransListModal, setTransListModal] = useState(false);
  const [showDriverListModal, setDriverListModal] = useState(false);
  const [vehicleError, setVehicleError] = useState(false);
  const [vehicleVerified, setVehicleVerified] = useState(false);
  const [transporterDetails, setTransporterDetails] = useState(transporterValue);
  const [vehicleNumber, setVehicleNumber] = useState(
    createTrasnporterForm?.values?.pickUpVehicleNumber
  );
  const MOBILE_NUMBER_REGEX = '^[6789][0-9]{9}$';
  const [
    { vehicleLoading },
    { doAddTransporter, doUpdateTransporter, doDeleteTransporter, doGetVehicleDetails },
  ] = useTransporter();

  const [{ driverValue }, { dofetchDriver, doUpdateDriver, doAddDriver, doDeleteDriver }] =
    useDriver();

  useAsyncEffect(async () => {
    if (createTrasnporterForm?.values?.transporterId) {
      await dofetchDriver();
    }
  }, []);

  useAsyncEffect(async () => {
    if (driverValue?.length) {
      setDriverList(
        driverValue.filter((e) => e.transporterId === createTrasnporterForm?.values?.transporterId)
      );
    }
  }, [driverValue]);

  const transporterForm = useFormik({
    initialValues: {
      id: null,
      name: '',
      phoneNumber: '',
      contactPerson: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: () => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          name: Yup.string().required('name required'),
          contactPerson: Yup.string().required('ContactPerson required'),
          phoneNumber: Yup.string()
            .matches(MOBILE_NUMBER_REGEX, 'Mobile Number is required')
            .required('Mobile Number is required'),
        });
      });
    },
    onSubmit: (values, actions) => {
      setTransListModal(false);
      addUpdateTransporter(values);
      createTrasnporterForm.setFieldValue('transporterId', values.id);
      setTranporterModal(false);
    },
  });

  const driverForm = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      name: '',
      id: null,
      phoneNumber: '',
    },
    validateOnBlur: true,
    validationSchema: () => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          name: Yup.string().required('Name is required'),
          phoneNumber: Yup.string()
            .matches(MOBILE_NUMBER_REGEX, 'Mobile Number is required')
            .required('Mobile Number is required'),
        });
      });
    },
    onSubmit: (values, actions) => {
      setDriverListModal(false);
      createTrasnporterForm.setFieldValue('driverName', values.name);
      createTrasnporterForm.setFieldValue('driverMobile', values.phoneNumber);
      addUpdateDriver({ ...values, transporterId: createTrasnporterForm?.values?.transporterId });
      setDriverModal(false);
    },
  });

  useEffect(() => {
    if (!showTransListModal) setTransporterDetails(transporterValue);
  }, [showTransListModal]);

  useEffect(() => {
    if (!showDriverListModal && !isEmpty(driverValue))
      setDriverList(
        driverValue.filter((e) => e.transporterId === createTrasnporterForm?.values?.transporterId)
      );
  }, [showDriverListModal]);

  const addUpdateTransporter = async (payload) => {
    const response = payload?.id
      ? await doUpdateTransporter(payload)
      : await doAddTransporter(payload);
    setDriverList(response?.drivers);
    const error = hasError(response);
    if (error) {
      return;
    }
    toast.success(`Transporter ${payload.id ? 'Updated' : 'Added'} Successfully`);
    await dofetchTransporter();
  };

  const verfiyVehicleNumber = async () => {
    setVehicleVerified(false);
    setVehicleError(false);
    try {
      const response = await doGetVehicleDetails({ rcNumber: vehicleNumber.replace(' ', '') });
      if (response.statusCode === 422) {
        setVehicleVerified(false);
        setVehicleError(true);
      } else {
        setVehicleVerified(true);
        setVehicleError(false);
        createTrasnporterForm.setFieldValue(
          'pickUpVehicleNumber',
          response?.result?.registration_no?.toUpperCase() || vehicleNumber
        );
        createTrasnporterForm.setFieldValue('vehicleDetails', response);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  const addUpdateDriver = async (payload) => {
    const response =
      payload?.id !== null ? await doUpdateDriver(payload) : await doAddDriver(payload);
    const error = hasError(response);
    if (error) {
      return;
    }
    toast.success(`Driver ${payload.id ? 'Updated' : 'Added'} Successfully`);
    await dofetchDriver();
  };

  const tranporterHandler = (tranporterObj) => {
    createTrasnporterForm.setFieldValue('transporterId', tranporterObj.id);
    createTrasnporterForm.setFieldValue('driverName', '');
    createTrasnporterForm.setFieldValue('driverMobile', '');
    setDriverList(tranporterObj?.drivers);
    setTranporterModal(false);
    setTransListModal(false);
  };

  const driverHandler = (driverObj) => {
    createTrasnporterForm.setFieldValue('driverName', driverObj.name);
    createTrasnporterForm.setFieldValue('driverMobile', driverObj.phoneNumber);
    setDriverModal(false);
    setDriverListModal(false);
  };

  const addTransporterHandler = () => {
    setTranporterModal(true);
    transporterForm.resetForm();
  };

  const addDriverHandler = () => {
    setDriverModal(true);
    driverForm.handleReset();
  };

  const deleteTransporterHandler = async () => {
    const result = await doDeleteTransporter(transporterForm?.values?.id);
    const error = hasError(result);
    if (error) {
      return;
    }
    await dofetchTransporter();
    toast.success('Transporter Deleted Successfully.');
    setTransListModal(true);
    setTranporterModal(false);
  };

  const deleteDriverHandler = async (deleteType, deleteName) => {
    const driverName = createTrasnporterForm.values.driverName;
    const result = await doDeleteDriver(driverForm?.values?.id);
    const error = hasError(result);
    if (error) {
      return;
    }

    if (deleteType === 'Driver' && deleteName.toUpperCase() === driverName.toUpperCase()) {
      createTrasnporterForm.setFieldValue('driverName', '');
      createTrasnporterForm.setFieldValue('driverMobile', '');
    }

    toast.success('Driver Deleted Successfully.');
    setDriverListModal(true);
    setDriverModal(false);
    await dofetchDriver();
  };

  const handleVehicleNumber = (vehicleNumber) => {
    if (!isSpecialCharacter(vehicleNumber)) setVehicleNumber(vehicleNumber?.toUpperCase());
  };

  const handleTransporterSearch = (Value) => {
    const val = Value?.target?.value?.toUpperCase()?.trim();
    if (val) {
      const originalValue = transporterValue;
      const filteredTransporterValue = originalValue?.filter((data) =>
        data?.name?.toUpperCase()?.trim()?.includes(val)
      );
      setTransporterDetails(filteredTransporterValue);
    } else setTransporterDetails(transporterValue);
  };

  const handleDriverSearch = (Value) => {
    const val = Value?.target?.value?.toUpperCase()?.trim();
    if (val) {
      const originalValue = driverValue.filter(
        (e) => e.transporterId === createTrasnporterForm?.values?.transporterId
      );
      const filteredTransporterValue = originalValue?.filter((data) =>
        data?.name?.toUpperCase()?.trim()?.includes(val)
      );
      setDriverList(filteredTransporterValue);
    } else
      setDriverList(
        driverValue.filter((e) => e.transporterId === createTrasnporterForm?.values?.transporterId)
      );
  };

  return (
    <Section title="SELECT/ADD TRANSPORTER, DRIVER & VEHICLE">
      <FormField label="TRANSPORTER">
        <SelectDropdown
          visible={showTransListModal}
          setVisible={setTransListModal}
          onClick={() => (isPRO ? '' : setTransListModal(true))}
          listData={transporterDetails}
          modalTitle="Select Transporter"
          searchPlaceHolderName="Search Transporter"
          value={
            transporterDetails?.find((e) => e.id === createTrasnporterForm.values.transporterId)
              ?.name || ''
          }
          onSearch={handleTransporterSearch}
          renderItem={(e, idx) => (
            <TransporterListView
              onClick={() => tranporterHandler(e)}
              transObj={e}
              onEdit={() => {
                transporterForm.setValues(e);
                setTranporterModal(true);
              }}
            />
          )}
          addHandler={addTransporterHandler}
          actions
        />
      </FormField>
      <FormField label="TRUCK NUMBER">
        <FormControl variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            type={'text'}
            style={{ border: vehicleError === true ? '1px solid red' : '' }}
            value={vehicleNumber?.toUpperCase() || ''}
            onChange={(e) => handleVehicleNumber(e.target.value)}
            disabled={isPRO}
            endAdornment={
              <InputAdornment position="end">
                {createTrasnporterForm?.values?.vehicleDetails?.response_code === '1' &&
                createTrasnporterForm?.values?.pickUpVehicleNumber ===
                  vehicleNumber.replace(' ', '') ? (
                  <CheckedIcon style={{ width: '20px', height: '20px' }} />
                ) : vehicleVerified ? (
                  <CheckedIcon style={{ width: '20px', height: '20px' }} />
                ) : (
                  <InputLabel
                    onClick={() => verfiyVehicleNumber()}
                    disabled={vehicleLoading}
                    style={{ color: '#005dff' }}
                    edge="end">
                    Verify
                  </InputLabel>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </FormField>
      {vehicleLoading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size="2rem" />
        </div>
      ) : (
        <div
          style={{
            borderStyle: 'dashed',
            borderWidth: 'thin',
            backgroundColor: '#f7faff',
            borderRadius: 5,
            padding: 10,
            marginBottom: 16,
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div>
              <Typography variant="caption" display="block" gutterBottom>
                VEHICLE CLASS
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                <CheckedIcon />{' '}
                {createTrasnporterForm?.values?.vehicleDetails?.result?.vehicle_class || 'N/A'}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" display="block" gutterBottom>
                FULE TYPE
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                <CheckedIcon />{' '}
                {createTrasnporterForm?.values?.vehicleDetails?.result?.fuel || 'N/A'}
              </Typography>
            </div>
          </div>
          <Typography variant="caption" display="block" gutterBottom>
            MODEL & MANUFACTURE DETAILS
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            <CheckedIcon /> {createTrasnporterForm?.values?.vehicleDetails?.result?.maker || 'N/A'}
          </Typography>
        </div>
      )}

      <FormField label="DRIVER">
        <SelectDropdown
          visible={showDriverListModal}
          setVisible={setDriverListModal}
          onClick={() => (isPRO ? '' : setDriverListModal(true))}
          listData={driverlist}
          modalTitle="Truck Driver"
          searchPlaceHolderName="Search Driver"
          value={createTrasnporterForm.values.driverName}
          onSearch={handleDriverSearch}
          renderItem={(e, idx) => (
            <DriverListView
              onClick={() => driverHandler(e)}
              driverObj={e}
              onEdit={() => {
                driverForm.setValues(e);
                setDriverModal(true);
              }}
            />
          )}
          addHandler={addDriverHandler}
          actions
        />
      </FormField>

      <OutlinedInput
        margin="dense"
        className="m-0"
        id="outlined-error-helper-text"
        fullWidth
        style={{ borderStyle: 'dashed', borderWidth: 'thin' }}
        disabled
        startAdornment={<InputAdornment position="start">DRIVER NUMBER</InputAdornment>}
        endAdornment={
          <InputAdornment position="end">
            <>{createTrasnporterForm.values.driverMobile}</>
          </InputAdornment>
        }
      />
      <AppModal
        title={`${transporterForm?.values?.id ? 'Edit' : 'Add'}  Transporter`}
        backArrowDisplay={true}
        editMode={transporterForm?.values?.id}
        deleteHandler={deleteTransporterHandler}
        visible={showTranporterModal}
        setVisible={setTranporterModal}>
        <DeliveryTransporterForm transporterForm={transporterForm} isPRO={isPRO} />
      </AppModal>
      <AppModal
        title={`${driverForm?.values?.id ? 'Edit' : 'Add'}  Driver`}
        backArrowDisplay={true}
        editMode={driverForm?.values?.id}
        deleteHandler={deleteDriverHandler}
        visible={showDriverModal}
        setVisible={setDriverModal}
        editType="Driver"
        editName={driverForm?.values?.name}>
        <DeliveryDriverForm
          transporterId={createTrasnporterForm?.values?.transporterId}
          driverForm={driverForm}
          transporterValue={transporterValue}
          isPRO={isPRO}
        />
      </AppModal>
    </Section>
  );
};

export default DeliveryTransporter;
