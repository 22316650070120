import { BrowseNodeAPI } from './browseNodeAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchBrowseNodes = createAsyncThunk('browseNodes/fetchBrowseNodes', async () => {
  const response = await BrowseNodeAPI.fetchBrowseNodes();
  return response.data;
});
export const fetchImpactMetrics = createAsyncThunk('browseNodes/fetchImpactMetrics', async () => {
  const response = await BrowseNodeAPI.fetchImpactMetrics();
  return response.data;
});
