import React from 'react';
import './QuantityOverviewCard.scss';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';

const QuantityOverviewCard = ({ title, value, percent, children, path }) => {
  const history = useHistory();

  return (
    <Card
      className="QuantityOverviewCard"
      onClick={() => {
        history.push(path);
      }}>
      <h6>{title}</h6>
      <h3>
        <CountUp start={0} separator={','} end={value} duration={1} />
        <span className="text-small"> kg</span>
      </h3>
      {percent != undefined ? <div className="top-badge">{Math.round(percent)}%</div> : null}
      {children}
    </Card>
  );
};

QuantityOverviewCard.propTypes = {};

export default QuantityOverviewCard;
