import { DIM } from './Dims';
import { Colors } from './Colors';

const GlobalStyles = {
  screenContainer: {
    flex: 1,
    marginTop: 14,
    padding: DIM.screenPadding,
    backgroundColor: Colors.screenBgColor,
  },
  section: {
    backgroundColor: Colors.white,
    padding: DIM.sectionPadding,
  },
  listStyle: {
    overflow: 'auto',
    height: `calc( 100vh -  ${DIM.headerHeight}px )`,
  },
};

export default GlobalStyles;
