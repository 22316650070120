import * as firebase from 'firebase/app';
import 'firebase/messaging';

class PushNotification {
  static initializeFirebase;
  static messaging;
  static fcmToken = null;
  /**
   * Initialize notification
   */
  static init = () => {
    console.log('Init Analytics');
    this.initializeFirebase = firebase.initializeApp({
      apiKey: 'AIzaSyAOzEM6K__Maig4NwWeeucRltk95R1r11k',
      authDomain: 'recykal-stag.firebaseapp.com',
      databaseURL: 'https://recykal-stag.firebaseio.com',
      projectId: 'recykal-stag',
      storageBucket: 'recykal-stag.appspot.com',
      messagingSenderId: '57664449267',
      appId: '1:57664449267:web:2a7bc3957f62c53858e586',
    });

    this.messaging = this.initializeFirebase.messaging();

    this.messaging.usePublicVapidKey(
      // Project Settings => Cloud Messaging => Web Push certificates
      'BIbhKaWS6TGbMCuszwQQ5CO5HCXVKhr2KMuO2zNuN364sWfZfkzDlTE_0Hx5Qrtx_ajFN_hlh4GZm2i0_WRjmtg'
    );

    this.messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      // ...
    });
  };

  static deleteToken = async () => {
    if (!this.fcmToken) return;
    return this.messaging.deleteToken(this.fcmToken);
  };
  static askForPermissionToReceiveNotifications = async () => {
    try {
      await this.messaging.requestPermission();
      const token = await this.messaging.getToken();
      this.fcmToken = token;
      return token;
    } catch (error) {
      return error;
    }
  };
}

export default PushNotification;
