import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Icon, SvgIcon, Typography } from '@material-ui/core';
import EmptyIcon from '../../../assets/img/_App/empty.png';
import EmptyView from './EmptyView';
const PageView = (props) => {
  const {
    loading,
    height,
    hasNoData,
    children,
    title = 'Not Results!',
    message = 'There is data to display',
  } = props;
  return loading || hasNoData ? (
    <div
      style={{ height: height || '90vh' }}
      className={'d-flex align-items-center justify-content-center text-center'}>
      {loading ? (
        <CircularProgress />
      ) : hasNoData ? (
        <EmptyView hasNoData={hasNoData} title={title} message={message} />
      ) : null}
    </div>
  ) : (
    children
  );
};

PageView.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.any,
  message: PropTypes.any,
  hasNoData: PropTypes.bool,
};

export default PageView;
