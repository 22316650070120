import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',

    backgroundColor: '#2e384d',

    // height: '50px',
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: '#2e384d',
    width: '100%',
    height: '50px',
    color: '#FFFFFF',
  },
  toolBar: { paddingLeft: 0, paddingRight: 0, minHeight: 'auto' },
  verticalDivider: { backgroundColor: '#ffffff' },
}));
