import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { EX_SUMMARY_VIEW } from '../Constants/Constants';
import { toLocalDate } from '../Utils/Helper';
import { useFinancialYear } from '../../hooks/useFinancialYear';

const SummaryViewToggleButton = ({ handleViewChange, activeView }) => {
  const { isCurrentFinancialYear, selectedFinancialYear } = useFinancialYear();

  useEffect(() => {
    if (!isCurrentFinancialYear) {
      activeView !== EX_SUMMARY_VIEW.FY && handleViewChange('FY');
    } else {
      activeView !== EX_SUMMARY_VIEW.TILL_MONTH && handleViewChange(EX_SUMMARY_VIEW.TILL_MONTH);
    }
  }, [selectedFinancialYear]);

  return (
    <>
      {isCurrentFinancialYear && (
        <ToggleButtonGroup
          className="view-toggle"
          name="execution_view"
          as={'div'}
          value={activeView}
          type="radio"
          onChange={handleViewChange}>
          <ToggleButton
            size="sm"
            key={EX_SUMMARY_VIEW.FY}
            variant="secondary"
            value={EX_SUMMARY_VIEW.FY}>
            FY{' '}
            {`${toLocalDate(selectedFinancialYear?.fromDate, 'YYYY')}-${toLocalDate(
              selectedFinancialYear?.toDate,
              'YYYY'
            )}`}
          </ToggleButton>
          <ToggleButton
            size="sm"
            key={EX_SUMMARY_VIEW.TILL_MONTH}
            variant="secondary"
            value={EX_SUMMARY_VIEW.TILL_MONTH}>
            Year-To-Month
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
};

SummaryViewToggleButton.propTypes = {
  handleViewChange: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
};

export default SummaryViewToggleButton;
