import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import StyledRadio from '../StyledRadio/StyledRadio';

import { useStyles } from './SortByModel.styles';

const SortByModel = ({ open, onSelect, onClose, sortByFilter, setSortByFilter, sortByDate }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    if (event.target.value === 'Latest on Top')
      setSortByFilter({ ...sortByFilter, LatestonTop: true, OlderonTop: false });
    else setSortByFilter({ ...sortByFilter, LatestonTop: false, OlderonTop: true });
    setValue(value === event.target.value ? null : event.target.value);
  };
  useEffect(() => {
    sortByFilter?.LatestonTop === true ? setValue('Latest on Top') : setValue('Older on Top');
  }, []);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <h3 id="transition-modal-title">Sort By</h3>
          <FormLabel component="legend" className={classes.span}>
            DATE : {sortByDate || 'NA'}
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="customized-radios"
            value={value}
            onChange={handleChange}>
            <FormControlLabel
              value="Latest on Top"
              control={<StyledRadio />}
              label="Latest on Top"
            />
            <FormControlLabel
              value="Older on Top"
              control={<StyledRadio />}
              label="Oldest on Top"
            />
          </RadioGroup>
          <Divider className={classes.horizontalDivider} />
          <div className={classes.buttonGroup}>
            <Button className={classes.closeButton} onClick={onClose}>
              Close
            </Button>
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Button className={`${classes.closeButton} ${classes.selectButton}`} onClick={onSelect}>
              Select
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SortByModel;
