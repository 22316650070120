import React, { useEffect, useState } from 'react';
import './NotificationPermissionBar.scss';
import { Button } from 'react-bootstrap';
import PushNotification from '../../Config/push-notification';
import { useDevice } from '../../../hooks';
import { FCM_ERROR_CODE } from '../../Constants/Constants';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotificationPermissionBar = () => {
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const { device, sendDeviceInfo } = useDevice();
  useEffect(() => {
    requestPermission();
    updateRunCount();
  }, []);

  const updateRunCount = () => {
    let runCount = localStorage.getItem('runCount') || '0';
    runCount = parseInt(runCount);
    runCount++;
    localStorage.setItem('runCount', runCount?.toString());
  };

  const requestPermission = () => {
    PushNotification.askForPermissionToReceiveNotifications().then(
      (deviceToken) => {
        if (deviceToken.code === FCM_ERROR_CODE.PERMISSION_BLOCKED) {
          sendDeviceInfo();
          setShowNotificationBar(true);
        } else if (typeof deviceToken === 'string') {
          sendDeviceInfo({ deviceToken });
          setShowNotificationBar(false);
        } else {
          sendDeviceInfo();
        }
      }
    );
  };
  return showNotificationBar ? (
    <div className="NotificationPermissionBar">
      <div>
        <span className="mr-3">Please Enable notification from settings</span>
        {/*<Button size={'sm'} onClick={requestPermission}>*/}
        {/*  Enable*/}
        {/*</Button>*/}
        <Button
          className="text-white"
          size={'sm'}
          variant={'link'}
          style={{ float: 'right' }}
          onClick={() => setShowNotificationBar(false)}
        >
          <FontAwesomeIcon className={''} icon={faTimes} />
        </Button>
      </div>
    </div>
  ) : null;
};

export default NotificationPermissionBar;
