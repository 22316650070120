import React from 'react';

function FetchIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1430_5484)">
        <path
          d="M15.6656 5.10625C14.8969 2.64062 12.5938 0.9375 10 0.9375C7.40625 0.9375 5.10313 2.64062 4.33437 5.10625C2.17188 5.5625 0.625 7.45625 0.625 9.6875C0.625 12.1375 2.5375 14.1906 4.97813 14.3625C4.98438 14.3625 4.99375 14.3625 5 14.3625C5.1625 14.3625 5.3 14.2375 5.3125 14.0719C5.325 13.9 5.19375 13.75 5.02187 13.7375C2.90625 13.5875 1.25 11.8094 1.25 9.6875C1.25 7.69687 2.67188 6.01562 4.63438 5.6875C4.75313 5.66875 4.85 5.57812 4.88438 5.4625C5.51875 3.16562 7.62187 1.5625 10.0031 1.5625C12.3844 1.5625 14.4875 3.16562 15.1219 5.4625C15.1531 5.58125 15.25 5.66875 15.3719 5.6875C17.3312 6.01562 18.7563 7.7 18.7563 9.6875C18.7563 10.8906 18.2281 12.0219 17.3062 12.7937C16.6438 13.3531 15.8406 13.6812 14.9844 13.7406C14.8125 13.7531 14.6813 13.9031 14.6938 14.075C14.7063 14.2469 14.8531 14.3781 15.0281 14.3656C16.0188 14.2969 16.9469 13.9187 17.7094 13.275C18.7719 12.3875 19.3813 11.0812 19.3813 9.69062C19.3813 7.4625 17.8313 5.56562 15.6719 5.10937L15.6656 5.10625Z"
          fill="#9EA9C1"
        />
        <path
          d="M15.9087 12.9406C15.9587 12.8281 15.9399 12.6938 15.8556 12.6031L13.0431 9.47812C12.9243 9.34687 12.6993 9.34687 12.5806 9.47812L9.76805 12.6031C9.68368 12.6938 9.66493 12.8281 9.71493 12.9406C9.76493 13.0531 9.8743 13.125 9.9993 13.125H11.5618V18.75C11.5618 18.9219 11.7024 19.0625 11.8743 19.0625H13.7493C13.9212 19.0625 14.0618 18.9219 14.0618 18.75V13.125H15.6243C15.7493 13.125 15.8587 13.0531 15.9087 12.9406ZM9.9993 15.3125H8.4368V9.6875C8.4368 9.51562 8.29618 9.375 8.1243 9.375H6.2493C6.07743 9.375 5.9368 9.51562 5.9368 9.6875V15.3125H4.3743C4.2493 15.3125 4.13992 15.3844 4.08992 15.4969C4.03992 15.6094 4.05867 15.7437 4.14305 15.8344L6.95555 18.9594C7.01493 19.025 7.0993 19.0625 7.1868 19.0625C7.2743 19.0625 7.35868 19.025 7.41805 18.9594L10.2306 15.8344C10.3149 15.7437 10.3337 15.6094 10.2837 15.4969C10.2337 15.3844 10.1243 15.3125 9.9993 15.3125Z"
          fill="#9EA9C1"
        />
      </g>
      <defs>
        <clipPath id="clip0_1430_5484">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FetchIcon;
