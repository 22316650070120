import React from 'react';

function ArrowIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.33203 16.0003C5.33203 15.2639 5.92898 14.667 6.66536 14.667H25.332C26.0684 14.667 26.6654 15.2639 26.6654 16.0003C26.6654 16.7367 26.0684 17.3337 25.332 17.3337H6.66536C5.92898 17.3337 5.33203 16.7367 5.33203 16.0003Z"
        fill="#667085"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9415 5.72353C17.4622 6.24423 17.4622 7.08845 16.9415 7.60915L8.55098 15.9997L16.9415 24.3902C17.4622 24.9109 17.4622 25.7551 16.9415 26.2758C16.4208 26.7965 15.5766 26.7965 15.0559 26.2758L5.72256 16.9425C5.20186 16.4218 5.20186 15.5776 5.72256 15.0569L15.0559 5.72353C15.5766 5.20283 16.4208 5.20283 16.9415 5.72353Z"
        fill="#667085"
      />
    </svg>
  );
}

export default ArrowIcon;
