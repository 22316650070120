import React from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import FormField from '../../../../shared/Components/_App/FormField';
import Section from '../../../../shared/Components/_App/Section';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: 'blue',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
}));
const SupervisorForm = (props) => {
  const classes = styles();
  const { supervisorForm } = { ...props };
  return (
    <Box>
      <Section>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField
              label="SUPERVISOR NAME"
              value={supervisorForm.values.name}
              error={supervisorForm.errors?.name}
              isValid={!supervisorForm.errors?.name && supervisorForm.dirty}
              onChange={supervisorForm.handleChange('name')}
              onBlur={supervisorForm.handleBlur('name')}
              touched={supervisorForm.touched?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="MOBILE NUMBER"
              value={supervisorForm.values.phoneNumber}
              error={supervisorForm.errors?.phoneNumber}
              isValid={!supervisorForm.errors?.phoneNumber && supervisorForm.dirty}
              onChange={supervisorForm.handleChange('phoneNumber')}
              onBlur={supervisorForm.handleBlur('phoneNumber')}
              touched={supervisorForm.touched?.phoneNumber}
            />
          </Grid>
        </Grid>
      </Section>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={!supervisorForm.isValid || !supervisorForm.dirty}
        onClick={() => supervisorForm.handleSubmit()}>
        {' '}
        Save & Select
      </Button>
    </Box>
  );
};

export default SupervisorForm;
