import React, { useState } from 'react';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Typography,
  Link,
  ListItemIcon,
} from '@material-ui/core';
import { ReactComponent as DateIcon } from '../../../../assets/img/_App/planner.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/img/_App/wall_clock.svg';
import { toLocalDate } from '../../../../shared/Utils/Helper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'end',
  },
  linkStyl: {
    color: '#33ab2e',
  },
}));
const DeliveryScheduleDetails = (props) => {
  const { expand, data, showEditChange, isPRO } = { ...props };
  const classes = useStyles();
  const [showPickupDateTimeCard, setPickupDateTimeCard] = useState(!expand);

  const onExpand = () => {
    if (expand) setPickupDateTimeCard(!showPickupDateTimeCard);
  };
  return (
    <Paper elevation={0} className="mb-3 mt-3">
      <div onClick={() => onExpand()}>
        <List style={{ paddingBottom: showPickupDateTimeCard ? 8 : 0 }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  className="mb-2"
                  variant="body1"
                  color="textPrimary"
                  style={{ color: '#2e384d', fontFamily: 'font-semibold' }}>
                  Pickup Date & Time
                </Typography>
              }
            />
            {!isPRO && (
              <ListItemSecondaryAction>
                {expand ? (
                  showPickupDateTimeCard ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : (
                  <ListItemText
                    primary={
                      <Typography className={`${classes.alignRight} mb-2`}>
                        <Link style={{ color: '#33ab2e' }} onClick={showEditChange}>
                          Change
                        </Link>
                      </Typography>
                    }
                  />
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <Divider />
        </List>
      </div>

      {showPickupDateTimeCard && (
        <ListItem alignItems="center">
          <ListItemIcon style={{}}>
            <DateIcon />
          </ListItemIcon>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Pickup Date</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {toLocalDate(data?.pickUpLoadingDate, 'DD-MMM-YYYY') || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemIcon style={{}}>
            <ClockIcon />
          </ListItemIcon>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Pickup Time</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {toLocalDate(data?.pickUpLoadingDate, 'hh:mm a') || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      )}
    </Paper>
  );
};

export default DeliveryScheduleDetails;
