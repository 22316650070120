import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Link,
  makeStyles,
  Paper,
  ListItemAvatar,
  Grid,
} from '@material-ui/core';

import { useRegionsMap } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  mainHead: {
    color: '#2e384d',
    fontFamily: 'font-semibold',
  },
  alignRight: {
    textAlign: 'end',
  },
  linkStyl: {
    color: '#33ab2e',
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DeliveryUlbDetails = (props) => {
  const { expand, data, showEditChange, isEditable, isPRO } = { ...props };
  const classes = useStyles();
  const [showLocationCard, setLocationCard] = useState(!expand);
  const regions = useRegionsMap();

  return (
    <Paper elevation={0} className="mb-3">
      <List style={{ paddingBottom: showLocationCard ? 8 : 0 }}>
        <ListItem>
          <ListItemText
            primary={
              <Typography
                className={`${classes.mainHead} mb-2`}
                variant="body1"
                color="textPrimary">
                ULB Information
              </Typography>
            }
          />
          {!isPRO && (
            <ListItemText
              primary={
                isEditable && (
                  <Typography className={`${classes.alignRight} mb-2`}>
                    <Link href="#" className={classes.linkStyl} onClick={showEditChange}>
                      Change
                    </Link>
                  </Typography>
                )
              }
            />
          )}
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar style={{ minWidth: '19px' }}></ListItemAvatar>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>ULB Name</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {data?.ulbName || 'NA'}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          {' '}
          <ListItemAvatar style={{ minWidth: '19px' }}></ListItemAvatar>
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography>Address</Typography>
                <Typography component="span" variant="body2" color="textPrimary">
                  {data?.ulbAddress || 'NA'}
                </Typography>
                <Grid container className="pt-2">
                  <Grid xs="4">
                    <Typography>State</Typography>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {data.ulbState ? regions[data.ulbState]?.name : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid xs="4">
                    <Typography>District</Typography>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {data.ulbDistrict
                        ? regions[data.ulbState]?.districts[data.ulbDistrict]?.name
                        : 'N/A'}{' '}
                    </Typography>
                  </Grid>
                  <Grid xs="2">
                    <Typography>Pincode</Typography>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {data.ulbPincode ? data.ulbPincode : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default DeliveryUlbDetails;
