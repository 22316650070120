import API from '../../shared/Config/Api';
import ApiEndpoints from '../../shared/Config/ApiEndpoints.const';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

export const fetchUnplannedWorkOrders = async (params) => {
  return await API.get(APIEndpoints.FETCH_UNPLANNED_WORK_ORDERS, null, params);
};
export const fetchUnplannedWorkOrdersNew = async (params) => {
  return await API.get(APIEndpoints.FETCH_UNPLANNED_WORK_ORDERS_NEW, null, params);
};

export const fetchPlanningData = async (workOrderId) => {
  return await API.get(APIEndpoints.UNPLANNED_PLAN + workOrderId);
};

export const fetchSupplyFromVendors = async (params) => {
  return await API.get(ApiEndpoints.SUPPLY_FROM_VENDORS, null, params);
};

export const createDraftWorkOrder = async (payload) => {
  return await API.post(APIEndpoints.CREATE_DRAFT_WORK_ORDER, payload);
};

export const updateDraftWorkOrder = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_DRAFT_WORK_ORDER, payload);
};

export const deleteDraftWorkOrder = async (payload) => {
  return await API.delete(APIEndpoints.DELETE_DRAFT_WORK_ORDER + payload);
};

export const planningModuleCards = async (payload) => {
  return await API.get(APIEndpoints.PLANING_MODULES_CARDS, null, payload);
};

export const fetchBrandwisePlannedWorkOrderById = async (workOrderId) => {
  return await API.get(APIEndpoints.FETCH_BRANDWISE_PLANNED_WORK_ORDER_BY_ID + workOrderId);
};

export const fetchBrandwisePlannedDeliveriesByWorkOrderId = async (payload) => {
  return await API.get(APIEndpoints.DELIVERIES, null, payload);
};

export const freeWoQuantity = async (payload) => {
  return await API.put(APIEndpoints.FREE_WO_QUANTITY, null, payload);
};

export const closeWorkOrders = async (payload) => {
  return await API.put(APIEndpoints.WORK_ORDERS_CLOSE, payload);
};

export const fetchBrandAllocationDetails = async (payload) => {
  return await API.get(APIEndpoints.FETCH_BRAND_ALLOCATION_DETAILS, null, payload);
};

export const fetchBrandAllocationDetailsNew = async (payload) => {
  return await API.get(APIEndpoints.FETCH_BRAND_ALLOCATION_DETAILS_NEW, null, payload);
};

export const fetchVendorCapacities = async () => {
  return (await API.get(APIEndpoints.CUSTOMER_CAPACITIES))?.data;
};

export const fetchBrandSpecialDocuments = async () => {
  return (await API.get(APIEndpoints.BRAND_DOCUMENTS))?.data;
};

export const allocateWoQuantity = async (payload) => {
  return await API.put(APIEndpoints.ALLOCATE_WO_QUANTITY, null, payload);
};

export const getSPOverviewByCategory = async (params) => {
  return await API.get(APIEndpoints.GET_SP_BRIEF_OVERVIEW_BY_CATEGORY, null, params);
};
