import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const getAccount = async () => {
  return await API.get(APIEndpoints.GET_ACCOUNT, {
    ignoreError: true,
  });
};
const updateDevice = async (payload) => {
  return await API.post(APIEndpoints.DEVICE_DETAILS, payload, {
    ignoreError: true,
  });
};
const fetchMyClients = async () => {
  return await API.get(APIEndpoints.MY_CLIENTS + '?size=1000');
};

const updateProfileInfo = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_PROFILE_INFO_DETAILS, payload);
};
const updateContactPerson = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_CONTACT_PERSON_DETAILS, payload);
};
const uploadLogo = async (payload) => {
  return await API.post(APIEndpoints.UPLOAD_PROFILE_LOGO, payload);
};
const changePassword = async (payload) => {
  return await API.post(APIEndpoints.UPDATE_PASSWORD, payload);
};
const updateProfile = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_PROFILE, payload);
};
const getGlobalNotifications = async () => {
  return await API.get(APIEndpoints.GET_ACTIVITY_LOG, {
    ignoreError: true,
  });
};
const setNotificationTapped = async (payload) => {
  return await API.post(APIEndpoints.SET_LOG_READ + '?id=' + payload);
};
const getTimelineLogs = async () => {
  const response = await API.get(APIEndpoints.GET_TIMELINE_LOG);
  return response;
};

export const AccountAPI = {
  getAccount,
  updateDevice,
  fetchMyClients,
  updateProfileInfo,
  updateContactPerson,
  uploadLogo,
  changePassword,
  updateProfile,
  getGlobalNotifications,
  setNotificationTapped,
  getTimelineLogs,
};
