import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchWorkOrderItems = async (params) => {
  return await API.get(APIEndpoints.WORK_ORDER_ITEMS, null, params);
};

const fetchPendingWorkOrders = async (params) => {
  return await API.get(APIEndpoints.WORK_ORDERS_PENDING_ITEMS, null, params);
};

const fetchPendingWO = async (params) => {
  return await API.get(APIEndpoints.PENDING_WO, null, params);
};

const fetchWorkOrderById = async (workOrderId, params) => {
  return await API.get(APIEndpoints.WORK_ORDERS + '/' + workOrderId, null, params);
};

const fetchWorkOrderLogs = async (workOrderId) => {
  return await API.get(APIEndpoints.WORK_ORDER_LOG, null, {
    'workOrderId.equals': workOrderId,
    sort: 'changedOn,desc',
  });
};

const fetchWorkOrdersByBrandId = async (params) => {
  return await API.get(APIEndpoints.WORK_ORDERS_BY_BRAND, null, params);
};

const fetchAllWorkOrders = async (params) => {
  return await API.get(APIEndpoints.WORK_ORDERS, null, params);
};

const updateWorkOrder = async (payload) => {
  return await API.put(APIEndpoints.WORK_ORDERS, payload);
};

const deleteWorkOrderDocument = async (docId) => {
  return await API.delete(APIEndpoints.DELETE_WO_DOC + '/' + docId);
};

const deleteWorkOrder = async (workOrderId) => {
  return await API.delete(APIEndpoints.WORK_ORDERS + '/' + workOrderId);
};

const createWorkOrder = async (payload) => {
  return await API.post(APIEndpoints.WORK_ORDERS, payload);
};

const uploadDocuments = async (docDetails) => {
  return await API.post(APIEndpoints.DOCUMENT_ATTACH, docDetails);
};

const getOrderSummary = async (fromDate, toDate) => {
  return (await API.get(APIEndpoints.GET_ORDER_SUMMARY, null, { fromDate, toDate })).data;
};

const closeWorkOrders = async (payload) => {
  return await API.put(APIEndpoints.WORK_ORDERS_CLOSE, payload);
};

const openWorkOrder = async (payload) => {
  return await API.put(APIEndpoints.WORK_ORDERS_OPEN, payload);
};

const fetchFulfilmentPlan = async (woid, plans) => {
  return await API.get([APIEndpoints.WORK_ORDERS, woid, 'fulfilment-plan'].join('/'));
};
const updateFulfilmentPlan = async (woid, plans) => {
  return await API.post([APIEndpoints.WORK_ORDERS, woid, 'fulfilment-plan'].join('/'), plans);
};

const getDraftWorkOrders = async (params) => {
  return await API.get(APIEndpoints.WORK_ORDERS, null, params);
};

const acceptRejectDraftWorkOrders = async (params) => {
  return await API.put(APIEndpoints.ACCEPT_REJECT_DRAFT_WORK_ORDER, null, params);
};

export const WorkOrderService = {
  fetchWorkOrderItems,
  fetchPendingWorkOrders,
  fetchWorkOrderById,
  updateWorkOrder,
  deleteWorkOrderDocument,
  deleteWorkOrder,
  createWorkOrder,
  uploadDocuments,
  getOrderSummary,
  fetchAllWorkOrders,
  closeWorkOrders,
  openWorkOrder,
  fetchWorkOrderLogs,
  fetchFulfilmentPlan,
  updateFulfilmentPlan,
  getDraftWorkOrders,
  acceptRejectDraftWorkOrders,
  fetchPendingWO,
  fetchWorkOrdersByBrandId,
};
