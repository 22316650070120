import React from 'react';
import Slider from 'react-slick';
import DashBoardOverViewByBrand from '../DashboardOverViewByBrand';
import { Col, Row } from 'react-bootstrap';

const settings = {
  infinite: false,
  speed: 100,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
};
const DashboardOverviewByCustomer = (props) => {
  const { data, direction, title } = props;

  return (
    <div className="DashboardOverviewByCustomer p-4">
      <Row className="">
        <Col xs={12}>
          <h6 className="text-medium">{title}</h6>
        </Col>
        <Col xs={12}>
          {data.length > 0 ? (
            <Slider {...settings}>
              {data.map((cust, idx) => {
                return (
                  <DashBoardOverViewByBrand
                    key={`xx${idx}`}
                    brandData={cust}
                    direction={direction}
                  />
                );
              })}
            </Slider>
          ) : (
            <p className="p-4 text-muted" style={{ textAlign: 'center' }}>
              No data found
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
};

DashboardOverviewByCustomer.propTypes = {};

export default DashboardOverviewByCustomer;
