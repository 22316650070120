import React, { useEffect } from 'react';
import './AddProDetails.scss';
import { Button, Spinner } from 'react-bootstrap';
import back from '../../../assets/img/back.png';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { publish } from '../../../shared/Events/AppEvents';
import { APP_EVENTS } from '../../../shared/Constants/Constants';

const AddProDetails = (props) => {
  //call ComponentOnMount
  useEffect(() => {
    props.setHeader(
      <>
        <img onClick={props.onBack} src={back} />
        <h5 className="heading mt-3 mb-4  ">EPR Registration</h5>
      </>
    );
  }, []);

  const validate = (values) => {
    const errors = {};
    //radio validation
    if (values.hasEprAccount === 'true' && !values.regulatorRegNo) {
      errors.regulatorRegNo = 'PWM Number required';
    }
    if (!values.privacy) {
      errors.privacy = 'Accept Privacy policy';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      hasEprAccount: JSON.stringify(props.hasEprAccount),
      regulatorRegNo: props.regulatorRegNo,
      privacy: false,
    },
    validate,
    validateOnMount: false,
    onSubmit: (values) => {
      props.onRegister(values);
    },
  });

  function showPrivacyPolicy() {
    window.open('https://recykal.com/recykal-terms-conditions/', '_blank');
    publish(APP_EVENTS.topic.PRIVACY_POLICY, APP_EVENTS.event.SHOW_PRIVACY_MODAL, {
      hideAccept: true,
    });
  }
  return (
    <div className="sign-up animated fadeIn">
      <Form onSubmit={formik.handleSubmit} noValidates>
        <p className="fs-16 text-medium">Do you have an EPR registration? {formik.hasEprAccount}</p>
        <Form.Group className="mb-4 radio-custom text-regular">
          <Form.Check
            custom
            inline
            label="Yes"
            type="radio"
            name="hasEprAccount"
            value="true"
            checked={formik.values.hasEprAccount === 'true'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="Yes"
          />
          <Form.Check
            custom
            inline
            label="No"
            value="false"
            name="hasEprAccount"
            type="radio"
            checked={formik.values.hasEprAccount === 'false'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="no"
          />
        </Form.Group>
        {formik.values.hasEprAccount === 'true' ? (
          <Form.Group className="mb-4">
            <Form.Label className="mb-0 text-medium">Enter PWM no.</Form.Label>
            <Form.Control
              className="p-0 float-label"
              type="text"
              required
              {...formik.getFieldProps('regulatorRegNo')}
              isInvalid={!!formik.errors.regulatorRegNo && formik.touched.regulatorRegNo}
            />
            <Form.Control.Feedback type="invalid">Enter valid PWM number</Form.Control.Feedback>
          </Form.Group>
        ) : null}

        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            required
            {...formik.getFieldProps('privacy')}
            type="checkbox"
            label={
              <span>
                I have read and agree to the{' '}
                <span className="pointer text-primary" onClick={showPrivacyPolicy}>
                  Privacy Policy
                </span>
              </span>
            }
          />
        </Form.Group>

        <Button
          className=" float-right px-4 my-4"
          size="lg"
          disabled={!formik.isValid || props.loading}
          variant="primary"
          type="submit">
          {props.loading ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span> Loading</span>
            </div>
          ) : (
            'Sign up'
          )}
        </Button>
      </Form>
    </div>
  );
};

export default AddProDetails;
