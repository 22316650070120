// TODO remove usage of this function and use hasAuthority
const isUserBrand = (accountData) => {
  const brandIndex = accountData?.user?.profile?.productSubscriptions?.findIndex((productSub) => {
    return productSub?.actor === 'BRAND';
  });
  return brandIndex !== -1;
};

// TODO remove usage of this function and use hasAuthority
const isUserServiceProvider = (accountData) => {
  const brandIndex = accountData?.user?.profile?.productSubscriptions?.findIndex((productSub) => {
    return productSub?.actor === 'SERVICE_PROVIDER';
  });
  return brandIndex !== -1;
};

export default {
  isUserBrand,
  isUserServiceProvider,
};
