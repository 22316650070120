import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchStats = async (queryParams) => {
  return await API.get(APIEndpoints.BRAND_STATS, null, queryParams);
  //   get: (url, option, query, successCallback, errorCallback)
};

const fetchBrandsLight = async (payload) => {
  return await API.get(APIEndpoints.BRAND, payload, {
    size: 10000,
    responseType: 'tiny',
  });
  //   get: (url, option, query, successCallback, errorCallback)
};

export const brandAPI = {
  fetchStats,
  fetchBrandsLight,
};
