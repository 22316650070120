import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  logoutModal: {
    display: 'flex',
    alignItems: 'end',
  },
  modalBody: {
    width: '100%',
    height: '150px',
    borderRadius: '10px',
    margin: '15px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  modalText: {
    textAlign: 'center',
    padding: '10px',
  },
  editButton: {
    marginLeft: '40px',
  },
  deleteButton: {
    marginLeft: '40px',
    marginTop: '20px',
  },
  icon: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: '14px',
  },
  text: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: '12px',
  },
  cancelText: {
    textAlign: 'center',
    marginTop: '17px',
    marginBottom: '0px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#33ab2e',
  },
  cancelSection: {
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '60px',
    backgroundColor: '#eaf7e9',
  },
  deleteDeliveryModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteDeliveryModalBody: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    width: '75%',
    position: 'absolute',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  deleteDeliveryModalText: {
    textAlign: 'center',
    padding: '10px',
  },
  deleteDeliveryModalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  deleteDeliveryModalButton: {
    height: '40px',
    margin: '5px',
  },
}));

const DeliveryEditAndDeleteOptionModal = ({
  isDeliveryDeleteEditModalVisible,
  setIsDeliveryDeleteEditModalVisible,
  showEdit,
  id,
  DeliveryService,
  isDeleteOptionVisible,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isDeleteDeliveryModalVisible, setIsDeleteDeliveryModalVisible] = useState(false);

  const handleDelete = async () => {
    const response = await DeliveryService.deletePWADeliveryById(id);
    if (response?.status === 200) {
      toast.success('Delivery deleted successfully');
      setIsDeleteDeliveryModalVisible(false);
      history.goBack();
    } else toast.success('Failed to delete');
  };

  return (
    <>
      <Modal
        open={isDeliveryDeleteEditModalVisible}
        onClose={() => setIsDeliveryDeleteEditModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.logoutModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Paper className={classes.modalBody}>
          <div style={{ marginTop: '20px' }}>
            {showEdit && (
              <div className={classes.editButton}>
                <div>
                  <FontAwesomeIcon size="sm" icon={faEdit} className={classes.icon} />
                  <span
                    className={classes.text}
                    style={{ marginLeft: '6px' }}
                    onClick={() => history.push(`/update-delivery/${id}`)}>
                    Edit
                  </span>
                </div>
              </div>
            )}
            {isDeleteOptionVisible && (
              <div className={classes.deleteButton}>
                <div>
                  <FontAwesomeIcon size="sm" icon={faTrashAlt} className={classes.icon} />
                  <span
                    className={classes.text}
                    style={{ marginLeft: '10px' }}
                    onClick={() => setIsDeleteDeliveryModalVisible(true)}>
                    Delete
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={classes.cancelSection}>
            <p
              className={classes.cancelText}
              onClick={() => setIsDeliveryDeleteEditModalVisible(false)}>
              Cancel
            </p>
          </div>
        </Paper>
      </Modal>
      <Modal
        open={isDeleteDeliveryModalVisible}
        onClose={() => setIsDeleteDeliveryModalVisible(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.deleteDeliveryModal}>
        <Paper className={classes.deleteDeliveryModalBody}>
          <Typography className={classes.deleteDeliveryModalText}>
            Are you sure you want to Delete{' '}
            <span style={{ fontWeight: 'bold', fontSize: '17px' }}>{id}</span>?
          </Typography>
          <div className={classes.deleteDeliveryModalButtonContainer}>
            <Button
              variant="contained"
              className={classes.deleteDeliveryModalButton}
              onClick={() => setIsDeleteDeliveryModalVisible(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.deleteDeliveryModalButton}
              onClick={handleDelete}>
              Yes, Delete
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
};

export default DeliveryEditAndDeleteOptionModal;
