import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

// fetchTarget: (payload-null, options - null, queryParams-{}, successCallback, errorCallback)
// get: (url, option, query, successCallback, errorCallback)
// API.get,
// APIEndpoints.TARGETS,
// action.payload - null,
// action.queryParams - {},
// action.successCallback,
// action.errorCallback
const fetchTarget = async (queryParams) => {
  return await API.get(APIEndpoints.TARGETS, null, queryParams);
};

const createTarget = async (payload) => {
  return await API.post(APIEndpoints.TARGETS, payload);
};

const updateTarget = async (payload) => {
  return await API.put(APIEndpoints.TARGETS, payload);
};
export const targetAPI = {
  fetchTarget,
  createTarget,
  updateTarget,
};
