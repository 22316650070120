import React, { useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PageView from '../../../shared/Components/_App/PageView';
import { Avatar, Divider, ListItemSecondaryAction, Typography } from '@material-ui/core';
import { ReactComponent as ProfileIcon } from '../../../assets/img/_App/profile.svg';
import { ReactComponent as LocationIcon } from '../../../assets/img/_App/location.svg';
import { ReactComponent as SupervisorIcon } from '../../../assets/img/_App/supervisor.svg';
import { ReactComponent as TransporterIcon } from '../../../assets/img/_App/transporter.svg';
import { ReactComponent as ServiceIcon } from '../../../assets/img/_App/service.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/img/_App/logout.svg';
import { ReactComponent as ChangePasswordIcon } from '../../../assets/img/_App/change-password-icon.svg';
import { useAccount } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import AppModal from '../../../shared/Components/_App/AppModal';
import ProfileChangePasswordForm from './ProfileInformationPage/ProfileChangePassword';
import PushNotification from '../../../shared/Config/push-notification';
import { ChatService } from '../../../shared/Services/ChatService';
import AppAnalytics from '../../../shared/Config/AppAnalytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: '20px',
  },
  Avatar: {
    background: '#ebf1ff',
  },
  listsec: {
    padding: '15px',
  },
  imageavatar: {
    borderRadius: '10px',
    width: '45px',
    height: '45px',
  },
  demo: {
    maxHeight: '82vh',
    overflow: 'auto',
  },
}));

const ProfileList = [
  {
    title: 'Personal Info',
    icon: <ProfileIcon />,
    link: '/profile/information',
  },
  /* {
    title: 'Manage Locations',
    icon: <LocationIcon />,
    link: '/profile/locations',
  },
  {
    title: 'Manage Supervisors',
    icon: <SupervisorIcon />,
    link: '/profile/supervisors',
  },
  {
    title: 'Transporter Details',
    icon: <TransporterIcon />,
    link: '/profile/transporters',
  },
  {
    title: 'Service History',
    icon: <ServiceIcon />,
    link: '/profile/history',
  },*/
];

const ProfilePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const account = useAccount();
  const [showChangePassModal, setShowChangePassModal] = useState(false);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
      AppAnalytics.clearUserProperties();
      await PushNotification.deleteToken();
      await ChatService().clearUserData();
    } catch (e) {
    } finally {
      window.location.reload();
    }
  };

  return (
    <PageView loading={false}>
      <div className={classes.demo}>
        <List className={classes.root} disablePadding>
          <ListItem className={classes.listsec}>
            <ListItemAvatar>
              <Avatar className={classes.imageavatar} src={account?.user?.imageUrl}></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography component="h5" variant="h4" style={{ fontSize: 'medium' }}>
                  {account?.user?.firstName}
                </Typography>
              }
              secondary={'Service Provider'}
            />
          </ListItem>
        </List>{' '}
        <List className={classes.root}>
          {ProfileList.map((obj) => (
            <>
              <ListItem onClick={() => history.push(obj.link)} className={classes.listsec}>
                <ListItemAvatar>
                  <Avatar className={classes.Avatar}>{obj.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={obj.title} />
                <ListItemSecondaryAction>
                  <ArrowForwardIosIcon fontSize={'small'} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
          ))}
          <ListItem className={classes.listsec} onClick={() => setShowChangePassModal(true)}>
            <ListItemAvatar>
              <Avatar className={classes.Avatar}>
                <ChangePasswordIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Change Password" />
          </ListItem>
          <Divider />
          <ListItem className={classes.listsec} onClick={handleLogout}>
            <ListItemAvatar>
              <Avatar className={classes.Avatar}>
                <LogoutIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Logout" />
          </ListItem>{' '}
          <Divider />
          <ListItem className={classes.listsec}>
            <ListItemText primary="App Version - 2.4.2" />
          </ListItem>{' '}
        </List>
      </div>
      <AppModal
        title={'Change Password'}
        visible={showChangePassModal}
        setVisible={setShowChangePassModal}>
        <ProfileChangePasswordForm setShowChangePassModal={setShowChangePassModal} />
      </AppModal>
    </PageView>
  );
};

export default ProfilePage;
