import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './DocumentModal.scss';
import moment from 'moment';

export default function DeliveryDetailsTab({ documentDeliveryDetails, selectedContainer }) {
  const [deliveryDetails, setDeliveryDetails] = useState({});

  useEffect(() => {
    setDeliveryDetails({
      vehicleNumber: documentDeliveryDetails?.pickUpVehicleNumber,
      loadingDate:
        selectedContainer === 'PICKUP'
          ? documentDeliveryDetails?.pickUpLoadingDate
          : documentDeliveryDetails?.dropOffUnloadDate,
      weighBridgeNumber:
        selectedContainer === 'PICKUP'
          ? documentDeliveryDetails?.pickUpWeighBridgeNumber
          : documentDeliveryDetails?.dropOffWeighBridgeNumber,
      grossWeight:
        selectedContainer === 'PICKUP'
          ? documentDeliveryDetails?.pickUpGrossWeight
          : documentDeliveryDetails?.dropOffGrossWeight,
      tareWeight:
        selectedContainer === 'PICKUP'
          ? documentDeliveryDetails?.pickUpTareWeight
          : documentDeliveryDetails?.dropOffTareWeight,
      netWeight:
        selectedContainer === 'PICKUP'
          ? documentDeliveryDetails?.pickUpNetWeight
          : documentDeliveryDetails?.dropOffNetWeight,
      eWayBillNo: documentDeliveryDetails?.pickUpEWayBillNo,
      lrNumber: documentDeliveryDetails?.pickUpLrNumber,
      invoiceNumber: documentDeliveryDetails?.pickUpInvoiceNumber,
    });
  }, [documentDeliveryDetails]);

  return (
    <div style={{ paddingTop: '20px', marginLeft: '28px', paddingBottom: '12px' }}>
      <div>
        <p className="delRowHeading">Vehicle Number</p>
        <p className="delRowText">
          {deliveryDetails?.vehicleNumber ? deliveryDetails?.vehicleNumber : 'NA'}
        </p>
      </div>
      <div>
        <p className="delRowHeading">
          Date of {selectedContainer === 'PICKUP' ? 'Loading' : 'UnLoading'}
        </p>
        <p className="delRowText">
          {deliveryDetails?.loadingDate
            ? moment(deliveryDetails?.loadingDate).format('DD/M/YYYY')
            : 'NA'}
        </p>
      </div>
      <div>
        <p className="delRowHeading">Weighbridge Details</p>
        <Row style={{ marginTop: '15px' }}>
          <Col sm={6} md={6} lg={6}>
            <h6 className="weighBridgeRowHeading">Weighbridge Number</h6>
            <p className="delRowText">
              {deliveryDetails?.weighBridgeNumber ? deliveryDetails?.weighBridgeNumber : 'NA'}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <h6 className="weighBridgeRowHeading">Gross Weight</h6>
            <p className="delRowText">
              {deliveryDetails?.grossWeight ? `${deliveryDetails?.grossWeight} kg` : 'NA'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <h6 className="weighBridgeRowHeading">Tare Weight</h6>
            <p className="delRowText">
              {deliveryDetails?.tareWeight ? `${deliveryDetails?.tareWeight} kg` : 'NA'}
            </p>
          </Col>
          <Col sm={6} md={6} lg={6}>
            <h6 className="weighBridgeRowHeading">Net Weight</h6>
            <p className="delRowText">
              {deliveryDetails?.netWeight ? `${deliveryDetails?.netWeight} kg` : 'NA'}
            </p>
          </Col>
        </Row>
      </div>
      <div>
        <p className="delRowHeading">E-Way Bill Number</p>
        <p className="delRowText">
          {deliveryDetails?.eWayBillNo ? deliveryDetails?.eWayBillNo : 'NA'}
        </p>
      </div>
      <div>
        <p className="delRowHeading">LR Number</p>
        <p className="delRowText">{deliveryDetails?.lrNumber ? deliveryDetails?.lrNumber : 'NA'}</p>
      </div>
      <div>
        <p className="delRowHeading">Invoice Number</p>
        <p className="delRowText">
          {deliveryDetails?.invoiceNumber ? deliveryDetails?.invoiceNumber : 'NA'}
        </p>
      </div>
    </div>
  );
}
