import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, ProgressBar, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './DashboardExecutionOverView.scss';

const DashboardExecutionOverview = ({ data, direction, isBrand }) => {
  let {
    currentOrderQtyReceived,
    currentOrderQtyGiven,
    deliveredQtyReceived,
    acceptedReceived,
    inReviewReceived,
    disputedReceived,
    orderQtyGiven,
    acceptedGiven,
    inReviewGiven,
    disputedGiven,
    deliveredQtyGiven,
    selfCreatedReceived,
  } = data || {};
  let unallocated = Math.max(currentOrderQtyReceived - currentOrderQtyGiven, 0);

  const [directionData, setDirectionData] = useState({});
  const [orderQty, setOrderQty] = useState();
  useEffect(() => {
    if (direction === 'given') {
      setDirectionData({
        ACCEPTED: { title: 'Approved By Client', value: acceptedGiven },
        IN_REVIEW: {
          title: 'Awaiting for client approval',
          value: inReviewGiven,
        },
        DISPUTED: { title: 'Disputed', value: disputedGiven },
        PENDING: {
          title: 'Pending Fulfilment',
          value: currentOrderQtyReceived - (deliveredQtyGiven || 0),
        },
      });
      setOrderQty(currentOrderQtyReceived);
    } else {
      setDirectionData({
        ACCEPTED: {
          title: isBrand ? 'Achieved' : 'Accepted',
          value: acceptedReceived,
        },
        IN_REVIEW: { title: 'Received for Review', value: inReviewReceived },
        DISPUTED: { title: 'Disputed', value: disputedReceived },
        PENDING: {
          title: 'Pending Fulfilment',
          value:
            currentOrderQtyGiven - (acceptedReceived + inReviewReceived + disputedReceived) || 0,
        },
        UNALLOCATED: {
          title: isBrand ? 'UnAllocated' : 'Self Fulfilled',
          value: isBrand ? unallocated : selfCreatedReceived,
        },
      });
      setOrderQty(currentOrderQtyGiven);
    }
  }, [data]);
  return (
    <div className="ExecutionOverview">
      <Row className="mb-4">
        <Col xs={6}>
          <h6 className="text-medium">Execution Overview </h6>
        </Col>
        <Col xs={6}>
          <NavLink to={`/executions?` + new URLSearchParams(window.location.search)}>
            <Button className="float-right" variant="primary">
              Details
            </Button>
          </NavLink>
        </Col>
      </Row>

      <div className="d-inline">
        <Badge className="ml-1 mr-1 overview-met">&nbsp;&nbsp;&nbsp;</Badge>{' '}
        {`${directionData?.ACCEPTED?.title}(${directionData?.ACCEPTED?.value || 0} Kg)`}
        <Badge className="ml-4 mr-1 overview-workInProgress">&nbsp;&nbsp;&nbsp;</Badge>{' '}
        {`${directionData?.IN_REVIEW?.title} (${directionData?.IN_REVIEW?.value || 0} Kg)`}
        <Badge className="ml-4 mr-1 overview-Atrisk">&nbsp;&nbsp;&nbsp;</Badge>{' '}
        {`${directionData?.DISPUTED?.title} (${directionData?.DISPUTED?.value || 0} Kg)`}
        <Badge className="ml-4 mr-1 overview-pending">&nbsp;&nbsp;&nbsp;</Badge>{' '}
        {`${directionData?.PENDING?.title}(${directionData?.PENDING?.value || 0} Kg)`}
        {direction === 'received' && (
          <>
            <Badge className="ml-4 mr-1 overview-unallocated">&nbsp;&nbsp;&nbsp;</Badge>{' '}
            {`${directionData?.UNALLOCATED?.title}(${directionData?.UNALLOCATED?.value || 0} Kg)`}
          </>
        )}
      </div>

      <ProgressBar className="mt-3">
        <ProgressBar
          className="overview-met"
          now={(directionData?.ACCEPTED?.value / orderQty) * 100}
          key={1}
        />
        <ProgressBar
          className="overview-workInProgress"
          now={(directionData?.IN_REVIEW?.value / orderQty) * 100}
          key={2}
        />
        <ProgressBar
          className="overview-Atrisk"
          now={(directionData?.DISPUTED?.value / orderQty) * 100}
          key={3}
        />
        <ProgressBar
          className="overview-pending"
          now={(directionData?.PENDING?.value / orderQty) * 100}
          key={4}
        />
        {direction === 'received' && (
          <ProgressBar
            className="overview-unallocated"
            now={(directionData?.UNALLOCATED?.value / orderQty) * 100}
            key={5}
          />
        )}
      </ProgressBar>
    </div>
  );
};
export default DashboardExecutionOverview;
