import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UploadDocBody from './UploadDocBody';
import ProcessingDocs from './ProcessingDocs';
import StepperStatus from './StepperStatus';

function BulkDeliveryModal({
  show,
  handleClose,
  discardFn,
  batchId,
  uploadHandler,
  processDocumentHandler,
  setUploaded,
  uploaded,
}) {
  const [file, setFile] = useState({ id: 0, name: null, content: null });
  const [loading, setLoading] = useState({ status: 0, value: false, error: null });
  const [isProcessed, setIsProcessed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessed(false);
    setFile({ id: 0, name: '', content: null });
    setLoading({ status: 0, value: false, error: null });
  }, []);

  const documentHandler = (files, providerId, providerName, recylerId, recyclerName) => {
    if (Math.floor(files[0].size / 1000000) > 500) {
      setLoading((prev) => ({ ...prev, error: 'File Size should not exceed 500 MB' }));
      return;
    }

    if (loading.error !== null) setLoading((prev) => ({ ...prev, error: null }));

    setIsProcessed(true);
    setUploaded(1);
    setFile({ id: providerId, name: providerName, content: files });
    uploadHandler(providerId, providerName, files, recylerId, recyclerName);
  };

  const processDocHandler = () => {
    processDocumentHandler(batchId);
    setIsProcessing(true);
  };

  const processHandler = () => {
    handleClose();
    discardFn(batchId, 'Batch Discarded !!!');
  };

  const processInBGHandler = () => {
    setLoading({ status: 0, value: false, error: null });
    if (!isProcessing) processDocHandler();
    handleClose();
  };

  return (
    <Modal
      className="bulk-modal"
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static">
      <div className="p-4">
        <Modal.Header closeButton className="mb-3">
          <Modal.Title>Create Batch Transactions</Modal.Title>
        </Modal.Header>

        <Modal.Body className="bg-secondary rounded-3 bulk-modal--body">
          {!isProcessed && <UploadDocBody uploadFn={documentHandler} />}
          {isProcessed && (
            <ProcessingDocs
              file={file}
              btnStatus={loading.value}
              uploaded={uploaded}
              uploadFn={processDocHandler}
              processFn={processHandler}
              setIsProcessed={setIsProcessed}
              batchId={batchId}
              isProcessing={isProcessing}
            />
          )}
          {isProcessing && <StepperStatus actionDel={'uploaded'} />}
          {loading.error && (
            <div className="d-flex justify-content-center align-items-md-center h-50">
              <p>{loading.error}</p>
            </div>
          )}
        </Modal.Body>

        {isProcessed && (
          <Modal.Footer className="mt-3">
            {loading.status === 0 && (
              <>
                <Button
                  disabled={(!batchId && !isProcessed) || isProcessing || uploaded !== 2}
                  variant="outline-primary"
                  onClick={() => {
                    processHandler();
                  }}>
                  Discard Batch
                </Button>
                <Button
                  variant="primary"
                  disabled={loading.value || uploaded !== 2}
                  onClick={processInBGHandler}>
                  Process in Background
                </Button>
              </>
            )}
            {loading.status === 1 && (
              <Button variant="outline-primary" disabled={uploaded !== 2} onClick={handleClose}>
                Run in Background
              </Button>
            )}
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
}

export default BulkDeliveryModal;
