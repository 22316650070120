import React from 'react';
import { Loader } from '../../../shared/Components';

const ExecutionTableLoader = (props) => {
  return <Loader>Loading</Loader>;
};

ExecutionTableLoader.propTypes = {};

export default ExecutionTableLoader;
