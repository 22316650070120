const brandCards = [
  {
    id: 1,
    title: 'Set targets by materials',
    icon: 'grid.svg',
    status: true,
    url: '/target',
  },
  {
    id: 2,
    title: 'Set targets by geographies',
    icon: 'locations-step.svg',
    url: '/target',
    status: false,
  },
  {
    id: 3,
    title: 'Add service providers',
    icon: 'service-provider.svg',
    url: '/service-provider',
    status: false,
  },
  {
    id: 4,
    title: 'Create work orders',
    icon: 'list.svg',
    url: '/work-orders/create',
    status: false,
  },
  // {
  //   id: 5,
  //   title: "Upload documentation",
  //   icon: "upload-document.svg",
  //   status: false
  // },
  {
    id: 5,
    title: 'Provide access to other users',
    icon: 'roles.svg',
    url: '/users',
    status: false,
  },
];
const serviceProviderCards = [
  {
    id: 1,
    title: 'Add service providers',
    icon: 'service-provider.svg',
    url: '/service-provider',
    status: false,
  },
  {
    id: 2,
    title: 'Create work orders',
    icon: 'list.svg',
    url: '/work-orders/create',
    status: false,
  },
  {
    id: 3,
    title: 'Provide access to other users',
    icon: 'roles.svg',
    url: '/users',
    status: false,
  },
];
export { brandCards, serviceProviderCards };
