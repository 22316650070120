import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';
import { toServerDate } from '../../shared/Utils/Helper';

const fetchUlb = async (params, isPRO) => {
  const response = await API.get(
    isPRO ? APIEndpoints.FETCH_ULB : APIEndpoints.SP_FETCH_ULB,
    null,
    params
  );
  return response;
};

const deleteUlb = async (id) => {
  const response = await API.delete(APIEndpoints.FETCH_ULB + `/${id}`, null);
  return response.data;
};

const addUlb = async (params) => {
  const response = await API.post(APIEndpoints.FETCH_ULB, params);
  return response.data;
};

const updateUlb = async (params) => {
  const response = await API.put(APIEndpoints.FETCH_ULB, params);
  return response.data;
};

const getUlbById = async (id) => {
  const response = await API.get(APIEndpoints.FETCH_ULB + '/' + id, null);
  return response.data;
};

const approveSignedDoc = async (id, type) => {
  const response = await API.post(APIEndpoints.APPROVE_ULB_DOC + id + '/' + type);
  return response.data;
};

const uploadUnsignedDoc = async (params, id, month) => {
  const response = await API.post(
    APIEndpoints.UPLOAD_UNSIGNED_DOC + id + '/assign-doc?month=' + month,
    params
  );
  return response.data;
};

const uploadSignedDoc = async (params, id) => {
  const response = await API.put(APIEndpoints.UPLOAD_SIGNED_DOC + id + '/signed-doc', params);
  return response.data;
};

const attachDocument = async (docDetails) => {
  return await API.post(APIEndpoints.DOCUMENT_ATTACH, docDetails);
};

const assignDocToSP = async (docId, spId, doc) => {
  return await API.put(
    APIEndpoints.APPROVE_ULB_DOC + docId + '/assign-to-customer?assignTo=' + spId,
    doc
  );
};

const regenerateDoc = async (letter) => {
  const response = await API.put(
    APIEndpoints.APPROVE_ULB_DOC + letter.id + '/re-generate',
    letter?.unsignedDoc
  );
  return response.data;
};

export const UlbService = {
  fetchUlb,
  deleteUlb,
  addUlb,
  approveSignedDoc,
  uploadUnsignedDoc,
  uploadSignedDoc,
  updateUlb,
  attachDocument,
  getUlbById,
  assignDocToSP,
  regenerateDoc,
};
