import React from 'react';
import { ListItemText, ListItemSecondaryAction, List, ListItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const TransporterListView = ({ onClick, transObj, onEdit }) => {
  return (
    <List>
      <ListItem key={transObj.id}>
        <ListItemText onClick={onClick} primary={transObj.name} />
        <ListItemText primary={transObj.phoneNumber} style={{ textAlign: 'right' }} />

        <ListItemSecondaryAction>
          <EditIcon fontSize={'small'} color={'primary'} onClick={onEdit} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

const DriverListView = ({ onClick, driverObj, onEdit }) => {
  return (
    <List>
      <ListItem key={driverObj?.id}>
        <ListItemText onClick={onClick} primary={driverObj.name} />
        <ListItemText primary={driverObj.mobileNo} style={{ textAlign: 'right' }} />

        <ListItemSecondaryAction>
          <EditIcon fontSize={'small'} color={'primary'} onClick={onEdit} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};
export { TransporterListView, DriverListView };
