import React, { useState, useEffect } from 'react';
import AppModal from '../../../../shared/Components/_App/AppModal';
import SelectDropdown from '../../../../shared/Components/_App/SelectDropdown/SelectDropdown';
import FormLabel from '../../../../shared/Components/_App/FormLabel';
import Section from '../../../../shared/Components/_App/Section';
import FormField from '../../../../shared/Components/_App/FormField';
import { toCapitalize } from '../../../../../src/shared/Utils/Helper';
import { UlbService } from '../../../ULB/ulb.service';
import { Paper, Typography, makeStyles, FormControlLabel, Grid } from '@material-ui/core';
import { UlbListView } from './UlbItems';
import Checkbox from '@material-ui/core/Checkbox';
import DeliveryUlbForm from './DeliveryUlbForm';
import { useRegionsMap } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#f7faff',
    borderColor: '#E7EDFE',
    padding: theme.spacing(1),
    marginBottom: 5,
  },
}));

const DeliveryUlb = (props) => {
  const classes = useStyles();
  const { createDeliveryForm } = { ...props };
  const [ulbList, setUlbList] = useState([]);
  const [ulbListData, setUlbListData] = useState([]);
  const [masterULBData, setMasterULBData] = useState([]);
  const regions = useRegionsMap();

  const [showUlbModal, setShowUlbModal] = useState(false);
  const [showUlbList, setShowUlbList] = useState(false);
  const [ulbPresent, setUlbPresent] = useState(false);

  useEffect(() => {
    if (showUlbList) {
      async function fetchData() {
        const response = await UlbService.fetchUlb({ size: 1000 }, true);
        if (response?.data) setUlbList(response.data);
      }
      fetchData();
    }
  }, [showUlbList]);

  const ulbHandler = (obj) => {
    createDeliveryForm.setFieldValue('ulbId', obj.id);
    createDeliveryForm.setFieldValue('ulbName', obj.name);
    createDeliveryForm.setFieldValue('ulbAddress', obj.addr);
    createDeliveryForm.setFieldValue('ulbState', obj.stateId);
    createDeliveryForm.setFieldValue('ulbDistrict', obj.districtId);
    createDeliveryForm.setFieldValue('ulbPincode', obj.pincode);
    setShowUlbList(false);
  };

  useEffect(() => {
    if (ulbPresent) {
      setShowUlbModal(true);
      createDeliveryForm.setFieldValue('ulbId', '');
      createDeliveryForm.setFieldValue('ulbName', '');
      createDeliveryForm.setFieldValue('ulbAddress', '');
      createDeliveryForm.setFieldValue('ulbState', '');
      createDeliveryForm.setFieldValue('ulbDistrict', '');
      createDeliveryForm.setFieldValue('ulbPincode', '');
    }
  }, [ulbPresent]);

  useEffect(() => {
    if (ulbList) {
      if (createDeliveryForm?.values?.pickUpState) {
        const ulbListFilteredData = ulbList.filter(
          (e) => e.stateId === createDeliveryForm?.values?.pickUpState
        );
        setUlbListData(ulbListFilteredData);
        setMasterULBData(ulbListFilteredData);
      } else {
        setUlbListData(ulbList);
        setMasterULBData(ulbList);
      }
    }
  }, [ulbList]);

  const filterData = (value) => {
    const newData = masterULBData?.filter(function (data) {
      return (
        data?.name?.toUpperCase()?.trim()?.includes(value?.toUpperCase()) ||
        data?.addr?.toUpperCase()?.trim()?.includes(value?.toUpperCase()) ||
        data?.pincode?.toString().includes(value)
      );
    });
    setUlbListData(newData);
  };

  return (
    <Section
      title="ULB INFORMATION"
      ulbComponent={
        <FormControlLabel
          control={
            <Checkbox
              disabled={createDeliveryForm?.values?.pickUpState === null}
              checked={ulbPresent}
              onChange={(e) => setUlbPresent(!ulbPresent)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="ULB not present in the list"
        />
      }>
      <FormField label="ULB NAME">
        <SelectDropdown
          actions={false}
          visible={showUlbList}
          setVisible={setShowUlbList}
          onClick={() => setShowUlbList(true)}
          value={toCapitalize(createDeliveryForm?.values?.ulbName)}
          //disabled={!ulbPresent}
          listData={ulbListData}
          isSeachBox={true}
          modalTitle="Select ULB"
          filterData={filterData}
          renderItem={(e, idx) => (
            <UlbListView onClick={() => ulbHandler(e)} ulbObj={e} key={idx} />
          )}>
          <Paper elevation={0} variant={'outlined'} className={classes.paper}>
            <FormLabel label={'Address'} size={'small'} />
            <Typography color={'secondary'} variant={'h6'}>
              {createDeliveryForm.values.ulbAddress
                ? createDeliveryForm?.values?.ulbAddress
                : 'N/A'}
            </Typography>
            <Grid container className="pt-2">
              <Grid xs="4">
                <FormLabel label={'State'} size={'small'} />
                <Typography color={'secondary'} variant={'h6'}>
                  {createDeliveryForm.values.ulbState
                    ? regions[createDeliveryForm.values.ulbState]?.name
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid xs="4">
                <FormLabel label={'District'} size={'small'} />
                <Typography color={'secondary'} variant={'h6'}>
                  {createDeliveryForm.values.ulbDistrict
                    ? regions[createDeliveryForm.values.ulbState]?.districts[
                        createDeliveryForm?.values?.ulbDistrict
                      ]?.name
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid xs="2">
                <FormLabel label={'Pincode'} size={'small'} />
                <Typography color={'secondary'} variant={'h6'}>
                  {createDeliveryForm.values.ulbPincode
                    ? createDeliveryForm?.values?.ulbPincode
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </SelectDropdown>
      </FormField>
      <AppModal
        title={'ADD ULB'}
        visible={showUlbModal}
        setVisible={setShowUlbModal}
        setUlbPresent={setUlbPresent}>
        <DeliveryUlbForm
          formik={createDeliveryForm}
          ulbPresent={ulbPresent}
          setUlbPresent={setUlbPresent}
          setShowUlbModal={setShowUlbModal}
        />
      </AppModal>
    </Section>
  );
};

export default DeliveryUlb;
