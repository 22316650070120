import React from 'react';
import SupplyFromVendors from './SupplyFromVendors';

export default function DemandAndSupply(props) {
  const {
    supplyData,
    handleSupplyRowSelection,
    handleGenerateRowSelection,
    setGenerateWoDeliveries,
    setAllocateWoDelivery,
    isInnerArrowActive,
    setIsInnerArrowActive,
    tabKey,
    setTabKey,
    generateWoDeliveries,
    handleGenerateWoAgainstDeliveries,
    allocateWoDelivery,
  } = props;

  return (
    <div>
      <div>
        <SupplyFromVendors
          supplyData={supplyData}
          handleSupplyGenerateWoRow={handleGenerateRowSelection}
          handleSupplyAllocateWoRow={handleSupplyRowSelection}
          setGenerateWoDeliveries={setGenerateWoDeliveries}
          setAllocateWoDelivery={setAllocateWoDelivery}
          allocateWoDelivery={allocateWoDelivery}
          isInnerArrowActive={isInnerArrowActive}
          setIsInnerArrowActive={setIsInnerArrowActive}
          tabKey={tabKey}
          setTabKey={setTabKey}
          generateWoDeliveries={generateWoDeliveries}
          handleGenerateWoAgainstDeliveries={handleGenerateWoAgainstDeliveries}
        />
      </div>
    </div>
  );
}
