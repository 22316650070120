/**
 * @input cell : react table cell
 * @input depthAccessorClassNameMap : example {'1': {'id': 'className'}}
 */
export const getCssClassNames = (cell, depthAccessorClassNameMap) => {
  if (depthAccessorClassNameMap?.[cell.row.depth]?.[cell.column.id]) {
    return depthAccessorClassNameMap[cell.row.depth][cell.column.id];
  }
  return '';
};
