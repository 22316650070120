import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Col, InputGroup, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import './LoginContent.scss';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginContent = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleVision = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      username: props?.email,
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().email('Invalid Username').required('Email required'),
      password: Yup.string()
        .min(4, 'Password must be at least 4 characters long')
        .required('Password required'),
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      props.loginAction(values);
    },
  });

  return (
    <div className="LoginContent animated fadeIn">
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Form.Group>
          <Form.Label className="mb-0">Email </Form.Label>
          <Form.Control
            className="px-2 py-0 float-label email-field"
            id="username"
            name="username"
            type="email"
            value={formik.values.username}
            onChange={formik.handleChange}
            disabled={
              formik?.values?.username &&
              process.env.REACT_APP_ENV !== 'stage' &&
              process.env.REACT_APP_ENV !== 'development'
            }
            placeholder="eg. abc@company.com"
            isInvalid={!!formik.errors.username}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mt-4">
          <Form.Label className="mb-0">Password</Form.Label>
          <Form.Group className="password-field">
            <InputGroup>
              <Form.Control
                className="px-2 py-0 float-label"
                required
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.password}
                placeholder="********"
              />
              <InputGroup.Append>
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={toggleVision}
                  className="icon-style ml-2"
                />
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Control.Feedback type="invalid" className="d-inline">
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col xs="12">
            <Button
              className="btn-block"
              variant="primary"
              size="lg"
              disabled={!formik.isValid || props.loading}
              type="submit">
              {props.loading ? (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span> Logging</span>
                </div>
              ) : (
                'Login'
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="6" className={'d-flex align-items-center'}>
            <Button className="pl-0" variant="link" onClick={props?.setPreviousView}>
              Back
            </Button>
          </Col>
          <Col xs="6" className={'d-flex align-items-center justify-content-end'}>
            <a className="" href="/reset-password">
              Forgot password
            </a>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoginContent;
