import React from 'react';
import { ListItemText, ListItemSecondaryAction, List, ListItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const SupervisorListView = ({ onClick, svObj, key, onEdit, user, displayMyself }) => {
  return (
    <ListItem key={key}>
      <ListItemText
        onClick={onClick}
        primary={displayMyself(user?.mobile, svObj.phoneNumber, svObj.name)}
      />
      {user?.mobile !== svObj.phoneNumber && (
        <ListItemText primary={svObj.phoneNumber} style={{ textAlign: 'right' }} />
      )}

      <ListItemSecondaryAction>
        {user?.mobile !== svObj.phoneNumber && (
          <EditIcon fontSize={'small'} color={'primary'} onClick={onEdit} />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const TransporterListView = ({ onClick, transObj, key, onEdit }) => {
  return (
    <List>
      <ListItem key={key}>
        <ListItemText onClick={onClick} primary={transObj.name} />
        <ListItemText primary={transObj.mobileNo} style={{ textAlign: 'right' }} />
      </ListItem>
    </List>
  );
};
export { SupervisorListView, TransporterListView };
