import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PushNotification from './shared/Config/push-notification';
import types from './state/User/types';
import store from './store';
import Theme from './theme/Theme';
import { serviceWorkerInit, serviceWorkerUpdate } from './state/User/userSlice';

const App1 = () => {
  return (
    <div>
      Hello
      <img src={'https://www.interplayit.com/wp-content/uploads/2018/10/profile-placeholder.jpg'} />
    </div>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Provider store={store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch(serviceWorkerInit()),
  onUpdate: (registration) => store.dispatch(serviceWorkerUpdate(registration)),
});
PushNotification.init();
