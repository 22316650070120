import {
  getTransporter,
  updateTransporter,
  addTransporter,
  deleteTransporter,
  getDriver,
  addDriver,
  updateDriver,
  getSuperviors,
  updateSupervisor,
  createSupervisor,
  deleteSupervisor,
  getVehicleDetails,
  getPickupLocations,
  getDropLocations,
  postPickupLocations,
  getDeliveryList,
  deleteDriver,
  loginRequest,
  otpVerify,
  superVisorRequestOTP,
  getTrackingDetails,
} from '../shared/Services/backend-api';
import { useAsyncFn } from 'react-use';
import { useSelector } from 'react-redux';
import { useRegions } from '.';
import { isEmpty } from 'lodash';

export function useTransporter() {
  const [
    { error: transError, loading: transLoading, value: transporterValue },
    dofetchTransporter,
  ] = useAsyncFn(async (id) => {
    const response = await getTransporter(id);
    return response;
  });

  const [
    { error: addTransError, loading: addTransLoading, value: addTransValue },
    doAddTransporter,
  ] = useAsyncFn(async (payload) => {
    const response = await addTransporter(payload);
    return response;
  });

  const [
    { error: updateTransError, loading: updateTransLoading, value: updateTransValue },
    doUpdateTransporter,
  ] = useAsyncFn(async (payload) => {
    const response = await updateTransporter(payload);
    return response;
  });

  const [{ error: deleteError, loading: deleting, value: deleteValue }, doDeleteTransporter] =
    useAsyncFn(async (id) => {
      const response = await deleteTransporter(id);
      return response;
    });

  const [
    { error: vehicleError, loading: vehicleLoading, value: vehicleValue },
    doGetVehicleDetails,
  ] = useAsyncFn(async (params) => {
    const response = await getVehicleDetails(params);
    return response;
  });

  return [
    {
      transError,
      transLoading,
      transporterValue,
      addTransError,
      addTransLoading,
      addTransValue,
      updateTransError,
      updateTransLoading,
      updateTransValue,
      deleteError,
      deleting,
      deleteValue,
      vehicleError,
      vehicleLoading,
      vehicleValue,
    },
    {
      dofetchTransporter,
      doAddTransporter,
      doUpdateTransporter,
      doDeleteTransporter,
      doGetVehicleDetails,
    },
  ];
}

export function useDriver() {
  const [{ error: driverError, loading: driverLoading, value: driverValue }, dofetchDriver] =
    useAsyncFn(async (id) => {
      const response = await getDriver(id);
      return response;
    });

  const [{ error: addDriverError, loading: addDriverLoading, value: addDriverValue }, doAddDriver] =
    useAsyncFn(async (payload) => {
      const response = await addDriver(payload);
      return response;
    });

  const [
    { error: updateDriverError, loading: updateDriverLoading, value: updateDriverValue },
    doUpdateDriver,
  ] = useAsyncFn(async (payload) => {
    const response = await updateDriver(payload);
    return response;
  });

  const [{ error: deleteError, loading: deleting, value: deleteValue }, doDeleteDriver] =
    useAsyncFn(async (id) => {
      const response = await deleteDriver(id);
      return response;
    });
  return [
    {
      driverError,
      driverLoading,
      driverValue,
      addDriverError,
      addDriverLoading,
      addDriverValue,
      updateDriverError,
      updateDriverLoading,
      updateDriverValue,
      deleteError,
      deleting,
      deleteValue,
    },
    {
      dofetchDriver,
      doAddDriver,
      doUpdateDriver,
      doDeleteDriver,
    },
  ];
}

export function useSupervisor() {
  const [{ error: svError, loading: svLoading, value: svValue }, dofetchSuperviosr] = useAsyncFn(
    async () => {
      const response = await getSuperviors();
      return response;
    }
  );
  const [{ error: createError, loading: creating, value: createValue }, doCreateSupervisor] =
    useAsyncFn(async (payload) => {
      const response = await createSupervisor(payload);
      return response;
    });
  const [{ error: updateError, loading: updating, value: updateValue }, doUpdateSupervisor] =
    useAsyncFn(async (payload) => {
      const response = await updateSupervisor(payload);
      return response;
    });

  const [{ error: deleteError, loading: deleting, value: deleteValue }, doDeleteSupervisor] =
    useAsyncFn(async (id) => {
      const response = await deleteSupervisor(id);
      return response;
    });
  return [
    {
      svError,
      svLoading,
      svValue,
      createError,
      creating,
      createValue,
      updateError,
      updating,
      updateValue,
      deleteError,
      deleting,
      deleteValue,
    },
    { dofetchSuperviosr, doUpdateSupervisor, doCreateSupervisor, doDeleteSupervisor },
  ];
}

export function useLocations() {
  const customerId = useSelector((state) => state?.account?.user?.profile?.id);
  const regions = useRegions();
  const [
    {
      error: fetchPickupLocationError,
      loading: fetchPickupLocationLoading,
      value: fetchPickupLocationValue,
    },
    dofetchPickupLocation,
  ] = useAsyncFn(async () => {
    const response = await getPickupLocations(customerId);
    const filteredLocation = response?.filter(
      (data) => !isEmpty(data?.state) && !isEmpty(data?.city)
    );
    const _response = filteredLocation?.map((add) => {
      const state = regions?.find(
        (value) => value?.name?.toLowerCase()?.trim() === add?.state?.toLowerCase()?.trim()
      );
      const city =
        state &&
        state?.districts?.find(
          (value) => value?.name?.toLowerCase()?.trim() === add?.city?.toLowerCase()?.trim()
        );

      return { ...add, pickUpCity: city?.id, pickUpState: state?.id };
    });

    return _response;
  });
  const [
    {
      error: fetchDropLocationError,
      loading: fetchDropLocationLoading,
      value: fetchDropLocationValue,
    },
    dofetchDropLocation,
  ] = useAsyncFn(async () => {
    const response = await getDropLocations(customerId);
    var dropLocationData = [];
    response.map((recykal) => {
      if (recykal?.coProcessor) {
        recykal.address.map((addr) => {
          if (addr.isShipping) {
            dropLocationData.push({
              ...addr,
              name: recykal?.shortName || recykal?.businessName,
              dropOffCity: addr?.region?.id,
              dropOffState: addr?.region?.parent?.id,
              recyklerId: recykal?.id,
            });
            return;
          }
        });
        return;
      }
    });
    return dropLocationData;
  });

  const [
    {
      error: addPickupLocationError,
      lodaing: addPickupLocationLoading,
      value: addPickupLocationValue,
    },
    doaddPickupLocation,
  ] = useAsyncFn(async (payload) => {
    const response = await postPickupLocations(payload);
    return response;
  });

  return [
    {
      fetchPickupLocationError,
      fetchPickupLocationLoading,
      fetchPickupLocationValue,
      fetchDropLocationError,
      fetchDropLocationLoading,
      fetchDropLocationValue,
      addPickupLocationError,
      addPickupLocationLoading,
      addPickupLocationValue,
    },
    dofetchPickupLocation,
    dofetchDropLocation,
    doaddPickupLocation,
  ];
}

export function useDelivery() {
  const [
    { error: deliveryError, loading: deliveryLoading, value: deliveryValue },
    dofetchDelivery,
  ] = useAsyncFn(async (payload) => {
    const response = await getDeliveryList(payload);
    return response;
  });

  return [
    {
      deliveryError,
      deliveryLoading,
      deliveryValue,
    },
    {
      dofetchDelivery,
    },
  ];
}

export function useRequestOTP() {
  const [{ error: requestError, loading: requestLoading, value: requestValue }, doRequestOTP] =
    useAsyncFn(async (payload) => {
      const response = await superVisorRequestOTP(payload);
      return response;
    });

  const [{ error: verifyError, loading: verifyLoading, value: verifyValue }, doVerifyOTP] =
    useAsyncFn(async (payload) => {
      const response = await otpVerify(payload);
      return response;
    });

  return [
    {
      requestError,
      requestLoading,
      requestValue,
      verifyError,
      verifyLoading,
      verifyValue,
    },

    { doRequestOTP, doVerifyOTP },
  ];
}

export function useTracking(id) {
  const [{ error, loading, value }, doFetchTracking] = useAsyncFn(async (id) => {
    const response = await getTrackingDetails(id);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
    },

    { doFetchTracking },
  ];
}
