import React, { useEffect } from 'react';
import './AddContactPersonDetails.scss';
import { Button } from 'react-bootstrap';
import back from '../../../assets/img/back.png';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddContactPersonDetails = (props) => {
  useEffect(() => {
    props.setHeader(
      <>
        <img onClick={props.onBack} src={back} />
        <h5 className="heading mt-3">
          Enter contact person
          <br /> details
        </h5>
      </>
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      contactPersonEmail: props.contactPersonEmail,
      contactPersonMobile: props.contactPersonMobile,
      contactPersonName: props.contactPersonName,
      password: props.password,
    },
    validationSchema: Yup.object({
      contactPersonEmail: Yup.string().email('Invalid email address').required('Email Required'),
      contactPersonName: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'Numbers and special char are not allowed')
        .required('Name Required'),
      contactPersonMobile: Yup.string()
        .max(10, 'Invalid mobile number')
        .min(10, 'Invalid mobile number')
        .required('Mobile Required'),
      password: Yup.string().min(4, 'Should be Min. 4 Char').required('Password Required'),
    }),
    onSubmit: (value) => {
      props.nextAction(value);
    },
  });
  return (
    <div className="animated fadeIn">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-4">
          <Form.Label className="mb-0 text-medium">Name </Form.Label>
          <Form.Control
            id="contactPersonName"
            className="p-0 float-label"
            type="text"
            {...formik.getFieldProps('contactPersonName')}
            isInvalid={formik.touched.contactPersonName && !!formik.errors.contactPersonName}
            placeholder="eg. Swetha Kumari"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.contactPersonName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="mb-0 text-medium">Mobile Number </Form.Label>
          <Form.Control
            id="contactPersonMobile"
            className="p-0 float-label"
            type="number"
            min="1"
            {...formik.getFieldProps('contactPersonMobile')}
            isInvalid={formik.touched.contactPersonMobile && !!formik.errors.contactPersonMobile}
            placeholder="84XXXXXX90"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.contactPersonMobile}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="mb-0 text-medium">Official email id </Form.Label>
          <Form.Control
            id="contactPersonEmail"
            className="p-0 float-label"
            type="text"
            {...formik.getFieldProps('contactPersonEmail')}
            isInvalid={formik.touched.contactPersonEmail && !!formik.errors.contactPersonEmail}
            placeholder="eg. abcd@domain.com"
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.contactPersonEmail}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Form.Label className="mb-0 text-medium">Password </Form.Label>
          <Form.Control
            id="password"
            className="p-0 float-label"
            type="password"
            {...formik.getFieldProps('password')}
            isInvalid={formik.touched.password && !!formik.errors.password}
            placeholder="******"
          />
          <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
        </Form.Group>
        <Button
          className="float-right px-4 my-4"
          variant="primary"
          size="lg"
          disabled={!formik.isValid}
          type="submit">
          Next
        </Button>
      </Form>
    </div>
  );
};

export default AddContactPersonDetails;
