import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@material-ui/core';
import EmptyIcon from '../../../assets/img/_App/empty.png';

const EmptyView = (props) => {
  const {
    className,
    hasNoData,
    title = 'Not found!',
    message = 'No result for this',
    emptyStyle,
  } = props;
  return (
    hasNoData && (
      <div
        className={
          'align-items-center d-flex flex-column justify-content-center w-100 ' + className
        }
        style={emptyStyle}>
        <img style={{ width: '9rem' }} src={EmptyIcon} />
        <Typography color={'secondary'} variant={'subtitle1'}>
          {title}
        </Typography>
        <p>{message}</p>
      </div>
    )
  );
};

EmptyView.propTypes = {
  title: PropTypes.any,
  message: PropTypes.any,
  hasNoData: PropTypes.bool,
  className: PropTypes.string,
};

export default EmptyView;
