import { useAccount } from './useAccount';
import moment from 'moment';
import { keyBy } from 'lodash';
const quarterArray = [
  {
    id: 1,
    name: 'Apr, May, Jun',
    key: 'q1',
  },
  {
    id: 2,
    name: 'Jul, Aug, Sept',
    key: 'q2',
  },
  {
    id: 3,
    name: 'Oct, Nov, Dec',
    key: 'q3',
  },
  {
    id: 4,
    name: 'Jan, Feb, Mar',
    key: 'q4',
  },
];
export const useFinancialYear = () => {
  const { selectedFinancialYear, currentYear, previousFinancialYear } = useAccount();

  function getCurrentQuarter(d) {
    let now = d || new Date();
    const quarterVal = Math.floor(now.getMonth() / 3);
    const quarter = quarterVal === 0 ? 4 : quarterVal;
    const firstDate = new Date(now.getFullYear(), quarter * 3, 1);
    const endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
    return { quarter, firstDate, endDate };
  }

  function getCurrentHalfYearValue(d) {
    const currentMonth = d || moment(new Date(), 'YYYY/MM/DD').month() + 1;

    if (currentMonth >= 4 && currentMonth <= 9) {
      return {
        startDate: moment.utc(moment().month(3).startOf('month')).format(),
        endDate: moment.utc(moment().month(8).endOf('month')).format(),
      };
    } else if (currentMonth >= 0 && currentMonth <= 12) {
      return {
        startDate: moment.utc(moment().month(9).startOf('month')).format(),
        endDate: moment.utc(moment().month(2).endOf('month').add('years', 1)).format(),
      };
    }
  }

  function _getQuarterRange(quarter) {
    const fyStart = selectedFinancialYear.fromDate;
    const quartersObj = {
      1: {
        start: moment(fyStart).month(3).startOf('month').toISOString(),
        end: moment(fyStart).month(5).endOf('month').toISOString(),
      },
      2: {
        start: moment(fyStart).month(6).startOf('month').toISOString(),
        end: moment(fyStart).month(8).endOf('month').toISOString(),
      },
      3: {
        start: moment(fyStart).month(9).startOf('month').toISOString(),
        end: moment(fyStart).month(11).endOf('month').toISOString(),
      },
      4: {
        start: moment(fyStart).month(0).startOf('month').add(1, 'years').toISOString(),
        end: moment(fyStart).month(2).endOf('month').add(1, 'years').toISOString(),
      },
    };
    return quartersObj[quarter];
  }
  function getQuarters() {
    return keyBy(
      quarterArray.map((q) => ({
        ...q,
        ..._getQuarterRange(q.id),
      })),
      'id'
    );
  }
  const result = {
    isCurrentFinancialYear: true,
    selectedFinancialYear: selectedFinancialYear,
    currentQuarter: getCurrentQuarter(),
    getQuarters,
    currentYear: currentYear,
    getCurrentHalfYear: getCurrentHalfYearValue(),
    previousFinancialYear,
  };
  result.isCurrentFinancialYear = selectedFinancialYear.toDate === currentYear.value.toDate;

  return result;
};
