import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const PoStatusTab = ({ selectedStatus, setSelectedStatus }) => {
  const [list, setList] = useState([
    {
      name: 'Pending by Default',
      select: false,
      status: 'PENDING',
      internalStatus: 'PENDING',
    },
    { name: 'Fulfilled', select: false, status: ' FULFILLED', internalStatus: 'MET' },
    {
      name: 'Partially Fulfilled',
      select: false,
      status: 'PARTIALLY_FULFILLED',
      internalStatus: 'DELIVERY_INITIATED',
    },
    { name: 'Closed', select: false, status: 'CLOSED', internalStatus: 'CLOSED' },
  ]);
  const handleChange = (event, idx) => {
    const clone = list.map((select, id) => {
      if (idx === id) return { ...select, select: !select.select };
      else return { ...select, select: false };
    });
    setList(clone);
    setSelectedStatus({
      ...selectedStatus,
      workOrderStatus: clone.find((status) => status.select === true),
    });
  };
  useEffect(() => {
    const clone = list.map((select, id) => {
      if (select.status === selectedStatus?.workOrderStatus?.status)
        return { ...select, select: !select.select };
      else return { ...select, select: false };
    });
    setList(clone);
  }, []);
  console.log('selectedStatus', selectedStatus);
  return (
    <>
      {list?.map((status, idx) => (
        <div>
          <FormControlLabel
            control={<GreenCheckbox name={status?.name} checked={status?.select} />}
            label={status?.name}
            onChange={(e) => handleChange(e, idx)}
          />
        </div>
      ))}
    </>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#8797ad',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default PoStatusTab;
