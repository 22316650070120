import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Divider, Paper, Radio, Typography } from '@material-ui/core';
import deliverySuccess from '../../../assets/img/_App/delivery_success.svg';

import { useStyles } from './DeliverySuccessModal.styles';

const DeliverySuccessModal = ({ documents, open, onSelect, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <div className="w-50 m-auto pt-5 pb-5">
            <img
              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
              src={deliverySuccess}
            />

            <Typography style={{ textAlign: 'center' }} variant="h4">
              {documents ? 'Documents Uploaded Successfully' : `Delivery successfully \n created`}
            </Typography>
          </div>
          <div className={classes.buttonGroup}>
            {documents ? (
              <Button className={`${classes.continueButton}`} onClick={onSelect}>
                Continue
              </Button>
            ) : (
              <>
                <Button className={`${classes.goHomeButton} `} onClick={onClose}>
                  Go Home
                </Button>
                <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
                <Button className={`${classes.continueButton}`} onClick={onSelect}>
                  Continue
                </Button>
              </>
            )}
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default DeliverySuccessModal;
