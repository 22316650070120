const extractError = (res) => {
  if (res?.data?.errorCode && res?.data?.errorType) {
    return res?.data;
  } else if (res?.headers['x-recykalapp-error']) {
    return {
      message: res.headers['x-recykalapp-error'],
      key: res.headers['x-recykalapp-key'],
    };
  } else if (res?.data?.message) {
    return res?.data;
  }
};

export default extractError;
