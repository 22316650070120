import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import XLSX from 'xlsx';
import './WorkOrderCreate.scss';

export const BulkWorkOrder = ({ constructWorkOrder, excelInProgress, isBasicDetailsPresent }) => {
  const handleCSVChange = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    reader.readAsBinaryString(file[0]);
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      //  console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      constructWorkOrder(data);
    };
  };

  return (
    <>
      {isBasicDetailsPresent && (
        <>
          <input
            type="file"
            className="hide_file"
            size="60"
            accept=".xlsx"
            disabled={excelInProgress}
            onChange={(e) => handleCSVChange(e.target.files)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Button className="button_section" disabled={excelInProgress}>
            Upload
          </Button>
        </>
      )}
      <Button variant={'outline-secondary'} style={{ marginRight: '10px' }}>
        <a href={'/Bulk_work_order_new.xlsx'} download="Bulk_work_order_new.xlsx">
          Download
        </a>
      </Button>
    </>
  );
};
