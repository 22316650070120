/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const DeliveryStatusTab = ({ selectedStatus, setSelectedStatus }) => {
  const [list, setList] = useState([
    {
      name: 'In Progress',
      select: false,
      status: 'IN_PROGRESS',
      internalStatus: 'IN_PROGRESS',
    },
    { name: 'In Transit', select: false, status: 'IN_TRANSIT', internalStatus: 'IN_TRANSIT' },
    {
      name: 'In Review',
      select: false,
      status: 'IN_REVIEW',
      internalStatus: 'IN_REVIEW',
    },
    { name: 'Approved', select: false, status: 'APPROVED', internalStatus: 'APPROVED' },
    { name: 'Disputed', select: false, status: 'DISPUTED', internalStatus: 'REJECTED' },
  ]);

  const handleChange = (event, idx) => {
    const clone = list.map((select, id) => {
      if (idx === id) return { ...select, select: !select.select };
      else return { ...select, select: false };
    });
    setList(clone);
    setSelectedStatus({
      ...selectedStatus,
      deliveryStatus: clone.find((status) => status.select === true),
    });
  };

  useEffect(() => {
    const clone = list.map((select, id) => {
      if (select.status === selectedStatus?.deliveryStatus?.status)
        return { ...select, select: !select.select };
      else return { ...select, select: false };
    });
    setList(clone);
  }, []);

  return (
    <>
      {list?.map((status, idx) => (
        <div>
          <FormControlLabel
            control={<GreenCheckbox name={status?.name} checked={status?.select} />}
            label={status?.name}
            onChange={(e) => handleChange(e, idx)}
          />
        </div>
      ))}
    </>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#8797ad',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default DeliveryStatusTab;
