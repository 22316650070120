import find from 'lodash/find';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

const filterRegionsByTarget = (states, target) => {
  if (states && target) {
    const filteredStates = states.map((state, stateIndex) => {
      for (let i = 0; i < target.workOrderItems.length; i++) {
        const stateFromTarget = find(target.workOrderItems[i].states, {
          stateId: state.id,
        });
        if (stateFromTarget) {
          state.districts = state.districts.filter((dist) => {
            return find(stateFromTarget.districts, { districtId: dist.id });
          });
          return state;
        }
      }
    });
    return filteredStates
      ? reject(filteredStates, (obj) => !obj || isEmpty(obj))
      : [];
  } else {
    return [];
  }
};

const getDistrictByStateId = (regions, stateId) => {
  if (!(regions && regions.length > 0)) return [];
  const state = regions.find((region) => region.id === stateId);
  if (state != null || state != undefined) {
    return state.districts;
  }
  return [];
};

const getDistrictById = (regions, distId) => {
  let district = null;
  if (!(regions && regions.length > 0)) return null;
  regions.find((region) => {
    const dist = region.districts.find((dis) => dis.id === distId);
    if (dist != null || dist != undefined) {
      district = dist;
    }
  });
  return district;
};

const getStateById = (regions, stateId) => {
  if (!(regions && regions.length > 0)) return null;
  const state = regions.find((region) => region.id === stateId);
  return state || null;
};

export default {
  filterRegionsByTarget,
  getDistrictByStateId,
  getDistrictById,
  getStateById,
};
