const NEW_ITEM = {
  itemId: null,
  browseNodeId: null,
  states: [],
  unitType: '',
  qty: 0,
};
const NEW_STATE = {
  stateId: null,
  totalQty: 0,
  districts: [],
};
const NEW_DIST = {
  districtId: null,
  totalQty: 0,
};

export class TargetUtils {
  static getItemDetails = (targetData, itemId) => {
    return targetData?.workOrderItems?.find((item) => {
      return item.itemId === itemId;
    });
  };

  static getTotalQtyForStates = (targetData, itemId) => {
    return this.getItemDetails(targetData, itemId)
      ?.states?.map((state) => state.totalQty)
      ?.reduce((prev, next) => prev + next, 0);
  };

  static getTotalQtyForDistricts = (targetData, itemId, stateId) => {
    return this.getStateDetails(targetData, itemId, stateId)
      ?.districts?.map((state) => state.totalQty)
      ?.reduce((prev, next) => prev + next, 0);
  };

  static getStateDetails = (targetData, itemId, stateId) => {
    return this.getItemDetails(targetData, itemId)?.states?.find(
      (state) => state.stateId === stateId
    );
  };

  static getDistrictDetails = (targetData, itemId, stateId, districtId) => {
    return this.getStateDetails(targetData, itemId, stateId)?.districts?.find(
      (dist) => dist.districtId === districtId
    );
  };

  static getStateQty = (targetData, itemId, stateId) => {
    return this.getItemDetails(targetData, itemId)?.states?.find(
      (state) => state.stateId === stateId
    )?.totalQty;
  };

  static getDistrictQty = (targetData, itemId, stateId, districtId) => {
    return this.getStateDetails(targetData, itemId, stateId)?.districts?.find(
      (dist) => dist.districtId === districtId
    )?.totalQty;
  };

  static getBrowseNodeDetails = (targetData, browseNodeId) => {
    return targetData?.browseNodes?.find((item) => {
      return item.browseNodeId === browseNodeId;
    });
  };

  static getUnAllocatedQtyForStates = (targetData, itemId) => {
    return (
      TargetUtils.getItemDetails(targetData, itemId)?.qty -
      TargetUtils.getTotalQtyForStates(targetData, itemId)
    );
  };

  static getUnAllocatedQtyForDistricts = (targetData, itemId, stateId) => {
    return (
      TargetUtils.getStateQty(targetData, itemId, stateId) -
      TargetUtils.getTotalQtyForDistricts(targetData, itemId, stateId)
    );
  };

  // setters all are by reference methods

  static addNewItemToTarget = (targetData, itemId, browseNodeId, unitType, qty) => {
    const itemToAdd = { ...NEW_ITEM };
    itemToAdd.itemId = itemId;
    itemToAdd.browseNodeId = browseNodeId;
    itemToAdd.unitType = unitType;
    itemToAdd.qty = qty;
    targetData.workOrderItems.push(itemToAdd);
  };

  static addNewStateToTargetItem = (targetData, stateId, qty, itemIndex) => {
    const stateToAdd = { ...NEW_STATE };
    stateToAdd.stateId = stateId;
    stateToAdd.totalQty = qty;
    targetData.workOrderItems[itemIndex].states.push(stateToAdd);
  };

  static addNewDistToworkOrderItemstate = (targetData, districtId, itemIndex, stateIndex, qty) => {
    const distToAdd = { ...NEW_DIST };
    distToAdd.districtId = districtId;
    distToAdd.totalQty = qty;
    targetData.workOrderItems[itemIndex].states[stateIndex].districts.push(distToAdd);
  };

  static updateQtyToUnallocatedState = (
    targetData,
    itemId,
    browseNodeId,
    itemIndex,
    qty,
    callBackAfterUpdate
  ) => {
    const unallocatedQtyOfItem = this.getUnAllocatedQtyForStates(targetData, itemId);
    if (unallocatedQtyOfItem !== null) {
      targetData.workOrderItems[itemIndex].qty =
        targetData.workOrderItems[itemIndex].qty - unallocatedQtyOfItem + qty;
      //recalculateAndUpdateCategoryQty(browseNodeId);
      callBackAfterUpdate && callBackAfterUpdate();
    }
  };

  static updateQtyToUnallocatedDistrict = (
    targetData,
    itemId,
    stateId,
    itemIndex,
    stateIndex,
    qty
  ) => {
    const unallocatedQtyOfState = this.getUnAllocatedQtyForDistricts(targetData, itemId, stateId);
    if (unallocatedQtyOfState !== null) {
      targetData.workOrderItems[itemIndex].states[stateIndex].totalQty =
        targetData.workOrderItems[itemIndex].states[stateIndex].totalQty -
        unallocatedQtyOfState +
        qty;
    }
  };

  static updateQtyToTargetDistrict = (
    targetData,
    districtId,
    itemId,
    stateId,
    itemIndex,
    stateIndex,
    qty
  ) => {
    if (districtId === 'unallocated_district') {
      TargetUtils.updateQtyToUnallocatedDistrict(
        targetData,
        itemId,
        stateId,
        itemIndex,
        stateIndex,
        qty
      );
    } else {
      const districtIndex = targetData.workOrderItems[itemIndex].states[
        stateIndex
      ].districts.findIndex((dist) => dist.districtId === districtId);
      if (districtIndex === -1) {
        TargetUtils.addNewDistToworkOrderItemstate(
          targetData,
          districtId,
          itemIndex,
          stateIndex,
          qty
        );
      } else {
        targetData.workOrderItems[itemIndex].states[stateIndex].districts[
          districtIndex
        ].totalQty = qty;
      }
    }
  };
}
