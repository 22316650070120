import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTreeTable from 'bootstrap-react-treetable';
import { groupBy, isEmpty, mapValues, sumBy, values } from 'lodash';
import './ExecutionTargetList.scss';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { useExecutionColumns } from './useExecutionColumns';
import classNames from 'classnames';
import { DelayRender } from '../../../shared/Components';
import HasAuthority from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import EmptyIcon from '../../../assets/img/empty-table.svg';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import { applySummaryFilter } from '../../../state/Execution/executionSlice';
const ExecutionTargetList = (props) => {
  const { hasAuth } = useAuthority();
  const { view, filters, loading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [subHeader, setSubHeader] = useState([]);
  const [data, setData] = useState([]);
  const [tableControls, setTableControls] = useState({});
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const { fixedColumnsLeft, vendorColumns, clientColumns } = useExecutionColumns(view, filters);
  let tableControlsInit = {
    visibleRows: 1,
    allowSorting: true,
    showExpandCollapseButton: false,
    showPagination: false,
    initialRowsPerPage: 100000,
    showResetSortingButton: false,
  };
  useEffect(() => {
    setTimeout(() => {
      setData([...buildData(props.data)]);
    }, 0);
  }, [props.data]);

  useEffect(() => {
    setData([]);
    let col = [];
    let _extraHeader = null;
    if (
      (!filters.clientId && !filters.serviceProviderId) ||
      (filters.clientId && filters.serviceProviderId)
    ) {
      col = [...fixedColumnsLeft, ...clientColumns, ...vendorColumns];
      _extraHeader = !isBrand ? (
        <tr className="extra-header">
          <th></th>
          <th className="demand" colSpan={5} style={{ textAlign: 'center' }}>
            Demand Side
          </th>
          <th className="supply" colSpan={5} style={{ textAlign: 'center' }}>
            Supply Side
          </th>
          <th className="self" colSpan={2} style={{ textAlign: 'center' }}>
            Self
          </th>
        </tr>
      ) : null;
    } else {
      if (filters.clientId) {
        col = [...fixedColumnsLeft, ...clientColumns];
        _extraHeader = !isBrand ? (
          <tr className="extra-header">
            <th></th>
            <th className="demand" colSpan={5} style={{ textAlign: 'center' }}>
              Demand Side
            </th>
          </tr>
        ) : null;
      }
      if (filters.serviceProviderId) {
        col = [...fixedColumnsLeft, ...vendorColumns];
        _extraHeader = !isBrand ? (
          <tr className="extra-header">
            <th></th>
            <th className="supply" colSpan={5} style={{ textAlign: 'center' }}>
              Supply Side
            </th>
            <th className="self" colSpan={2} style={{ textAlign: 'center' }}>
              Self
            </th>
          </tr>
        ) : null;
      }
    }
    setSubHeader(_extraHeader);
    setColumns(col);

    if (!filters.stateIds && !filters.districtIds) {
      setTableControls({ ...tableControlsInit, visibleRows: 1 });
    } else if (filters.stateIds && filters.districtIds) {
      setTableControls({ ...tableControlsInit, visibleRows: 3 });
    } else {
      if (filters.stateIds) {
        setTableControls({ ...tableControlsInit, visibleRows: 2 });
      }
      if (filters.districtIds) {
        setTableControls({ ...tableControlsInit, visibleRows: 3 });
      }
    }
  }, [filters, view]);

  /**
   * generate dataset in parent child object
   * @param data
   * @return {{data: {[p: string]: *}, children: []|{data: {[p: string]: *}, children: []|(null|{data: {[p: string]: *}, children: []})[]}[]}[]}
   */
  function buildData(data) {
    const aggKeys = [
      'orderQtyReceived',
      'orderQtyDueReceived',
      'deliveredQtyReceived',
      'inReviewReceived',
      'disputedReceived',
      'acceptedReceived',
      'pendingReceived',
      'orderQtyGiven',
      'orderQtyDueGiven',
      'deliveredQtyGiven',
      'inReviewGiven',
      'disputedGiven',
      'acceptedGiven',
      'pendingGiven',
      'selfCreatedReceived',
      'unallocatedReceived',
    ];
    const aggregate = (a) =>
      Object.fromEntries(aggKeys.map((metric) => [metric, sumBy(a, metric) || 0]));

    function getGroupItem(array, key) {
      const temp = groupBy(array, key);
      return Object.keys(temp).map((i) => {
        const childs = temp[i];
        return {
          data: {
            name: i,
            itemId: childs[0].itemId,
            ...aggregate(childs),
            isParent: true,
          },
          children: getGroupState(childs, 'stateName'),
        };
      });
    }

    function getGroupState(array, key) {
      const temp = groupBy(array, key);
      const _keys = Object.keys(temp);
      if (_keys?.length === 1 && _keys[0] === 'null') return [];
      return _keys.map((i) => {
        const childs = temp[i];
        return {
          data: {
            name: i != 'null' ? i : 'All States',
            itemId: childs[0].itemId,
            stateId: childs[0].stateId,
            ...aggregate(childs),
            isParent: true,
          },
          children: i === 'null' ? [] : getGroupDistrict(childs, 'districtName'),
        };
      });
    }

    function getGroupDistrict(array, key) {
      const temp = groupBy(array, key);
      const _keys = Object.keys(temp);
      if (_keys?.length === 1 && _keys[0] === 'null') return [];
      return _keys.map((i) => {
        const childs = temp[i];
        return {
          data: {
            name: i != 'null' ? i : 'All District',
            itemId: childs[0].itemId,
            stateId: childs[0].stateId,
            districtId: childs[0].districtId,
            ...aggregate(childs),
            isParent: true,
          },
          children: [],
        };
      });
    }

    const _data = getGroupItem(data, 'itemName');
    return _data;
  }
  console.log('client', filters);

  const clearFilter = () => {
    dispatch(applySummaryFilter(mapValues(filters, (f) => null)));
  };

  const buildTotal = (e) => {
    return buildData(props.data)
      .map((row) => row?.data[e])
      ?.reduce((prev, next) => roundOffAmount(prev + next), 0);
  };

  const comboTotal = (tgt1, tgt2, tgt3) => {
    return buildTotal(tgt1) + buildTotal(tgt2) + buildTotal(tgt3);
  };

  const viewBasedTotal = (tgt1, tgt2) => {
    return view === 'FY' ? buildTotal(tgt1) : buildTotal(tgt2);
  };

  const isPROFooter = (filters) => {
    const proClientkeys = [
      view === 'FY' ? 'orderQtyReceived' : 'orderQtyDueReceived',
      'inReviewGiven',
      'disputedGiven',
      'acceptedGiven',
      'pendingReceived',
    ];
    const proClientTotal = proClientkeys.map((e) => {
      if (e === 'pendingReceived') {
        return (
          viewBasedTotal('orderQtyReceived', 'orderQtyDueReceived') -
          comboTotal('inReviewGiven', 'acceptedGiven', 'disputedGiven')
        );
      } else {
        return buildTotal(e);
      }
    });
    const proVendorKeys = [
      view === 'FY' ? 'orderQtyGiven' : 'orderQtyDueGiven',
      'inReviewReceived',
      'disputedReceived',
      'acceptedReceived',
      'pendingGiven',
      'selfCreatedReceived',
      'unallocatedReceived',
    ];
    const proVendorTotal = proVendorKeys.map((e) => {
      if (e === 'pendingGiven') {
        return (
          viewBasedTotal('orderQtyGiven', 'orderQtyDueGiven') -
          comboTotal('inReviewReceived', 'acceptedReceived', 'disputedReceived')
        );
      } else {
        return buildTotal(e);
      }
    });
    return (
      <table className="table1">
        <tbody>
          <tr>
            <td className={'text-medium font-semibold'}>Total</td>

            {(!filters?.serviceProviderId || filters?.clientId) &&
              proClientTotal.map((e) => {
                return <td className={'border-bottom font-semibold'}>{e.toFixed(2)}</td>;
              })}

            {(filters.clientId === null || filters.clientId === undefined) &&
              proVendorTotal.map((e) => {
                return <td className={'border-bottom font-semibold'}>{e.toFixed(2)}</td>;
              })}
          </tr>
        </tbody>
      </table>
    );
  };

  const isBrandFooter = () => {
    const proBrandkeys = [
      view === 'FY' ? 'orderQtyReceived' : 'orderQtyDueReceived',
      view === 'FY' ? 'orderQtyGiven' : 'orderQtyDueGiven',
      'inReviewReceived',
      'disputedReceived',
      'acceptedReceived',
      'pendingReceived',
      'UnAllocatedQty',
    ];
    const brandTotal = proBrandkeys.map((e) => {
      if (e === 'pendingReceived') {
        return (
          viewBasedTotal('orderQtyGiven', 'orderQtyDueGiven') -
          comboTotal('inReviewReceived', 'acceptedReceived', 'disputedReceived')
        );
      } else if (e === 'UnAllocatedQty') {
        return view === 'FY'
          ? buildTotal('orderQtyReceived') - buildTotal('orderQtyGiven')
          : buildTotal('orderQtyDueReceived') - buildTotal('orderQtyDueGiven');
      } else {
        return buildTotal(e);
      }
    });
    return (
      <table className="brandtable">
        <tbody>
          <tr>
            <td className={'text-medium font-semibold'}>Total</td>
            {brandTotal.map((e) => {
              return <td className={'border-bottom font-semibold'}>{e.toFixed(2)}</td>;
            })}
          </tr>
        </tbody>
      </table>
    );
  };
  return (
    <div className="ExecutionTargetList">
      {data && data?.length > 0 ? (
        <div
          className={classNames('animated fadeIn', {
            'no-colors':
              isBrand ||
              !(
                (filters.clientId != null && filters.serviceProviderId != null) ||
                (!filters.clientId && !filters.serviceProviderId)
              ),
          })}>
          {
            <>
              <BootstrapTreeTable
                columns={columns}
                className="rcyl-table mb-0"
                extraHeader={subHeader}
                tableData={buildData(props.data)}
                control={tableControls}
              />
              {isBrand ? isBrandFooter() : isPROFooter(filters)}
            </>
          }
        </div>
      ) : (
        <DelayRender>
          <Row>
            <div className="text-center mx-auto mt">
              <img width="150" src={EmptyIcon} />
              {values(filters).every(isEmpty) ? (
                <>
                  <HasAuthority authorities={[AUTHORITY2.BRAND]}>
                    <h5 className="py-3">
                      You have not added any targets yet for the selected Financial Year. Add and
                      start fulfilling for EPR targets!
                    </h5>
                    <Button
                      variant={'btn-primary'}
                      onClick={() => {
                        history.push('target');
                      }}
                      className="mx-auto btn-primary px-3">
                      Set Target
                    </Button>
                  </HasAuthority>
                  <HasAuthority authorities={[AUTHORITY2.SERVICE_PROVIDER]}>
                    <h5 className="py-3">
                      You have not received any targets yet from your clients for the selected
                      Financial Year!
                    </h5>
                  </HasAuthority>
                </>
              ) : (
                <>
                  <h5 className="py-3">
                    There is no data for current applied filter. Please change or clear the filter!
                  </h5>
                  <Button
                    variant={'btn-primary'}
                    onClick={clearFilter}
                    className="mx-auto btn-primary px-3">
                    Clear Filter
                  </Button>
                </>
              )}
            </div>
          </Row>
        </DelayRender>
      )}
    </div>
  );
};
export default ExecutionTargetList;
