import { createSlice } from '@reduxjs/toolkit';
import { fetchStats, fetchBrandsLight } from './brandThunks';

const initialState = {
  stats: {},
  isFetchingStats: false,
  fetchStatsError: null,
  listLight: [],
  isFetchingListLight: false,
  fetchListLightError: null,
};

export const brandSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchStats.pending]: (state) => {
      state.isFetchingStats = true;
      state.fetchStatsError = null;
    },
    [fetchStats.fulfilled]: (state, action) => {
      state.isFetchingStats = false;
      state.fetchStatsError = null;
      state.stats = action.payload;
    },
    [fetchStats.rejected]: (state) => {
      state.isFetchingStats = false;
      // state.fetchStatsError = action.payload;
      state.fetchStatsError = 500;
    },

    [fetchBrandsLight.pending]: (state) => {
      state.isFetchingListLight = true;
      state.fetchListLightError = null;
    },
    [fetchBrandsLight.fulfilled]: (state, action) => {
      state.isFetchingListLight = false;
      state.fetchListLightError = null;
      state.listLight = action.payload;
    },
    [fetchBrandsLight.rejected]: (state) => {
      state.isFetchingListLight = false;
      // state.fetchListLightError = action.payload;
      state.fetchListLightError = 500;
    },
  },
});

// export const {} = brandSlice.actions;

export default brandSlice.reducer;
