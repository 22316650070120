import React, { useState } from 'react';
import { InputBase, Grid, ListItem, ListItemText, Paper, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useStyles } from './DeliveryPage.app.styles';
import { DeliveryStatus, Region } from '../../../shared/Components/GenericComponent';
import { Container } from '@material-ui/core';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { getArialDistance, toLocalDate } from '../../../shared/Utils/Helper';
import { useAsyncEffect } from '../../../hooks';
import PageView from '../../../shared/Components/_App/PageView';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const PlannedDeliveriesPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { selectedFinancialYear } = useFinancialYear();
  const [deliverylist, setDeliveryList] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [deliveryParams, setDeliveryParams] = useState({
    deliveryDateFrom: selectedFinancialYear?.fromDate,
    deliveryDateTo: selectedFinancialYear?.toDate,
    sort: 'createdOn,desc',
    direction: 'RECEIVED',
    itemIds: appMaterialItems,
    page: 0,
    size: 10,
    assignedStatus: 'IN_PROGRESS',
    isFetchMore: true,
  });

  useAsyncEffect(async () => {
    if (deliveryParams.isFetchMore) {
      setDeliveryLoading(true);
      const response = await DeliveryService.fetchDeliveries(deliveryParams);
      if (response?.deliveries) {
        if (isEmpty(deliverylist)) {
          setDeliveryList(response?.deliveries?.received);
          setCount(response?.totalCount);
          setDeliveryLoading(false);
        } else {
          const updatedData = [...deliverylist, ...response?.deliveries?.received];
          setDeliveryList(updatedData);
          setDeliveryLoading(false);
        }
      }
    }
  }, [deliveryParams.isFetchMore, deliveryParams?.page, deliveryParams['ids']]);

  const handleClick = (e) => {
    const deliveryData = e.deliveryDetails;
    if (
      // deliveryData.pickUpSupervisorMobile &&
      // deliveryData.dropSupervisorMobile &&
      // deliveryData.transporterId &&
      // deliveryData.driverName &&
      deliveryData.pickUpVehicleNumber
    ) {
      history.push(`/delivery-view/${e.id}`);
    } else {
      history.push(`/update-delivery/${e.id}`);
    }
  };
  const fromTabView = props.location.pathname?.includes('/tabs/');
  const getDistance = (del) => {
    const {
      pickUpLatitude,
      pickUpLongitude,
      dropOffLatitude,
      dropOffLongitude,
    } = del?.deliveryDetails;
    return getArialDistance(
      { lat: pickUpLatitude, lng: pickUpLongitude },
      { lat: dropOffLatitude, lng: dropOffLongitude }
    );
  };

  const filterDelivery = (txt) => {
    if (txt?.length > 3) {
      setDeliveryList([]);
      setDeliveryParams({ ...deliveryParams, page: 0, isFetchMore: true, ids: txt });
    } else {
      delete deliveryParams['ids'];
      setDeliveryParams({ ...deliveryParams, page: 0, isFetchMore: true });
    }
  };

  const fetchMoreData = () => {
    if (deliveryParams?.isFetchMore) {
      if (deliverylist?.length !== count) {
        setDeliveryParams({ ...deliveryParams, page: deliveryParams.page + 1, isFetchMore: true });
      } else setDeliveryParams({ ...deliveryParams, isFetchMore: false });
    }
  };

  return (
    <Container>
      <PageView>
        <Grid item xs={12} md={12} sm={6}>
          <Paper className={classes.root} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder="Search open deliveries"
              inputProps={{ 'aria-label': 'search open deliveries' }}
              onChange={(e) => filterDelivery(e.target.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* <List> */}
        <Grid
          className={classes.demo}
          style={{ paddingBottom: fromTabView ? '40px' : 0 }}
          spacing={1}>
          {!deliveryLoading && deliverylist?.length === 0 ? (
            <Paper>
              <EmptyView
                hasNoData={isEmpty(deliverylist)}
                className={'p-5'}
                message={'No data for selected criteria'}
              />
            </Paper>
          ) : (
            <div id="scrollableDiv" style={{ maxHeight: '74vh', overflow: 'auto' }}>
              <InfiniteScroll
                dataLength={deliverylist?.length}
                next={fetchMoreData}
                hasMore={deliveryParams?.isFetchMore}
                loader={deliveryLoading ? <h4 style={{ textAlign: 'center' }}>Loading...</h4> : ''}
                scrollableTarget="scrollableDiv">
                {deliverylist?.map((e) => (
                  <Grid key={e.id} className={classes.greyBg} item xs={12} md={6} sm={6}>
                    <ListItem className={classes.whiteBg} button onClick={() => handleClick(e)}>
                      <Grid container>
                        <ListItemText primary={e.id} secondary={toLocalDate(e.deliveryDate)} />
                      </Grid>
                      <Grid container>
                        <ListItemText
                          primary={`${Number(e?.qty) / 1000} MT's`}
                          secondary={
                            <DeliveryStatus
                              status={e.assignedDeliveries && e.assignedDeliveries[0]?.status}
                            />
                          }
                        />
                      </Grid>
                      <Grid container>
                        <ListItemText
                          primary={getDistance(e) + ' Km'}
                          secondary={<Region stateId={e?.stateId} districtId={e?.districtId} />}
                        />
                      </Grid>
                    </ListItem>
                  </Grid>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Grid>
      </PageView>
    </Container>
  );
};

export default PlannedDeliveriesPage;
