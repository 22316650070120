import { executionAPI } from './executionAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTargetExecution = createAsyncThunk(
  'execution/fetchTargetExecution',
  async (queryParams) => {
    const response = await executionAPI.fetchTargetExecution(queryParams);
    return response;
  }
);
