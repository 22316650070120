import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { DOCUMENT_STATUS_LIST } from '../../Constants/Constants';

const DocStatusLabel = (props) => {
  const documentList = [
    ...DOCUMENT_STATUS_LIST,
    ...[
      {
        label: 'No Name',
        id: 'NO_NAME',
        color: '#ffcf8f',
      },
      {
        label: 'Named',
        id: 'NAMED',
        color: '#7297ff',
      },
    ],
  ];

  let [docStatus, setDocStatus] = useState(
    documentList.find((obj) => obj.id === props.docStatusId)
  );
  const style = {
    padding: '3px',
    fontSize: '9px',
    lineHeight: '2px',
    marginRight: '3px',
  };

  return (
    <>
      {docStatus.color ? (
        <Badge
          variant={'success'}
          style={{ ...style, backgroundColor: docStatus.color }}
        >
          &nbsp;
        </Badge>
      ) : null}{' '}
      {docStatus.label} {props.count ? `(${props.count})` : null}
    </>
  );
};

export default DocStatusLabel;
