import React from 'react';
import { useSelector } from 'react-redux';
import JwtUtils from '../shared/Utils/jwt.utils';

export const useAppAuth = () => {
  const account = useSelector(({ account }) => account) || [];
  if (window.location.pathname.includes('supervisor')) {
    if (localStorage.getItem('authenticate_token')) {
      const jwtData = JwtUtils().getTokenData();
      console.log('jwtData', jwtData);
      if (jwtData.auth === 'ROLE_EPR_SUPERVISOR') {
        return false;
      }
      return true;
    }
    return true;
  }
  return false;
};
