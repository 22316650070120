import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchServiceProviders = async () => {
  return await API.get(APIEndpoints.SERVICE_PROVIDER, null, {
    size: 10000,
    vendorTypes: 'SERVICE_PROVIDER, PWP',
  });
};

export const serviceProviderAPI = {
  fetchServiceProviders,
};
