import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    bottom: 10,
    width: '95%',
    position: 'absolute',
  },
  generateDocPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    width: '95%',
    padding: '20px',
  },
  generateDoc: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    width: '95%',
    padding: '20px',
  },
  generateDocPaperRejected: {
    backgroundColor: theme.palette.background.paper,
    width: '95%',
    maxHeight: '60vh',
    overflow: 'auto',
  },
  approveAvtar: {
    width: '50px',
    height: '50px',
    fontSize: '30px',
    backgroundColor: 'rgb(240, 255, 237)',
    margin: 'auto',
  },
  alertAvtar: {
    width: '25px',
    height: '25px',
    fontSize: '15px',
    backgroundColor: '#ffffe9',
    margin: 'auto',
    border: '1px solid #ffc918',
  },
  span: { color: '#8797ad' },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  submitButton: {
    width: '100%',
    textAlign: 'centre',
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
  skipButton: {
    width: '100%',
    textAlign: 'centre',
    fontSize: 'large',
    fontFamily: 'font-bold',
    backgroundColor: '#eff5ff',
    color: '#005dff',
    marginTop: '12px',
  },

  buttonGroup: { display: 'flex' },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    borderRadius: '50%',
    backgroundColor: '#33ab2e',
    backgroundImage: 'linear-gradient(90deg, #33ab2e,#33ab2e)',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  goHomeButton: {
    width: '100%',
    borderBottomLeftRadius: 20,
    backgroundColor: '#eff5ff',
    color: '#005dff',
  },
  continueButton: {
    width: '100%',
    backgroundColor: '#eaf7e9',
    color: '#33ab2e',
    borderBottomRightRadius: 20,
  },
}));
