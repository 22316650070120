import { createSlice } from '@reduxjs/toolkit';
import { fetchTargetExecution } from './executionThunks';

const initialState = {
  summary: [],
  deliverySummary: [],
  orderSummary: [],
  filters: {
    browseNodeIds: null,
    itemIds: null,
    stateIds: null,
    districtIds: null,
    clientId: null,
    serviceProviderId: null,
  },
  view: 'TILL_MONTH',
  isFetching: true,
  fetchError: null,
};

export const executionSlice = createSlice({
  name: 'execution',
  initialState,
  reducers: {
    applySummaryFilter: (state, action) => {
      state.filters = action.payload;
    },
    ChangeView: (state, action) => {
      state.view = action.payload;
    },
  },
  extraReducers: {
    [fetchTargetExecution.pending]: (state) => {
      state.isFetching = true;
      state.fetchError = null;
    },
    [fetchTargetExecution.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.fetchError = null;
      state.orderSummary = action.payload.orderSummary;
      state.deliverySummary = action.payload.deliverySummary;
    },
    [fetchTargetExecution.rejected]: (state) => {
      state.isFetching = false;
      state.fetchError = 500;
    },
  },
});

export const { applySummaryFilter, ChangeView } = executionSlice.actions;

export default executionSlice.reducer;
