import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useDistricts } from '../../../hooks';
import { orderBy } from 'lodash';

const DropdownDistrict = (props) => {
  let districts = useDistricts(props.stateId);
  districts = orderBy(districts, ['name']);
  if (props.filterIds && props.filterIds.length > 0) {
    districts = districts.filter((dist) => props.filterIds.indexOf(dist.id) >= 0);
  }

  return (
    <>
      {districts ? (
        <Select
          {...props}
          className={classNames('react-select', {
            'is-invalid': props.isInvalid,
          })}
          classNamePrefix="select"
          value={
            props.value && {
              ...districts?.find(
                (o) =>
                  o.name === props.value ||
                  o.name === props.value.sourceDistrict ||
                  o.id === props.value ||
                  o.id === props.value.sourceDistrict
              ),
            }
          }
          options={districts}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option.id}`}
          noOptionsMessage={() => 'District not found!'}
          components={{
            IndicatorSeparator: null,
          }}
        />
      ) : null}
    </>
  );
};

export default DropdownDistrict;
