import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchBrowseNodes = async () => {
  return await API.get(APIEndpoints.BROWSE_NODES);
};
const fetchImpactMetrics = async () => {
  return await API.get(`${window.location.origin}${APIEndpoints.IMPACT_METRICS}`);
};
export const BrowseNodeAPI = {
  fetchBrowseNodes,
  fetchImpactMetrics,
};
