import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

const DropdownSelect = (inProps) => {
  const { value, className, isInvalid, options, valueKey = 'id', isDisabled, ...props } = inProps;
  const selectedValues = props.isMulti
    ? value
    : value && {
        ...options?.find((o) => o[valueKey] === value || o[valueKey] === value[valueKey]),
      };
  return (
    <Select
      className={classNames(
        'react-select',
        {
          'is-invalid': isInvalid,
        },
        className
      )}
      isDisabled={isDisabled}
      classNamePrefix="select"
      value={selectedValues}
      options={options}
      {...props}
    />
  );
};

DropdownSelect.propTypes = {
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.any,
  valueKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};

export default DropdownSelect;
