import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    serviceWorkerInit: (state) => {
      state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
    },
    serviceWorkerUpdate: (state, action) => {
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = action.payload;
    },
  },
  extraReducers: {},
});

export const { serviceWorkerInit, serviceWorkerUpdate } = userSlice.actions;

export default userSlice.reducer;
