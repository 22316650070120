import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import './DashboardOverViewByState.scss';
import { useRegionsMap } from '../../hooks/useRegions';
import { sortBy } from 'lodash';

//Default options declared for overview bystate graph
const options = {
  tooltips: {
    displayColors: true,
    mode: 'single',
    callbacks: {
      label: function (tooltipItems, data) {
        return data.datasets[tooltipItems.datasetIndex].label + ' : ' + tooltipItems.yLabel + ' kg';
      },
    },
  },
  // tooltips: {
  //   displayColors: true,
  //   callbacks: {
  //     mode: 'x',
  //   },
  // },
  scales: {
    xAxes: [
      {
        stacked: true,
        label: 'States',
        barPercentage: 0.3,
        maxBarThickness: 25,
        scaleLabel: {
          display: true,
          labelString: 'States',
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        barPercentage: 0.3,
        maxBarThickness: 25,
        ticks: {
          beginAtZero: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'No of tons',
        },
        type: 'linear',
      },
    ],
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: { position: 'none' },
};

const DashboardOverViewByState = ({ data, direction, isBrand }) => {
  //States
  const statesMap = useRegionsMap();
  const [dataFinal, setDataFinal] = useState({});
  let states = [];
  let accepted = [],
    inReview = [],
    disputed = [],
    unallocated = [],
    pending = [];

  useEffect(() => {
    //TO-DO check direction once
    if (!data) return;
    data = sortBy(data, ['stateId']);

    data.map((d) => {
      const isDataExist =
        direction === 'received'
          ? d.acceptedReceived ||
            d.inReviewReceived ||
            d.disputedReceived ||
            d.currentOrderQtyGiven ||
            d.deliveredQtyReceived ||
            (!isBrand && d.selfCreatedReceived)
          : d.acceptedGiven ||
            d.inReviewGiven ||
            d.disputedGiven ||
            d.currentOrderQtyReceived ||
            d.deliveredQtyGiven;
      if (isDataExist) {
        states.push(statesMap[d.stateId]?.code ?? 'All');
        if (direction === 'received') {
          accepted.push(d.acceptedReceived);
          inReview.push(d.inReviewReceived);
          disputed.push(d.disputedReceived);
          pending.push(Math.max((d.currentOrderQtyGiven || 0) - (d.deliveredQtyReceived || 0)));
          isBrand
            ? unallocated.push(Math.max(d.currentOrderQtyReceived - d.currentOrderQtyGiven, 0))
            : unallocated.push(d.selfCreatedReceived || 0);
        } else {
          accepted.push(d.acceptedGiven);
          inReview.push(d.inReviewGiven);
          disputed.push(d.disputedGiven);
          pending.push(Math.max((d.currentOrderQtyReceived || 0) - (d.deliveredQtyGiven || 0)));
          isBrand
            ? unallocated.push(Math.max(d.currentOrderQtyReceived - d.currentOrderQtyGiven, 0))
            : unallocated.push(d.selfCreatedReceived || 0);
        }
      }
    });

    let objSets = [
      {
        label: direction === 'received' ? 'Achieved' : 'Approved by Client',
        backgroundColor: '#7ae1ca',
        data: accepted,
      },
      {
        label: direction === 'received' ? 'In Review' : 'Awaiting for client approval',
        backgroundColor: '#f8cb1c',
        data: inReview,
      },
      {
        label: 'Disputed',
        backgroundColor: '#e66d71',
        data: disputed,
      },
      {
        label: 'Pending Fulfilment',
        backgroundColor: '#ed9714',
        data: pending,
      },
      {
        label: isBrand ? 'Unallocated' : 'Self Fulfilled',
        backgroundColor: '#7297fe',
        data: unallocated,
      },
    ];

    // removing unallocated for client overview
    if (direction !== 'received') objSets.pop();

    setDataFinal({
      labels: states,
      datasets: [...objSets],
    });
  }, [data]);

  return (
    <div className="DashboardOverViewByState">
      <Row>
        <Col xs={8}>
          <h6 className="mb-4 text-medium">Overview by State</h6>
        </Col>
        <Col xs={4}></Col>
      </Row>
      <div>
        <Bar data={dataFinal} options={options} height={300} />
      </div>
    </div>
  );
};

export default DashboardOverViewByState;
