import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { difference, isEmpty } from 'lodash';
import FormField from '../../../../shared/Components/_App/FormField';
import { DeliveryService } from '../../../../features/Delivery/delivery.service';
import WarningIcon from '../../../../assets/img/ic_warning.svg';

const WeightDetails = (props) => {
  const {
    formik,
    isWeighBridgeNumberPresent,
    setIsWeighBridgeNumberPresent,
    isPickup,
    deliveryData,
    maxQty,
    isCreate,
    saveLoading,
    isEdit,
    delivery,
    status,
    isQtyEditable,
  } = { ...props };
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isQuantityExceeding, setIsQuantityExceeding] = useState(false);
  const [isNetWeightNegative, setIsNetWeightNegative] = useState(false);

  useEffect(() => {
    if (isPickup && !isCreate && !isEmpty(delivery?.deliveryDetails)) {
      const deliveryArray = [
        delivery?.deliveryDetails?.pickUpGrossWeight,
        delivery?.deliveryDetails?.pickUpNetWeight,
        delivery?.deliveryDetails?.pickUpTareWeight,
        delivery?.deliveryDetails?.pickUpLrNumber,
        delivery?.deliveryDetails?.pickUpWeighBridgeNumber,
      ];
      const formikArray = [
        formik?.values?.pickUpGrossWeight,
        formik?.values?.pickUpNetWeight,
        formik?.values?.pickUpTareWeight,
        formik?.values?.pickUpLrNumber,
        formik?.values?.pickUpWeighBridgeNumber,
      ];
      const isDeliveryArrayEmpty = difference(formikArray, deliveryArray);
      if (
        isEmpty(isDeliveryArrayEmpty) ||
        isDeliveryArrayEmpty?.includes(null) ||
        isDeliveryArrayEmpty?.includes('')
      )
        setIsButtonEnabled(true);
      else setIsButtonEnabled(false);
    } else if (!isPickup && !isCreate && !isEmpty(delivery?.deliveryDetails)) {
      const deliveryArray = [
        delivery?.deliveryDetails?.dropOffGrossWeight,
        delivery?.deliveryDetails?.dropOffNetWeight,
        delivery?.deliveryDetails?.dropOffTareWeight,
        delivery?.deliveryDetails?.dropOffWeighBridgeNumber,
      ];
      const formikArray = [
        formik?.values?.dropOffGrossWeight,
        formik?.values?.dropOffNetWeight,
        formik?.values?.dropOffTareWeight,
        formik?.values?.dropOffWeighBridgeNumber,
      ];
      const isDeliveryArrayEmpty = difference(formikArray, deliveryArray);
      if (
        isEmpty(isDeliveryArrayEmpty) ||
        isDeliveryArrayEmpty?.includes(null) ||
        isDeliveryArrayEmpty?.includes('') ||
        formik?.values?.dropOffTareWeight === null ||
        isEmpty(formik?.values?.dropOffWeighBridgeNumber)
      )
        setIsButtonEnabled(true);
      else setIsButtonEnabled(false);
    }
  }, [formik?.values, delivery]);

  const handleqtyChange = (value, fieldType, type) => {
    if (type === 'pickup') {
      let pickUpNetWeight = null;
      if (fieldType === 'pickUpGrossWeight')
        pickUpNetWeight =
          formik?.values?.pickUpTareWeight === null
            ? value
            : value - formik?.values?.pickUpTareWeight;
      else
        pickUpNetWeight =
          value === null
            ? formik?.values?.pickUpGrossWeight
            : formik?.values?.pickUpGrossWeight - value;
      formik.setFieldValue(fieldType, value);
      formik.setFieldValue('pickUpNetWeight', pickUpNetWeight);
      //   if (!deliveryData?.id && pickUpNetWeight === null) formik.setFieldValue('qty', pickUpNetWeight);
      if (isQtyEditable)
        formik.setFieldValue('qty', pickUpNetWeight === null ? 0 : pickUpNetWeight);
      if (
        !isCreate &&
        isQtyEditable &&
        (maxQty < pickUpNetWeight ||
          pickUpNetWeight?.toString()?.includes('-') ||
          pickUpNetWeight === 0)
      )
        setIsQuantityExceeding(true);
      else setIsQuantityExceeding(false);
      if (!isQtyEditable && (pickUpNetWeight?.toString()?.includes('-') || pickUpNetWeight === 0))
        setIsNetWeightNegative(true);
      else setIsNetWeightNegative(false);
    } else {
      let dropOffNetWeight = null;
      if (fieldType === 'dropOffGrossWeight')
        dropOffNetWeight =
          formik?.values?.dropOffTareWeight === null
            ? value
            : value - formik?.values?.dropOffTareWeight;
      else
        dropOffNetWeight =
          value === null
            ? formik?.values?.dropOffGrossWeight
            : formik?.values?.dropOffGrossWeight - value;
      formik.setFieldValue(fieldType, value);
      formik.setFieldValue('dropOffNetWeight', dropOffNetWeight);
      //   if (!deliveryData?.id && dropOffNetWeight === null) formik.setFieldValue('qty', dropOffNetWeight);
      formik.setFieldValue('qty', dropOffNetWeight);
      if (
        maxQty < dropOffNetWeight ||
        dropOffNetWeight?.toString()?.includes('-') ||
        dropOffNetWeight === 0
      )
        setIsQuantityExceeding(true);
      else setIsQuantityExceeding(false);
    }
  };

  const handleWeighBridgeCheck = async (value, type, fieldType) => {
    const isSameDetailsPresent = !isEmpty(deliveryData)
      ? deliveryData?.deliveryDetails[fieldType]?.trim() === value?.trim()
      : false;
    if (type === 'PICKUP')
      setIsWeighBridgeNumberPresent({
        ...isWeighBridgeNumberPresent,
        pickUpWeighBridgeCheckLoading: true,
      });
    else
      setIsWeighBridgeNumberPresent({
        ...isWeighBridgeNumberPresent,
        dropOffWeighBridgeCheckLoading: true,
      });
    formik.setFieldValue(fieldType, value);
    if (!isEmpty(value) && !isSameDetailsPresent) {
      const response = await DeliveryService.checkWeighBridgeNumber(type, value);
      if (type === 'PICKUP')
        setIsWeighBridgeNumberPresent({
          ...isWeighBridgeNumberPresent,
          isPickUpWeighBridgePresent: response?.data === true ? true : false,
          pickUpWeighBridgeCheckLoading: false,
        });
      else
        setIsWeighBridgeNumberPresent({
          ...isWeighBridgeNumberPresent,
          isDropOffWeighBridgePresent: response?.data === true ? true : false,
          dropOffWeighBridgeCheckLoading: false,
        });
    } else {
      if (type === 'PICKUP')
        setIsWeighBridgeNumberPresent({
          ...isWeighBridgeNumberPresent,
          isPickUpWeighBridgePresent: false,
        });
      else
        setIsWeighBridgeNumberPresent({
          ...isWeighBridgeNumberPresent,
          isDropOffWeighBridgePresent: false,
        });
    }
  };

  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e'];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
  };

  const isSaveButtonEnabled =
    isWeighBridgeNumberPresent?.pickUpWeighBridgeCheckLoading ||
    isWeighBridgeNumberPresent?.dropOffWeighBridgeCheckLoading ||
    saveLoading ||
    isButtonEnabled ||
    isQuantityExceeding ||
    status ||
    isNetWeightNegative;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6}>
        <FormField
          FormControlClassName="mb-0"
          label="GROSS WEIGHT (In KG)"
          type="number"
          value={
            formik?.values[isPickup ? 'pickUpGrossWeight' : 'dropOffGrossWeight']
              ? formik?.values[isPickup ? 'pickUpGrossWeight' : 'dropOffGrossWeight']
              : ''
          }
          onKeyDown={handleKeyDown}
          disabled={status}
          onChange={(e) =>
            handleqtyChange(
              isEmpty(e?.target?.value) || e?.target?.value?.trim() === '0'
                ? null
                : Number(e?.target?.value),
              isPickup ? 'pickUpGrossWeight' : 'dropOffGrossWeight',
              isPickup ? 'pickup' : 'dropOff'
            )
          }
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <FormField
          FormControlClassName="mb-0"
          label="TARE WEIGHT (In KG)"
          type="number"
          disabled={status}
          value={
            formik?.values[isPickup ? 'pickUpTareWeight' : 'dropOffTareWeight']
              ? formik?.values[isPickup ? 'pickUpTareWeight' : 'dropOffTareWeight']
              : ''
          }
          onKeyDown={handleKeyDown}
          onChange={(e) =>
            handleqtyChange(
              isEmpty(e?.target?.value) || e?.target?.value?.trim() === '0'
                ? null
                : Number(e?.target?.value),
              isPickup ? 'pickUpTareWeight' : 'dropOffTareWeight',
              isPickup ? 'pickup' : 'dropOff'
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          FormControlClassName="mb-0"
          label="NET WEIGHT (In KG)"
          type="number"
          value={
            formik?.values[isPickup ? 'pickUpNetWeight' : 'dropOffNetWeight'] === null
              ? ''
              : formik?.values[isPickup ? 'pickUpNetWeight' : 'dropOffNetWeight']
          }
          disabled={true}
        />
      </Grid>
      {isQuantityExceeding && (
        <Typography color="error" variant="body1">
          {`Net Quantity should be <= Need of WorkOrder`}
        </Typography>
      )}
      {isNetWeightNegative && (
        <Typography color="error" variant="body1">
          {`Net Quantity can't be negative or 0`}
        </Typography>
      )}
      {isPickup && (
        <Grid item xs={12} sm={12}>
          <FormField
            FormControlClassName="mb-0"
            label="LORRY RECEIPT"
            type="text"
            disabled={status}
            {...formik.getFieldProps('pickUpLrNumber')}
          />
        </Grid>
      )}
      <Grid item xs={isCreate ? 12 : 6} sm={isCreate ? 12 : 6}>
        <FormField
          FormControlClassName="mb-0"
          label="WEIGHBRIDGE NO"
          type="text"
          value={formik?.values[isPickup ? 'pickUpWeighBridgeNumber' : 'dropOffWeighBridgeNumber']}
          disabled={status}
          onChange={(e) =>
            handleWeighBridgeCheck(
              e?.target?.value,
              isPickup ? 'PICKUP' : 'DROP',
              isPickup ? 'pickUpWeighBridgeNumber' : 'dropOffWeighBridgeNumber'
            )
          }
        />
        {isWeighBridgeNumberPresent[
          isPickup ? 'isPickUpWeighBridgePresent' : 'isDropOffWeighBridgePresent'
        ] &&
          formik?.values[isPickup ? 'pickUpWeighBridgeNumber' : 'dropOffWeighBridgeNumber'] && (
            <p style={{ color: '	#FF6347', marginTop: '5px' }}>
              <img width="12px" className="mr-1" src={WarningIcon} />
              Weigh Bridge Number exists in the system
            </p>
          )}
        {isWeighBridgeNumberPresent[
          isPickup ? 'pickUpWeighBridgeCheckLoading' : 'dropOffWeighBridgeCheckLoading'
        ] && <p style={{ color: '	#FF6347', marginTop: '5px' }}>Checking...</p>}
      </Grid>
      {!isCreate && (
        <Grid
          item
          xs={6}
          sm={6}
          style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: '15px' }}
            disabled={isSaveButtonEnabled}
            onClick={() => formik.handleSubmit()}>
            {saveLoading ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default WeightDetails;
