const DownloadFile = (doc, fileName) => {
  fetch(doc.viewUrl, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
      window.open(doc.viewUrl);
    });
};

export default DownloadFile;
