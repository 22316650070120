import React, { useEffect, useState } from 'react';
import { subscribe } from '../../Events/AppEvents';
import { Button, Image, Modal } from 'react-bootstrap';
import serverError from '../../../assets/img/server-down.svg';
import { ChatService } from '../../Services/ChatService';
import { APP_EVENTS } from '../../Constants/Constants';
import { uniq } from 'lodash';
const { event, topic } = APP_EVENTS;

const ServerException = (props) => {
  //services present in array only show error modal
  const enableServices = ['epr', 'stakeholders'];
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    serviceName: [],
    url: [],
  });
  /**
   * check show error modal enable for current failed services
   * @param str - failed url
   * @return {boolean}
   */
  function hasAPIenabled(errorPayload) {
    try {
      const { config } = errorPayload;
      const { pathname } = new URL(config.url);
      const serviceName = pathname.split('/')[1];
      setError((prevState = {}) => {
        return {
          serviceName: uniq([...prevState.serviceName, serviceName]),
          url: uniq([...prevState.url, config.url]),
        };
      });
      return enableServices.indexOf(serviceName) > -1;
    } catch (e) {}
    return false;
  }
  useEffect(() => {
    subscribe(topic.SERVER_ERROR, (ev, payload) => {
      console.log('EVENT called', event, payload);
      console.log('ServerException', error);
      if (hasAPIenabled(payload) && !showModal && ev === event.SERVER_DOWN_503) {
        setShowModal(true);
      }
    });
  }, []);
  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        onExited={() => {
          setError({
            serviceName: [],
            url: [],
          });
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className={'text-center'}>
          <div className="p-5 d-flex flex-column align-items-center justify-content-center">
            <Image width={250} className="img-fluid my-3" src={serverError} />
            <h3 className="mt-3">Uh, oh, Seems server not responding!</h3>
            <p className="my-4 text-medium px-4">
              Look like we are updating something new for you. Please try again after few minute. if
              this continues happening Please email us at{' '}
              <a href="mailto:support@recykal.com">support@recykal.com</a> So that we can correct
              our mistake.
            </p>
            {error.serviceName && (
              <p className="text-muted">Service : {error.serviceName?.toString()} </p>
            )}
            <Button
              onClick={() => setShowModal(false)}
              className="my-3"
              variant={'primary'}
              size={'lg'}>
              Okay, Got It.
            </Button>
            <Button onClick={() => ChatService().openChat('EPR Platform Support')} variant={'link'}>
              Chat with Support
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ServerException.propTypes = {};

export default ServerException;
