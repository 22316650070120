/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import Viewer from 'react-viewer';
import RangeSlider from 'react-bootstrap-range-slider';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import './DocumentModal.scss';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DocumentHistoryItem from '../../DocumentHistory/DocumentHistoryItem/DocumentHistoryItem';
import { DeliveryService } from '../../delivery.service';
import { uploadDocument } from '../../../../shared/Utils/FileUpload';
import DeliveryDetailsTab from './DeliveryDetailsTab';
import HasAuthority from '../../../../shared/Components/HasAuthority/HasAuthorityNew';

const DocumentModal = (props) => {
  const {
    document,
    docTypeList,
    deliveryId,
    show,
    closeFn,
    convertBytesToKb,
    displayAlert,
    onCompressedImgSave,
    isShowActionForMpDelivery,
    deliveryStatus,
    saleOrderId,
    setDelivery,
    isAPICallForImageView,
    isAllDocList,
    documentDeliveryDetails,
    selectedContainer,
  } = props;

  const browserImageSize = require('browser-image-size');
  const account = useSelector(({ account }) => account);
  const [displayRaiseDispute, setDisplayRaiseDispute] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState([]);
  const [, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [imageStyle, setImageStyle] = useState(null);
  const [disputeNote, setDisputeNote] = useState('');
  const [documentType, setDocumentType] = useState(null);
  const [, setMetaData] = useState({});
  const [documentTypesList, setDocumentTypesList] = useState([]);
  const [docTickets, setDocTickets] = useState([]);
  const [newImage, setNewImage] = useState();
  const [showEditOptions, setEditOptions] = useState(false);
  const [qualityValue, setQualityValue] = useState(100);
  const [rotateVal, setRotateVal] = useState(90);
  const [compressedFileObj, setCompressedFileObj] = useState();
  const [isFileCompressed, setIsFileCompressed] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [allTickets, setAllTickets] = useState([]);

  const ownerId = document?.fromCustomerId || document?.toCustomerId;
  const isOwner = ownerId === account.user.profile?.id;
  const isReceiver = document?.toCustomerId === account.user.profile?.id;
  const canDisputeMpDocs =
    deliveryStatus === 'IN_REVIEW' &&
    saleOrderId &&
    !['APPROVED', 'REJECTED'].includes(document?.status);

  const canDispute =
    (isReceiver && !isOwner && !['APPROVED', 'REJECTED'].includes(document?.status)) ||
    canDisputeMpDocs;

  const fetchTickets = async (deliveryId) => {
    try {
      const response = await DeliveryService.fetchTickets(deliveryId);
      if (response.status === 200) {
        setAllTickets(response?.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (show && isAPICallForImageView) {
      fetchTickets(deliveryId);
      doFetchDelivery();
    }
  }, [show, isAPICallForImageView]);

  const filterTickets = (allTickets) => {
    const arr = [];
    allTickets.forEach((ticket) => {
      const match =
        ticket.deliveryDocuments &&
        ticket.deliveryDocuments.find((doc) => {
          //TODO: handle for doc being id directly
          return doc === document?.id || doc.id === document?.id;
        });
      if (match) {
        arr.push(ticket);
      }
    });

    return arr.sort((ticket1, ticket2) => {
      return moment(ticket2.ticketDate) - moment(ticket1.ticketDate);
    });
  };

  /**
   * get delivery by @id from server
   * @return {Promise<void>}
   */
  const doFetchDelivery = async () => {
    try {
      const response = await DeliveryService.fetchDeliveryById(deliveryId);
      setDeliveryDetails(response?.data);
      setDelivery(response?.data);
    } catch (error) {
      setError(error?.response?.data);
    }
  };

  useEffect(() => {
    setDocTickets(filterTickets(allTickets));
  }, [allTickets, document]);

  useEffect(() => {
    const list = [];
    if (isAllDocList) {
      for (let docLists of docTypeList) {
        list.push(docLists);
      }
    } else {
      docTypeList.forEach((docKindList) => {
        docKindList.documents.forEach((docType) => {
          list.push(docType);
        });
      });
    }
    setDocumentTypesList(list);
  }, [docTypeList]);

  const raiseTicket = async () => {
    const ticketDetails = {
      comment: disputeNote,
      deliveryId: deliveryId,
      deliveryDocuments: [document.id],
    };
    try {
      const response = await DeliveryService.raiseTicket(ticketDetails);
      if (response?.data) {
        toast.success('Document disputed!');
        setDisplayRaiseDispute(false);
        doFetchDelivery();
        if (response?.data.deliveryDocuments[0]) {
          fetchTickets(deliveryId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (document) {
      if (document.documentMetaData) {
        const metaData = document.documentMetaData.metaData;
        setMetaData({ ...metaData });
      }
      if (document.document) {
        setDocumentType(document.document.type);
      }
      if (canDispute) {
        setDisplayRaiseDispute(canDispute);
      }
    }
  }, [document]);

  const resizeFile = async (fileData, quality, rotate) => {
    const fileName = fileData.fileName;
    let blob = await fetch(newImage, { cache: 'no-store' }).then((r) => r.blob());
    const file = new File([blob], fileName);

    try {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        quality,
        rotate,
        (fileObj) => {
          if (fileObj) {
            const reader = new FileReader();
            reader.readAsDataURL(fileObj);
            reader.onload = function (e) {
              setNewImage(e.target.result);
            };
          }
          browserImageSize(fileObj).then(function (size) {
            fileObj.width = size.width;
            fileObj.height = size.height;
          });
          setCompressedFileObj(fileObj);
          setIsFileCompressed(true);
        },
        'file'
      );
    } catch (err) {
      console.log(err);
    }
  };

  const resetToOriginalOrCompressedImage = () => {
    if (document?.document?.original !== null) {
      setNewImage(document?.document?.original.viewUrl);
      setCompressedFileObj(document?.document?.original);
    } else {
      setNewImage(document?.document?.viewUrl);
      setCompressedFileObj(document?.document);
    }
  };

  const revertToOriginalHandler = () => {
    setRotateVal(90);
    setQualityValue(100);
    setEditOptions(false);
    setIsFileCompressed(false);
    resetToOriginalOrCompressedImage();
  };

  const qualityHandler = (value) => {
    setQualityValue(value);
    if (value !== '100') {
      resizeFile(document?.document, value, 0);
    } else {
      resetToOriginalOrCompressedImage();
    }
  };

  const rotateHandler = () => {
    setRotateVal(90);
    resizeFile(document?.document, qualityValue, rotateVal);
  };

  const editImageHandler = () => {
    setEditOptions(true);
    setQualityValue(100);
    resetToOriginalOrCompressedImage();
  };

  const doSaveCompressedImg = (newDoc) => {
    if (document.document.original !== null) {
      newDoc.document.id = document.document.id;
      newDoc.document.original = document.document.original;
    } else {
      newDoc.document.original = document.document;
    }
    compressedDocumentAttach(newDoc.document);
  };

  const compressedDocumentAttach = async (documentReqData) => {
    const response = await DeliveryService.uploadCompressedDocument(documentReqData);
    if (response?.data) {
      setEditOptions(false);
      setNewImage(response?.data?.viewUrl);
      onCompressedImgSave();
    }
  };

  /**
   * upload document to s3 server
   * @param file
   * @param delDocId
   * @param type
   */
  const uploadDocumentToS3 = (file, delDocId) => {
    const fileDetails = {
      active: true,
      entity: 'DELIVERY',
      fileName: file.name || file.fileName,
      fileSize: file.size || file.fileSize,
      contentType: file.type,
      type: document?.document?.type,
      width: file.width,
      height: file.height,
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded.document?.filaeName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: 0,
        },
      });
    };

    const successCallback = () => {
      const oldUploadStatus = { ...uploadStatus };
      setUploadStatus({
        ...oldUploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'DONE',
          uploading: false,
        },
      });
      doSaveCompressedImg(docTobeUploaded);
    };

    const progressCallback = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      const newStatus = {
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: percentCompleted,
        },
      };
      setUploadStatus(newStatus);
    };

    const failureCallback = () => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
      toast.error('Failed to upload the document');
    };

    if (file.id !== null && file.id !== undefined) {
      const newFileObj = {};
      newFileObj.doc = document?.document;
      newFileObj.doc.id = document?.document.id;
      newFileObj.doc.fileName = document?.document.original.fileName;
      newFileObj.doc.fileSize = document?.document.original.fileSize;
      newFileObj.doc.height = document?.document.original.height;
      newFileObj.doc.width = document?.document.original.width;
      newFileObj.doc.url = document?.document.original.url;
      newFileObj.doc.viewUrl = document?.document.original.viewUrl;
      newFileObj.doc.contentType = document?.document.original.contentType;
      compressedDocumentAttach(newFileObj.doc);
    } else {
      uploadDocument(
        fileDetails,
        file,
        signedURLCallback,
        successCallback,
        failureCallback,
        progressCallback
      );
    }
  };

  return (
    <Modal
      className="modal delivard-image-details"
      size="lg"
      show={show}
      onHide={closeFn}
      onExit={() => setEditOptions(false)}
      centered>
      <Modal.Header closeButton className="border-0 px-5 pt-4 pb-1">
        {document && (
          <Row className="w-100">
            <Col xs={8}>
              <Row>
                <Col xs={9}>
                  <h5 className="mb-0">
                    {document?.document && document?.document?.type?.includes('PICKUP')
                      ? 'Pick Up'
                      : 'Drop Off'}
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Modal.Header>
      <Modal.Body className="px-5 pt-0">
        {document && (
          <Row>
            <Col xs={8} className="align-self-center">
              {document?.document?.contentType === 'application/pdf' ? (
                <div className="text-center bg-white border-radius-5px">
                  <embed
                    className="w-100 img-height img-fluid"
                    name="plugin"
                    src={document?.document?.viewUrl}
                    type="application/pdf"
                  />
                </div>
              ) : (
                <div>
                  <Image
                    onClick={() => {
                      setVisible(true);
                    }}
                    onMouseOver={() => {
                      setImageStyle('cursorStyle border border-primary shadow-lg bg-white rounded');
                    }}
                    onMouseOut={() => {
                      setImageStyle('');
                    }}
                    crossOrigin="anonymous"
                    className={`w-100 img-height img-fluid ${imageStyle}`}
                    src={newImage ? newImage : document?.document?.viewUrl}
                  />
                  <div>
                    <Viewer
                      visible={visible}
                      scalable={true}
                      onClose={() => {
                        setVisible(false);
                      }}
                      images={[{ src: newImage ? newImage : document?.document?.viewUrl, alt: '' }]}
                      zIndex={9999}
                    />
                  </div>
                </div>
              )}
              {deliveryId ? (
                <Row className="ml-0 mr-0">
                  <>
                    <Row className="mt-3">
                      <Col>
                        <p>
                          File size :{' '}
                          {compressedFileObj?.size || compressedFileObj?.fileSize
                            ? convertBytesToKb(
                                compressedFileObj?.size || compressedFileObj?.fileSize
                              )
                            : convertBytesToKb(document?.document?.fileSize)}{' '}
                          KB{' '}
                          {compressedFileObj?.size || compressedFileObj?.fileSize
                            ? displayAlert(compressedFileObj?.size || compressedFileObj?.fileSize)
                            : displayAlert(document?.document?.fileSize)}
                          {!showEditOptions &&
                          document?.document?.contentType !== 'application/pdf' &&
                          !isShowActionForMpDelivery ? (
                            <Button className="ml-2" onClick={() => editImageHandler()}>
                              Edit Image
                            </Button>
                          ) : (
                            ''
                          )}
                        </p>
                      </Col>
                      {showEditOptions ? (
                        <>
                          <Col md={3}>
                            <Form.Label className="mb-0">Quality</Form.Label>
                            <RangeSlider
                              value={qualityValue}
                              onChange={(e) => setQualityValue(e.target.value)}
                              onAfterChange={(e) => qualityHandler(e.target.value)}
                              tooltip="on"
                            />
                          </Col>
                          <Col md={1} className="ml-4">
                            <FontAwesomeIcon
                              style={{ marginTop: '15px', cursor: 'pointer' }}
                              icon={faRedo}
                              onClick={() => rotateHandler()}
                            />
                          </Col>
                          <Col md={2}>
                            <Button
                              style={{ marginRight: '25px' }}
                              onClick={() => revertToOriginalHandler()}>
                              Revert
                            </Button>
                          </Col>
                          <Col md={2}>
                            <HasAuthority
                              permissionsAllowed={[
                                {
                                  groupName: 'Delivery',
                                  moduleName: 'Delivery',
                                  name: 'upload_document',
                                },
                              ]}>
                              <Button
                                disabled={
                                  !isFileCompressed && document?.document?.original === null
                                }
                                onClick={() => uploadDocumentToS3(compressedFileObj)}>
                                Save
                              </Button>
                            </HasAuthority>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </>
                </Row>
              ) : (
                ''
              )}
              <Row>
                <Col xs={8} className="pl-0">
                  <div className="mt-4">
                    <h5 className="mb-1">
                      {documentTypesList.find((docType) => {
                        return docType.type === documentType;
                      })?.title || <span className="text-danger">Document type not set</span>}
                    </h5>
                  </div>
                  <div>
                    <p className="mb-0">
                      {document?.createdOn &&
                        moment(document.createdOn).format('DD MMM YYYY h:mm A')}
                    </p>
                  </div>
                </Col>
                <Col xs={4} className="p-0 mt-4 align-self-center">
                  <Button
                    onClick={(e) => {
                      window.open(document.document && document.document.viewUrl);
                    }}
                    variant={'outline-secondary btn-icon float-right download-bg'}
                  />
                  <span
                    className="float-right mt-2 pointer"
                    onClick={(e) => {
                      window.open(document.document && document.document.viewUrl);
                    }}>
                    Download
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <div className="tabs-bg tab-ver-3">
                <Tabs defaultActiveKey={1} className="mr-0 ml-0 tab-v3 nav-tabs">
                  {(selectedContainer === 'PICKUP' ||
                    selectedContainer === 'DROP' ||
                    selectedContainer === 'CERTIFICATE') && (
                    <Tab eventKey={1} title="Delivery Details">
                      <DeliveryDetailsTab
                        documentDeliveryDetails={documentDeliveryDetails}
                        selectedContainer={selectedContainer}
                      />
                    </Tab>
                  )}
                  <Tab eventKey={2} title="Dispute and Resolutions" className="p-4 resolution">
                    <Col className="p-0">
                      <PerfectScrollbar
                        options={{ suppressScrollX: true }}
                        className=""
                        style={{ height: '50vh' }}>
                        <div className="mr-4">
                          {docTickets &&
                            docTickets.length > 0 &&
                            docTickets.map((ticket, idx) => {
                              return (
                                <DocumentHistoryItem
                                  ticket={ticket}
                                  key={idx}
                                  delivery={deliveryDetails}
                                  docTypeList={docTypeList}
                                  doFetchDelivery={doFetchDelivery}
                                  fetchAllTickets={fetchTickets}
                                />
                              );
                            })}
                          {displayRaiseDispute && (
                            <div>
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows="3"
                                  onChange={(e) => {
                                    setDisputeNote(e.target.value);
                                  }}
                                  placeholder="Why are you disputing?"
                                />
                              </Form.Group>
                              <div className="text-right">
                                <HasAuthority
                                  permissionsAllowed={[
                                    {
                                      groupName: 'Delivery',
                                      moduleName: 'Delivery',
                                      name: 'dispute_documents',
                                    },
                                  ]}>
                                  <Button
                                    className="ml-3 text-white"
                                    variant="danger"
                                    type="submit"
                                    onClick={raiseTicket}
                                    disabled={!disputeNote}>
                                    Dispute
                                  </Button>
                                </HasAuthority>
                              </div>
                            </div>
                          )}
                        </div>
                      </PerfectScrollbar>
                    </Col>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentModal;
