import React, { useState, useEffect } from 'react';
import {
  InputBase,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { useStyles } from './DeliveryPage.app.styles';
import RequireAttentionStatusTab from '../../../shared/Components/_App/Modal/RequireAttentionStatusTab';
import { Region, DeliveryStatus } from '../../../shared/Components/GenericComponent';
import { Container } from '@material-ui/core';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { useDelivery } from '../../../hooks/hooks';
import { toCapitalize, toLocalDate } from '../../../shared/Utils/Helper';
import { useAsyncEffect } from '../../../hooks';
import PageView from '../../../shared/Components/_App/PageView';
import Filter from '../../../shared/Components/_App/Modal/Filter';
import { isEmpty } from 'lodash';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const RequiresAttentionDeliveriesPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { selectedFinancialYear } = useFinancialYear();
  const searchParams = new URLSearchParams(window.location.search);
  // const [{ deliveryValue, deliveryLoading }, { dofetchDelivery }] = useDelivery();
  const [deliveryValue, setDeliveryValue] = useState({});
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [openFilter, setOpenfilter] = useState(false);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [masterDeliveries, setMasterDeliveries] = useState([]);
  const [search, setSearch] = useState();
  const [deliveryDetails, setDeliveryDetails] = useState({
    page: 0,
    isFetchMore: true,
    isFilterSortFetch: false,
    isNormalFetch: true,
    normalFetchPage: 0,
    filterFetchPage: 0,
    deliveryCount: 0,
    isSearch: false,
    isFilterApplied: searchParams.get('isFilterApplied') || 'false',
    isFilter: false,
  });
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
  const [filterdeliverylist, setFilterDeliveryList] = useState([]);
  const [deliveryParams, setDeliveryParams] = useState({
    deliveryDateFrom: selectedFinancialYear?.fromDate,
    deliveryDateTo: selectedFinancialYear?.toDate,
    page: 0,
    size: 10,
    sort: searchParams.get('sort') || 'createdOn,desc',
    direction: 'RECEIVED',
    itemIds: appMaterialItems,
    assignedStatus: 'IN_PROGRESS,DISPUTED',
    'requiresAttention.equals': true,
  });

  useAsyncEffect(async () => {
    if (deliveryDetails.isFetchMore) {
      setDeliveryLoading(true);
      if (deliveryDetails.isFilterApplied === 'true') {
        let delParams = { ...deliveryParams };
        if (searchParams.get('isSearch') === 'true') {
          delParams = {
            ...delParams,
            ids: searchParams.get('search'),
          };
        } else if (searchParams.get('vehicleNumber') === 'true') {
          delParams = {
            ...delParams,
            vehicleNumber: '',
            assignedStatus: 'IN_PROGRESS',
          };
        } else if (searchParams.get('docStatus') === 'IN_REVIEW') {
          delParams = {
            ...delParams,
            'docStatus.in': 'IN_REVIEW',
            assignedStatus: 'IN_PROGRESS',
          };
        } else if (searchParams.get('assignedDocStatus') === 'REJECTED') {
          delParams = {
            ...delParams,
            'assignedDocStatus.in': 'REJECTED',
            assignedStatus: 'IN_PROGRESS',
          };
        } else if (searchParams.get('assignedStatus') === 'DISPUTED') {
          delParams = {
            ...delParams,
            assignedStatus: 'DISPUTED',
          };
        }
        const response = await DeliveryService.fetchDeliveries(delParams);
        if (!isEmpty(response?.deliveries))
          modifyDeliveries(response?.deliveries, response?.totalCount);
        if (isEmpty(deliveryData)) setCount(response?.totalCount);
      } else {
        const response = await DeliveryService.fetchDeliveries(deliveryParams);
        if (!isEmpty(response?.deliveries))
          modifyDeliveries(response?.deliveries, response?.totalCount);
        if (isEmpty(deliveryData)) setCount(response?.totalCount);
      }
    }
  }, [deliveryDetails.isFetchMore, deliveryDetails.page]);

  const modifyDeliveries = (deliveryValue, totalCount) => {
    deliveryValue.received.map((e) => {
      const detailsData = e.deliveryDetails;
      const docsUploadedBySupervisor = e?.deliveryDocuments?.filter((e) => {
        return e?.status === 'IN_REVIEW';
      });
      const documentsRejectedByFulfillmentTeam = e?.assignedDeliveries[0]?.deliveryDocuments.find(
        (e) => e.status === 'REJECTED'
      );

      if (!detailsData.pickUpVehicleNumber) {
        return (e.docStatus = 'DETAILS_MISSING');
      } else if (e?.assignedDeliveries[0]?.status === 'DISPUTED') {
        return (e.docStatus = 'DELIVERY_FAILED');
      } else if (!isEmpty(documentsRejectedByFulfillmentTeam)) {
        return (e.docStatus = 'DOCUMENTS_REJECTED');
      } else if (docsUploadedBySupervisor?.length > 0) {
        return (e.docStatus = 'PENDING_APPROVAL');
      }
    });
    if (isEmpty(deliveryData)) {
      setDeliveryData(deliveryValue?.received);
      if (deliveryDetails.isNormalFetch && !deliveryDetails.isSearch) {
        setMasterDeliveries(deliveryValue?.received);
        setDeliveryDetails({ ...deliveryDetails, deliveryCount: totalCount });
      } else if (!deliveryDetails.isSearch) {
        setFilterDeliveryList(deliveryValue?.received);
      }
    } else {
      const updatedData = [...deliveryData, ...deliveryValue?.received];
      setDeliveryData(updatedData);
      if (deliveryDetails.isNormalFetch) setMasterDeliveries(updatedData);
      else setFilterDeliveryList(updatedData);
    }
    setDeliveryLoading(false);
  };

  const [deliveryFilter, setWofilter] = useState({
    deliveryDocStatus: null,
    LatestonTop: searchParams.get('sort')
      ? searchParams.get('sort') === 'createdOn,desc'
        ? true
        : false
      : true,
    OlderonTop: false,
  });

  useEffect(() => {
    if (searchParams.get('isFilterApplied') === 'true') {
      if (searchParams.get('vehicleNumber') === 'true') {
        const status = {
          internalStatus: 'DETAILS_MISSING',
          name: 'Details Missing',
          select: true,
          status: 'DETAILS_MISSING',
        };
        setWofilter({
          ...deliveryFilter,
          deliveryDocStatus: status,
        });
      } else if (searchParams.get('docStatus') === 'IN_REVIEW') {
        const status = {
          name: 'Pending Approval',
          select: true,
          status: 'PENDING_APPROVAL',
          internalStatus: 'PENDING_APPROVAL',
        };
        setWofilter({
          ...deliveryFilter,
          deliveryDocStatus: status,
        });
      } else if (searchParams.get('assignedDocStatus') === 'REJECTED') {
        const status = {
          name: 'Documents Rejected',
          select: true,
          status: 'DOCUMENTS_REJECTED',
          internalStatus: 'DOCUMENTS_REJECTED',
        };
        setWofilter({
          ...deliveryFilter,
          deliveryDocStatus: status,
        });
      } else if (searchParams.get('assignedStatus') === 'DISPUTED') {
        const status = {
          name: 'Delivery Failed',
          select: true,
          status: 'DELIVERY_FAILED',
          internalStatus: 'DELIVERY_FAILED',
        };
        setWofilter({
          ...deliveryFilter,
          deliveryDocStatus: status,
        });
      }
    }
  }, []);

  const [previousFilter, setPreviousFilter] = useState({
    deliveryDocStatus: null,
  });
  const handleOpen = () => {
    setOpenfilter(true);
  };
  const closeFilterModal = () => {
    setOpenfilter(false);
  };
  const handleClose = () => {
    setWofilter(previousFilter);
    closeFilterModal();
  };
  const handleOpenSortBy = () => {
    setOpenSortBy(true);
  };
  const handleCloseSortBy = () => {
    setWofilter({ ...deliveryFilter, LatestonTop: false, OlderonTop: false });
    setOpenSortBy(false);
  };
  const clearAll = () => {
    setPreviousFilter({ ...previousFilter, deliveryDocStatus: null });
    setWofilter({ ...deliveryFilter, deliveryDocStatus: null });
    if (searchParams.get('isFilterApplied') !== 'true') setDeliveryData(masterDeliveries);
    const updatedDelivery = {
      deliveryDateFrom: deliveryParams?.deliveryDateFrom,
      deliveryDateTo: deliveryParams?.deliveryDateTo,
      page: 0,
      sort: deliveryParams?.sort,
      direction: deliveryParams?.direction,
      browseNodeIds: deliveryParams?.browseNodeIds,
      size: deliveryParams?.size,
      assignedStatus: 'IN_PROGRESS,DISPUTED',
      'requiresAttention.equals': true,
    };
    if (searchParams.get('isFilterApplied') !== 'true')
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
      });
    else
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
      });
    history.push({
      search: '',
    });
    setDeliveryParams({ ...updatedDelivery });
    closeFilterModal();
  };

  const applyFilter = () => {
    if (deliveryFilter?.deliveryDocStatus) {
      setDeliveryData([]);
      if (deliveryFilter?.deliveryDocStatus?.internalStatus === 'DELIVERY_FAILED') {
        if (deliveryParams['docStatus.in']) delete deliveryParams['docStatus.in'];
        if (deliveryParams['assignedDocStatus.in']) delete deliveryParams['assignedDocStatus.in'];
        delete deliveryParams['vehicleNumber'];
        setDeliveryParams({ ...deliveryParams, page: 0, assignedStatus: 'DISPUTED' });
        searchParams.get('isFilterApplied') === 'true'
          ? history.push({
              search: `assignedStatus=DISPUTED&sort=${deliveryParams?.sort}&isFilterApplied=true`,
            })
          : history.push({
              search: `assignedStatus=DISPUTED&sort=${deliveryParams?.sort}`,
            });
      } else if (deliveryFilter?.deliveryDocStatus?.internalStatus === 'PENDING_APPROVAL') {
        if (deliveryParams['assignedDocStatus.in']) delete deliveryParams['assignedDocStatus.in'];
        delete deliveryParams['vehicleNumber'];
        setDeliveryParams({
          ...deliveryParams,
          page: 0,
          'docStatus.in': 'IN_REVIEW',
          assignedStatus: 'IN_PROGRESS',
        });
        searchParams.get('isFilterApplied') === 'true'
          ? history.push({
              search: `docStatus=IN_REVIEW&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}&isFilterApplied=true`,
            })
          : history.push({
              search: `docStatus=IN_REVIEW&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}`,
            });
      } else if (deliveryFilter?.deliveryDocStatus?.internalStatus === 'DETAILS_MISSING') {
        if (deliveryParams['assignedDocStatus.in']) delete deliveryParams['assignedDocStatus.in'];
        if (deliveryParams['docStatus.in']) delete deliveryParams['docStatus.in'];
        setDeliveryParams({
          ...deliveryParams,
          page: 0,
          assignedStatus: 'IN_PROGRESS',
          vehicleNumber: '',
        });
        searchParams.get('isFilterApplied') === 'true'
          ? history.push({
              search: `vehicleNumber=true&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}&isFilterApplied=true`,
            })
          : history.push({
              search: `vehicleNumber=true&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}`,
            });
      } else if (deliveryFilter?.deliveryDocStatus?.internalStatus === 'DOCUMENTS_REJECTED') {
        if (deliveryParams['docStatus.in']) delete deliveryParams['docStatus.in'];
        delete deliveryParams['vehicleNumber'];
        setDeliveryParams({
          ...deliveryParams,
          page: 0,
          assignedStatus: 'IN_PROGRESS',
          'assignedDocStatus.in': 'REJECTED',
        });
        searchParams.get('isFilterApplied') === 'true'
          ? history.push({
              search: `assignedDocStatus=REJECTED&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}&isFilterApplied=true`,
            })
          : history.push({
              search: `assignedDocStatus=REJECTED&assignedStatus=IN_PROGRESS&sort=${deliveryParams?.sort}`,
            });
      }
      // else setDeliveryParams({ ...deliveryParams, page: 0, assignedStatus: "DISPUTED" });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        isNormalFetch: false,
        filterFetchPage: 0,
        isFilter: true,
      });
    } else {
      const updatedDelivery = {
        deliveryDateFrom: deliveryParams?.deliveryDateFrom,
        deliveryDateTo: deliveryParams?.deliveryDateTo,
        page: 0,
        sort: deliveryParams?.sort,
        direction: deliveryParams?.direction,
        browseNodeIds: deliveryParams?.browseNodeIds,
        size: deliveryParams?.size,
        assignedStatus: 'IN_PROGRESS,IN_TRANSIT,DISPUTED',
        'requiresAttention.equals': true,
      };
      setDeliveryDetails({
        ...deliveryDetails,
        isNormalFetch: true,
        isFetchMore: true,
        isFilter: false,
        page: deliveryDetails?.page + 1,
        isFilterSortFetch: false,
        normalFetchPage: 0,
        filterFetchPage: 0,
        deliveryCount: 0,
        isSearch: false,
        isFilterApplied: searchParams.get('isFilterApplied') || 'false',
      });
      setDeliveryData([]);
      setMasterDeliveries([]);
      setDeliveryParams({ ...updatedDelivery });
      history.push({
        search: '',
      });
    }
    closeFilterModal();
  };

  const onSelect = () => {
    setDeliveryData([]);

    if (deliveryFilter?.deliveryDocStatus) {
      setDeliveryParams({
        ...deliveryParams,
        sort: deliveryFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc',
        page: 0,
      });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        filterFetchPage: 0,
      });
      const searchedQuey = window.location.search.slice(1);
      const newSearchedQuery = searchedQuey.replace(
        searchParams.get('sort'),
        deliveryFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc'
      );
      history.push({
        search: `${newSearchedQuery}`,
      });
    } else if (deliveryDetails.isNormalFetch) {
      setDeliveryParams({
        ...deliveryParams,
        sort: deliveryFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc',
        page: 0,
      });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        normalFetchPage: 0,
        deliveryCount: 0,
      });
      history.push({
        search: `sort=${deliveryFilter?.LatestonTop ? 'createdOn,desc' : 'createdOn,asc'}`,
      });
    }
    setOpenSortBy(false);
  };

  const handleClick = (e) => {
    const deliveryData = e.deliveryDetails;
    if (
      // deliveryData.pickUpSupervisorMobile &&
      // deliveryData.dropSupervisorMobile &&
      // deliveryData.transporterId &&
      // deliveryData.driverName &&
      deliveryData.pickUpVehicleNumber
    ) {
      if (deliveryDetails?.isFilter) {
        const searchedQuery = window.location.search.slice(1);
        searchParams.get('requires') === 'true'
          ? history.push({
              pathname: `/delivery-view/${e.id}`,
              search: `${searchedQuery}`,
            })
          : history.push({
              pathname: `/delivery-view/${e.id}`,
              search: `${searchedQuery}&requires=true`,
            });
      } else history.push(`/delivery-view/${e.id}`);
    } else {
      if (deliveryDetails?.isFilter) {
        const searchedQuery = window.location.search.slice(1);
        searchParams.get('requires') === 'true'
          ? history.push({
              pathname: `/update-delivery/${e.id}`,
              search: `${searchedQuery}`,
            })
          : history.push({
              pathname: `/update-delivery/${e.id}`,
              search: `${searchedQuery}&requires=true`,
            });
      } else history.push(`/update-delivery/${e.id}`);
    }
  };
  const fromTabView = props.location.pathname?.includes('/tabs/');

  const displayDetailsMissing = (data) => {
    const detailsData = data.deliveryDetails;
    if (!detailsData.pickUpVehicleNumber) {
      return 'Truck number';
    } else if (data?.deliveryDocuments?.length === 0) {
      return 'Document';
    } else {
      return '';
    }
  };

  const docStatusStyle = ({ docStatus }) => {
    if (['PENDING_APPROVAL'].indexOf(docStatus) >= 0) {
      return { color: '#ff8700' };
    }
    if (['DOCUMENTS_REJECTED'].indexOf(docStatus) >= 0) {
      return { color: '#e80000' };
    }
    if (['DELIVERY_FAILED'].indexOf(docStatus) >= 0) {
      return { color: '#e80000' };
    }
    if (['DETAILS_MISSING'].indexOf(docStatus) >= 0) {
      return { color: '#b0bac9' };
    }
    return { color: '#b0bac9' };
  };

  const searchFilterFunction = (text) => {
    setSearchValue(text);
    if (text.length > 3) {
      setDeliveryData([]);
      setDeliveryParams({ ...deliveryParams, page: 0, ids: text });
      setDeliveryDetails({
        ...deliveryDetails,
        isFetchMore: true,
        page: deliveryDetails.page + 1,
        isSearch: true,
        isFilter: true,
      });
      if (searchParams.get('isSearch') !== 'true')
        history.push({
          search: `search=${text}&isSearch=true&isFilterApplied=true`,
        });
      else {
        const searchedQuey = window.location.search.slice(1);
        const newSearchedQuery = searchedQuey.replace(searchParams.get('search'), text);
        history.push({
          search: newSearchedQuery,
        });
      }
    } else {
      if (searchParams.get('isFilterApplied') !== 'true')
        setDeliveryDetails({ ...deliveryDetails, isSearch: false });
      if (deliveryFilter?.deliveryDocStatus && searchParams.get('isSearchApplied') !== 'true')
        setDeliveryData(filterdeliverylist);
      if (deliveryDetails.isNormalFetch && searchParams.get('isSearchApplied') !== 'true')
        setDeliveryData(masterDeliveries);
      if (text.length === 0) {
        delete deliveryParams['ids'];
        if (searchParams.get('isSearchApplied') === 'true') {
          const updatedDelivery = {
            deliveryDateFrom: deliveryParams?.deliveryDateFrom,
            deliveryDateTo: deliveryParams?.deliveryDateTo,
            page: 0,
            sort: deliveryParams?.sort,
            direction: deliveryParams?.direction,
            browseNodeIds: deliveryParams?.browseNodeIds,
            size: deliveryParams?.size,
            assignedStatus: 'IN_PROGRESS,DISPUTED',
            'requiresAttention.equals': true,
          };
          setDeliveryParams({ ...updatedDelivery });
          setDeliveryDetails({
            ...deliveryDetails,
            isNormalFetch: true,
            isFetchMore: true,
            page: deliveryDetails.page + 1,
            isFilter: false,
          });
        }
        history.push({
          search: '',
        });
      }
    }
  };

  const fetchMoreData = () => {
    if (deliveryDetails.isNormalFetch) {
      if (deliveryData.length !== deliveryDetails.deliveryCount) {
        setDeliveryParams({ ...deliveryParams, page: deliveryDetails.normalFetchPage + 1 });
        setDeliveryDetails({
          ...deliveryDetails,
          isFetchMore: true,
          page: deliveryDetails.page + 1,
          normalFetchPage: deliveryDetails.normalFetchPage + 1,
        });
      } else setDeliveryDetails({ ...deliveryDetails, isFetchMore: false });
    } else if (deliveryFilter?.deliveryDocStatus) {
      if (deliveryData.length !== count) {
        setDeliveryParams({ ...deliveryParams, page: deliveryDetails.filterFetchPage + 1 });
        setDeliveryDetails({
          ...deliveryDetails,
          isFetchMore: true,
          page: deliveryDetails.page + 1,
          filterFetchPage: deliveryDetails.filterFetchPage + 1,
        });
      } else setDeliveryDetails({ ...deliveryDetails, isFetchMore: false });
    }
  };

  return (
    <Container>
      <PageView>
        <Grid item xs={12} md={12} sm={6}>
          <Paper className={classes.root} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder="Search open deliveries"
              inputProps={{ 'aria-label': 'search open deliveries' }}
              value={searchValue}
              onChange={(e) => searchFilterFunction(e.target.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* <List> */}
        <Grid style={{ paddingBottom: '50px' }} spacing={1}>
          {!deliveryLoading && deliveryData?.length === 0 ? (
            <EmptyView
              hasNoData={isEmpty(deliveryData)}
              className={'p-5'}
              message={'No data for selected criteria'}
              emptyStyle={{ background: 'white' }}
            />
          ) : (
            <>
              <div id="scrollableDiv" style={{ maxHeight: '74vh', overflow: 'auto' }}>
                <InfiniteScroll
                  dataLength={deliveryData?.length}
                  next={fetchMoreData}
                  hasMore={deliveryDetails.isFetchMore}
                  loader={
                    deliveryLoading ? <h4 style={{ textAlign: 'center' }}>Loading...</h4> : ''
                  }
                  scrollableTarget="scrollableDiv">
                  {deliveryData?.map((e) => (
                    <Grid key={e.id} className={classes.greyBg} item xs={12} md={6} sm={6}>
                      <ListItem className={classes.whiteBg} button onClick={() => handleClick(e)}>
                        <Grid container>
                          <ListItemText primary={e.id} secondary={toLocalDate(e.createdOn)} />
                        </Grid>
                        <Grid container>
                          <ListItemText
                            primary={
                              <DeliveryStatus
                                status={
                                  e.assignedDeliveries[0] ? e.assignedDeliveries[0]?.status : ''
                                }
                              />
                            }
                            secondary={<Region stateId={e?.stateId} districtId={e?.districtId} />}
                          />
                        </Grid>
                        <Grid container>
                          <ListItemText
                            primary={
                              <Typography style={{ color: 'red' }} variant="span">
                                {displayDetailsMissing(e)}
                              </Typography>
                            }
                            secondary={
                              <Typography style={docStatusStyle(e)}>
                                {toCapitalize(e?.docStatus) || 'NA'}
                              </Typography>
                            }
                          />
                        </Grid>
                      </ListItem>
                    </Grid>
                  ))}
                </InfiniteScroll>
              </div>
            </>
          )}
        </Grid>
        <Filter
          bottomMargin={{ bottom: 0 }}
          openFilter={openFilter}
          openSortBy={openSortBy}
          onSelect={() => onSelect()}
          handleOpenSortBy={() => handleOpenSortBy()}
          handleCloseSortBy={() => handleCloseSortBy()}
          handleClose={handleClose}
          handleClear={() => clearAll()}
          onDone={() => applyFilter()}
          handleOpen={handleOpen}
          sortByFilter={deliveryFilter}
          setSortByFilter={setWofilter}
          sortByDate={'Created Date'}
          Tabs={[
            {
              tabName: 'Status',
              component: (
                <RequireAttentionStatusTab
                  selectedStatus={deliveryFilter}
                  setSelectedStatus={setWofilter}
                />
              ),
            },
          ]}
        />
      </PageView>
    </Container>
  );
};

export default RequiresAttentionDeliveriesPage;
