import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.piecelabel.js';

const options = {
  legend: {
    display: false,
  },

  // pieceLabel: {
  //   render: 'percentage',
  //   fontSize: 14,
  //   fontColor: '#ffffff',
  // },
  tooltips: {
    enabled: true,
    mode: 'single',
    callbacks: {
      label: function (tooltipItems, data) {
        return (
          data.labels[tooltipItems.index] +
          ' : ' +
          data.datasets[0].data[tooltipItems.index] +
          ' kg'
        );
      },
    },
  },
  precision: 2,
  maintainAspectRatio: false,
};

const DashboardBrandTarget = (props) => {
  //states
  const [dataFinal, setDataFinal] = useState({});
  const { summary = {}, ...customer } = props.brandData;
  let metData = [];

  //On brand data change,re-rendering the data
  useEffect(() => {
    //TO-DO remove props.direction in every line. Check direction only once
    const acceptedReceived =
      props.direction === 'received' ? summary.acceptedReceived || 0 : summary.acceptedGiven || 0;
    const inReviewReceived =
      props.direction === 'received' ? summary.inReviewReceived || 0 : summary.inReviewGiven || 0;
    const disputedReceived =
      props.direction === 'received' ? summary.disputedReceived || 0 : summary.disputedGiven || 0;
    const pending =
      props.direction === 'received'
        ? summary.currentOrderQtyGiven - (summary.deliveredQtyReceived || 0)
        : summary.currentOrderQtyReceived - (summary.deliveredQtyGiven || 0);
    const unallocated = props.direction === 'received' && (summary.selfCreatedReceived || 0);
    metData.push(acceptedReceived, inReviewReceived, disputedReceived, pending, unallocated);

    const chartVariables = {
      ACHIEVED: props.direction === 'received' ? 'Achieved' : 'Approved by Client',
      INREVIEW: props.direction === 'received' ? 'InReview' : 'Awaiting for client approval',
      DISPUTED: 'Disputed',
      PENDING: 'Pending for fullfilment',
      UNALLOCATED: props.isBrand ? 'Unallocated' : 'Self Fulfilled',
    };
    setDataFinal({
      labels: [
        chartVariables.ACHIEVED,
        chartVariables.INREVIEW,
        chartVariables.DISPUTED,
        chartVariables.PENDING,
        chartVariables.UNALLOCATED,
      ],
      datasets: [
        {
          backgroundColor: ['#7ae1ca', '#f8cb1c', '#e66d71', '#ed9714', '#7297fe'],
          data: metData,
        },
      ],
    });
  }, [props.brandData]);

  return (
    <>
      <div>
        <Pie width={130} height={130} data={dataFinal} options={options} />
      </div>
      <div className="mt-3 px-2 text-center">
        {(props.direction === 'received' ? customer?.businessName : customer?.name) || 'Internal'}
      </div>
    </>
  );
};

export default DashboardBrandTarget;
