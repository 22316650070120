import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { toLocalDate, toServerDate } from '../Utils/Helper';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { useFinancialYear } from '../../hooks/useFinancialYear';
import { InputGroup } from 'react-bootstrap';
const RcylDatePicker = (props) => {
  const {
    startDate,
    endDate,
    isInvalid,
    disabled,
    onApply,
    controlId,
    placeholder,
    inputClass,
    minDate,
    maxDate,
    errorMessage,
    inputProps,
    ...restProps
  } = props;

  const { selectedFinancialYear: FY, isCurrentFinancialYear } = useFinancialYear();
  const formatDate = (date) => (date ? moment(date).format('DD-MMM-YYYY') : '');
  let displayValue = startDate
    ? `${formatDate(toLocalDate(startDate))} to ${formatDate(toLocalDate(endDate))}`
    : '';
  displayValue = restProps.singleDatePicker ? formatDate(toLocalDate(startDate)) : displayValue;
  // displayValue = formatDate(displayValue);
  function onDateSelect($event, picker) {
    const { startDate, endDate } = { ...picker };
    const date = {
      startDate: toServerDate(startDate),
      endDate: toServerDate(endDate),
    };
    onApply(date, $event);
  }

  const _minDate = minDate ? moment(minDate) : moment(FY.fromDate);
  const _startDate = startDate
    ? moment(startDate)
    : isCurrentFinancialYear
    ? moment()
    : moment(_minDate);
  const _endDate = endDate ? moment(endDate) : false;

  return (
    <DateRangePicker
      minDate={_minDate}
      maxDate={maxDate ? moment(maxDate) : moment(FY.toDate)}
      autoApply={true}
      currentOptions={{
        startDate: _startDate,
        endDate: _endDate,
      }}
      alwaysShowCalendars={false}
      startDate={_startDate}
      endDate={_endDate}
      onApply={onDateSelect}
      {...restProps}>
      <InputGroup>
        <Form.Control
          isInvalid={isInvalid}
          placeholder={placeholder || 'Select Dates'}
          disabled={disabled}
          autoComplete={'off'}
          className={`datepicker-field form-control-one ${inputClass}`}
          onChange={() => {}}
          value={displayValue}
          {...inputProps}
        />
        {errorMessage && (
          <Form.Control.Feedback type="invalid">
            {errorMessage ?? 'Please select Date'}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </DateRangePicker>
  );
};

RcylDatePicker.propTypes = {
  errorMessage: PropTypes.string,
  inputClass: PropTypes.string,
  isInvalid: PropTypes.bool,
  controlId: PropTypes.string,
};

export default RcylDatePicker;
