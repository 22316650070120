import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';
import axios from 'axios';

function getSummaryData(urls, options, params) {
  // console.log(urls, options, params, 'urls, options, paramsurls, options, params');
  return axios.all(urls.map((url) => API.get(url, options, params))).then(
    axios.spread((res1, res2) => {
      return {
        orderSummary: res1.data,
        monthlySummary: res2.data,
      };
    })
  );
}

const fetchMonthlyFulFillment = async (queryParams) => {
  return await getSummaryData(
    [APIEndpoints.GET_ORDER_SUMMARY, APIEndpoints.GET_MONTHLY_FULFILLMENT_SUMMARY],
    null,
    queryParams
  );
};

export const reportsAPI = {
  fetchMonthlyFulFillment,
};
