import React, { useEffect, useState, useCallback } from 'react';
import {
  Avatar,
  Divider,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import Geocode from 'react-geocode';
import { useDropzone } from 'react-dropzone';
import pickup_truck from '../../../../src/assets/img/_App/pickup_truck.svg';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import Section from '../../../shared/Components/_App/Section';
import { uploadDocument } from '../../../shared/Utils/FileUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruck,
  faPhoneAlt,
  faComments,
  faCamera,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';
import { Loader } from '../../../shared/Components';
import { useStyles } from './SupervisorDeliveryPage.app.styles';
import DocumentCard from '../../../features/_App/Delivery/DocumentCard';
import DeliveryTranporterDetails from '../../../features/_App/Delivery/DeliveryTransorter/DeliveryTranporterDetails';
import DeliveryLocationsDetail from '../../../features/_App/Delivery/DeliveryLocations/DeliveryLocationsDetail';
import SupervisorPickupDetails from '../../../features/_App/Delivery/SupervisorPickupDetails';
import { useAccount } from '../../../hooks';
import { hasError } from '../../../shared/Utils/Helper';
import DeliverySuccessModal from '../../../features/_App/Modal/DeliverySuccessModal';
import { toast } from 'react-toastify';
import AppHeader from '../../../layout/AppHeader';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CheckedIcon } from '../../../assets/img/_App/checked.svg';
import AppModal from '../../../shared/Components/_App/AppModal';
import { useSelector } from 'react-redux';
import DocumentTypeModal from '../../../features/_App/Delivery/DeliveryDocument/DeliveryUntaggedDocuments/DocumentTypeModal';
import { useTransporter } from '../../../hooks/hooks';

const SupervisorDeliveryPage = (props) => {
  const [delivery, setDelivery] = useState({});
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({});
  const [documents, setDocuments] = useState([]);
  const classes = useStyles();
  const { user } = useAccount();
  const [showSuccess, setShowSuccess] = useState(false);
  const [tickets, setTickets] = useState();
  const [vehicleDetails, setVehicleDetails] = useState({});

  const [selectedDoc, setSelectedDoc] = useState();
  const [docTagModal, setDocTagModal] = useState(false);
  const [docList, setDocList] = useSelector([]);
  const [{ transError, transLoading, transporterValue }, { dofetchTransporter }] = useTransporter();
  const browserImageSize = require('browser-image-size');
  Geocode.setApiKey('AIzaSyCi7nUKy2NI09uwYjSqbyYrbCnZXyLIOCY');

  const { id } = props.match.params;
  const history = useHistory();
  const mobile = history?.location?.state?.mobile;
  const docTypeList = [];
  docList.map((docList) => {
    if (docList.id === 'pickupDocs') {
      docList.documents.map((finalDocs) => {
        docTypeList.push({
          _id: finalDocs.type,
          name: 'Pickup ' + '' + finalDocs.title,
        });
      });
    } else if (docList.id === 'dropDocs') {
      docList.documents.map((finalDocs) => {
        docTypeList.push({
          _id: finalDocs.type,
          name:
            finalDocs.type === 'DROP_INWARD_TYPE'
              ? finalDocs.title
              : 'Drop off ' + '' + finalDocs.title,
        });
      });
    }
  });
  const doGetDocumentList = async () => {
    try {
      const response = await DeliveryService.getDocumentList();
      if (response?.status === 200) {
        setDocList(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    doGetDocumentList();
    doFetchDelivery();
    doFetchTickets();
    dofetchTransporter();
  }, []);

  useEffect(() => {
    if (tickets && delivery) {
      const dDocuments = delivery?.deliveryDocuments?.map((e) => {
        const docticket = tickets?.find((t) => t.docId === e.id && t.status === 'OPEN');

        if (e.id === docticket?.docId) {
          e.ticketId = docticket?.id;
        }
        return e;
      });

      setDelivery({ ...delivery, deliveryDocuments: dDocuments });
    }
  }, [tickets]);

  const doFetchTickets = async () => {
    const response = await DeliveryService.fetchTickets(id);
    if (response?.data) {
      const tick = response?.data?.map((e) => {
        const a = e.deliveryDocuments[0];
        const b = a.document;
        b.ticket = e.id;
        e.docId = a.id;
        return e;
      });
      setTickets(tick);
    }
  };

  const doFetchDelivery = async () => {
    try {
      const response = await DeliveryService.fetchDeliveryById(id);
      if (response.data) {
        const supervisorType =
          response.data.deliveryDetails?.pickUpSupervisorMobile === parseInt(user?.mobile || mobile)
            ? 'PICKUP'
            : 'DROP';

        setDelivery({ ...response.data, supervisorType });
        setVehicleDetails(JSON.parse(response.data?.deliveryDetails?.vehicleDetails || '{}'));
        const docs = response.data.deliveryDocuments?.filter(
          (e) => e.status === 'IN_REVIEW' || e.status === 'REJECTED'
        );
        setDocuments([...docs]);
      }
    } catch (error) {
      setError(error?.response?.data);
    }
  };

  const onFileSelected = (files, docId, type) => {
    console.log('detail', files, docId, type);
    files.forEach((file) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
              const extractedAddress = response.results[0].formatted_address;
              if (file.type === 'application/pdf') {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onloadend = function () {
                  var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                  file.pageCount = count;
                  file.latitude = position.coords.latitude;
                  file.longitude = position.coords.longitude;
                  file.metadata = { address: extractedAddress };
                  uploadDocumentToS3(file, docId, type);
                };
              } else {
                browserImageSize(file).then(function (size) {
                  file.width = size.width;
                  file.height = size.height;
                  file.latitude = position.coords.latitude;
                  file.longitude = position.coords.longitude;
                  file.metadata = { address: extractedAddress };
                  uploadDocumentToS3(file, docId, type);
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        });
      }
    });
  };

  const uploadDocumentToS3 = (file, delDocId, type) => {
    const fileDetails = {
      entityId: id,
      entity: 'DELIVERY',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: delivery?.supervisorType,
      active: true,
      latitude: file.latitude,
      longitude: file.longitude,
      width: file.width,
      height: file.height,
      pageCount: file.pageCount,
      metadata: JSON.stringify(file.metadata),
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setDocuments((prevDoc) => {
        const docCopy = [...prevDoc];
        return [...docCopy, docTobeUploaded];
      });
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: 0,
        },
      });
    };

    const successCallback = (signedURLResponse) => {
      const uploadDetails = signedURLResponse.data;
      const oldUploadStatus = { ...uploadStatus };
      setUploadStatus({
        ...oldUploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'DONE',
          uploading: false,
        },
      });
      if (docTobeUploaded?.id) {
        replaceDocument(docTobeUploaded);
      }
    };
    const progressCallback = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      const newStatus = {
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: percentCompleted,
        },
      };
      setUploadStatus(newStatus);
    };

    const failureCallback = (error) => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
    };

    uploadDocument(
      fileDetails,
      file,
      signedURLCallback,
      successCallback,
      failureCallback,
      progressCallback
    );
  };

  const replaceDocument = async (doc) => {
    const messageData = {
      ticketId: delivery?.deliveryDocuments?.find((e) => e.id === doc.id)?.ticketId,
      text: 'resolve',
      createdOn: Date.now(),
      replyToMessageId: null,
      resolve: true,
      attachments: [doc],
    };
    // dispatch(
    //   ticketActions.replyTicket(messageData, (data) => {
    //     if (data) {
    //       toast.success('Document replaced successfully and sent for approval');
    //       doFetchDelivery();
    //     }
    //   })
    // );
    try {
      const response = await DeliveryService.replyTicket(messageData);
      if (response?.data) {
        toast.success('Document replaced successfully and sent for approval');
        doFetchDelivery();
      }
    } catch (err) {
      console.log('error', err);
    }
  };
  const handleDelete = (delDoc) => {
    let index = null;
    const doc = [...documents];
    index = doc?.findIndex((e) => e.document.fileName === delDoc.document.fileName);
    doc.splice(index, 1);
    setDocuments(doc);
  };

  const onSubmit = () => {
    const doc = documents.filter(
      (e) => e.document?.type === delivery?.supervisorType && e.id === undefined
    );
    if (doc.length) {
      let success = true;
      doc.map(async (e) => {
        const response = await DeliveryService.uploadDeliveryDocument({
          document: e.document,
          deliveryId: delivery?.id,
        });
        const error = hasError(response);
        if (error) {
          success = false;
          toast.error(error);
        }
      });
      setShowSuccess(success);
    } else {
      toast.warn('Upload atleast one document');
    }
  };

  const handleDocClick = (doc) => {
    setDocTagModal(true);
    setSelectedDoc(doc);
  };

  return (
    <>
      <AppHeader
        titleRender={(route) => (
          <div className={classes.headerText}>
            <Typography variant={'subtitle2'} noWrap>
              #{id}
            </Typography>
          </div>
        )}
      />
      <div style={{ overflow: 'auto', height: '83vh' }}>
        <Paper className="pl-3 pr-3 " elevation="0">
          <Typography className="font-bold pt-3" variant="h3">
            Hi, <br />{' '}
            {delivery?.supervisorType === 'PICKUP'
              ? delivery?.deliveryDetails?.pickUpSupervisorName
              : delivery?.deliveryDetails?.dropSupervisorName}
            !
          </Typography>
          <Grid container direction="row">
            <Grid item xs={8} className="m-auto">
              <Typography variant="subtitle1">New delivery is assigned to you.</Typography>
            </Grid>
            <Grid xs={4} item>
              <img className="float-right" src={pickup_truck} />
            </Grid>
          </Grid>
          <div className={classes.takePicture}>
            <FontAwesomeIcon
              size="3x"
              color="#005dff"
              icon={faCamera}
              style={{ background: '#eff5ff', position: 'relative', zIndex: '999' }}
            />
            <input
              accept="image/jpeg, image/png"
              type="file"
              style={{ position: 'absolute', zIndex: '9999', opacity: '0' }}
              capture="environment"
              onChange={(e) => onFileSelected([...e.target.files])}
            />
            <Typography style={{ color: '#005dff', marginLeft: '5px' }} variant="h4">
              Take Pictures
            </Typography>
          </div>

          <DocumentCard
            documents={documents}
            uploadStatus={uploadStatus}
            handleDelete={handleDelete}
            onFileSelected={onFileSelected}
            onDocClick={handleDocClick}
            isSupervisorDelivery={true}
          />
        </Paper>
        <DeliveryTranporterDetails
          transporterValue={transporterValue}
          data={delivery?.deliveryDetails}
          isEditable={false}
        />
        <Divider />
        <div
          style={{
            borderRadius: 5,
            padding: 20,
            marginBottom: 16,
            backgroundColor: 'white',
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
            <div>
              <Typography variant="caption" display="block" gutterBottom>
                VEHICLE CLASS
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                <CheckedIcon /> {vehicleDetails?.result?.vehicle_class || 'N/A'}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" display="block" gutterBottom>
                FULE TYPE
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                <CheckedIcon /> {vehicleDetails?.result?.fuel || 'N/A'}
              </Typography>
            </div>
          </div>
          <Typography variant="caption" display="block" gutterBottom>
            MODEL & MANUFACTURE DETAILS
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            <CheckedIcon /> {vehicleDetails?.result?.maker || 'N/A'}
          </Typography>
        </div>
        <div style={{ backGroundColor: '#efefef', marginTop: '15px' }}>
          <DeliveryLocationsDetail isEditable={false} data={delivery?.deliveryDetails} />
        </div>
        <SupervisorPickupDetails data={delivery} />
        <Button
          disabled={
            !documents.filter(
              (e) => e.document?.type === delivery?.supervisorType && e.id === undefined
            ).length
          }
          onClick={() => onSubmit()}
          color="primary"
          variant="contained"
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            textAlign: 'centre',
          }}>
          Submit
        </Button>
        {showSuccess && (
          <DeliverySuccessModal
            onSelect={() => {
              setShowSuccess(false);
              doFetchDelivery();
            }}
            documents={true}
            open={showSuccess}
          />
        )}
        {
          <AppModal
            title={
              docTypeList.find((e) => e._id === selectedDoc?.document?.type)?.name ||
              'Untagged Document'
            }
            visible={docTagModal}
            setVisible={setDocTagModal}>
            <DocumentTypeModal
              docList={[selectedDoc]}
              selectedDocument={selectedDoc}
              doFetchDelivery={doFetchDelivery}
              delivery={delivery}
              isView={true}
            />
          </AppModal>
        }
      </div>
    </>
  );
};

export default SupervisorDeliveryPage;
