import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backArrowIcon: {
    margin: '10px',
  },
  submitBottom: {
    width: '100%',
    textAlign: 'centre',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
  goHomeBottom: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: '#33ab2e',
    position: 'fixed',
    bottom: 0,
    borderRadius: 0,
  },
  deliveryMain: {
    maxHeight: '83vh',
    overflow: 'auto',
  },
  proDeliveryMain: {
    maxHeight: '92vh',
    overflow: 'auto',
  },
  headerText: {
    lineHeight: '12px',
  },
}));
