/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTable, useSortBy, useRowSelect, usePagination } from 'react-table';
import { Table } from 'react-bootstrap';
import { PAGE_CONFIG, pageOptions } from '../../../shared/Constants/Constants';
import PaginationComponent from '../../../features/Customers/PaginationComponent';
import { Loader } from '../../../shared/Components';

function BulkDeliveryTable({
  DATA,
  TABLEPARAM,
  COLUMNDEF,
  tableStateFn,
  pageChangeFn,
  setSelectedTxns,
  isPaginate = true,
  isLoading,
  isMapWO,
  filterApplied,
}) {
  const NoOfPages = Math.ceil(TABLEPARAM?.total / (isMapWO ? 10 : PAGE_CONFIG.size));
  const PAGE_SIZE = isMapWO ? 10 : PAGE_CONFIG.size;

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state: { pageIndex },
    selectedFlatRows,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
  } = useTable(
    {
      columns: COLUMNDEF,
      data: DATA,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
      initialState: {
        pageSize: PAGE_SIZE,
        pageIndex: TABLEPARAM?.pageKey ?? 0,
      },
      manualPagination: true,
      pageCount: NoOfPages,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (isMapWO && setSelectedTxns !== undefined) {
      const selectedTxns = selectedFlatRows?.filter((item) => item?.isSelected);
      const selectedTxnValues = selectedTxns?.map((txn) => txn?.values);
      setSelectedTxns(selectedTxnValues);
    }
  }, [selectedFlatRows?.length]);

  useEffect(() => {
    if (tableStateFn !== undefined) {
      tableStateFn((prev) => ({ ...prev, pageKey: pageIndex ?? 0 }));
    }
  }, [TABLEPARAM?.pages]);

  return (
    <>
      <Table
        {...getTableProps()}
        responsive
        className="rcyl-table1 p-0 table-bordered border-bottom-0 work-order-items-edit">
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header', rows)}</th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <></>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} key={`main${i}`}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>

      {isLoading && (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Loader />
        </div>
      )}

      {pageOptions?.length > 1 && isPaginate && !filterApplied ? (
        <PaginationComponent
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={() => {
            previousPage();
            pageChangeFn(pageIndex - 1);
          }}
          nextPage={() => {
            nextPage();
            pageChangeFn(pageIndex + 1);
          }}
          gotoPage={(page) => {
            gotoPage(page);
            pageChangeFn(page);
          }}
          currentPage={TABLEPARAM?.pageKey ?? 0}
          numberOfPages={NoOfPages}
          totalCount={filterApplied ? rows?.length : TABLEPARAM?.total}
          pageSize={isMapWO ? 10 : PAGE_CONFIG.size}
        />
      ) : (
        isPaginate && <p>Total Count: {filterApplied ? rows?.length : TABLEPARAM?.total}</p>
      )}
    </>
  );
}

export default BulkDeliveryTable;
