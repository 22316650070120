import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { useStyles } from './DeliverySuccessModal.styles';

const DeliverySubmitForApprovalModal = ({ qty, open, onSelect, onClose, submitInProgress }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <div className="m-auto p-5">
            <Typography style={{ textAlign: 'center' }} variant="h4">
              Do you want to proceed with {qty} kg as final quantity
            </Typography>
          </div>
          <div className={classes.buttonGroup}>
            <>
              <Button className={`${classes.goHomeButton} `} onClick={onClose}>
                Cancel
              </Button>
              <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
              <Button
                className={`${classes.continueButton}`}
                onClick={onSelect}
                disabled={submitInProgress}>
                {submitInProgress ? 'Approving...' : 'Continue'}
              </Button>
            </>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default DeliverySubmitForApprovalModal;
