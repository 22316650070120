import React, { useEffect, useState } from 'react';
import Loader from '../../../shared/Components/Loader/Loader';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './WorkOrderDetails.scss';
import DocumentModal from '../DocumentModal';
import WorkOrderItems from '../../../features/WorkOrder/WorkOrderItems/WorkOrderItems';
import {
  EDITABLE_BRAND_WO_STATUSES,
  WORK_ORDER_TABLE_COLUMNS,
  WORK_ORDER_UTILS,
} from '../../../features/WorkOrder/WokrOrderConstants';
import { toast } from 'react-toastify';
import PromptModal from '../../../shared/Components/PromptModal/PromptModal';
import { AccountSelectors } from '../../../state/Account';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import { useAccount, useAsyncEffect, useMaterialCategory, useRegions } from '../../../hooks';
import { useClients } from '../../../hooks/useCustomers';
import { toLocalDate, toFinYear } from '../../../shared/Utils/Helper';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import WorkOrderChangeLog from './WorkOrderChangeLog';
import { flatMap, uniq } from 'lodash';
import HasAuthority from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import UploadScrollableCard from '../../../shared/Components/UploadScrollableCard/UploadScrollableCard';
import RejectWorkOrderModal from '../../../features/WorkOrder/RejectWorkOrderModal';

const BrandWorkOrderDetails = (props) => {
  const history = useHistory();
  const { hasAuth } = useAuthority();
  const isPRO = hasAuth([AUTHORITY2.PRO]);
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const isWorkOrderOutgoing = isBrand;
  const [users, setUsers] = useState([]);
  const [workOrder, setWorkOrder] = useState({
    documents: [],
    workOrderItems: [],
  });

  const { brands } = useSelector(({ account }) => {
    return { brands: account.clients };
  });
  const { hasPermission } = useAuthority();
  const canAudit =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'audit_trail',
          },
        ],
      }));

  const canCloseWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'close_work_order',
          },
        ],
      }));
  const canEditWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'can_edit',
          },
        ],
      }));
  const canDeleteWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'can_delete',
          },
        ],
      }));
  const canApproveRejectWO =
    isPRO &&
    hasPermission({
      permissionsAllowed: [
        {
          groupName: 'Execute_Targets',
          moduleName: 'Work_Orders',
          name: 'approve_reject_wo',
        },
      ],
    });
  const canViewWO =
    isBrand ||
    (isPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: 'can_view',
          },
        ],
      }));

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [displayDocumentModal, setDisplayDocumentModal] = useState(false);
  const [, setDeleteAllowed] = useState(true);
  const [closeWoModal, setCloseWoModal] = useState(false);
  const [openWoModal, setOpenWoModal] = useState(false);
  const [workOrderCombinedCategory, setWorkOrderCombinedCategory] = useState('');
  const [brandsForVendorWorkOrder, setBrandsForVendorWorkOrder] = useState([]);
  const [logs, setLogs] = React.useState();
  const [showHistory, setShowHistory] = React.useState();
  const [showRejectModal, setShowRejectModal] = useState();
  const [rejectionComments, setRejectionComments] = useState();
  const [loading, setLoading] = useState();

  const region = useRegions();
  const browseNodes = useMaterialCategory();
  const account = useAccount();
  const clients = useClients(true);
  const isUserServiceProvider = AccountSelectors.isUserServiceProvider(account);
  const workOrderId = props.match.params.id;
  const isUserRapidue = account.user?.profile?.id === 8651;

  useEffect(() => {
    const WoCombinedCategory = workOrder?.workOrderItems?.every((workOrderItem) =>
      ['1', '2', '4', '6']?.includes(workOrderItem?.browseNodeId)
    )
      ? 'plastic'
      : workOrder?.workOrderItems?.every((workOrderItem) => workOrderItem?.browseNodeId === '3')
      ? 'Ewaste'
      : undefined;
    setWorkOrderCombinedCategory(WoCombinedCategory);
    const brandList =
      WoCombinedCategory === 'plastic'
        ? uniq(
            flatMap(
              workOrder?.workOrderItems?.map((workOrderItem) => {
                return workOrderItem?.associatedBrands?.map(
                  (associatedBrandId) =>
                    brands.find((brand) => brand?.id === associatedBrandId)?.name
                );
              })
            )
          )
        : [brands.find((brand) => brand?.id === workOrder?.brandId)?.name];
    setBrandsForVendorWorkOrder(brandList);
  }, [workOrder, brands]);

  useAsyncEffect(async () => {
    getWorkOrdersItems();
    try {
      const response = await DeliveryService.getUsersDetails();
      if (response.status === 200) {
        setUsers(response?.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [props.location.search]);

  const doDeleteWorkOrder = async () => {
    setShowDeleteConfirmModal(false);
    try {
      await WorkOrderService.deleteWorkOrder(workOrder?.id);
      toast.success('Work order deleted successfully.');
      history.goBack();
    } catch (e) {}
  };

  const getWorkOrdersItems = async () => {
    try {
      const response = await WorkOrderService.fetchWorkOrderById(
        workOrderId,
        decodeURIComponent(props?.location?.search)
      );

      if (response.data) {
        const workOrderData = { ...response?.data };
        if (workOrderData?.workOrderItems.length) {
          workOrderData.workOrderItems.forEach((workOrderItem) => {
            if (workOrderItem.fulfilledQty !== null && workOrderItem?.fulfilledQty > 0) {
              setDeleteAllowed(false);
              return;
            }
          });
        }
        setWorkOrder({
          ...workOrderData,
          workOrderItems: workOrderData?.workOrderItems?.map((wo_item) => ({
            ...wo_item,
            stateCode: wo_item?.stateCode !== null ? wo_item?.stateCode : 0,
            stateId: wo_item?.stateId !== null ? wo_item?.stateId : 0,
          })),
        });
      }

      const logData = (await WorkOrderService.fetchWorkOrderLogs(workOrderId)).data;
      setLogs(logData);
    } catch (e) {}
  };

  const fetchDeliveries = async ({
    row: {
      values: { itemId, stateId, districtId },
      index,
    },
  }) => {
    const { deliveries } = await DeliveryService.fetchDeliveries({
      direction: isWorkOrderOutgoing ? 'RECEIVED' : 'ASSIGNED',
      ids: workOrder?.id,
      itemIds: itemId,
      stateIds: stateId,
      districtIds: districtId,
      size: 100,
    });
    if (deliveries?.received?.length || deliveries?.assigned?.length) {
      const orderItems = [...workOrder.workOrderItems];
      orderItems[index].subRows = WORK_ORDER_UTILS.transformDeliveryDataToWorkOrderItem(
        isWorkOrderOutgoing ? deliveries?.received : deliveries?.assigned
      );
      setWorkOrder({
        ...workOrder,
        workOrderItems: orderItems,
      });
    }
  };

  const docAttach = async (documentReqData) => {
    const response = await WorkOrderService.uploadDocuments(documentReqData);
    if (response?.data) {
      let existingValue = workOrder.documents || [];
      existingValue.push(response?.data);
      setWorkOrder((prevOrder = {}) => {
        return {
          ...prevOrder,
          documents: existingValue,
        };
      });
    }
  };

  const handleDocChange = (uploadDocList) => {
    uploadDocList.forEach((newData) => {
      newData.document.entityId = workOrderId;
      docAttach(newData.document);
    });
  };

  const doDeleteDocuments = async (delDoc) => {
    if (delDoc.id) {
      try {
        await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
        setDeletedDocToLocal(delDoc);
      } catch (e) {}
    } else {
      setDeletedDocToLocal(delDoc);
    }
  };

  const setDeletedDocToLocal = (delDoc) => {
    let index = null;
    const { documents } = { ...workOrder };
    if (!!delDoc.id) {
      index = documents?.findIndex((e) => e.id === delDoc.id);
    } else {
      index = documents?.findIndex((e) => e.fileName === delDoc.fileName);
    }
    documents.splice(index, 1);
    setWorkOrder({ ...workOrder, documents: [...documents] });
  };

  const doOpenCloseWorkOrders = async (type) => {
    try {
      if (type === 'CLOSE') {
        const closeWoRes = await WorkOrderService.closeWorkOrders([workOrderId]);
        if (closeWoRes.status === 200) {
          setCloseWoModal(false);
          toast.success('Work Order Closed successfully');
          getWorkOrdersItems();
        }
      } else {
        const openWoRes = await WorkOrderService.openWorkOrder(workOrderId);
        if (openWoRes.status === 200) {
          setOpenWoModal(false);
          getWorkOrdersItems();
          toast.success('Work order opened successfully');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isInReview = workOrder?.status === 'IN_REVIEW';
  const isRejected = workOrder?.status === 'REJECTED';

  const approveRejectHandler = async (status) => {
    setLoading(true);
    const rejectCommentObj = status === 'REJECTED' ? { rejectionComments: rejectionComments } : {};
    try {
      const approveResp = await WorkOrderService.updateWorkOrder({
        ...workOrder,
        status: status,
        ...rejectCommentObj,
      });
      if (status === 'IN_PROGRESS') {
        toast.success('Work order approved');
      }
      if (status === 'REJECTED') {
        toast.success('Work order rejected');
        setShowRejectModal(false);
        setRejectionComments();
      }
      getWorkOrdersItems();
    } catch (err) {}
    setLoading(false);
  };

  const hadnleRejectModal = () => {
    setShowRejectModal((prev) => !prev);
  };

  return (
    <div className={'animated fadeIn BrandWorkOrderDetails filter-div'}>
      <React.Suspense fallback={<Loader />}>
        <Card className="main-card ">
          <Form onSubmit={(e) => {}}>
            <Card.Header className="bg-white border-0  py-0">
              <Row className="padding-aligned align-self-center">
                <Col className="d-flex align-items-center" xs={4}>
                  <Button
                    className={'mr-3 back-btn'}
                    onClick={() =>
                      isBrand ? history.push('/work-orders') : history.push('/work-orders-incoming')
                    }>
                    <FontAwesomeIcon className={''} icon={faChevronLeft} />
                  </Button>
                  <h4 className="">Work order - {workOrderId}</h4>
                </Col>
                <Col className="d-flex buttons justify-content-end">
                  {canAudit && isUserRapidue && (
                    <Button
                      className={' float-right text-white font-bold mr-3'}
                      onClick={() => setShowHistory(!showHistory)}>
                      {showHistory ? 'Hide Audit Trail' : 'Show Audit Trail'}
                    </Button>
                  )}
                  {canCloseWO && ['IN_REVIEW', 'IN_PROGRESS'].includes(workOrder.status) && (
                    <Button
                      variant="danger"
                      className={'float-right text-white font-bold mr-3'}
                      onClick={() => setCloseWoModal(true)}>
                      Close Work Order
                    </Button>
                  )}

                  <HasAuthority authorities={[AUTHORITY2.BRAND, AUTHORITY2.PRO]}>
                    {workOrder?.status === 'IN_PROGRESS' && (
                      <Button
                        className={' float-right text-white font-bold mr-3'}
                        onClick={() => history.push(`/work-order/fulfilmentplan/${workOrderId}`)}>
                        FulFilment Plan
                      </Button>
                    )}
                  </HasAuthority>

                  {canEditWO && EDITABLE_BRAND_WO_STATUSES?.includes(workOrder.status) && (
                    <Button
                      className={'save-btn  text-dark font-regular mr-3'}
                      onClick={() =>
                        history.push('/work-orders-brandPro/edit/' + workOrderId + '?isEdit=true')
                      }
                      variant={'outline-secondary'}>
                      Edit
                    </Button>
                  )}
                  {workOrder.draft && canDeleteWO && (
                    <Button
                      className={'save-btn  text-dark font-regular ml-3'}
                      onClick={() => setShowDeleteConfirmModal(true)}
                      variant={'outline-secondary'}>
                      Delete
                    </Button>
                  )}

                  {canApproveRejectWO && isInReview && (
                    <Button
                      className={'save-btn  font-regular ml-3'}
                      disabled={loading}
                      onClick={() => approveRejectHandler('IN_PROGRESS')}>
                      Approve
                    </Button>
                  )}
                  {canApproveRejectWO && isInReview && (
                    <Button
                      className={'font-regular ml-3'}
                      style={{
                        color: '#F97066',
                        backgroundColor: 'white',
                        borderColor: '#F97066',
                      }}
                      disabled={loading}
                      onClick={() => hadnleRejectModal()}>
                      Reject
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Header>
            {canViewWO && (
              <Card.Body className={'p-0'}>
                <Row>
                  <Col xs={12}>
                    <div className={'bg-secondary'}>
                      <Row className={'padding-2rem details-filter'}>
                        <Col xs={12}>
                          <h6 className="mb-0">Provide Work Order Details</h6>
                        </Col>
                        <Col xs={10} className={'mt-4'}>
                          <Row className={'mb-4 filter'}>
                            {isPRO && (
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className=" text-small">Brand</Form.Label>
                                  <p>
                                    {
                                      clients?.find(
                                        (client) => client.id === workOrder.fromCustomerId
                                      )?.name
                                    }
                                  </p>
                                </Form.Group>
                              </Col>
                            )}
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">Start date</Form.Label>
                                <p>{toLocalDate(workOrder?.fromDate)}</p>
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">
                                  Estimated delivery date
                                </Form.Label>
                                <p>{toLocalDate(workOrder?.toDate)}</p>
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">Entity Type</Form.Label>
                                <p>{workOrder?.entityType || '-'}</p>
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">PO Date</Form.Label>
                                <p>{toLocalDate(workOrder?.poDate) || '-'}</p>
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">PO Number</Form.Label>
                                <p>
                                  {workOrder?.poDetails && workOrder?.poDetails.length
                                    ? workOrder?.poDetails
                                        ?.map((poDetail) => {
                                          return poDetail.purchaseOrderNumber;
                                        })
                                        .join(', ')
                                    : workOrder?.poNumber || '-'}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">Payment Term(Days) </Form.Label>
                                <p>{workOrder?.paymentTerm || '-'}</p>
                              </Form.Group>
                            </Col>

                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">
                                  Traceability Documents
                                </Form.Label>
                                <p>{workOrder?.traceabilityDocuments === true ? 'Yes' : 'No'}</p>
                              </Form.Group>
                            </Col>

                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">Fulfillment Year</Form.Label>
                                <p>
                                  {`${toFinYear(
                                    workOrder?.fulfillmentYearStartDate,
                                    workOrder?.fulfillmentYearEndDate
                                  )}`}
                                </p>
                              </Form.Group>
                            </Col>

                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">Fulfillment Cycle</Form.Label>
                                <p>{workOrder?.workOrderItems?.[0]?.fulfillmentCycle || '-'}</p>
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} sm={3}>
                              <Form.Group>
                                <Form.Label className=" text-small">
                                  Additional Invoicing requirements
                                </Form.Label>
                                <p>{workOrder?.isInvoicing ? 'Yes' : 'No'}</p>
                              </Form.Group>
                            </Col>
                            {workOrder?.isInvoicing && (
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label className=" text-small">Comments</Form.Label>
                                  <p>{workOrder?.comments ?? '-'}</p>
                                </Form.Group>
                              </Col>
                            )}

                            {isRejected && (
                              <Col xs={3} md={3} sm={3}>
                                <Form.Group>
                                  <Form.Label style={{ color: '#D92D20' }} className="text-small">
                                    Rejection Reason
                                  </Form.Label>
                                  <p>{workOrder?.rejectionComments ?? '-'}</p>
                                </Form.Group>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col xs={2} md={2} sm={2}>
                          <UploadScrollableCard isEdit={false} documents={workOrder?.documents} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Row className={'padding-2rem details-filter'}>
                      <Col xs={12}>
                        <h6 className="mb-0">Targets and deliveries</h6>
                      </Col>
                      <Col xs={12} className={'pt-3'}>
                        {workOrder?.fromCustomerId ? (
                          <WorkOrderItems
                            isWorkOrderOutgoing={isWorkOrderOutgoing}
                            columns={
                              isWorkOrderOutgoing
                                ? isUserServiceProvider
                                  ? WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS_FOR_SP
                                  : WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS
                                : WORK_ORDER_TABLE_COLUMNS.WORK_ORDER_ITEMS_INCOMING
                            }
                            isUserServiceProvider={isUserServiceProvider}
                            browseNodes={browseNodes}
                            onSubRowShow={fetchDeliveries}
                            account={account}
                            regions={region}
                            data={workOrder?.workOrderItems}
                            isHUL={
                              account?.user?.profile?.id === 9053 ||
                              workOrder?.fromCustomerId === 9053
                            }
                          />
                        ) : (
                          <Loader />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {isUserRapidue && showHistory && (
                  <div
                    className={'m-5 details-filter'}
                    style={{
                      width: '93%',
                      height: '40%',
                      overflow: 'auto',
                    }}>
                    <h6 className="mb-3">History</h6>
                    <WorkOrderChangeLog
                      logs={logs}
                      userList={users}
                      browseNodes={browseNodes}
                      workOrder={workOrder}
                    />
                  </div>
                )}
              </Card.Body>
            )}
          </Form>
        </Card>
        <PromptModal
          singleButton={false}
          show={showDeleteConfirmModal}
          title="Delete Work order"
          text="Are you sure you want to delete work order ?"
          onDismiss={() => setShowDeleteConfirmModal(false)}
          onSuccess={() => doDeleteWorkOrder()}
          onHide={() => setShowDeleteConfirmModal(false)}
        />
        <DocumentModal
          isAPICallForImageView={true}
          displayDocumentModal={displayDocumentModal}
          documents={workOrder.documents}
          handleDocChange={handleDocChange}
          deletDoc={doDeleteDocuments}
          canUpdate={isWorkOrderOutgoing}
          setDisplayDocumentModal={() => setDisplayDocumentModal(false)}
          groupName={isWorkOrderOutgoing ? 'Manage_Vendors' : 'Execute_Targets'}
          moduleName={'Work_Orders'}
        />
        <PromptModal
          show={closeWoModal}
          text={'Are you sure you want to close the work order?'}
          subText={
            'Note: Once the work order is closed the service provider will not be able to assign any deliveries to the work order'
          }
          dismissBtnText={'No'}
          successBtnText={'Close work order'}
          onDismiss={() => setCloseWoModal(false)}
          onSuccess={() => {
            setCloseWoModal(false);
            doOpenCloseWorkOrders('CLOSE');
          }}
          onHide={() => setCloseWoModal(false)}
          size={'md'}
          title={'Close Work Order'}
        />
        <RejectWorkOrderModal
          isOpen={showRejectModal}
          hideModal={hadnleRejectModal}
          rejectionComments={rejectionComments}
          setRejectionComments={setRejectionComments}
          loading={loading}
          approveRejectHandler={approveRejectHandler}
        />

        <PromptModal
          show={openWoModal}
          text={'Are you sure you want to open the work order?'}
          subText={
            'Note: Once the work order is opend the service provider will be able to assign any deliveries to the work order'
          }
          dismissBtnText={'No'}
          successBtnText={'Open work order'}
          onDismiss={() => setOpenWoModal(false)}
          onSuccess={() => {
            setCloseWoModal(false);
            doOpenCloseWorkOrders('OPEN');
          }}
          onHide={() => setOpenWoModal(false)}
          size={'md'}
          title={'Open Work Order'}
        />
      </React.Suspense>
    </div>
  );
};

export default BrandWorkOrderDetails;
