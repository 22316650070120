import React from 'react';
import { dequal as deepEqual } from 'dequal';

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepEffect;
