import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MarkerIcon } from '../../../../assets/img/_App/marker.svg';

const TruckTracking = ({ traces, apiHasLoaded, markIconRendering }) => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        defaultCenter={{
          lat: traces[2]?.lat,
          lng: traces[2]?.lng,
        }}
        bootstrapURLKeys={{
          key: 'AIzaSyBCjZ5nnSS7CkkEE2Dwvkb0uCoCbKzTAY0',
          libraries: ['places', 'geometry'],
        }}
        draggable={true}
        zoom={8}
        onGoogleApiLoaded={apiHasLoaded}
        yesIWantToUseGoogleMapApiInternals>
        <MarkerIcon lat={traces[0]?.lat} lng={traces[0]?.lng} />
        <MarkerIcon lat={traces[1]?.lat} lng={traces[1]?.lng} />
        <MarkerIcon lat={traces[2]?.lat} lng={traces[2]?.lng} />
        <MarkerIcon lat={traces[3]?.lat} lng={traces[3]?.lng} />
      </GoogleMapReact>
    </div>
  );
};
export default TruckTracking;
