/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import './PaginationComponent';

const PaginationComponent = (props) => {
  const {
    currentPage,
    numberOfPages,
    totalCount,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageSize,
  } = props;

  const [, setActivePage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const page_size = pageSize || 20;

  useEffect(() => {
    setActivePage(currentPage + 1);
    const visiblePages = getVisiblePages(currentPage + 1, numberOfPages);
    setVisiblePages(filterPages(visiblePages, numberOfPages));
  }, [currentPage, numberOfPages]);

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [page - 2, page - 1, page, page + 1, page + 2];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [total - 4, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5];
      }
    }
  };

  const getDisplayPaginator = () => {
    const startItem = currentPage * page_size + 1;
    const endIntex = startItem - 1 + page_size;
    const endItem = endIntex > totalCount ? totalCount : endIntex;
    return `${startItem} - ${endItem} of ${totalCount}`;
  };

  return (
    <Row>
      <Col className="align-items-center col d-flex justify-content-between">
        <div className="font-regular">{getDisplayPaginator()}</div>
        <Pagination className="mb-0">
          <Pagination.Item onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            First
          </Pagination.Item>
          <Pagination.Item onClick={previousPage} disabled={!canPreviousPage}>
            Prev
          </Pagination.Item>
          {visiblePages.map((page) => {
            return (
              <Pagination.Item
                key={`pagination_button_${page}`}
                onClick={() => gotoPage(page - 1)}
                active={currentPage + 1 === page}>
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Item onClick={nextPage} disabled={!canNextPage}>
            Next
          </Pagination.Item>
          <Pagination.Item onClick={() => gotoPage(numberOfPages - 1)} disabled={!canNextPage}>
            Last
          </Pagination.Item>
        </Pagination>
      </Col>
    </Row>
  );
};

export default PaginationComponent;
