import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
// import { Colors } from '../../../theme/Colors';
import AppHeader from '../../../layout/AppHeader';
import FinancialYearModal from './FinancialYearModal';
import { ReactComponent as NotificationIcon } from '../../../assets/img/_App/notification.svg';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

// const useStyles = makeStyles({
//   grow: {
//     flexGrow: 1,
//   },
//   title: {
//     display: 'block',
//     fontSize: '1.273rem',
//   },
//   header: { backgroundColor: Colors.appBgColor, color: '#2e374d' },
// });

const HomeHeader = ({ setUpdatedFinancialYear }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <AppHeader
        leftRender={() =>
          /*<IconButton
            edge="start"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit">
            <UserIcon />
          </IconButton>*/ null
        }
        titleRender={(route) => 'Home'}
        rightRender={(route) => (
          <>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit">
              <NotificationIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit">
              <CalendarTodayIcon onClick={() => setOpenModal(true)} />
            </IconButton>
          </>
        )}
      />
      <FinancialYearModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setUpdatedFinancialYear={setUpdatedFinancialYear}
      />
    </>
  );
};

export default HomeHeader;
