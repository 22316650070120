import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import './ResetPasswordContent.scss';
import * as Yup from 'yup';
import { AuthViews } from '../../../views/Auth/_authView.const';
import Api from '../../../shared/Config/Api';
import ApiEndpoints from '../../../shared/Config/ApiEndpoints.const';
import tickSign from '../../../assets/img/ticksign.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const ResetPasswordContent = (props) => {
  const [activeView, setActiveView] = useState(
    window.location.pathname === '/create-password'
      ? AuthViews.CREATE_PASSWORD_FROM_LINK
      : AuthViews.RESET_PASSWORD
  );
  const [isLoading, setIsLoading] = useState(false);
  const [resetKey, setResetKey] = useState(null);
  const headerForReset = (
    <div className={'mb-5'}>
      <p>
        <FontAwesomeIcon icon={faLock} />
      </p>
      <h5 className="heading">Reset Password</h5>
    </div>
  );
  const headerForCreate = (
    <div className={'mb-5'}>
      <p>
        <FontAwesomeIcon icon={faLock} />
      </p>
      <h5 className="heading">Create Password</h5>
    </div>
  );
  useEffect(() => {
    props.passActiveView(activeView);
  }, [activeView]);
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    setResetKey(params.get('key'));
    if (params.get('email')) {
      props.setHeader(headerForCreate);
    }
    if (params.get('key')) {
      props.setHeader(headerForReset);
      setActiveView(AuthViews.RESET_PASSWORD_FROM_LINK);
    }
  }, []);
  const resetPassWithEmailForm = useFormik({
    initialValues: {
      mail: '',
    },
    validationSchema: Yup.object({
      mail: Yup.string().email('Invalid Username').required('Email required'),
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      //dispatch
      requestResetPassword(values.mail);
    },
  });

  const resetPassWitLinkForm = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Password required.')
        .min(8, 'Password has to be at-least 8 characters long')
        .matches(/[A-Z]/, 'Password should contain at least one Upper-Case Character')
        .matches(/[a-z]/, 'Password should contain at least one Lower-Case Character')
        .matches(/\d/, 'Password should contain at least one numerical digit')
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          'Password should contain at least one special character'
        ),
      confirmPassword: Yup.string()
        .required('Confirm password required.')
        .test('passwords-match', 'Passwords must match', function (value) {
          return resetPassWitLinkForm.values.newPassword === value;
        }),
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      //dispatch
      window.location.pathname === '/create-password'
        ? createPassword({ ...values })
        : resetPassword({
            ...values,
            key: resetKey,
          });
    },
  });

  const resetPassword = (params) => {
    setIsLoading(true);
    Api.post(ApiEndpoints.RESET_PASS, params).then(
      () => {
        setIsLoading(false);
        props.setHeader(headerForReset);
        setActiveView(AuthViews.RESET_PASSWORD_FROM_LINK_SUCCESS);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const createPassword = (params) => {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get('email');
    setIsLoading(true);
    Api.post(ApiEndpoints.CREATE_PASS, { ...params, email }).then(
      () => {
        setIsLoading(false);
        setActiveView(AuthViews.CREATE_PASSWORD_FROM_LINK_SUCCESS);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const requestResetPassword = (params) => {
    setIsLoading(true);
    Api.post(ApiEndpoints.RESET_PASS_REQUEST, params).then(
      () => {
        setIsLoading(false);
        setActiveView(AuthViews.RESET_PASSWORD_SUCCESS);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const renderSwitch = () => {
    switch (activeView) {
      case AuthViews.RESET_PASSWORD:
        return (
          <Col xs={12} className={'p-0'}>
            <Form onSubmit={resetPassWithEmailForm.handleSubmit} className="pt-5" noValidate>
              <Form.Group>
                <Form.Label className="mb-0">Email </Form.Label>
                <Form.Control
                  className="p-0 float-label"
                  id="mail"
                  name="mail"
                  type="email"
                  value={resetPassWithEmailForm.values.mail}
                  onChange={resetPassWithEmailForm.handleChange}
                  placeholder="Enter email to reset password."
                  isInvalid={!!resetPassWithEmailForm?.errors?.mail}
                />
                <Form.Control.Feedback type="invalid">
                  {resetPassWithEmailForm.errors?.mail}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col xs={6} className={'d-flex align-items-center'}>
                  <a className="" href="/login">
                    Go to login
                  </a>
                </Col>
                <Col xs={6}>
                  <Button
                    className="float-right px-4 my-4"
                    variant="primary"
                    size="lg"
                    disabled={!resetPassWithEmailForm.isValid}
                    type="submit">
                    {isLoading ? (
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span> Loading</span>
                      </div>
                    ) : (
                      'Continue'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        );
      case AuthViews.RESET_PASSWORD_SUCCESS:
        return (
          <Col xs={12} className={'p-0'}>
            <div className={'d-flex align-items-center'}>
              <img src={tickSign} height={50} width={50} className={'my-3'} />
            </div>
            <br />
            Password reset link has been sent to your email.
            <br />
            Please follow the instructions.
            <Col xs={6} className={'p-0 mt-4'}>
              <a className="" href="/login">
                Go to login
              </a>
            </Col>
          </Col>
        );
      case AuthViews.RESET_PASSWORD_FROM_LINK:
        return (
          <Col xs={12} className={'p-0'}>
            <Form onSubmit={resetPassWitLinkForm.handleSubmit} className="" noValidate>
              <Form.Group>
                <Form.Label className="mb-0">Password </Form.Label>
                <Form.Control
                  className="p-0 float-label"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={resetPassWitLinkForm.values.newPassword}
                  onChange={resetPassWitLinkForm.handleChange}
                  placeholder="*******"
                  isInvalid={!!resetPassWitLinkForm?.errors?.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {resetPassWitLinkForm.errors?.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Confirm Password </Form.Label>
                <Form.Control
                  className="p-0 float-label"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={resetPassWitLinkForm.values.confirmPassword}
                  onChange={resetPassWitLinkForm.handleChange}
                  placeholder="*******"
                  isInvalid={!!resetPassWitLinkForm?.errors?.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {resetPassWitLinkForm.errors?.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col xs={6} className={'d-flex align-items-center'}>
                  <a className="" href="/login">
                    Go to login
                  </a>
                </Col>
                <Col xs={6}>
                  <Button
                    className="float-right px-4 my-4"
                    variant="primary"
                    size="lg"
                    disabled={!resetPassWitLinkForm.isValid}
                    type="submit">
                    {isLoading ? (
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span> Resetting</span>
                      </div>
                    ) : (
                      'Reset'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        );
      case AuthViews.RESET_PASSWORD_FROM_LINK_SUCCESS:
        return (
          <Col xs={12} className={'p-0'}>
            <div className={'d-flex align-items-center'}>
              <img src={tickSign} height={50} width={50} className={'my-3'} />
            </div>
            <br />
            Your password has been reset successfully.
            <Col xs={6} className={'p-0 mt-2'}>
              <a className="" href="/login">
                Try login
              </a>
            </Col>
          </Col>
        );
      case AuthViews.CREATE_PASSWORD_FROM_LINK:
        return (
          <Col xs={12} className={'p-0'}>
            <Form onSubmit={resetPassWitLinkForm.handleSubmit} className="" noValidate>
              <Form.Group>
                <Form.Label className="mb-0">Password </Form.Label>
                <Form.Control
                  className="p-0 float-label"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={resetPassWitLinkForm.values.newPassword}
                  onChange={resetPassWitLinkForm.handleChange}
                  placeholder="*******"
                  isInvalid={!!resetPassWitLinkForm?.errors?.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {resetPassWitLinkForm.errors?.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0">Confirm Password </Form.Label>
                <Form.Control
                  className="p-0 float-label"
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={resetPassWitLinkForm.values.confirmPassword}
                  onChange={resetPassWitLinkForm.handleChange}
                  placeholder="*******"
                  isInvalid={!!resetPassWitLinkForm?.errors?.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {resetPassWitLinkForm.errors?.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col xs={6} className={'d-flex align-items-center'}>
                  <a className="" href="/login">
                    Go to login
                  </a>
                </Col>
                <Col xs={6}>
                  <Button
                    className="float-right px-4 my-4"
                    variant="primary"
                    size="lg"
                    disabled={!resetPassWitLinkForm.isValid}
                    type="submit">
                    {isLoading ? (
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span> Creating</span>
                      </div>
                    ) : (
                      'Create'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        );
      case AuthViews.CREATE_PASSWORD_FROM_LINK_SUCCESS:
        return (
          <Col xs={12} className={'p-0'}>
            <div className={'d-flex align-items-center'}>
              <img src={tickSign} height={50} width={50} className={'my-3'} />
            </div>
            <br />
            Your password has been created successfully.
            <Col xs={6} className={'p-0 mt-2'}>
              <a className="" href="/login">
                Try login
              </a>
            </Col>
          </Col>
        );
    }
  };

  return (
    <div className="animated fadeIn">
      <Row className="justify-content-center ml-0 mr-0">
        {activeView === AuthViews.RESET_PASSWORD ||
        activeView === AuthViews.RESET_PASSWORD_SUCCESS ? (
          <Col xs={12} className={'p-0'}>
            <h6 className={'heading'}>Forgot Password</h6>
          </Col>
        ) : null}

        {renderSwitch()}
      </Row>
    </div>
  );
};

export default ResetPasswordContent;
