import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Image } from 'react-bootstrap';
import Badge from '@material-ui/core/Badge';
import { Loader } from '../../../shared/Components';
import { faTrashAlt, faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './DocumentCard.app.styles';
import { useDropzone } from 'react-dropzone';
import deleteIcon from '../../../assets/img/delete.svg';
import rejectIcon from '../../../assets/img/_App/reject.svg';
import { DELIVERY_STATUS, DOCUMENT_STATUSES } from '../../../shared/Constants/Constants';
import { isEmpty } from 'lodash';

const DocumentCard = ({
  onFileSelected,
  documents,
  uploadStatus,
  handleDelete,
  onDocClick,
  isPRO,
  onDelete,
  delivery,
  isSupervisorDelivery,
  isnotDelete,
  usersList,
}) => {
  const classes = useStyles();
  const [selectedDoc, setSelectedDoc] = useState({});
  const [isResolve, setIsResolve] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (selectedDoc || isResolve) {
        onFileSelected(acceptedFiles, selectedDoc?.id, selectedDoc?.document?.type, isResolve);
      } else {
        onFileSelected(acceptedFiles);
      }
    },
    [selectedDoc, isResolve]
  );

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    maxSize: '10485760',
    multiple: true,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    maxSize: '10485760',
    multiple: true,
  });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    maxSize: '10485760',
    multiple: true,
  });

  const assignedDeliveryStatus =
    delivery?.assignedDeliveries && delivery?.assignedDeliveries[0]?.status;

  const proStatusBadgeDisplay = (docObj) => {
    return (
      <>
        {docObj?.status === DOCUMENT_STATUSES.IN_REVIEW && (
          <Badge className={classes.reviewBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>In Review</Typography>
          </Badge>
        )}
        {docObj?.status === DOCUMENT_STATUSES.APPROVED && (
          <Badge className={classes.approveBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>Approved</Typography>
          </Badge>
        )}
        {docObj?.status === DOCUMENT_STATUSES.REJECTED && (
          <Badge className={classes.rejectBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>Rejected</Typography>
          </Badge>
        )}
      </>
    );
  };

  const spAssignedDocStatusBadgeDisplay = (docObj) => {
    return (
      <>
        {docObj?.assignedStatus === DOCUMENT_STATUSES.IN_REVIEW && (
          <Badge className={classes.reviewBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>In Review</Typography>
          </Badge>
        )}
        {docObj?.assignedStatus === DOCUMENT_STATUSES.APPROVED && (
          <Badge className={classes.approveBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>Approved</Typography>
          </Badge>
        )}
        {docObj?.assignedStatus === DOCUMENT_STATUSES.REJECTED && (
          <Badge className={classes.rejectBadgeStyl}>
            <Typography style={{ fontSize: '12px' }}>Rejected</Typography>
          </Badge>
        )}
      </>
    );
  };

  const getEncodedUrl = (url) => {
    var encodedUrl = encodeURIComponent(url);
    return encodedUrl;
  };

  const imagePdfView = (tile) => {
    return (
      <>
        {' '}
        {tile.document.contentType === 'application/pdf' ? (
          <div onClick={() => onDocClick(tile)}>
            <iframe
              type="application/pdf"
              // src={tile.document.viewUrl}
              src={tile.document.viewUrl}
              style={{ width: '100px', height: '100px', pointerEvents: 'none' }}></iframe>
          </div>
        ) : (
          <Image
            className={classes.img}
            src={tile.document.viewUrl}
            onClick={() => onDocClick(tile)}
          />
        )}
      </>
    );
  };

  /*const displayStatusToSpBasedOnAssignedDeliveryDocStatus = (tile, mainDocId) => {
    const assignedDeliveryStatus = delivery?.assignedDeliveries[0]?.status;
    const assignedDeliveryDocs = delivery?.assignedDeliveries[0]?.deliveryDocuments;

    const compareMainDocWithAssignedDocObj = assignedDeliveryDocs?.find(
      (doc) => doc?.document?.id === mainDocId
    );
    return (
      <>
        {statusBadgeDisplay(compareMainDocWithAssignedDocObj)}
        {assignedDeliveryStatus !== DELIVERY_STATUS.APPROVED.id &&
          compareMainDocWithAssignedDocObj?.status === DOCUMENT_STATUSES.IN_REVIEW && (
            <Avatar className={classes.deleteAvatar}>
              <Image src={deleteIcon} width="11px" onClick={() => onDelete(tile)} />
            </Avatar>
          )}
        {imagePdfView(tile)}
      </>
    );
  };*/

  const pickUpdocsNotFromSupervisor = documents?.filter((e) =>
    e.document?.type?.includes('PICKUP_')
  );
  const dropdocsNotFromSupervisor = documents?.filter((e) => e.document?.type?.includes('DROP_'));
  return (
    <GridList
      cellHeight={180}
      style={{ height: documents?.length > 0 ? '130px' : '' }}
      className="mb-2">
      {documents?.map((tile, index) => (
        <>
          <GridListTile
            style={{ width: '25%', height: '100px', borderColor: 'red' }}
            key={tile?.document.viewUrl}>
            {uploadStatus && uploadStatus[tile?.document?.fileName]?.uploading ? (
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <Loader hideText={true} size="sm" />
                <p>{uploadStatus[tile?.document?.fileName]?.progress}%</p>
              </div>
            ) : (
              <>
                {(pickUpdocsNotFromSupervisor || dropdocsNotFromSupervisor) && (
                  <>{imagePdfView(tile)}</>
                )}
                {!isPRO && isSupervisorDelivery === undefined && (
                  <>
                    {' '}
                    {usersList?.length > 0 &&
                      !isEmpty(usersList?.find((e) => e.id === tile?.document?.uploadedBy)) &&
                      tile?.assignedStatus === 'IN_REVIEW' && (
                        <Avatar className={classes.deleteAvatar}>
                          <Image src={deleteIcon} width="11px" onClick={() => onDelete(tile)} />
                        </Avatar>
                      )}
                    {tile.status === DOCUMENT_STATUSES.APPROVED && (
                      <Avatar className={classes.approveAvtar}>
                        <FontAwesomeIcon size="sm" color="green" icon={faCheck} />
                      </Avatar>
                    )}
                    {tile.status === DOCUMENT_STATUSES.REJECTED && (
                      <Avatar className={classes.rejectAvatar}>
                        <Image src={rejectIcon} width="11px" />
                      </Avatar>
                    )}
                    {spAssignedDocStatusBadgeDisplay(tile)}
                    {imagePdfView(tile)}
                  </>
                )}

                {isPRO && isSupervisorDelivery === undefined && (
                  <>
                    {proStatusBadgeDisplay(tile)}
                    {assignedDeliveryStatus !== DELIVERY_STATUS.APPROVED.id &&
                      tile?.status === DOCUMENT_STATUSES.IN_REVIEW &&
                      usersList.length > 0 &&
                      !isEmpty(usersList?.find((e) => e.id === tile?.document?.uploadedBy)) && (
                        <Avatar className={classes.deleteproAvatar}>
                          <Image src={deleteIcon} width="11px" onClick={() => onDelete(tile)} />
                        </Avatar>
                      )}
                    {imagePdfView(tile)}
                  </>
                )}
              </>
            )}

            {isSupervisorDelivery && (
              <>
                {tile.status === 'REJECTED' || tile?.assignedStatus === 'REJECTED' ? (
                  <div
                    style={{ height: '88%' }}
                    {...getRootProps1({
                      onClick: (e) =>
                        tile.status === 'REJECTED' || tile?.assignedStatus === 'REJECTED'
                          ? setSelectedDoc(tile)
                          : setSelectedDoc(null),
                    })}>
                    <input {...getInputProps1()} />
                    <img className={classes.rejectedImg} src={tile.document.viewUrl} />

                    <Typography align="center" color="error">
                      <FontAwesomeIcon size="sm" icon={faTimesCircle} /> Rejected
                    </Typography>
                  </div>
                ) : (
                  <>{imagePdfView(tile)}</>
                )}
              </>
            )}

            {uploadStatus && !uploadStatus[tile?.document?.fileName]?.uploading && (
              <GridListTileBar
                titlePosition="top"
                actionPosition="right"
                style={{
                  background: 'transparent',
                }}
                actionIcon={
                  tile?.document?.id === null ? (
                    <Avatar className={classes.icon} onClick={() => handleDelete(tile)}>
                      <FontAwesomeIcon size="sm" icon={faTrashAlt} />
                    </Avatar>
                  ) : null
                }
              />
            )}
            {!isPRO && tile.status === 'REJECTED' && isSupervisorDelivery === undefined && (
              <GridListTileBar
                titlePosition="bottom"
                actionPosition="center"
                style={{
                  background: 'transparent',
                  left: '-21px',
                  right: '10px',
                }}
                actionIcon={
                  <div
                    {...getRootProps({
                      onClick: (e) =>
                        tile.status === 'REJECTED' ? setSelectedDoc(tile) : setSelectedDoc(null),
                    })}>
                    <input {...getInputProps()} />
                    <Button
                      style={{
                        color: 'black',
                        background: 'white',
                        height: '20px',
                        width: '66px',
                        border: '1px solid black',
                      }}
                      className="resolve m-1"
                      size="sm">
                      Replace
                    </Button>
                  </div>
                }
              />
            )}

            {!isPRO && tile.assignedStatus === 'REJECTED' && isSupervisorDelivery === undefined && (
              <GridListTileBar
                titlePosition="top"
                style={{
                  background: 'transparent',
                  left: '-10px',
                  right: '44px',
                  top: '-3px',
                }}
                actionIcon={
                  <div
                    {...getRootProps2({
                      onClick: (e) => {
                        setSelectedDoc(tile);
                        setIsResolve(true);
                      },
                    })}>
                    <input {...getInputProps2()} />
                    <Button
                      style={{
                        color: 'white',
                        background: 'blue',
                        height: '23px',
                        width: '66px',
                        border: '1px solid black',
                        width: '51px',
                        minWidth: '48px',
                        fontWeight: 'bold',
                        fontSize: '11px',
                      }}
                      className="resolve m-1"
                      size="sm">
                      Resolve
                    </Button>
                  </div>
                }
              />
            )}
          </GridListTile>
        </>
      ))}
    </GridList>
  );
};

export default DocumentCard;
