import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getSummaryFacets } from '../../../state/Execution/selectors';
import { useDispatch } from 'react-redux';
import { compact, flatMap, groupBy, map, mapValues } from 'lodash';

const ExecutionFacets = (props) => {
  const { facetData, filters } = props;
  const [selectedValues, setSelectedValue] = useState();
  const dispatch = useDispatch();
  const [optionData, setOptionData] = useState([]);
  const facets = getSummaryFacets(facetData);

  useEffect(() => {
    console.log();
  }, [filters]);
  const facetChange = (values) => {
    console.log('facetChange', values);
    const f = mapValues(groupBy(values, 'filterKey'), (v, k) => v.map((d) => d.value));
    props.onChange(f);
  };
  /**
   * generate options for Select Dropdown
   * @return array of {label: k,  options: v}
   */
  useEffect(() => {
    const options = map(groupBy(facets, 'facetLabel'), (v, k) => ({
      label: k,
      options: v,
    }));
    setOptionData(options);
  }, [facetData]);

  useEffect(() => {
    const _options = flatMap(mapValues(optionData, (p) => p.options));
    const _selected = compact(
      map(
        filters,
        (v, k) => v && _options.filter((_o) => _o.filterKey === k && v.indexOf(_o.value) >= 0)
      )
    ).flat();
    setSelectedValue(_selected);
  }, [facetData, filters]);
  console.log('selectedValues', selectedValues);
  return (
    <div>
      <Select
        value={selectedValues}
        options={optionData}
        isMulti
        onChange={facetChange}
        getOptionValue={(option) => `${option.value}`}
        getOptionLabel={(option) => `${option.label}`}
      />
    </div>
  );
};

ExecutionFacets.propTypes = {};

export default ExecutionFacets;
