import { createSelector } from 'reselect';
import { flatMap, keyBy, groupBy, sumBy, map, toNumber } from 'lodash';

const getItemDetails = (target, itemId) => {
  return target?.workOrderItems?.find((item) => {
    return item.itemId === itemId;
  });
};

const getTotalQtyForStates = (target, itemId) => {
  return getItemDetails(target, itemId)
    ?.states?.map((state) => state.totalQty)
    ?.reduce((prev, next) => prev + next, 0);
};

const getTotalQtyForDistricts = (target, itemId, stateId) => {
  return getStateDetails(target, itemId, stateId)
    ?.districts?.map((state) => state.totalQty)
    ?.reduce((prev, next) => prev + next, 0);
};

const getStateDetails = (target, itemId, stateId) => {
  return getItemDetails(target, itemId)?.states?.find((state) => state.stateId === stateId);
};

const getDistrictDetails = (target, itemId, stateId, districtId) => {
  return getStateDetails(target, itemId, stateId)?.districts?.find(
    (dist) => dist.districtId === districtId
  );
};

const getStateQty = (target, itemId, stateId) => {
  return getItemDetails(target, itemId)?.states?.find((state) => state.stateId === stateId)
    ?.totalQty;
};

const getDistrictQty = (target, itemId, stateId, districtId) => {
  return getStateDetails(target, itemId, stateId)?.districts?.find(
    (dist) => dist.districtId === districtId
  )?.totalQty;
};

const getBrowseNodeDetails = (target, browseNodeId) => {
  return target?.browseNodes?.find((item) => {
    return item.browseNodeId === browseNodeId;
  });
};

const targetDate = (state) => state.target?.data;
const getBrowseNode = (state) => state.browseNodes?.data;
const getStates = (state) => state.region?.data;
const getTarget = createSelector(
  [targetDate, getBrowseNode, getStates],
  (target, browseNodes, regions) => {
    let categories = keyBy(browseNodes, 'id');
    let items = keyBy(flatMap(browseNodes, 'items'), 'id');
    let states = keyBy(regions, 'id');
    let districts = keyBy(flatMap(regions, 'districts'), 'id');

    const grouped = (d, key) => {
      const g = groupBy(d, key);
      if (g[null]) delete g[null];
      if (g[undefined]) delete g[undefined];
      return g;
    };
    return {
      ...target,
      workOrderItems: map(grouped(target?.workOrderItems, 'itemId'), (v, k) => {
        return {
          itemId: k,
          browseNodeId: v[0].browseNodeId,
          qty: sumBy(v, 'qty'),
          remainingQty: sumBy(v, 'remainingQty') || 0,
          states: map(
            grouped(
              v?.filter((data) => data?.qty !== 0),
              'stateId'
            ),
            (v1, k1) => ({
              stateId: toNumber(k1),
              stateName: states[k1]?.name,
              totalQty: sumBy(v1, 'qty'),
              remainingQty: sumBy(v1, 'remainingQty') || 0,
              districts: map(
                grouped(
                  v1?.filter((data) => data?.qty !== 0),
                  'districtId'
                ),
                (v2, k2) => ({
                  districtId: toNumber(k2),
                  districtName: districts[k2]?.name,
                  totalQty: sumBy(v2, 'qty'),
                  remainingQty: sumBy(v2, 'remainingQty'),
                })
              ),
            })
          ),
        };
      }),
      browseNodes: map(grouped(target?.workOrderItems, 'browseNodeId'), (v, k) => ({
        browseNodeId: k,
        totalQty: sumBy(v, 'qty'),
        remainingQty: 0,
      })),
    };
  }
);

export default {
  getItemDetails,
  getStateDetails,
  getTotalQtyForStates,
  getTotalQtyForDistricts,
  getDistrictDetails,
  getStateQty,
  getDistrictQty,
  getBrowseNodeDetails,
  getTarget,
};
