import Api from '../../../shared/Config/Api';
import ApiEndpoints from '../../../shared/Config/ApiEndpoints.const';

export const fetchMarketPlaceDeliveries = async (params) => {
  return await Api.get(ApiEndpoints.DELIVERIES, null, params);
};

export const fetchDemands = async (params) => {
  return await Api.get(ApiEndpoints.WORK_ORDER_ITEMS, null, params);
};

export const fetchMonthWiseCredits = async (params) => {
  return await Api.get(ApiEndpoints.MARKETPLACE_CREDITS, null, params);
};

export const generateMarketPlaceWorkOrder = async (payload) => {
  return await Api.post(ApiEndpoints.GENERATE_MARKETPLACE_WORK_ORDER, payload);
};
export const allocateMpDeliveryToBrand = async (assignmentDetails) => {
  return await Api.put(ApiEndpoints.ASSIGN_DELIVERY, assignmentDetails);
};
