import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, InputAdornment, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import FormField from '../../../../../shared/Components/_App/FormField';
import { useStyles } from './DocListModal.styles';
import { isEmpty } from 'lodash';

const DocRejectListModal = ({ open, onSelect, onClose }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const [othersVal, setOthersVal] = React.useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const reasonList = [
    { id: 'Forged', name: 'Forged' },
    { id: 'Image is not clear', name: 'Image is not clear' },
    { id: 'Vehicle number is not visible', name: 'Vehicle number is not visible' },
    { id: 'others', name: 'Others' },
  ];

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <div className="ml-3 pt-5 pb-5">
            <Typography variant="h5" className="mb-3" style={{ fontFamily: 'font-semibold' }}>
              Image Rejection Reason
            </Typography>
            <RadioGroup
              aria-label="gender"
              name="customized-radios"
              value={value}
              onChange={handleChange}>
              {reasonList?.map((e) => {
                return <FormControlLabel value={e.id} control={<StyledRadio />} label={e.name} />;
              })}
            </RadioGroup>{' '}
            {value === 'others' && (
              <FormField>
                <FormControl variant="outlined" style={{ marginRight: '10px' }}>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    value={othersVal}
                    onChange={(e) => setOthersVal(e.target.value)}
                  />
                </FormControl>
              </FormField>
            )}
          </div>
          <div className={classes.buttonGroup}>
            <Button className={`${classes.goHomeButton} `} onClick={onClose}>
              Close
            </Button>
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Button
              className={`${classes.continueButton}`}
              disabled={value === 'others' && isEmpty(othersVal)}
              onClick={() => onSelect(value, othersVal)}>
              Submit
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classes.checkedIcon} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default DocRejectListModal;
