import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import waterIcon from './../../assets/img/water.svg';
import energyIcon from './../../assets/img/energy.svg';
import oilIcon from './../../assets/img/oil.svg';
import landfillIcon from './../../assets/img/landfill.svg';
import pollutionIcon from './../../assets/img/pollution.svg';
import './SustainabilityImpact.scss';
import CountUp from 'react-countup';

const SustainabilityImpact = (props) => {
  const { data = {} } = props;
  const {
    waterLtsReceived,
    energySavedReceived,
    oilLtsReceived,
    noOfTreesReceived,
    landfillSpaceReceived,
    airPollutantsReceived,
  } = data;
  return (
    <div className="SustainabilityImpact">
      <Row>
        <Col xs={12}>
          <h6 className="mb-4 text-medium">Sustainability Impact</h6>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7}>
          <Row className="saved-section m-0" noGutters="true">
            <div className="headline">
              <span>Saved</span>
            </div>
            <Col xs={4}>
              <Image src={waterIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={waterLtsReceived}
                duration={1}
              />
              <div className="title">LITRES OF WATER</div>
            </Col>
            <Col xs={4}>
              <Image src={energyIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={energySavedReceived}
                duration={1}
              />
              <div className="title">kWh ENGERY</div>
            </Col>
            <Col xs={4}>
              <Image src={oilIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={oilLtsReceived}
                duration={1}
              />
              <div className="title">LITRES OF OIL</div>
            </Col>
            {/* <Col xs={3}>
              <Image src={treeIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={noOfTreesReceived}
                duration={1}
              />
              <div className="title">TREES</div>
            </Col> */}
          </Row>
        </Col>
        <Col xs={12} md={5}>
          <Row className="eliminated-section m-0">
            <div className="headline">
              <span style={{ color: '#D85757' }}>Eliminated</span>
            </div>
            <Col xs={6}>
              <Image src={pollutionIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={airPollutantsReceived}
                duration={1}
              />
              <div className="title">AIR POLLUTANTS</div>
            </Col>
            <Col xs={6}>
              <Image src={landfillIcon} />
              <CountUp
                className="quantity"
                start={0}
                separator={','}
                end={landfillSpaceReceived}
                duration={1}
              />
              <div className="title">CUBIC FEET OF LANDFILL</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

SustainabilityImpact.propTypes = {};

export default SustainabilityImpact;
