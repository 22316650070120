export const Colors = {
  primary: '#005dff',
  info: '#46345B',
  secondary: '#b0bac9',
  danger: '#EF6A7F',
  success: '#0D5C46',
  warning: '#FF8A00',
  black: '#0f243d',
  danger2: '#E53935',
  white: '#FFFFFF',
  lightBlack: '#0f243d55',
  grey: '#8797ad',
  eyeBlack: '#2e374d',
  lightGrey: '#f2f4f5',
  iceBlue: '#f7faff',
  defaultColor: '#2e384d',
  appBgColor: '#eff0f5',
  screenBgColor: '#ffffff',
  sectionTitleColor: '#8797ad',
  geyTextColor: '#667085',
};
