import { targetAPI } from './targetAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchTarget = createAsyncThunk('target/fetchTarget', async (queryParams) => {
  const response = await targetAPI.fetchTarget(queryParams);
  return response?.data?.[0];
});
export const createTarget = createAsyncThunk('target/createTarget', async (payload) => {
  return await targetAPI.createTarget(payload);
});
export const updateTarget = createAsyncThunk('target/updateTarget', async (payload) => {
  return await targetAPI.updateTarget(payload);
});
