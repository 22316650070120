import { Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import Section from '../../../../shared/Components/_App/Section';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as FolderIcon } from '../../../../assets/img/_App/folder.svg';
import DocumentCard from '../DocumentCard';
import { toLocalDate } from '../../../../shared/Utils/Helper';
import { uploadDocument } from '../../../../shared/Utils/FileUpload';
import { DeliveryService } from '../../../../features/Delivery/delivery.service';
import DeliveryUploadIcon from './DeliveryUploadIcon';
import WeightDetails from '../DeliveryLocations/WeightDetails';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

const browserImageSize = require('browser-image-size');
Geocode.setApiKey('AIzaSyCi7nUKy2NI09uwYjSqbyYrbCnZXyLIOCY');

const DeliveryUploadDocs = (props) => {
  const {
    onViewClick,
    isView,
    docConfigList,
    deliveryDocuments,
    handleDocClick,
    delivery,
    doFetchDelivery,
    isPRO,
    usersList,
    isPickup,
    tabName,
    isWeightDetailSection,
    maxQty,
  } = {
    ...props,
  };
  const [uploadStatus, setUploadStatus] = useState({});
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [isWeighBridgeNumberPresent, setIsWeighBridgeNumberPresent] = useState({
    isPickUpWeighBridgePresent: false,
    isDropOffWeighBridgePresent: false,
    pickUpWeighBridgeCheckLoading: false,
    dropOffWeighBridgeCheckLoading: false,
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      dropOffTareWeight: delivery?.deliveryDetails?.dropOffTareWeight || null,
      dropOffNetWeight: delivery?.deliveryDetails?.dropOffNetWeight || null,
      dropOffWeighBridgeNumber: delivery?.deliveryDetails?.dropOffWeighBridgeNumber,
      dropOffGrossWeight: delivery?.deliveryDetails?.dropOffGrossWeight || null,
      pickUpGrossWeight: delivery?.deliveryDetails?.pickUpGrossWeight || null,
      pickUpTareWeight: delivery?.deliveryDetails?.pickUpTareWeight || null,
      pickUpNetWeight: delivery?.deliveryDetails?.pickUpNetWeight || null,
      pickUpWeighBridgeNumber: delivery?.deliveryDetails?.pickUpWeighBridgeNumber,
      pickUpLrNumber: delivery?.deliveryDetails?.pickUpLrNumber,
      qty: delivery?.qty,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: (props) => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          pickUpGrossWeight: isPickup ? Yup.number().required() : undefined,
          pickUpTareWeight: isPickup ? Yup.number().required() : undefined,
          pickUpNetWeight: isPickup ? Yup.number().required() : undefined,
          pickUpWeighBridgeNumber: isPickup ? Yup.string().required() : undefined,
          pickUpLrNumber: isPickup ? Yup.string().required() : undefined,
          dropOffTareWeight: !isPickup ? Yup.number().required() : undefined,
          dropOffNetWeight: !isPickup ? Yup.number().required() : undefined,
          dropOffWeighBridgeNumber: !isPickup ? Yup.string().required() : undefined,
          dropOffGrossWeight: !isPickup ? Yup.number().required() : undefined,
        });
      });
    },
    onSubmit: async (values, actions) => {
      setSaveLoading(true);
      let deliveryWeightDetails = {};
      if (isPickup) {
        deliveryWeightDetails = {
          ...delivery?.deliveryDetails,
          pickUpGrossWeight: values?.pickUpGrossWeight,
          pickUpTareWeight: values?.pickUpTareWeight,
          pickUpNetWeight: values?.pickUpNetWeight,
          pickUpWeighBridgeNumber: values?.pickUpWeighBridgeNumber,
          pickUpLrNumber: values?.pickUpLrNumber,
        };
      } else {
        deliveryWeightDetails = {
          ...delivery?.deliveryDetails,
          dropOffGrossWeight: values?.dropOffGrossWeight,
          dropOffTareWeight: values?.dropOffTareWeight,
          dropOffNetWeight: values?.dropOffNetWeight,
          dropOffWeighBridgeNumber: values?.dropOffWeighBridgeNumber,
        };
      }
      const quantity =
        isPickup && delivery?.deliveryDetails?.dropOffNetWeight !== null
          ? delivery?.qty
          : values?.qty;
      const payload = { ...delivery, deliveryDetails: deliveryWeightDetails, qty: quantity };
      const response = await DeliveryService.updateDelivery(payload);
      if (response?.status === 201) {
        doFetchDelivery();
      }
      setSaveLoading(false);
    },
  });

  useEffect(() => {
    if (!isEmpty(delivery?.deliveryDetails) && !isPickup) {
      if (
        delivery?.deliveryDetails?.dropOffGrossWeight === null &&
        delivery?.deliveryDetails?.dropOffNetWeight === null &&
        delivery?.deliveryDetails?.dropOffTareWeight === null &&
        delivery?.deliveryDetails?.dropOffWeighBridgeNumber === null
      )
        setIsEdit(false);
      else setIsEdit(true);
    }
  }, []);

  const getTicketId = (tickets, delDocId) => {
    const deliveryDocs = deliveryDocuments?.find((e) => {
      if (e.id === delDocId) {
        return e;
      }
    });
    let docticket = tickets?.find(
      (t) => t.deliveryDocuments[0].document.id === deliveryDocs.document.id && t.status === 'OPEN'
    );
    return docticket?.id;
  };

  const getTicketDocId = (tickets, delDocId) => {
    const deliveryDocs = deliveryDocuments?.find((e) => {
      if (e.id === delDocId) {
        return e;
      }
    });
    let docticket = tickets?.find(
      (t) => t.deliveryDocuments[0].document.id === deliveryDocs.document.id && t.status === 'OPEN'
    );
    return docticket?.deliveryDocuments[0].id;
  };

  const getDocId = (tickets, delDocId) => {
    const deliveryDocs = deliveryDocuments?.find((e) => {
      if (e.id === delDocId) {
        return e;
      }
    });
    let docticket = tickets?.find(
      (t) => t.deliveryDocuments[0].document.id === deliveryDocs.document.id && t.status === 'OPEN'
    );
    return docticket?.deliveryDocuments[0].document.id;
  };
  const doReplyTicket = async (messageData) => {
    try {
      const response = await DeliveryService.replyTicket(messageData);
      if (response?.data) {
        toast.success('Document resolved successfully');
        doFetchDelivery();
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const doFetchTickets = async (doc, delDocId) => {
    const response = await DeliveryService.fetchTickets(delivery.assignedDeliveries[0].id);
    if (response?.data) {
      const tick = response?.data?.map((e) => {
        const a = e.deliveryDocuments[0];
        const b = a.document;
        b.ticket = e.id;
        e.docId = a.id;
        return e;
      });
      doc.id = getTicketDocId(tick, delDocId);
      doc.document.id = getDocId(tick, delDocId);
      const messageData = {
        ticketId: getTicketId(tick, delDocId),
        text: 'resolve',
        createdOn: Date.now(),
        replyToMessageId: null,
        resolve: true,
        attachments: [doc],
      };
      // dispatch(
      //   ticketActions.replyTicket(messageData, (data) => {
      //     if (data) {
      //       toast.success('Document resolved successfully');
      //       doFetchDelivery();
      //     }
      //   })
      // );
      doReplyTicket(messageData);
    }
  };
  const replaceDocument = async (doc, delDocId) => {
    doFetchTickets(doc, delDocId);
  };

  const handleFileSelected = (files, docId, type, imageSelectionType, isResolve) => {
    if (imageSelectionType?.toUpperCase() === 'CAMERA') {
      files.forEach((file) => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              (response) => {
                const extractedAddress = response.results[0].formatted_address;
                if (file.type === 'application/pdf') {
                  const reader = new FileReader();
                  reader.readAsBinaryString(file);
                  reader.onloadend = function () {
                    var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                    file.pageCount = count;
                    file.latitude = position.coords.latitude;
                    file.longitude = position.coords.longitude;
                    file.metadata = { address: extractedAddress };
                    uploadDocumentToS3(file, docId, type, isResolve);
                  };
                } else {
                  browserImageSize(file).then(function (size) {
                    file.width = size.width;
                    file.height = size.height;
                    file.latitude = position.coords.latitude;
                    file.longitude = position.coords.longitude;
                    file.metadata = { address: extractedAddress };
                    uploadDocumentToS3(file, docId, type, isResolve);
                  });
                }
              },
              (error) => {
                console.log(error);
              }
            );
          });
        }
      });
    } else files.forEach((file) => uploadDocumentToS3(file, docId, type, isResolve));
  };

  const uploadDocumentToS3 = (file, delDocId, type, isResolve) => {
    const fileDetails = {
      entityId: delivery.id,
      entity: 'DELIVERY',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
      active: true,
      width: file.width,
      height: file.height,
      pageCount: file.pageCount,
      latitude: file.latitude,
      longitude: file.longitude,
      metadata: JSON.stringify(file.metadata),
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setDocuments((prevDoc) => {
        const docCopy = [...prevDoc];
        return [...docCopy, docTobeUploaded];
      });
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: 0,
        },
      });
      if (isResolve) {
        replaceDocument(docTobeUploaded, delDocId);
      } else {
        uploadDocumentToServer(docTobeUploaded);
      }
    };

    const successCallback = (signedURLResponse) => {
      const oldUploadStatus = { ...uploadStatus };
      setUploadStatus({
        ...oldUploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'DONE',
          uploading: false,
        },
      });
    };
    const progressCallback = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      const newStatus = {
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: percentCompleted,
        },
      };
      setUploadStatus(newStatus);
    };

    const failureCallback = (error) => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
    };

    uploadDocument(
      fileDetails,
      file,
      signedURLCallback,
      successCallback,
      failureCallback,
      progressCallback
    );
  };

  /**
   * upload file to delivery
   * @param deliveryDocument
   */
  const uploadDocumentToServer = async (deliveryDocument) => {
    try {
      let response = await DeliveryService.uploadDeliveryDocument({
        ...deliveryDocument,
        deliveryId: delivery?.id,
      });
      if (response.data) {
        updateDocType(response.data.id, response.data.document.type);
      }
    } catch (error) {}
  };

  /**
   * called when request for change of doctype
   * @param docType
   */
  const updateDocType = async (docId, docType) => {
    try {
      await DeliveryService.updateDocumentType({
        deliveryDocumentId: docId,
        docType: docType,
      });
      if (isPRO && delivery?.itemCategoryName !== 'E-Waste') {
        await DeliveryService.updateStatus({
          deliveryId: delivery.id,
        });
      }
      if (
        !isPRO &&
        delivery?.assignedDeliveries?.some((d2) => d2?.status === 'IN_PROGRESS') &&
        delivery?.itemCategoryName !== 'E-Waste'
      ) {
        await DeliveryService.updateSPDeliveryStatus({
          deliveryId: delivery.id,
        });
      }
      doFetchDelivery();
    } catch (error) {}
  };

  /**
   * called when doc status filter tab clicked
   * @param doc
   */
  const deleteDocument = async (delDoc) => {
    //delete file from server
    if (delDoc.id) {
      try {
        await DeliveryService.deleteDeliveryDocument(delDoc?.id);
        if (isPRO && delivery?.itemCategoryName !== 'E-Waste') {
          await DeliveryService.updateStatus({
            deliveryId: delivery?.id,
          });
        }
        if (!isPRO && delivery?.itemCategoryName !== 'E-Waste') {
          await DeliveryService.updateSPDeliveryStatus({
            deliveryId: delivery?.id,
          });
        }
        doFetchDelivery();
      } catch (error) {}
    }
  };

  const getDisplayStatus = (type) => {
    const rejected = delivery?.deliveryDocuments.findIndex(
      (e) => e.document.type === type && e.status === 'REJECTED'
    );
    const inreview = delivery?.deliveryDocuments.findIndex(
      (e) => e.document.type === type && e.status === 'IN_REVIEW'
    );

    if (delivery?.deliveryDocuments.filter((e) => e.document.type === type)?.length === 0) {
      return 'No Documents';
    }
    if (rejected < 0 && inreview < 0) {
      return 'APPROVED';
    }
    if (rejected < 0 && inreview > 0) {
      return 'IN-REVIEW';
    }
    if (rejected > 0) {
      return 'REJECTED';
    }
    return 'No documents';
  };

  return (
    <>
      {isWeightDetailSection && (
        <>
          <Section title={tabName}>
            <WeightDetails
              formik={formik}
              isWeighBridgeNumberPresent={isWeighBridgeNumberPresent}
              setIsWeighBridgeNumberPresent={setIsWeighBridgeNumberPresent}
              isPickup={isPickup}
              isCreate={false}
              saveLoading={saveLoading}
              isEdit={isPickup ? isPickup : isEdit}
              delivery={delivery}
              maxQty={maxQty}
              status={
                delivery?.assignedDeliveries
                  ? delivery?.assignedDeliveries[0]?.status !== 'IN_PROGRESS'
                  : true
              }
              isQtyEditable={delivery?.deliveryDetails?.dropOffNetWeight === null}
            />
          </Section>
        </>
      )}
      <Section title={`${!isView ? 'UPLOAD DOCS' : ''}`}>
        <List disablePadding>
          {docConfigList &&
            docConfigList.map((e) => (
              <>
                <ListItem
                  onClick={() =>
                    deliveryDocuments?.filter((f) => f.document.type === e.type)?.length > 0
                      ? onViewClick(e)
                      : null
                  }
                  disableGutters>
                  <ListItemAvatar>
                    <Avatar style={{ background: 'none' }}>
                      <FolderIcon style={{ width: '25px', height: '25px' }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={e.title}
                    secondary={
                      !isView
                        ? deliveryDocuments?.filter((f) => f.document.type === e.type)?.length > 0
                          ? `Added on ${toLocalDate(
                              deliveryDocuments?.filter((f) => f.document.type === e.type)[
                                deliveryDocuments?.filter((f) => f.document.type === e.type)
                                  ?.length - 1
                              ].createdOn,
                              'DD-MMM-YYYY hh:mm a'
                            )}`
                          : 'Upload Document'
                        : getDisplayStatus(e.type)
                    }
                  />
                  {!isView && (
                    <DeliveryUploadIcon onFileSelected={handleFileSelected} settingType={e.type} />
                  )}
                </ListItem>
                {!isView && (
                  <DocumentCard
                    onDocClick={handleDocClick}
                    documents={deliveryDocuments?.filter((f) => f.document.type === e.type)}
                    onDelete={(doc) => deleteDocument(doc)}
                    delivery={delivery}
                    isPRO={isPRO}
                    onFileSelected={(file, delDocId, type, isResolve) =>
                      handleFileSelected(file, delDocId, type, null, isResolve)
                    }
                    usersList={usersList}
                  />
                )}
                <Divider />
              </>
            ))}
        </List>
      </Section>
    </>
  );
};
export default DeliveryUploadDocs;
