import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Divider, FormControlLabel, Paper, RadioGroup } from '@material-ui/core';
import StyledRadio from '../../../shared/Components/_App/StyledRadio/StyledRadio';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../../../shared/Components/_App/Modal/SortByModel.styles';
import { setFinancialYear } from '../../../state/Account/accountSlice';

const FinancialYearModal = ({ openModal, setOpenModal, setUpdatedFinancialYear }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedFinancialYear, financialYears } = useSelector(({ account }) => {
    return {
      selectedFinancialYear: account?.selectedFinancialYear,
      financialYears: account?.financialYears,
    };
  });
  const [value, setValue] = useState(selectedFinancialYear?.fromDate);

  useEffect(() => {
    const year = JSON.parse(localStorage.getItem('selectedFinancialYear'));
    if (year !== null) {
      // for updating year from localStorage
      setValue(year.fromDate);
      dispatch(setFinancialYear(year));
      setUpdatedFinancialYear(year);
    }
  }, []);

  const handleChange = (value) => {
    setValue(value?.target?.value);
  };

  const handleSelect = () => {
    const selectedYearValue = financialYears?.find((data) => data?.value?.fromDate === value)
      ?.value;
    localStorage.setItem('selectedFinancialYear', JSON.stringify(selectedYearValue));
    dispatch(setFinancialYear(selectedYearValue));
    setUpdatedFinancialYear(selectedYearValue);
    setOpenModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={openModal}>
        <Paper className={classes.paper}>
          <h3 id="transition-modal-title">Select Financial Year</h3>
          <RadioGroup
            aria-label="gender"
            name="customized-radios"
            value={value}
            onChange={handleChange}>
            {financialYears?.map((data) => {
              return (
                <FormControlLabel
                  value={data?.value?.fromDate}
                  control={<StyledRadio />}
                  label={data?.label}
                />
              );
            })}
          </RadioGroup>
          <Divider className={classes.horizontalDivider} />
          <div className={classes.buttonGroup}>
            <Button className={classes.closeButton} onClick={() => setOpenModal(false)}>
              Close
            </Button>
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Button
              className={`${classes.closeButton} ${classes.selectButton}`}
              onClick={handleSelect}>
              Select
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default FinancialYearModal;
