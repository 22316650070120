import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import Section from '../../../../../shared/Components/_App/Section';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const RejectedDocList = (props) => {
  const { docConfigList, deliveryDocuments, delivery } = {
    ...props,
  };

  const rejectedDocList = docConfigList.filter((e) =>
    deliveryDocuments.find((f) => f.document.type === e.type)
  );

  return (
    <Section title="" bodyStyle={{ padding: 0 }}>
      <List disablePadding>
        {rejectedDocList &&
          rejectedDocList.map((e) => (
            <>
              <ListItem
                disableGutters
                style={{ border: '1px solid #f1f3ff', padding: '5px 10px', margin: '10px 0px' }}>
                <ListItemText
                  primary={`${
                    e.title +
                    '(' +
                    delivery?.deliveryDocuments?.filter((f) => f.document.type === e.type)?.length +
                    ')'
                  }`}
                  secondary={
                    <Typography style={{ color: 'red' }}>
                      {' '}
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={faTimesCircle}
                        className="pr-2"
                        style={{ width: '20px' }}
                      />
                      Rejected
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ))}
      </List>
    </Section>
  );
};
export default RejectedDocList;
