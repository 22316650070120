import { createSlice } from '@reduxjs/toolkit';
import { fetchMonthlyFulFillment } from './reportsThunks';

const initialState = {
  monthlySummary: [],
  orderSummary: [],
  filters: {
    browseNodeIds: null,
    itemIds: null,
    clientId: null,
    serviceProviderId: null,
  },
  isFetching: true,
  fetchError: null,
};

export const executionSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    applySummaryFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: {
    [fetchMonthlyFulFillment.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchMonthlyFulFillment.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.fetchError = null;
      state.monthlySummary = action.payload.monthlySummary;
      state.orderSummary = action.payload.orderSummary;
    },
    [fetchMonthlyFulFillment.rejected]: (state) => {
      state.isFetching = false;
      state.fetchError = 500;
    },
  },
});

export const { applySummaryFilter } = executionSlice.actions;

export default executionSlice.reducer;
