import { brandAPI } from './brandAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchStats = createAsyncThunk('brand/fetchStats', async (queryParams) => {
  const response = await brandAPI.fetchStats(queryParams);
  return response.data;
});

export const fetchBrandsLight = createAsyncThunk('brand/fetchBrandsLight', async (payload) => {
  const response = await brandAPI.fetchBrandsLight(payload);
  return response.data;
});
