import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DelayRender = ({ children, waitBeforeShow = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    console.log(waitBeforeShow);
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
DelayRender.propTypes = {
  children: PropTypes.element.isRequired,
  waitBeforeShow: PropTypes.number,
};
export default DelayRender;
