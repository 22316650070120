import React from 'react';
import { Button, Card, Form, FormControl, Modal, Spinner } from 'react-bootstrap';
import FormField from '../../shared/Components/_App/FormField';
import { OutlinedInput } from '@material-ui/core';
import { isEmpty } from 'lodash';

function RejectWorkOrderModal(props) {
  const {
    isOpen,
    hideModal,
    rejectionComments,
    setRejectionComments,
    approveRejectHandler,
    loading,
  } = props;

  return (
    <Modal show={isOpen} onHide={hideModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm" className="p-2">
          <h4>Rejecting Work order?</h4>
          <span
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#98A2B3',
            }}>
            This would be sent back to Business Team for data corrections.
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="  modal-register-page">
        <Form.Group controlId="commentForm">
          <Form.Label
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#344054',
            }}>
            Specify Reason
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter your comment here"
            value={rejectionComments}
            onChange={(e) => setRejectionComments(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button
          variant="danger"
          className={'float-right text-white font-bold mr-3'}
          size="lg"
          disabled={isEmpty(rejectionComments) || loading}
          onClick={() => approveRejectHandler('REJECTED')}>
          {loading ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span> Rejecting Work Order</span>
            </div>
          ) : (
            'Reject Work Order'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RejectWorkOrderModal;
