import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { ChevronLeft } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import { AppBar } from '@material-ui/core';
import { DIM } from '../../../theme/Dims';
import { Colors } from '../../../theme/Colors';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '2px 4px',
    marginTop: '75px',
  },
  input: {
    width: '100%',
    backgroundColor: '#eff0f5',
    padding: theme.spacing(1),
    borderRadius: '25px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  iconButton: {
    position: 'absolute',
    right: 35,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: 'center',
  },
  deleteText: {
    color: '#005dff !important',
    fontFamily: 'font-bold',
    fontSize: 'small',
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: Colors.white,
    color: '#2e374d',
    borderBottom: `1px solid ${Colors.appBgColor}`,
  },
  divHeight: {
    marginTop: DIM.headerHeight,
  },
}));
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AppModal = (props) => {
  const {
    visible,
    setVisible,
    children,
    title,
    actions,
    addHandler,
    backArrowDisplay,
    deleteHandler,
    editMode,
    editType,
    editName,
    filterData,
    isSeachBox,
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Dialog fullScreen open={visible} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar elevation={0} position={'fixed'} className={classes.header}>
        <Toolbar>
          {backArrowDisplay ? (
            <>
              <IconButton
                edge="start"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleClose}
                color="inherit">
                <ChevronLeft color={'primary'} />
              </IconButton>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              {editMode && (
                <Link
                  onClick={() => deleteHandler(editType, editName)}
                  className={classes.deleteText}>
                  Delete
                </Link>
              )}
            </>
          ) : (
            <>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close">
                <CloseIcon color={'primary'} />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isSeachBox && (
        <div className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search with Source Name or Address"
            inputProps={{ 'aria-label': 'Search with Source Name or Address' }}
            onChange={(e) => filterData(e.target.value)}
            // value={search}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </div>
      )}
      <div className={!isSeachBox && classes.divHeight}>
        {children}
        {actions && (
          <div
            style={{
              textAlign: 'right',
              padding: '10px',
              position: 'fixed',
              bottom: '20px',
              right: '57px',
            }}>
            <Fab color="primary" aria-label="add" onClick={() => addHandler()}>
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default AppModal;
