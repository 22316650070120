import React from 'react';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
  Paper,
  Button,
} from '@material-ui/core';
import { ReactComponent as PickUpQtyIcon } from '../../../assets/img/_App/pickup_qty.svg';
import { ReactComponent as ItemIcon } from '../../../assets/img/_App/item.svg';
import { ReactComponent as SourceLocationIcon } from '../../../assets/img/_App/marker.svg';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';

// import { ReactComponent as SourceLocation } from '../../../assets/img/_App/source_location.svg';

const useStyles = makeStyles((theme) => ({
  mainHead: {
    color: '#2e384d',
    fontFamily: 'font-semibold',
    paddingLeft: '16px',
  },
  alignRight: {
    textAlign: 'end',
  },
  linkStyl: {
    color: '#33ab2e',
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const SupervisorPickupDetails = ({ data }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <List>
        <ListItemText
          primary={
            <Typography className={`${classes.mainHead} mb-2`} variant="body1" color="textPrimary">
              Pickup Details
            </Typography>
          }
        />
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#fff7ef' }}>
              <PickUpQtyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{ color: 'grey' }}>PICK UP QUANTITY</Typography>}
            secondary={
              <Typography style={{ color: 'black' }}>{data?.qty}Kg</Typography>
            }></ListItemText>
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#edfaff' }}>
              <ItemIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{ color: 'grey' }}>ITEM</Typography>}
            secondary={
              <Typography style={{ color: 'black' }}>{data?.item}</Typography>
            }></ListItemText>
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#eaf1ff' }}>
              <SourceLocationIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{ color: 'grey' }}>SOURCE TYPE</Typography>}
            secondary={
              <Typography style={{ color: 'black' }}>
                {data?.deliveryDetails?.pickUpLocationType}
              </Typography>
            }></ListItemText>
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#ebebeb' }}>
              <ItemIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{ color: 'grey' }}>SOURCE NAME</Typography>}
            secondary={
              <Typography style={{ color: 'black' }}>
                {data?.deliveryDetails?.sourceName}
              </Typography>
            }></ListItemText>
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#ecfffb' }}>
              <FontAwesomeIcon color="#449484" icon={faCalendar} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={{ color: 'grey' }}>DATE OF LOADING</Typography>}
            secondary={
              <Typography style={{ color: 'black' }}>
                {toLocalDate(data?.deliveryDetails?.pickUpLoadingDate)}
              </Typography>
            }></ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
};

export default SupervisorPickupDetails;
