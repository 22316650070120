import React, { useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import Section from '../../../../shared/Components/_App/Section';
import { ReactComponent as CalenderIcon } from '../../../../assets/img/_App/calender.svg';
import * as moment from 'moment';
import FormField from '../../../../shared/Components/_App/FormField';
import { toLocalDate, toServerDate } from '../../../../shared/Utils/Helper';
import { useFinancialYear } from '../../../../hooks/useFinancialYear';

const useStyles = makeStyles((theme) => ({
  sectionView: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const DeliverySchedule = ({ createSchedule }) => {
  const classes = useStyles();
  const [date, setDate] = useState(
    toLocalDate(createSchedule.values.pickUpLoadingDate, 'YYYY-MM-DD')
  );
  const [time, settime] = useState(toLocalDate(createSchedule.values.pickUpLoadingDate, 'HH:mm'));
  const { selectedFinancialYear } = useFinancialYear();

  const handleDateTime = (e, type) => {
    if (type === 'date') {
      let selectedDate = e.target.value;
      let selectedTime = time ? time : '00:00';
      let datetime = moment(selectedDate + ' ' + selectedTime);
      setDate(e.target.value);
      createSchedule.setFieldValue('pickUpLoadingDate', toServerDate(datetime));
    } else if (type === 'time') {
      let selectedDate = date ? date : moment().format('YYYY-MM-DD');
      let selectedTime = e.target.value;
      let datetime = moment(selectedDate + ' ' + selectedTime);
      settime(e.target.value);
      createSchedule.setFieldValue('pickUpLoadingDate', toServerDate(datetime));
    }
  };

  const isSameFinancialYear = moment(toLocalDate(new Date())).isBetween(
    toLocalDate(selectedFinancialYear.fromDate),
    toLocalDate(selectedFinancialYear.toDate)
  );

  return (
    <Section title={'SELECT PICKUP DATE & TIME'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormField
            label="PICKUP DATE"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            // InputProps={{
            //   endAdornment: <CalenderIcon />,
            // }}

            inputProps={{
              min: isSameFinancialYear
                ? moment(new Date()).format('YYYY-MM-DD')
                : moment(selectedFinancialYear.fromDate).format('YYYY-MM-DD'),
              max: moment(selectedFinancialYear.toDate).format('YYYY-MM-DD'),
            }}
            format="DD/MM/YYYY"
            style={{ width: 160 }}
            value={date}
            onChange={(e) => handleDateTime(e, 'date')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormField
            label="TIME"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 155 }}
            value={time}
            onChange={(e) => handleDateTime(e, 'time')}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default DeliverySchedule;
