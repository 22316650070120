import React from 'react';
import { browseNodeSelectors } from '../state/BrowseNode';
import { useMaterialCategory } from './';

export const useMaterialItems = (categoryId) => {
  const browseNodes = useMaterialCategory();
  if (browseNodes) {
    return browseNodeSelectors.getItemByCategoryId(browseNodes, categoryId);
  }
  return [];
};
