/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './RegisteredGstinCard.scss';
import { Button } from 'react-bootstrap';
import back from '../../../assets/img/back.png';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';

const RegisteredGstinCard = (props) => {
  const [addresses, setAddresses] = useState([...props.addresses]);

  useEffect(() => {
    props.setHeader(
      <>
        <img alt="back_icon" onClick={props.onBack} src={back} />
        <p className="heading mt-3">
          Select your GSTIN and <br /> Address for
        </p>
        <h5 className="heading">{addresses && addresses[0]?.name}</h5>
      </>
    );
  }, []);

  function isAddressSelected() {
    return addresses.findIndex((a) => a.selected) >= 0;
  }

  const handleChangeSelect = (index, newValue) => {
    const addArray = [...addresses];
    addArray[index].selected = newValue;
    setAddresses(addArray);
  };

  return (
    <div className="RegisterGstinCard sign-up animated fadeIn">
      <Form>
        <div className="addr-list-container">
          {addresses && addresses.length > 0 ? (
            addresses.map((addr, idx) => {
              return (
                <Form.Label
                  htmlFor={`address-${idx}`}
                  key={idx}
                  className={classNames('address-card mb-3  p-3', {
                    selected: addr.selected,
                  })}>
                  <Form.Check
                    custom
                    label={addr.state}
                    checked={addr.selected}
                    type="checkbox"
                    onChange={(e) => handleChangeSelect(idx, e.target.checked)}
                    id={`address-${idx}`}></Form.Check>
                  <div className="pl-4 mt-0 ml-2">
                    <div className="text-regular font-medium">
                      <span>{addr.gstin}</span>
                    </div>
                    <span className="mb-0 mt-2">{addr.street}</span>
                  </div>
                </Form.Label>
              );
            })
          ) : (
            <div>No GSTIN with pan {props.pan}</div>
          )}
        </div>
        <Button
          disabled={!isAddressSelected()}
          onClick={() => props.nextAction(addresses)}
          size="lg"
          variant="primary float-right px-4 my-2 login">
          Next
        </Button>
      </Form>
    </div>
  );
};

export default RegisteredGstinCard;
