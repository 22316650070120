import React from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeliveryUploadIcon = ({ onFileSelected, settingType }) => {
  const handleImageHandler = (e, imageSelectionName) => {
    onFileSelected([...e.target.files], null, settingType, imageSelectionName);
  };

  return (
    <>
      <ListItemAvatar style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ background: '#eff5ff', position: 'relative', zIndex: '999' }}>
            <ArrowUpwardIcon color="primary" />
          </Avatar>
          <input
            accept="image/jpeg, image/png"
            type="file"
            style={{ position: 'absolute', zIndex: '9999', opacity: '0' }}
            onChange={(e) => handleImageHandler(e, 'file')}
            multiple
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <Avatar style={{ background: '#eff5ff', position: 'relative', zIndex: '999' }}>
            <FontAwesomeIcon size="1x" icon={faCamera} color="#005dff" />
          </Avatar>
          <input
            accept="image/jpeg, image/png"
            type="file"
            style={{ position: 'absolute', zIndex: '9999', opacity: '0' }}
            capture="environment"
            onChange={(e) => handleImageHandler(e, 'camera')}
          />
        </div>
      </ListItemAvatar>
    </>
  );
};
export default DeliveryUploadIcon;
