import { createSlice } from '@reduxjs/toolkit';
import { fetchBrowseNodes, fetchImpactMetrics } from './browseNodeThunks';

const initialState = { data: [], metrics: [], isFetching: false, error: null };

export const BrowseNodeSlice = createSlice({
  name: 'browseNodes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBrowseNodes.pending]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [fetchBrowseNodes.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.data = action.payload;
    },
    [fetchBrowseNodes.rejected]: (state) => {
      state.isFetching = false;
      state.error = 500;
    },
    [fetchImpactMetrics.fulfilled]: (state, action) => {
      state.metrics = action.payload;
    },
  },
});

export default BrowseNodeSlice.reducer;
