import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@material-ui/core';

const Section = ({ children, title, bodyStyle, ulbComponent }) => (
  <Box>
    <div style={{ display: 'flex' }}>
      {title && (
        <>
          <Typography
            style={{ padding: 14, letterSpacing: '1px', color: '#8797ad' }}
            variant={'h5'}>
            {title?.toUpperCase()}
          </Typography>
        </>
      )}
      <div style={{ margin: '0px 18px' }}> {ulbComponent}</div>
    </div>

    <Paper elevation={0} style={{ padding: 20, ...bodyStyle }}>
      {children}
    </Paper>
  </Box>
);

Section.propTypes = {
  title: PropTypes.any,
  bodyStyle: PropTypes.object,
};

export default Section;
