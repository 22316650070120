import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Alert = ({ text, buttonText, dispatchingFunction, onClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch(dispatchingFunction());
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div
      style={{
        width: ' 20rem',
        background: '#fff',
        padding: '1rem',
        borderRadius: '3px',
      }}>
      {text}{' '}
      {buttonText && (
        <button className="btn btn-primary btn-sm" onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default Alert;
