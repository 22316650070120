import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import ImgWarning from '../../../assets/img/under_construction.svg';

const Page404 = (props) => {
  return (
    <div className="align-items-center h-100 d-flex">
      <Container>
        <Row className="text-center ">
          <Col md="12" className="align-items-center">
            <div className="p-3">
              <Image src={ImgWarning} width="30%" />
            </div>
          </Col>
          <Col md="12">
            <h2 className="pt-3 mb-3">Under Construction</h2>
            <p className="text-muted">
              We are currently working on this, We need few more days to bring interesting feature
              for you.
            </p>

            {/* <h2 className="pt-3">404</h2>
            <h4 className="pt-3">Oops! You're lost.</h4>
            <p className="text-muted">The page you are looking for was not found.</p> */}
          </Col>
          <Col md="12">
            <Button onClick={() => props.history.push('/')} variant={'primary'}>
              Take me home
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page404;
