import { Box, Grid, Typography, Button } from '@material-ui/core';
import React, { useState } from 'react';
import DropdownDistrict from '../../../../shared/Components/DropdownDistrict/DropdownDistrict';
import DropdownState from '../../../../shared/Components/DropdownState/DropdownState';
import AppModal from '../../../../shared/Components/_App/AppModal';
import FormField from '../../../../shared/Components/_App/FormField';
import Section from '../../../../shared/Components/_App/Section';
import Map from '../../../../shared/Components/_App/GoogleMap/Map';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: 'blue',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
}));
const DeliveryUlbForm = ({ formik, ulbPresent, setShowUlbModal, setUlbPresent }) => {
  const classes = styles();
  const [openMap, setOpenMap] = useState(false);
  const [editLocationFromMap, setEditLocationFromMap] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();

  /**
   * this will be used to handle state value
   * @param {*} value
   */

  function stateHandler(value) {
    formik.setFieldValue('ulbState', value.id);
    formik.setFieldValue('ulbDistrict', '');
  }

  /**
   * this will be used to set soucename , address, pincode,latitude,longitude,city based on address selection
   * @param {*} addressObj
   */

  function setAddressData(addressObj) {
    formik.setFieldValue('ulbAddress', addressObj.street !== undefined ? addressObj.street : '');
    formik.setFieldValue('ulbState', addressObj.state !== undefined ? addressObj.state?.id : '');
    formik.setFieldValue('ulbDistrict', addressObj.city !== undefined ? addressObj.city : '');
    formik.setFieldValue('ulbPincode', addressObj.zipCode !== undefined ? addressObj.zipCode : '');
    setOpenMap(false);
  }

  /**
   * this will be used to set address values on select from the drop down
   * @param {*} addressObj
   */
  function addressHandler(addressObj) {
    setAddressData(addressObj);
  }

  const disableButton =
    !formik.values.ulbAddress ||
    !formik.values.ulbDistrict ||
    !formik.values.ulbName ||
    !formik.values.ulbState;

  return (
    <Box>
      <Section bodyStyle={{ paddingBottom: 50 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField
              FormControlClassName={{ marginBottom: '10px' }}
              label="ULB Name"
              //helperText={formik.errors.ulbName}
              error={formik.errors.ulbName}
              value={formik.values.ulbName}
              isValid={!!formik.errors.ulbName && formik.dirty}
              onChange={(e) => {
                formik.setFieldValue('ulbName', e.target.value);
                formik.setFieldValue('ulbId', '');
              }}
              onBlur={formik.handleBlur('ulbName')}
              touched={formik.touched.ulbName}
            />
            <Typography variant="body1" color="textSecondary" style={{ marginBottom: '5px' }}>
              The Selected ULB doesnt have a Tie-Up with Rapidue. The Service Provider will ensure
              that a Tie-Up done between Rapidue and the ULB.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormField
              onClick={() => {
                setSelectedAddress();
                setEditLocationFromMap(false);
                setOpenMap(true);
              }}
              label="Address"
              disabled={formik.values.ulbAddress ? true : false}
              //helperText={formik.errors?.ulbAddress}
              error={formik.errors?.ulbAddress}
              value={formik.values.ulbAddress}
              isValid={!!formik.errors?.ulbAddress && formik.dirty}
              onChange={formik.handleChange('ulbAddress')}
              onBlur={formik.handleBlur('ulbAddress')}
              touched={formik.touched?.ulbAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Pincode"
              helperText={formik.errors.ulbPincode}
              error={formik.errors.ulbPincode ? true : false}
              value={formik.values.ulbPincode}
              errorMessage={formik.errors.ulbPincode}
              isValid={formik.touched.ulbPincode && !!formik.errors.ulbPincode}
              onChange={(e) => {
                formik.setFieldValue('ulbPincode', e.target.value);
              }}
              onBlur={formik.handleBlur('ulbPincode')}
              touched={formik.touched.ulbPincode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField label="State">
              <DropdownState
                isDisabled={!ulbPresent}
                name="ulbState"
                value={formik.values.ulbState}
                onChange={stateHandler}
                isInvalid={formik.touched.ulbState && !!formik.errors.ulbState}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField label="District">
              <DropdownDistrict
                isDisabled={!ulbPresent}
                name="ulbDistrict"
                value={formik.values.ulbDistrict}
                stateId={formik.values.ulbState}
                isInvalid={formik.touched.ulbDistrict && !!formik.errors.ulbDistrict}
                onChange={(val) => {
                  formik.setFieldValue('ulbDistrict', val.id);
                }}
              />
            </FormField>
          </Grid>
        </Grid>
      </Section>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={disableButton}
        onClick={() => {
          setShowUlbModal(false);
          setUlbPresent(false);
        }}>
        {' '}
        Save & Select
      </Button>
      <AppModal title={`Choose Location`} visible={openMap} setVisible={setOpenMap}>
        <Map
          onConfirmLocation={(selectedAddress) => addressHandler(selectedAddress)}
          openMapModel={openMap}
          editLocationFromMap={editLocationFromMap}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          ulb={true}
        />
      </AppModal>
    </Box>
  );
};

export default DeliveryUlbForm;
