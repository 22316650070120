import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DELIVERY_STATUS } from '../../../shared/Constants/Constants';
import classNames from 'classnames';

const ConfirmDeliveryModal = (props) => {
  const [rejectedDocumentsCount, setRejectedDocumentsCount] = useState(0);
  const {
    delivery,
    setDisplayApproveModal,
    displayApproveModal,
    approveDelivery,
    isMobile,
    approveDeliveryLoading,
  } = props;

  let inReviewDocCount = delivery?.deliveryDocuments?.filter(
    (dd) => dd.status == DELIVERY_STATUS.IN_REVIEW.id
  )?.length;

  useEffect(() => {
    let count = 0;
    if (delivery.deliveryDocuments) {
      delivery.deliveryDocuments.map((document) => {
        if (document.status === 'REJECTED') {
          count = count + 1;
        }
      });
    }
    setRejectedDocumentsCount(count);
  }, [props.delivery]);

  return (
    <Modal
      className="modal-small modal fixed-height"
      size="md"
      show={displayApproveModal?.fromPRO || displayApproveModal?.fromBrand}
      onHide={() => {
        setDisplayApproveModal({
          fromPRO: false,
          fromBrand: false,
        });
      }}
      centered>
      <Modal.Header closeButton className="">
        <h5>Confirm</h5>
      </Modal.Header>
      <Modal.Body className="">
        <p className="p-3">
          {inReviewDocCount > 0 ? (
            <div>
              <div>
                {inReviewDocCount} documents are still in review.
                <br />
                Approving the delivery will also approve these documents.
                <br />
                <br />
                Are you sure you still want to approve this delivery?
              </div>
            </div>
          ) : (
            <div>Are you sure you want to approve this delivery?</div>
          )}
        </p>
        <div className="float-right">
          {isMobile ? (
            <Button
              style={{ backgroundColor: '#e7edf9', color: 'black', border: 'none' }}
              className="ml-3 mb-3"
              onClick={() =>
                setDisplayApproveModal({
                  fromPRO: false,
                  fromBrand: false,
                })
              }>
              Cancel
            </Button>
          ) : (
            <Button
              variant="light"
              className="ml-3 mb-3"
              onClick={() =>
                setDisplayApproveModal({
                  fromPRO: false,
                  fromBrand: false,
                })
              }>
              Cancel
            </Button>
          )}
          <Button
            className="ml-3 text-white mb-3"
            type="submit"
            onClick={() => approveDelivery()}
            disabled={approveDeliveryLoading}>
            {approveDeliveryLoading ? `Approving` : `Yes, Approve Delivery`}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeliveryModal;
