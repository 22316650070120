import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropsType from 'prop-types';
import AppModal from '../AppModal';
import FormLabel from '../FormLabel';
import SearchIcon from '@material-ui/icons/Search';
import FormField from '../../../../shared/Components/_App/FormField';
import { makeStyles } from '@material-ui/core/styles';
import GlobalStyles from '../../../../theme/GlobalStyles';
import Loader from '../../Loader/Loader';
import EmptyView from '../EmptyView';
import PageView from '../PageView';
import { isEmpty } from 'lodash';

export const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    backgroundColor: '#eff0f5',
    margin: '20px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    marginBottom: '0px',
    backgroundColor: 'white',
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    backgroundColor: '#f7faff',
    borderColor: '#E7EDFE',
    padding: theme.spacing(1),
    marginBottom: 5,
  },
}));

const SelectDropdown = (props) => {
  const {
    setAutoClose,
    autoClose,
    ItemLocation = true,
    children2,
    value,
    placeholder,
    label,
    modalTitle,
    disabled,
    listData,
    renderItem,
    addHandler,
    loading,
    children,
    actions,
    visible,
    setVisible,
    onClick,
    onSearch,
    searchPlaceHolderName,
    readOnly,
    helperText,
    error,
    filterData,
    isSeachBox,
    ...restProps
  } = props;
  const classes = useStyle();

  useEffect(() => {
    if (autoClose) {
      setVisible(false);
      setAutoClose(false);
    }
  }, [autoClose]);

  return (
    <>
      <FormControl style={{ marginBottom: 10 }} fullWidth variant="outlined" onClick={onClick}>
        <FormLabel label={label} />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment style={{}} position="end">
                <FontAwesomeIcon icon={faCaretDown} />
              </InputAdornment>
            ),
            readOnly: readOnly || true,
          }}
          value={value}
          style={{ marginBottom: 8 }}
          placeholder={placeholder ? placeholder : 'Select'}
          variant="outlined"
          helperText={helperText}
          error={error}
        />
        {children}
      </FormControl>
      <AppModal
        visible={visible}
        setVisible={setVisible}
        title={modalTitle}
        actions={actions}
        addHandler={addHandler}
        filterData={filterData}
        isSeachBox={isSeachBox}>
        <Container disableGutters>
          <PageView loading={loading}>
            {searchPlaceHolderName && (
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  style={{ backgroundColor: '#eff0f5' }}
                  placeholder={searchPlaceHolderName}
                  inputProps={{ 'aria-label': { searchPlaceHolderName } }}
                  onChange={onSearch}
                />
                <IconButton className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}
            <List style={GlobalStyles.listStyle}>
              <Grid container>
                {ItemLocation ? (
                  isEmpty(listData) ? (
                    <EmptyView hasNoData={true} />
                  ) : (
                    listData?.map((item, idx) => (
                      <Grid item xs={12} md={6} sm={6} key={idx}>
                        {renderItem(item, idx)}
                      </Grid>
                    ))
                  )
                ) : (
                  children2
                )}
              </Grid>
            </List>
          </PageView>
        </Container>
      </AppModal>
    </>
  );
};
SelectDropdown.propTypes = {
  listItem: PropsType.array,
  value: PropsType.any,
  modalTitle: PropsType.string,
  renderItem: PropsType.any,
};
export default SelectDropdown;
