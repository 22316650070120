import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Link } from '@material-ui/core';
import { appRoutes } from '../../routes';

const IndexPage = () => {
  const history = useHistory();
  return (
    <Container>
      <h2>Index Page</h2>
      {appRoutes.map(({ path, name }) => (
        <Button
          fullWidth
          variant="contained"
          className="mb-2"
          color="primary"
          onClick={() => history.push(path)}>
          {name}
        </Button>
      ))}
    </Container>
  );
};

export default IndexPage;
