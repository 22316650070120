import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {
  capitalize,
  compact,
  filter,
  groupBy,
  indexOf,
  intersection,
  keyBy,
  map,
  mapKeys,
  mapValues,
  pick,
  sumBy,
  uniq,
  uniqBy,
} from 'lodash';
import { browseNodeSelectors } from '../BrowseNode';
import { regionSelectors } from '../Region';

//vendor => myid===fromcustomerid
//client => tocutomerid===myid
const getSummaryData = (state) => {
  return [...state.orderSummary, ...state.deliverySummary];
};
const getFilters = (state) => state.filters;
const getBrowseNodes = (state) => state.browseNodes;
const getRegions = (state) => state.region;
const currentCustomerId = (state) => state.currentCustomerId;
/**
 * get formatted execution and dashboard date
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: {deliverySummary: [], orderSummary: []}, res4: *, res5: *) => unknown>}
 */
const getExecutionSummary = createSelector(
  [getFilters, getSummaryData, getBrowseNodes, getRegions, currentCustomerId],
  (filters, summaryData, browseNodes, regions, customerId) => {
    if (isEmpty(summaryData)) return [];
    const isClientOrSpFilterApplied = filters.clientId || filters.serviceProviderId;
    const clientSpFilter = (o) =>
      intersection(
        [o.fromCustomerId, o.toCustomerId],
        [filters.clientId, filters.serviceProviderId]
      ).length > 0;
    summaryData = isClientOrSpFilterApplied ? filter(summaryData, clientSpFilter) : summaryData;
    summaryData = summaryData.filter((s) => {
      return (
        (isEmpty(filters.itemIds) ? true : indexOf(filters.itemIds, s.itemId) >= 0) &&
        (filters.stateIds > 0 ? indexOf(filters.stateIds, s.stateId) >= 0 : true) &&
        (filters.districtIds > 0 ? indexOf(filters.districtIds, s.districtId) >= 0 : true)
      );
    });
    let summary = prepareCombinedData(summaryData, customerId);

    const _data = mapItemDistrictData(summary, browseNodes, regions);
    const finalData = _data.filter((s) => {
      return isEmpty(filters.browseNodeIds)
        ? true
        : indexOf(filters.browseNodeIds, s.browseNodeId) >= 0;
    });
    return finalData;
  }
);

const getBrowseNodes1 = (state) => state.browseNodes?.data;
const getRegions1 = (state) => state.region?.data;
const getFilters1 = (state) => state.execution.filters;
const getActiveView1 = (state) => state.execution.view;
const currentCustomerId1 = (state) => state.account.user?.profile?.id;
const getSummaryData1 = (state) => {
  return [...state.execution.orderSummary, ...state.execution.deliverySummary];
};
const getSustainabilityMetrics = (state) => state.browseNodes?.metrics;

const sustainabilityAttrs = [
  'energySaved',
  'oilLts',
  'landfillSpace',
  'airPollutants',
  'waterLts',
  'noOfTrees',
];
const computeSustainabilityImapct = ({ accepted }, metrics) =>
  accepted ? mapValues(pick(metrics, sustainabilityAttrs), (v) => v * accepted) : {};

const getDashboardSummary = createSelector(
  [
    getActiveView1,
    getFilters1,
    getSummaryData1,
    currentCustomerId1,
    getBrowseNodes1,
    getRegions1,
    getSustainabilityMetrics,
  ],
  (activeView, filters, summaryData, customerId, browseNodes, states, sustainabilityMetrics) => {
    const metricsById = keyBy(sustainabilityMetrics, 'itemId');
    if (isEmpty(summaryData)) return [];
    summaryData = summaryData.map((d) => ({
      ...d,
      ...computeSustainabilityImapct(d, metricsById[d.itemId]),
    }));
    const itemIds = uniq(summaryData.map((d) => d.itemId));
    summaryData = mapItemDistrictData(summaryData, browseNodes, states);
    const _summaryData = summaryData.filter((s) => {
      return (
        (isEmpty(filters.browseNodeIds)
          ? true
          : indexOf(filters.browseNodeIds, s.browseNodeId) >= 0) &&
        (isEmpty(filters.itemIds) ? true : indexOf(filters.itemIds, s.itemId) >= 0)
      );
    });

    const facets = getSummaryFacets(summaryData);
    let summary = prepareCombinedData(_summaryData, customerId, [], activeView)[0];
    let stateWiseSummary = prepareCombinedData(_summaryData, customerId, ['stateId'], activeView);
    let customerWiseSummary = {};
    return { summary, stateWiseSummary, itemIds, facets, customerWiseSummary };
  }
);

const getOrderSummary = (state) => state.execution.orderSummary;
const getDeliverySummary = (state) => state.execution.deliverySummary;
const getClients = (state) => mapKeys(state.account.clients, 'id');
const getVendors = (state) => mapKeys(state.serviceProvider.listLight, 'id');
const getCustomerWiseSummary = createSelector(
  [
    getActiveView1,
    getFilters1,
    getOrderSummary,
    getDeliverySummary,
    currentCustomerId1,
    getBrowseNodes1,
    getRegions1,
    getClients,
    getVendors,
  ],
  (
    activeView,
    filters,
    _orderData,
    _deliveryData,
    customerId,
    browseNodes,
    states,
    clients,
    vendors
  ) => {
    const orderData = mapItemDistrictData(_orderData, browseNodes, states);
    const deliveryData = mapItemDistrictData(_deliveryData, browseNodes, states);
    const filterData = (data, key, custId) => {
      const result = data.filter((s) => {
        return (
          s[key] === custId &&
          (isEmpty(filters.browseNodeIds)
            ? true
            : indexOf(filters.browseNodeIds, s.browseNodeId) >= 0) &&
          (isEmpty(filters.itemIds) ? true : indexOf(filters.itemIds, s.itemId) >= 0)
        );
      });
      return result;
    };
    const clientsIds = uniq(
      orderData.filter((c) => c.fromCustomerId != customerId).map((d) => d.fromCustomerId)
    );
    const vendorsIds = uniq(
      orderData.filter((c) => c.toCustomerId != customerId).map((d) => d.toCustomerId)
    );

    const clientWiseSummary = clientsIds
      .map((id) => ({
        ...(clients[id] || {}),
        summary: prepareCombinedData(
          [
            ...filterData(orderData, 'fromCustomerId', id),
            ...filterData(deliveryData, 'toCustomerId', id),
          ],
          customerId,
          [],
          activeView
        )[0],
      }))
      .filter((e) => !isEmpty(e.summary));
    const vendorWiseSummary = vendorsIds
      .map((id) => ({
        ...(vendors[id] || {}),
        summary: prepareCombinedData(
          [
            ...filterData(orderData, 'toCustomerId', id),
            ...filterData(deliveryData, 'fromCustomerId', id),
          ],
          customerId,
          [],
          activeView
        )[0],
      }))
      .filter((e) => !isEmpty(e.summary));

    return { clientWiseSummary, vendorWiseSummary };
  }
);

/**
 * mapping of item, category, state district using ids
 * @param data
 * @param browseNodes
 * @param regions
 * @return {Array}
 */
function mapItemDistrictData(data, browseNodes, regions) {
  return map(data, (obj) => ({
    ...obj,
    browseNodeId: browseNodeSelectors.getItemByID(browseNodes, obj.itemId)?.browseNodeId || null,
    categoryName: browseNodeSelectors.getCategoryByItemId(browseNodes, obj.itemId)?.title || null,
    itemName: browseNodeSelectors.getItemByID(browseNodes, obj.itemId)?.name || null,
    stateName: regionSelectors.getStateById(regions, obj.stateId)?.name || null,
    districtName: regionSelectors.getDistrictById(regions, obj.districtId)?.name || null,
  }));
}

/**
 * prepare combined data from order and delivery summary response
 * @param orderSummary
 * @param deliverySummary
 * @return {Array}
 */

function prepareCombinedData(
  summaryData,
  customerId,
  groupKeys = ['itemId', 'stateId', 'districtId'],
  view = 'FY'
) {
  const me = customerId;
  /** chart for direction
   ————————————————————————
   PRO Login =>
   ———————————————————————
   Client Side
      Client Target    => fromCustomerId = clientId, toCustomerId =me   => received
      Client Delivery => fromCustomerId =me, toCustomerId = clientID  => given


   Vendor Side
      WO => fromCustomerId = me , toCustomerId = vendorID. => given.
      DEL => fromCustomerId = vendorId,  toCustomerId = me.  => received

   Internal
      WO	      => fromCustomerID = me,  toCustomerId = null    => given.
      Delivery => fromCustomerID =me, toCustomerId = me  =>  received 
      Self Created => fromCustomerID =null, toCustomerId = me  =>  received

   ————————————————————————
   BRAND Login
   ———————————————————————————————
   WO        =>  fromCustomerId = me, toCustomerId =vendorID   => given
   Delivery  => fromCustomerId =vendor, toCustomerId = me  => received
   MY Target => fromCustomerId = null, toCustomerId =me   => Received
   * @param o
   * @return {string}
   */
  const direction = (o) =>
    o.fromCustomerId === me && o.fromCustomerId != o.toCustomerId ? 'given' : 'received';
  let metrics = [
    'orderQty',
    'orderQtyDue',
    'deliveredQty',
    'inReview',
    'disputed',
    'accepted',
    'selfCreated',
    'unallocated',
    ...sustainabilityAttrs,
  ];
  const aggregate = (a) =>
    Object.fromEntries(metrics.map((metric) => [metric, sumBy(a, metric) || 0]));
  let data = [...summaryData];
  data = data.map((o) => ({ ...o, direction: direction(o) }));
  let grouped = groupBy(data, (x) => JSON.stringify(pick(x, groupKeys)));
  grouped = mapValues(grouped, (a) => groupBy(a, 'direction'));
  let agg = mapValues(grouped, (o) => mapValues(o, aggregate));
  agg = map(agg, (v, k) => ({
    ...JSON.parse(k),
    ...Object.assign(
      {},
      ...Object.keys(v).map((d) => mapKeys(v[d], (v1, k1) => k1 + capitalize(d)))
    ),
  }));
  const _agg = agg.map((d) => ({
    ...d,
    currentOrderQtyGiven: (view === 'FY' ? d.orderQtyGiven : d.orderQtyDueGiven) ?? null,
    currentOrderQtyReceived: (view === 'FY' ? d.orderQtyReceived : d.orderQtyDueReceived) ?? null,
  }));
  return _agg;
}

const getSummaryFacets = createSelector(
  (state) => state,
  (summaryData) => {
    const facetKeys = [
      {
        facetLabel: 'Categories',
        labelKey: 'categoryName',
        valueKey: 'browseNodeId',
        filterKey: 'browseNodeIds',
      },
      {
        facetLabel: 'Materials',
        labelKey: 'itemName',
        valueKey: 'itemId',
        filterKey: 'itemIds',
      },
      {
        facetLabel: 'States',
        labelKey: 'stateName',
        valueKey: 'stateId',
        filterKey: 'stateIds',
      },
      {
        facetLabel: 'Districts',
        labelKey: 'districtName',
        valueKey: 'districtId',
        filterKey: 'districtIds',
      },
    ];
    const options = map(facetKeys, (f) =>
      // {
      //     label: f.facetLabel,
      //     options: compact(uniqBy(summaryData, f.valueKey).map(obj => {
      //         if(obj[f.labelKey]) {
      //             return  {...f ,label: obj[f.labelKey], value: obj[f.valueKey]}
      //         }}))
      // }
      compact(
        uniqBy(summaryData, f.valueKey).map((obj) => {
          if (obj[f.labelKey]) {
            return { ...f, label: obj[f.labelKey], value: obj[f.valueKey] };
          }
        })
      )
    );
    return options?.flat();
  }
);

function displayUnallocatedqty(target, itemId, stateId, districtId) {
  let unAllocatedQty = 0;
  let woItemStatesFilter = target?.workOrderItems?.find((e) => e.itemId === itemId)?.states;
  let stateFilter = [];
  if (stateId) {
    stateFilter = woItemStatesFilter?.find((stateObj) => stateObj.stateId === stateId);
    unAllocatedQty = stateFilter?.totalQty;
    if (districtId) {
      unAllocatedQty = stateFilter?.districts?.find(
        (districtObj) => districtObj.districtId === districtId
      )?.totalQty;
    }
    return unAllocatedQty;
  }
}

function getUnallocated(
  orderSummary,
  currentCustomerId,
  itemId,
  stateId,
  districtId,
  target,
  isBrand
) {
  orderSummary = orderSummary?.filter((os) => os.itemId == itemId);
  if (stateId) {
    orderSummary = orderSummary?.filter((os) => os.stateId == stateId);
  }
  if (districtId) {
    orderSummary = orderSummary?.filter((os) => os.districtId == districtId);
  }
  if (orderSummary?.length == 0) return displayUnallocatedqty(target, itemId, stateId, districtId);
  if (!!orderSummary) orderSummary = prepareCombinedData(orderSummary, currentCustomerId, [])[0];
  return !!orderSummary
    ? isBrand
      ? displayUnallocatedqty(target, itemId, stateId, districtId) - orderSummary.orderQtyGiven || 0
      : (orderSummary.orderQtyReceived || 0) - (orderSummary.orderQtyGiven || 0)
    : 0;
}

export {
  getExecutionSummary,
  getSummaryFacets,
  getDashboardSummary,
  getCustomerWiseSummary,
  getUnallocated,
};
