import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const LoadingButton = (props) => {
  return (
    <>
      <Button
        className={props.className ? props.className : `float-right px-4 my-4`}
        name={props.name}
        id={props.id}
        variant={props.variant}
        disabled={props.disabled}
        type={props.type}
        {...props}>
        {props.loading ? (
          <div>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span> {props.loadingText ? props.loadingText : 'Logging'}</span>
          </div>
        ) : (
          props.children
        )}
      </Button>
    </>
  );
};

export default LoadingButton;
