import { createSlice } from '@reduxjs/toolkit';
import { fetchTarget, createTarget, updateTarget } from './targetThunks';

const initialState = {
  data: {
    fromDate: '',
    toDate: '',
    workOrderItems: [],
    browseNodes: [],
  },
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  fetchError: null,
  updateError: null,
  createError: null,
};

export const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTarget.pending]: (state) => {
      state.isFetching = true;
      state.fetchError = null;
    },
    [fetchTarget.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.fetchError = null;
      state.data = action.payload;
    },
    [fetchTarget.rejected]: (state) => {
      state.isFetching = false;
      state.fetchError = 500;
    },
    [createTarget.pending]: (state) => {
      state.isCreating = true;
      state.createError = null;
    },
    [createTarget.fulfilled]: (state, action) => {
      state.isCreating = false;
      state.createError = null;
      state.data = action.payload?.data;
    },
    [createTarget.rejected]: (state) => {
      state.isCreating = false;
      state.createError = 500;
    },
    [updateTarget.pending]: (state) => {
      state.isUpdating = true;
      state.updateError = null;
    },
    [updateTarget.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.updateError = null;
      state.data = action.payload?.data;
    },
    [updateTarget.rejected]: (state) => {
      state.isUpdating = false;
      state.updateError = 500;
    },
  },
});

export default targetSlice.reducer;
