/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Spinner } from 'react-bootstrap';
import { ReactComponent as BrandIcon } from '../../../assets/img/radio_brand.svg';
import { ReactComponent as ServiceProviderIcon } from '../../../assets/img/radio_service_provider.svg';
import './SignupContent.scss';
import { AuthViews } from '../../../views/Auth/_authView.const';
import classNames from 'classnames';
import RegisteredGstinCard from '../RegisteredGstinCard/RegisteredGstinCard';
import AddContactPersonDetails from '../AddContactPersonDetails/AddContactPersonDetails';
import AddProDetails from '../AddProDetails/AddProDetails';
import Api from '../../../shared/Config/Api';
import ApiEndpoints from '../../../shared/Config/ApiEndpoints.const';

const SignupContent = (props) => {
  const [pan, setPan] = useState(props.data.pan);
  const [regType, setRegType] = useState(props.data.regType);
  const [activeView, setActiveView] = useState(AuthViews.SIGNUP);
  const [data, setData] = useState(props.data);
  const [loading, setLoading] = useState(props.loading);
  const VIEWS = [
    AuthViews.SIGNUP,
    AuthViews.GSTIN_ADDRESS,
    AuthViews.CONTACT_PERSON,
    AuthViews.ADD_PRO_DETAILS,
  ];

  useEffect(() => {
    props.passActiveView(activeView);
  }, [activeView]);
  const setPreviousView = () => {
    const currentIndex = VIEWS.findIndex((form) => form === activeView);
    setActiveView(VIEWS[currentIndex - 1]);
  };

  const setNextView = (formData) => {
    const currentIndex = VIEWS.findIndex((form) => form === activeView);
    const nextView = VIEWS[currentIndex + 1];

    if (nextView === AuthViews.GSTIN_ADDRESS) {
      if (data.pan !== formData.pan || (data.addresses && data.addresses.length <= 0)) {
        getGSTINsByPan(formData);

        return;
      } else {
        setData({
          ...data,
          ...formData,
        });
        setActiveView(nextView);
      }
    } else if (nextView === AuthViews.CONTACT_PERSON) {
      setData({
        ...data,
        addresses: formData,
      });
      setActiveView(nextView);
    } else {
      setData({ ...data, ...formData });
      setActiveView(nextView);
    }
  };

  /**
   * Api call to get GSTIN by pan number
   * @param regType
   * @param pan
   */
  const getGSTINsByPan = ({ regType, pan }) => {
    const tempData = { ...data };
    setLoading(true);
    tempData.regType = regType;
    tempData.pan = pan;
    setData({
      ...data,
      ...tempData,
    });

    Api.get(ApiEndpoints.GET_GSTIN_BY_PAN + pan, { timeout: 30000 }).then(
      (response) => {
        setLoading(false);
        if (response.status === 200) {
          setData({
            ...tempData,
            ...{ addresses: response.data },
          });
          setActiveView(AuthViews.GSTIN_ADDRESS);
        }
        return response;
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const renderActiveView = () => {
    switch (activeView) {
      case AuthViews.SIGNUP:
        return (
          <div className="SingupContent animated fadeIn">
            <div className="pt-5">
              <label className="text-medium">Register as</label>
              <Form>
                <Form.Group md="6" className="select-radio-btn">
                  <Form.Label
                    htmlFor="brand"
                    className={classNames('position-relative text-center', {
                      checked: regType === 'BRAND',
                    })}>
                    <div className="check-bg">
                      <BrandIcon />
                      <Form.Check
                        custom
                        id="brand"
                        label=""
                        type="radio"
                        name="regType"
                        checked={regType === 'BRAND'}
                        onChange={(e) => setRegType(e.target.value)}
                        value="BRAND"
                      />
                    </div>
                    <label className="mt-1">Brand</label>
                  </Form.Label>
                  {/* <Form.Label
                    htmlFor="service_provider"
                    className={classNames('position-relative text-center ', {
                      checked: regType === 'SERVICE_PROVIDER',
                    })}>
                    <div className="check-bg ">
                      <ServiceProviderIcon />
                      <Form.Check
                        custom
                        id="service_provider"
                        label=""
                        type="radio"
                        name="regType"
                        checked={regType === 'SERVICE_PROVIDER'}
                        onChange={(e) => setRegType(e.target.value)}
                        value="SERVICE_PROVIDER"
                      />
                    </div>
                    <label className="mt-1">Service Provider</label>
                  </Form.Label> */}

                  <Form.Control.Feedback type="invalid">
                    Please Select Brand or service
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0">Company PAN number</Form.Label>
                  <Form.Control
                    className="p-0 float-label"
                    type="text"
                    id="panNumber"
                    value={pan}
                    onChange={(event) => setPan(event.target.value)}
                    isInvalid={
                      pan.length > 0 && !pan.match('^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$')
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">Invalid Pan Number</Form.Control.Feedback>
                </Form.Group>
                <Button
                  size="lg"
                  variant="primary float-right px-4 my-4"
                  disabled={
                    loading ||
                    regType.length <= 0 ||
                    !pan.match('^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$')
                  }
                  onClick={() => setNextView({ pan: pan, regType: regType })}>
                  {loading ? (
                    <div>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> Loading</span>
                    </div>
                  ) : (
                    'Next'
                  )}
                </Button>
              </Form>
            </div>
          </div>
        );

      case AuthViews.GSTIN_ADDRESS:
        return (
          <RegisteredGstinCard
            setHeader={(header) => props.setHeader(header)}
            onBack={setPreviousView}
            nextAction={(d) => setNextView(d)}
            {...data}
          />
        );

      case AuthViews.CONTACT_PERSON:
        return (
          <AddContactPersonDetails
            setHeader={(header) => props.setHeader(header)}
            onBack={setPreviousView}
            nextAction={(data) => setNextView(data)}
            {...data}
          />
        );

      case AuthViews.ADD_PRO_DETAILS:
        return (
          <AddProDetails
            setHeader={(header) => props.setHeader(header)}
            loading={props.loading}
            onBack={setPreviousView}
            onRegister={(values) => {
              setData({ ...data, ...values });
              props.onRegister(data);
            }}
            {...data}
          />
        );

      default:
        return <h2>Invalid View</h2>;
    }
  };

  return <>{renderActiveView()}</>;
};

export default SignupContent;
