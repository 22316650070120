const filterBrowseNodesByTarget = (browseNodes, target) => {
  if (browseNodes && target) {
    const nodes = browseNodes.filter((nodeData) => {
      return target.browseNodes.filter((nodeInTarget) => {
        return nodeInTarget.browseNodeId === nodeData.id;
      }).length;
    });
    nodes.forEach((node, index) => {
      nodes[index].items = node?.items.filter((item) => {
        return target.workOrderItems.filter((targetItem) => {
          return targetItem.itemId === item.id && targetItem.qty;
        }).length;
      });
    });
    return nodes;
  } else {
    return [];
  }
};

const getItemByCategoryId = (browseNodes, catId) => {
  const category = browseNodes.find((cat) => cat.id === catId);
  if (category != null || category !== undefined) {
    return category.items;
  }
  return [];
};

const getItemByID = (browseNodes, itemID) => {
  const allItems = [];
  browseNodes.forEach((browseNode) => {
    allItems.push(...browseNode.items);
  });
  const item = allItems.find((item) => item.id === itemID);
  return item;
};

const getCategoryByItemId = (browseNodes, itemId) => {
  const category = browseNodes.find((cat) => cat.items.find((item) => item.id === itemId));
  if (category != null || category !== undefined) {
    return category;
  }
  return {};
};

const getCategoryById = (browseNodes, catId) => {
  return browseNodes.find(({ id }) => id === catId);
};

export default {
  getItemByCategoryId,
  getCategoryByItemId,
  getItemByID,
  filterBrowseNodesByTarget,
  getCategoryById,
};
