import React from 'react';

const TAG = {
  REJECTED: { label: 'rejected', value: 'Failed' },
  INITIATED: { label: 'inprocess', value: 'Processing data' },
  IN_PROCESS: { label: 'inprocess', value: 'Processing data' },
  EXTRACTED: { label: 'inprocess', value: 'Processing data' },
  COMPLETED: { label: 'processed', value: 'Uploaded' },
  UPLOADED: { label: 'processed', value: 'Uploaded' },
  UPLOADING: { label: 'uploading', value: 'Uploading' },
  NA: { label: 'na', value: 'N/A' },
};

export function StatusTag({ status }) {
  return (
    <div className={`tag tag-${TAG?.[status ?? 'NA']?.label}`}>
      <span className={`tag-value value-${TAG?.[status ?? 'NA']?.label}`}>
        {TAG?.[status ?? 'NA']?.value}
      </span>
    </div>
  );
}

export function PillTag({ status }) {
  return (
    <div className="tag tag-pill d-flex justify-content-center align-items-center rounded-pill">
      <div className="tag-pill--prefix" />
      <span>{status}</span>
    </div>
  );
}
