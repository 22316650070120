/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Paper, Grid, Box, Divider } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { isEmpty, sortBy } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useTracking } from '../../../../hooks/hooks';
import GoogleMapReact from 'google-map-react';
import AppModal from '../../../../shared/Components/_App/AppModal';
import { ReactComponent as MarkerIcon } from '../../../../assets/img/_App/marker.svg';
import { getArialDistance, toLocalDate } from '../../../../shared/Utils/Helper';
import TruckTracking from '../../../../shared/Components/_App/GoogleMap/TruckTracking';
import { timeConvert } from '../../../../shared/Utils/Utils';
import { useAsyncEffect } from '../../../../hooks';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ReactComponent as AccordianArrow } from '../../../../assets/img/AccordianArrow.svg';
import { useStyles } from '../../Delivery/DocumentCard.app.styles';

const DeliveryTracking = ({
  id,
  data,
  traces,
  setTraces,
  isUnsubscribed,
  setIsUnsubscribed,
  consentStatus,
  setConsentStatus,
  trackingProgressDetails,
  setTrackingProgressDetails,
}) => {
  const [{ value }, { doFetchTracking }] = useTracking();
  const [activeKey, setActiveKey] = useState('0');
  const [mapVisible, setMapVisible] = useState(false);
  const classes = useStyles();

  const [distance, setDistance] = useState({
    distanceFromPickup: 0,
    distanceFromDrop: 0,
  });

  const apiHasLoaded = useCallback(
    ({ map, maps }) => {
      mapInstance.current = map;
      mapApi.current = maps;

      const flightPath = new mapApi.current.Polyline({
        path: traces,
        geodesic: false,
        strokeColor: 'blue',
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      flightPath.setMap(map);
    },
    [traces]
  );

  useAsyncEffect(async () => {
    const response = await doFetchTracking(id);
    setTrackingProgressDetails({
      createdOn: response?.data?.createdOn,
      consentDate: response?.data?.consentDate,
      unsubscribedAt: response?.data?.unsubscribedAt,
    });
    setConsentStatus(response?.data?.consentStatus);
    if (response?.data?.traces) {
      const tracesResponse = sortBy(JSON.parse(response?.data?.traces));
      setIsUnsubscribed(response?.data?.unsubscribed);
      setTraces([
        {
          lat: data?.pickUpLatitude,
          lng: data?.pickUpLongitude,
          timestamp: '',
        },
        {
          lat: tracesResponse[0]?.latitude,
          lng: tracesResponse[0]?.longitude,
          address: tracesResponse[0]?.address,
          timestamp: tracesResponse[0]?.timestamp,
        },
        {
          lat: tracesResponse[tracesResponse?.length - 1]?.latitude,
          lng: tracesResponse[tracesResponse?.length - 1]?.longitude,
          address: tracesResponse[tracesResponse?.length - 1]?.address,
          timestamp: tracesResponse[tracesResponse?.length - 1]?.timestamp,
        },
        {
          lat: data?.dropOffLatitude,
          lng: data?.dropOffLongitude,
          timestamp: '',
        },
      ]);
    } else {
      setIsUnsubscribed(response?.data?.unsubscribed);
    }
  }, [isUnsubscribed, trackingProgressDetails?.createdOn]);

  useEffect(() => {
    traces[1] && setActiveKey('1');
    const startTime = new Date(traces[2]?.timestamp);
    const lastTime = new Date(traces[1]?.timestamp);
    const timeDiff = lastTime.getTime() - startTime.getTime();

    setDistance({
      distanceFromPickup: getArialDistance(
        { lat: data?.pickUpLatitude, lng: data?.pickUpLongitude },
        { lat: traces[2]?.lat, lng: traces[2]?.lng }
      ),
      distanceFromDrop: getArialDistance(
        { lat: data?.dropOffLatitude, lng: data?.dropOffLongitude },
        {
          lat: traces[2]?.lat,
          lng: traces[2]?.lng,
        }
      ),
      timeDiff,
    });
  }, [traces]);

  const mapInstance = React.useRef();
  const mapApi = React.useRef();

  return (
    <Paper elevation={0}>
      <Accordion
        defaultActiveKey={['0']}
        activeKey={activeKey}
        className="px-2"
        onSelect={(e) => setActiveKey(e)}>
        <Card className={classes.trackingCard}>
          <div className={classes.accordianMain}>
            <Accordion.Toggle as={Card.Header} eventKey="0" className={classes.accordionHeader}>
              <Row>
                <Col sm="10">
                  <p className="font-bold">Tracking Progress</p>
                </Col>
                <Col sm="2">
                  <div>
                    <AccordianArrow
                      className="AccordianArrow"
                      style={{ transform: activeKey === '0' && `rotate(-180deg)` }}
                    />
                  </div>
                </Col>
              </Row>
            </Accordion.Toggle>
          </div>
          <Divider />
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Timeline align="left" style={{ alignItems: 'baseline', padding: 0 }}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={
                        trackingProgressDetails?.createdOn
                          ? { backgroundColor: '#33ab2e' }
                          : { backgroundColor: '#bdbdbd' }
                      }
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{ flex: 'auto' }}>
                    <>
                      <div>
                        <Typography className="mb-2" variant="body1" color="textSecondary">
                          TRACKING INITIATED
                        </Typography>
                      </div>
                      {trackingProgressDetails?.createdOn && (
                        <p>
                          On{' '}
                          {toLocalDate(trackingProgressDetails?.createdOn, 'DD/MM/YYYY, hh:mm a')}
                        </p>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
                <Divider />
                {!consentStatus && isEmpty(traces) && trackingProgressDetails?.unsubscribedAt && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            UNSUBSCRIBED
                          </Typography>
                        </div>
                        {
                          <p>
                            On{' '}
                            {toLocalDate(
                              trackingProgressDetails?.unsubscribedAt,
                              'DD/MM/YYYY, hh:mm a'
                            )}
                          </p>
                        }
                      </>
                    </TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={
                        consentStatus
                          ? { backgroundColor: '#33ab2e' }
                          : { backgroundColor: '#bdbdbd' }
                      }
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{ flex: 'auto' }}>
                    <>
                      <div>
                        <Typography className="mb-2" variant="body1" color="textSecondary">
                          CONSENT ACCEPTED
                        </Typography>
                      </div>
                      {consentStatus && trackingProgressDetails?.consentDate && (
                        <p>
                          On{' '}
                          {toLocalDate(trackingProgressDetails?.consentDate, 'DD/MM/YYYY, hh:mm a')}
                        </p>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
                <Divider />
                {consentStatus && isEmpty(traces) && trackingProgressDetails?.unsubscribedAt && (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            UNSUBSCRIBED
                          </Typography>
                        </div>
                        {
                          <p>
                            On{' '}
                            {toLocalDate(
                              trackingProgressDetails?.unsubscribedAt,
                              'DD/MM/YYYY, hh:mm a'
                            )}
                          </p>
                        }
                      </>
                    </TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={
                        traces[1] ? { backgroundColor: '#33ab2e' } : { backgroundColor: '#bdbdbd' }
                      }
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{ flex: 'auto' }}>
                    <>
                      <div>
                        <Typography className="mb-2" variant="body1" color="textSecondary">
                          TRACKING STARTED
                        </Typography>
                      </div>
                      {traces[1] && (
                        <p>On {toLocalDate(traces[1]?.timestamp, 'DD/MM/YYYY, hh:mm a')}</p>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
                <Divider />

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={
                        isUnsubscribed && !isEmpty(traces)
                          ? { backgroundColor: '#33ab2e' }
                          : { backgroundColor: '#bdbdbd' }
                      }
                    />
                  </TimelineSeparator>
                  <TimelineContent style={{ flex: 'auto' }}>
                    <>
                      <div>
                        <Typography className="mb-2" variant="body1" color="textSecondary">
                          TRACKING COMPLETED
                        </Typography>
                      </div>
                      {trackingProgressDetails?.unsubscribedAt && !isEmpty(traces) && (
                        <p>
                          On{' '}
                          {toLocalDate(
                            trackingProgressDetails?.unsubscribedAt,
                            'DD/MM/YYYY, hh:mm a'
                          )}
                        </p>
                      )}
                    </>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="mb-4">
          <div className={classes.accordianMain}>
            <Accordion.Toggle as={Card.Header} eventKey="1" className={classes.accordionHeader}>
              <Row>
                <Col sm="10">
                  <p className="font-bold">Shipment Progress</p>
                </Col>
                <Col sm="2">
                  <div>
                    <AccordianArrow
                      className="AccordianArrow"
                      style={{ transform: activeKey === '1' && `rotate(-180deg)` }}
                    />
                  </div>
                </Col>
              </Row>
            </Accordion.Toggle>
          </div>
          <Divider />
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {!isEmpty(traces) ? (
                <Timeline align="left" style={{ alignItems: 'baseline', padding: 0 }}>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            SHIPPING FROM
                          </Typography>
                          <Typography color="textSecondary">
                            On {toLocalDate(traces[1]?.timestamp, 'DD/MM/YYYY, hh:mm a')}
                          </Typography>
                        </div>
                        <Grid container>
                          <Grid item>
                            <div style={{ width: '220px' }}>
                              <Box
                                style={{ marginTop: '0px' }}
                                component="div"
                                my={2}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                bgcolor="background.paper">
                                {data?.pickUpAddress || 'NA'}
                              </Box>
                              <Grid container>
                                <Typography
                                  style={{ marginRight: '2px' }}
                                  align="left"
                                  inline
                                  color="primary">
                                  {`${distance.distanceFromPickup} Km `}
                                </Typography>
                                <Typography align="right" inline color="textSecondary">
                                  far from pickup location
                                </Typography>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    </TimelineContent>
                  </TimelineItem>
                  <Divider />

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            TRACKING STARTED
                          </Typography>
                          <Typography color="textSecondary">
                            On {toLocalDate(traces[1]?.timestamp, 'DD/MM/YYYY, hh:mm a')}
                          </Typography>
                        </div>
                        <Grid container>
                          <Grid item>
                            <div style={{ width: '220px' }}>
                              <Box
                                style={{ marginTop: '0px' }}
                                component="div"
                                my={2}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                bgcolor="background.paper">
                                {traces[1]?.address || 'NA'}
                              </Box>
                              <Grid container>
                                <Typography
                                  style={{ marginRight: '2px' }}
                                  align="left"
                                  inline
                                  color="primary">
                                  {`${distance.distanceFromPickup} Km `}
                                </Typography>
                                <Typography align="right" inline color="textSecondary">
                                  far from pickup location
                                </Typography>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    </TimelineContent>
                  </TimelineItem>
                  <Divider />

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            LAST AT
                          </Typography>
                          <Typography color="textSecondary">
                            On {toLocalDate(traces[2]?.timestamp, 'DD/MM/YYYY, hh:mm a')}
                          </Typography>
                        </div>
                        <div style={{ width: '220px' }}>
                          <Box
                            style={{ marginTop: '0px' }}
                            component="div"
                            my={2}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            bgcolor="background.paper">
                            {traces[2]?.address || 'NA'}
                          </Box>
                        </div>
                        <div style={{ height: '30vh', width: '100%', marginTop: '40px' }}>
                          <GoogleMapReact
                            onClick={() => setMapVisible(true)}
                            zoom={11}
                            bootstrapURLKeys={{
                              key: 'AIzaSyBCjZ5nnSS7CkkEE2Dwvkb0uCoCbKzTAY0',
                              libraries: ['places', 'geometry'],
                            }}
                            defaultCenter={{
                              lat: traces[2]?.lat,
                              lng: traces[2]?.lng,
                            }}
                            onGoogleApiLoaded={apiHasLoaded}
                            yesIWantToUseGoogleMapApiInternals>
                            <MarkerIcon lat={traces[0]?.lat} lng={traces[0]?.lng} />
                            <MarkerIcon lat={traces[1]?.lat} lng={traces[1]?.lng} />
                            <MarkerIcon lat={traces[2]?.lat} lng={traces[2]?.lng} />
                            <MarkerIcon lat={traces[3]?.lat} lng={traces[3]?.lng} />
                          </GoogleMapReact>
                        </div>

                        <Grid style={{ marginTop: '8px' }} container>
                          <Typography
                            style={{ marginRight: '2px' }}
                            align="left"
                            inline
                            color="primary">
                            {`${distance.distanceFromDrop} Km `}
                          </Typography>
                          <Typography align="right" inline color="textSecondary">
                            far from drop location
                          </Typography>
                        </Grid>

                        <Typography>
                          Estimated time :{' '}
                          {timeConvert(
                            (
                              ((distance.distanceFromDrop * 1000) /
                                (distance.distanceFromPickup * 1000)) *
                              Math.floor(distance.timeDiff / 60000)
                            ).toFixed(0)
                          )}
                        </Typography>
                        <Divider />
                      </>
                    </TimelineContent>
                  </TimelineItem>
                  <Divider />

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: 'auto' }}>
                      <>
                        <div>
                          <Typography className="mb-2" variant="body1" color="textSecondary">
                            DELIVERY TO
                          </Typography>
                        </div>
                        <Grid container>
                          <Grid item>
                            <div style={{ width: '220px' }}>
                              <Box
                                style={{ marginTop: '0px' }}
                                component="div"
                                my={2}
                                textOverflow="ellipsis"
                                overflow="hidden"
                                bgcolor="background.paper">
                                {data?.dropOffAddress || 'NA'}
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ) : consentStatus && !isUnsubscribed ? (
                <h5 style={{ textAlign: 'center' }}>
                  The driver has accepted to share location. Please wait for some time.
                </h5>
              ) : isEmpty(traces) && isUnsubscribed ? (
                <h5 style={{ textAlign: 'center' }}>No Tracking Availaible</h5>
              ) : (
                <h5 style={{ textAlign: 'center' }}>No Tracking Availaible</h5>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <AppModal visible={mapVisible} setVisible={setMapVisible} title={'Track truck'}>
        <TruckTracking traces={traces} apiHasLoaded={apiHasLoaded} />
      </AppModal>
    </Paper>
  );
};
export default DeliveryTracking;
