import React, { useState } from 'react';
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import DeliverySVDocsDetails from './DeliverySVDocsDetails';
import DeliveryUploadDocs from './DeliveryUploadDocs';
import DeliveryDownloadDocs from './DeliveryDownloadDocs';
import DeliveryTracking from './DeliveryTracking';
import { Grid } from '@material-ui/core';
import AppModal from '../../../../shared/Components/_App/AppModal';
import DocumentTypeModal from './DeliveryUntaggedDocuments/DocumentTypeModal';
import { DeliveryService } from '../../../Delivery/delivery.service';
import { useAsyncEffect } from '../../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  button: {
    '&:focus': {
      outline: 0,
    },
  },
}));

const theme = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        width: 'auto',
        borderRadius: '5px',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'white',
        color: '#313743',
      },
    },

    MuiTab: {
      root: {
        '&:hover': {
          backgroundColor: 'white',
          color: '#313743',
          border: '0px solid white',
        },
        '&$selected': {
          backgroundColor: 'white',
          color: '#313743',
          borderRadius: '5px',
          border: '0px solid white',
        },
        '&:focus': {
          backgroundColor: 'white',
          color: '#313743',
          border: '0px solid white',
        },
        button: {
          '&:focus': {
            outline: 0,
          },
        },
      },
    },
  },
});

const DeliveryDocument = (props) => {
  const { delivery, doFetchDelivery, doUpdateDelivery, isPRO, usersList, maxQty } = {
    ...props,
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedDoc, setSelectedDoc] = useState();
  const [docTagModal, setDocTagModal] = useState(false);
  const [docList, setDocList] = useState([]);
  const [traces, setTraces] = useState([]);

  useAsyncEffect(async () => {
    try {
      const response = await DeliveryService.getDocumentList();
      if (response?.status === 200) {
        setDocList(response?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUploadDocClick = (e) => {
    setDocTagModal(true);
    setSelectedDoc(e);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        className="mb-3"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && children}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const svDocs =
    delivery?.deliveryDocuments?.filter(
      (e) => e.document.type === 'PICKUP' || e.document.type === 'DROP'
    ) || [];

  const docTypeList = [];

  docList.forEach((docList) => {
    if (docList?.id === 'pickupDocs') {
      docList.documents.forEach((finalDocs) => {
        docTypeList.push({ _id: finalDocs.type, name: 'Pickup ' + finalDocs.title });
      });
    } else if (docList.id === 'dropDocs') {
      docList.documents.forEach((finalDocs) => {
        docTypeList.push({
          _id: finalDocs.type,
          name:
            finalDocs.type === 'DROP_INWARD_TYPE' ? finalDocs.title : 'Drop off ' + finalDocs.title,
        });
      });
    }
  });

  return (
    <Box className={classes.root}>
      <Grid item xs={12} md={12} sm={12}>
        <MuiThemeProvider theme={theme}>
          <AppBar
            position="static"
            color="default"
            className="m-3"
            style={{ background: 'eff3f5', color: '#8797ad' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              <Tab className={classes.button} label="Source Details" {...a11yProps(0)} />
              {!isPRO && <Tab className={classes.button} label="Download Docs" {...a11yProps(1)} />}
              <Tab className={classes.button} label="Tracking" {...a11yProps(2)} />
              <Tab className={classes.button} label="Destination Details" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
        </MuiThemeProvider>

        <TabPanel value={value} index={0}>
          {svDocs.length > 0 && (
            <DeliverySVDocsDetails
              delivery={delivery}
              docTypeList={docTypeList}
              documents={svDocs}
              doFetchDelivery={doFetchDelivery}
            />
          )}
          <DeliveryUploadDocs
            docConfigList={docList.find((e) => e.id === 'pickupDocs')?.documents}
            deliveryDocuments={delivery?.deliveryDocuments}
            handleDocClick={handleUploadDocClick}
            delivery={delivery}
            doFetchDelivery={doFetchDelivery}
            onViewClick={() => {}}
            isPRO={isPRO}
            usersList={usersList}
            isWeightDetailSection={true}
            isPickup={true}
            tabName={'SOURCE DETAILS'}
            maxQty={maxQty}
          />
        </TabPanel>
        {!isPRO && (
          <TabPanel value={value} index={1}>
            <DeliveryDownloadDocs
              id={delivery.id}
              docList={docList.find((e) => e.id === 'pickupDocs')?.documents}
              documents={
                delivery?.assignedDeliveries &&
                delivery?.assignedDeliveries[0]?.deliveryDocuments?.filter((e) =>
                  e.document?.type?.includes('PICKUP')
                )
              }
            />
          </TabPanel>
        )}

        <TabPanel value={value} index={!isPRO ? 2 : 1}>
          <DeliveryTracking
            id={delivery?.rootDeliveryId}
            data={delivery?.deliveryDetails}
            traces={traces}
            setTraces={setTraces}
          />
        </TabPanel>
        <TabPanel value={value} index={!isPRO ? 3 : 2}>
          <DeliveryUploadDocs
            docConfigList={docList.find((e) => e.id === 'dropDocs')?.documents}
            deliveryDocuments={delivery?.deliveryDocuments}
            doFetchDelivery={doFetchDelivery}
            handleDocClick={handleUploadDocClick}
            delivery={delivery}
            onViewClick={() => {}}
            isPRO={isPRO}
            usersList={usersList}
            isWeightDetailSection={true}
            isPickup={false}
            tabName={'DESTINATION DETAILS'}
            maxQty={maxQty}
          />
        </TabPanel>
        <AppModal
          title={docTypeList.find((e) => e._id === selectedDoc?.document?.type)?.name}
          visible={docTagModal}
          setVisible={setDocTagModal}>
          <DocumentTypeModal
            docList={delivery?.deliveryDocuments?.filter(
              (e) => e.document.type === selectedDoc?.document.type
            )}
            setSelectedDoc={setSelectedDoc}
            selectedDocument={selectedDoc}
            doFetchDelivery={doFetchDelivery}
            delivery={delivery}
            isView={selectedDoc?.status === 'IN_REVIEW' ? false : true}
            docTypeList={docTypeList}
            setVisible={setDocTagModal}
            doUpdateDelivery={doUpdateDelivery}
            docConfigList={docList.find((e) => e.id === 'pickupDocs')?.documents}
          />
        </AppModal>
      </Grid>
    </Box>
  );
};
export default DeliveryDocument;
