import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../Utils/Helper';
const Quantity = (props) => {
  const { value = 0, prefix, className, postfix, ...rest } = props;
  return (
    <>
      <span
        className={className}
        style={{ display: 'block' }}
        {...rest}
        dangerouslySetInnerHTML={{
          __html: (prefix ?? '') + '' + numberWithCommas(Math.round(value)) + '' + (postfix ?? ''),
        }}></span>
    </>
  );
};

Quantity.propTypes = {
  value: PropTypes.any.isRequired,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
};

export default memo(Quantity);
