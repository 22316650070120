import { useSelector } from 'react-redux';
import { keyBy, flatMap } from 'lodash';
import { deepKeyBy } from '../shared/Utils/Helper';

export const useRegions = () => {
  const states = useSelector(({ region }) => [...region.data]);
  return states;
};

export const useRegionsMap = () => {
  const states = useSelector(({ region }) => [...region.data]);
  const test = deepKeyBy(states, 'id');
  return test;
};

export const useDistrictsById = () => {
  const districts = useDistricts();
  return keyBy(flatMap(districts), 'id');
};

export const useDistricts = () => {
  const states = useSelector(({ region }) => [...region.data]);
  const districts = [];
  states.map((state) => {
    districts.push(state.districts);
  });
  return districts;
};
