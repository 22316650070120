import React, { useEffect } from 'react';
import { Button, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormField from '../../../../shared/Components/_App/FormField';
import Section from '../../../../shared/Components/_App/Section';
import { isEmpty } from 'lodash';
const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    textAlign: 'centre',
    backgroundColor: 'blue',
    position: 'fixed',
    bottom: 0,
    fontSize: 'large',
    fontFamily: 'font-bold',
  },
}));

const DeliveryTransporterForm = (props) => {
  const classes = styles();
  const { transporterForm } = { ...props };

  return (
    <Box>
      <Section>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField
              label="TRANSPORTER"
              value={transporterForm.values.name}
              error={!isEmpty(transporterForm.errors?.name)}
              isValid={!!transporterForm.errors?.name && transporterForm.touched?.name}
              onChange={transporterForm.handleChange('name')}
              onBlur={transporterForm.handleBlur('name')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="CONTACT PERSON"
              value={transporterForm.values.contactPerson}
              error={!isEmpty(transporterForm.errors?.contactPerson)}
              isValid={
                !!transporterForm.errors?.contactPerson && transporterForm.touched?.contactPerson
              }
              onChange={transporterForm.handleChange('contactPerson')}
              onBlur={transporterForm.handleBlur('contactPerson')}
            />
          </Grid>

          <Grid item xs={12}>
            <FormField
              label="MOBILE NUMBER"
              value={transporterForm.values.phoneNumber}
              error={!isEmpty(transporterForm.errors?.phoneNumber)}
              isValid={!!transporterForm.errors?.phoneNumber && transporterForm.touched.phoneNumber}
              onChange={transporterForm.handleChange('phoneNumber')}
              onBlur={transporterForm.handleBlur('phoneNumber')}
            />
          </Grid>
        </Grid>
      </Section>
      <Button
        type="submit"
        disabled={!transporterForm.isValid || !transporterForm.dirty}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => transporterForm.handleSubmit()}>
        {' '}
        Save & Select
      </Button>
    </Box>
  );
};

export default DeliveryTransporterForm;
