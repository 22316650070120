import React, { useEffect, useState } from 'react';
import Section from '../../../../shared/Components/_App/Section';
import FormField from '../../../../shared/Components/_App/FormField';
import SelectDropdown from '../../../../shared/Components/_App/SelectDropdown/SelectDropdown';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import SourceTypeModal from '../../../../shared/Components/_App/Modal/SourceTypeModal';
import DropdownDistrict from '../../../../shared/Components/DropdownDistrict/DropdownDistrict';
import DropdownState from '../../../../shared/Components/DropdownState/DropdownState';
import { useRegions } from '../../../../hooks';
import { useLocations } from '../../../../hooks/hooks';
import { isEmpty } from 'lodash';

const DeliveryLocationsForm = ({
  selectedAddress,
  setSelectedAddress,
  setOpenMap,
  setEditLocationFromMap,
  setEditLocation,
  editLocation,
  addPickupLocation,
  setVisiblePickupLocation,
  formik,
  states,
}) => {
  const [loading, setLoading] = useState(false);
  const pickupLocationForm = useFormik({
    initialValues: {
      name: '',
      sourceType: '',
      flatBuilding: '',
      streetAddress: '',
      zipCode: '',
      city: '',
      state: '',
      cityId: null,
      stateId: null,
      lat: null,
      lng: null,
      region: null,
      customerId: useSelector((state) => state?.account?.user?.profile?.id),
      landmark: '',
      isPickup: true,
    },
    validationSchema: (props) => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          name: Yup.string().required('Source Name is required'),
          sourceType: Yup.string().required('Source Name is required'),
          flatBuilding: Yup.string().required(),
          streetAddress: Yup.string().required('streetAddress is required'),
          zipCode: Yup.string().required('zipCode is required'),
          city: Yup.string().required('city is required'),
          state: Yup.string().required('state is required'),
        });
      });
    },
    onSubmit: (values, actions) => {
      setLoading(true);
      const {
        id,
        name,
        sourceType,
        flatBuilding,
        streetAddress,
        zipCode,
        city,
        state,
        lat,
        lng,
        landmark,
        region,
        isPickup,
        customerId,
      } = values;
      addUpdatePickupLocation({
        id,
        name,
        sourceType,
        flatBuilding,
        streetAddress,
        zipCode,
        city,
        state,
        lat,
        lng,
        landmark,
        region,
        isPickup,
        customerId,
      });
    },
  });

  useEffect(() => {
    if (!editLocation) {
      // pickupLocationForm.resetForm();
    }
  }, [editLocation]);

  const addUpdatePickupLocation = async (payload) => {
    const response = await doaddPickupLocation(payload);
    if (!isEmpty(response)) {
      pickupLocationForm.resetForm();
      setSelectedAddress();
      setEditLocation(false);
      if (payload?.id) {
        const stateDetails = states?.find(
          (data) => data?.name?.trim()?.toUpperCase() === payload?.state?.trim()?.toUpperCase()
        );
        const pickuplocation = {
          sourceName: payload?.name,
          pickUpLocationType: payload?.sourceType,
          pickUpPincode: parseInt(payload?.zipCode),
          pickUpCity: stateDetails?.districts?.find(
            (data) => data?.name?.trim()?.toUpperCase() === payload?.city?.trim()?.toUpperCase()
          )?.id,
          pickUpState: stateDetails?.id,
          pickUpAddress: payload?.streetAddress,
          pickUpLandMark: payload?.landmark,
          pickUpLatitude: payload?.lat,
          pickUpLongitude: payload?.lng,
        };
        formik.setValues({ ...formik.values, ...pickuplocation });
        setVisiblePickupLocation(false);
      }
      setLoading(false);
    } else setLoading(false);
  };

  const [
    {
      fetchPickupLocationError,
      fetchPickupLocationLoading,
      fetchPickupLocationValue,
      fetchDropLocationError,
      fetchDropLocationLoading,
      fetchDropLocationValue,
      addPickupLocationError,
      addPickupLocationLoading,
      addPickupLocationValue,
    },
    dofetchPickupLocation,
    dofetchDropLocation,
    doaddPickupLocation,
  ] = useLocations();

  const [openSourceType, setopenSourceType] = useState(false);
  const [selection, setSelection] = React.useState('');

  useEffect(() => {
    const addr = {
      id: selectedAddress?.id || pickupLocationForm?.values?.id,
      name: selectedAddress?.sourceName || pickupLocationForm?.values?.name,
      sourceType: selectedAddress?.sourceType || pickupLocationForm?.values?.sourceType,
      lat: selectedAddress?.latLng?.lat,
      flatBuilding: selectedAddress?.street,
      lng: selectedAddress?.latLng?.lng,
      cityId: selectedAddress?.city,
      stateId: selectedAddress?.state,
      state: selectedAddress?.state?.name,
      city: selectedAddress?.cityName,
      zipCode: selectedAddress?.zipCode,
      streetAddress: selectedAddress?.street,
    };
    pickupLocationForm.setValues({ ...pickupLocationForm.values, ...addr });
    setSelection(selectedAddress?.sourceType || pickupLocationForm?.values?.sourceType);
  }, [selectedAddress]);
  const updateSelection = (event, value) => {
    setSelection(value);
  };
  const onSelectSourceType = () => {
    pickupLocationForm.setFieldValue('sourceType', selection);
    setopenSourceType(false);
  };
  const handleDistrict = (val) => {
    pickupLocationForm.setFieldValue('cityId', val.id);
    pickupLocationForm.setFieldValue('city', val.name);
  };
  const handleState = (val) => {
    pickupLocationForm.setFieldValue('stateId', val);
    pickupLocationForm.setFieldValue('state', val.name);
  };
  return (
    <Box>
      <Section bodyStyle={{ paddingBottom: 50 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField label="Source Type">
              <SelectDropdown
                onClick={() => setopenSourceType(true)}
                value={pickupLocationForm?.values?.sourceType}
                error={pickupLocationForm.errors?.sourceType ? true : false}
                helperText={pickupLocationForm.errors?.sourceType}
              />
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Source Name"
              helperText={pickupLocationForm.errors?.name}
              error={pickupLocationForm.errors?.name ? true : false}
              value={pickupLocationForm.values.name}
              errorMessage={pickupLocationForm.errors?.name}
              isValid={!pickupLocationForm.errors?.name}
              onChange={pickupLocationForm.handleChange('name')}
              onBlur={pickupLocationForm.handleBlur('name')}
              touched={pickupLocationForm.touched?.name}
            />{' '}
          </Grid>
          <Grid item xs={12}>
            <FormField
              onClick={() => {
                setEditLocationFromMap(true);
                setOpenMap(true);
              }}
              label="Address"
              disabled={true}
              helperText={pickupLocationForm.errors?.streetAddress}
              error={pickupLocationForm.errors?.streetAddress ? true : false}
              value={pickupLocationForm.values.streetAddress}
              errorMessage={pickupLocationForm.errors?.streetAddress}
              isValid={!pickupLocationForm.errors?.streetAddress}
              onChange={pickupLocationForm.handleChange('streetAddress')}
              onBlur={pickupLocationForm.handleBlur('streetAddress')}
              touched={pickupLocationForm.touched?.streetAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="LandMark"
              helperText={pickupLocationForm.errors?.landmark}
              error={pickupLocationForm.errors?.landmark ? true : false}
              value={pickupLocationForm.values.landmark}
              errorMessage={pickupLocationForm.errors?.landmark}
              isValid={!pickupLocationForm.errors?.landmark}
              onChange={pickupLocationForm.handleChange('landmark')}
              onBlur={pickupLocationForm.handleBlur('landmark')}
              touched={pickupLocationForm.touched?.landmark}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormField
              label="Pincode"
              type="number"
              helperText={pickupLocationForm.errors?.zipCode}
              error={pickupLocationForm.errors?.zipCode ? true : false}
              value={pickupLocationForm.values.zipCode}
              errorMessage={pickupLocationForm.errors?.zipCode}
              isValid={!pickupLocationForm.errors?.zipCode}
              onChange={pickupLocationForm.handleChange('zipCode')}
              onBlur={pickupLocationForm.handleBlur('zipCode')}
              touched={pickupLocationForm.touched?.zipCode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField label="City">
              <DropdownDistrict
                name="dropOffState"
                value={pickupLocationForm?.values?.cityId}
                stateId={pickupLocationForm?.values?.stateId?.id}
                onChange={handleDistrict}
                isInvalid={pickupLocationForm?.errors?.city}
              />
            </FormField>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormField label="State">
              <DropdownState
                name="dropOffState"
                value={pickupLocationForm?.values?.stateId?.id}
                onChange={handleState}
                isInvalid={pickupLocationForm?.errors?.state}
              />
            </FormField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormField
              label="Latitude"
              value={pickupLocationForm.values.lat}
              errorMessage={pickupLocationForm.errors?.lat}
              isValid={!pickupLocationForm.errors?.lat}
              onChange={pickupLocationForm.handleChange('lat')}
              onBlur={pickupLocationForm.handleBlur('lat')}
              touched={pickupLocationForm.touched?.lat}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              label="Longitude"
              value={pickupLocationForm.values.lng}
              errorMessage={pickupLocationForm.errors?.lng}
              isValid={!pickupLocationForm.errors?.lng}
              onChange={pickupLocationForm.handleChange('lng')}
              onBlur={pickupLocationForm.handleBlur('lng')}
              touched={pickupLocationForm.touched?.lng}
              disabled
            />
          </Grid>
        </Grid>
      </Section>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        style={{ position: 'fixed', bottom: 0 }}
        disabled={loading}
        onClick={() => pickupLocationForm.handleSubmit()}>
        {addPickupLocation ? 'Save' : 'Save & Select'}
      </Button>
      <SourceTypeModal
        open={openSourceType}
        onSelect={() => onSelectSourceType()}
        onClose={() => setopenSourceType(false)}
        selection={selection}
        setSelection={setSelection}
        updateSelection={updateSelection}
      />
    </Box>
  );
};

export default DeliveryLocationsForm;
