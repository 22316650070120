import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const MaterialTab = ({ selectedStatus, setSelectedStatus, materialList, setMaterialList }) => {
  const handleChange = (event, idx) => {
    const clone = materialList.map((select, id) => {
      if (idx === id) return { ...select, select: !select.select };
      else return { ...select, select: false };
    });
    setMaterialList(clone);
    setSelectedStatus({
      ...selectedStatus,
      material: clone.find((status) => status.select === true),
    });
  };
  useEffect(() => {
    const clone = materialList.map((select, id) => {
      if (select.itemId === selectedStatus?.material?.itemId) return { ...select, select: true };
      else return { ...select, select: false };
    });
    setMaterialList(clone);
  }, []);
  return (
    <>
      {materialList?.map((material, idx) => (
        <div>
          <FormControlLabel
            control={<GreenCheckbox name={material?.itemName} checked={material?.select} />}
            label={material?.itemName}
            onChange={(e) => handleChange(e, idx)}
          />
        </div>
      ))}
    </>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: '#8797ad',
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default MaterialTab;
