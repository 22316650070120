import { reportsAPI } from './reportsAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchMonthlyFulFillment = createAsyncThunk(
  'report/fetchMonthlyFulFillment',
  async (queryParams) => {
    const response = await reportsAPI.fetchMonthlyFulFillment(queryParams);
    return response;
  }
);
