import React, { useEffect, useState } from 'react';
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { zip, omit, isEmpty, mapValues, get, mapKeys } from 'lodash';
import { useRegionsMap } from '../../../hooks';
import { toCapitalize, toLocalDate } from '../../../shared/Utils/Helper';
import { useSelector } from 'react-redux';

function flattenObj(obj, parent, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

function WorkOrderChangeLog({ logs, userList, browseNodes, workOrder }) {
  // const serviceProviders = useServiceProviders(true);
  const { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));

  let regionsMap = useRegionsMap();
  if (!logs?.length) return <></>;

  const diffs = zip([null, ...logs], logs)
    .filter(([prev, current]) => !!prev && !!current)
    .map(([prev, current]) => {
      let prevObj = prev && JSON.parse(prev.json);
      let currentObj = JSON.parse(current.json);
      [prevObj, currentObj] = [prevObj, currentObj].map(
        (wo) =>
          wo && {
            'Service Provider':
              serviceProviders.find((e) => e.id === Number(wo?.toCustomerId))?.name || 'NA',
            'Start Date': toLocalDate(wo?.fromDate),
            'Estimated Delivery Date': toLocalDate(wo.toDate),
            Status: toCapitalize(wo.status),
            Item: wo.workOrderItems.map((woi) => ({
              Material: woi?.itemName,
              State: regionsMap[woi?.stateId]?.name || 'NA',
              District: regionsMap[woi?.stateId]?.districts[woi?.districtId]?.name || 'NA',
              Target: woi?.qty,
              Rate: woi?.pricePerUnit,
              'Fulfilment Cycle': toCapitalize(woi?.fulfillmentCycle),
              'Delivery Date': toLocalDate(woi?.estimatedDelivery),
              'fulfilment plan':
                !isEmpty(woi?.fulfilmentPlan) &&
                mapKeys(woi?.fulfilmentPlan, function (value, key) {
                  return key ? toLocalDate(key) : '';
                }),
            })),

            'PO Number': wo?.poNumber,
            'Entity Type': wo?.entityType,
            'Customer Type': wo?.customerType,
            'Payment Term': wo?.paymentTerm,
            'Traceability Documents': wo?.traceabilityDocuments === true ? 'Yes' : 'No',
          }
      );
      let diff = prevObj && detailedDiff(currentObj, prevObj);
      if (diff) {
        diff.updated = mapValues(flattenObj(diff.updated), (v, k) => {
          return `${get(currentObj, k)} => ${v}`;
        });
        diff.deleted = Object.keys(flattenObj(diff.deleted));
      }
      return {
        ...omit(prev, 'json'),
        diff,
      };
    });

  const displayDifference = (diffObj) => {
    return (
      <>
        {diffObj?.updated && Object.getOwnPropertyNames(diffObj?.updated).length > 0 && (
          <>
            <p>
              <b>Updated</b>
            </p>
            {Object.keys(diffObj?.updated).map((key, i) => {
              const woItemIndex = parseInt(key.match(/^Item\.(\d+)\./)?.[1], 10);
              const woItem = workOrder?.workOrderItems[woItemIndex];
              const ItemName = woItem?.itemName;
              return (
                <p key={i}>
                  <li>
                    {key.replace(
                      /^Item\.\d+\./,
                      `${
                        woItem
                          ? `${
                              browseNodes.find((node) => node?.id === woItem?.browseNodeId)?.title
                            }, ${ItemName} ${
                              woItem?.stateId && `, ${regionsMap[woItem?.stateId]?.name}`
                            } ${
                              woItem?.districtId &&
                              `, ${
                                regionsMap[woItem?.stateId]?.districts[woItem?.districtId]?.name
                              }`
                            }`
                          : ''
                      }.`
                    )}
                    :{diffObj.updated[key]}
                  </li>
                </p>
              );
            })}
            {''}
          </>
        )}
        {diffObj?.added && Object.getOwnPropertyNames(diffObj?.added).length > 0 && (
          <>
            <p>
              <b>Added</b>
            </p>
            {Object.keys(diffObj?.added).map((key1, j) => (
              <p key={j}>
                {Object.keys(diffObj.added[key1]).map((obj, l) => (
                  <>
                    <span>Item {obj}</span>
                    <pre>{JSON.stringify(diffObj.added[key1][obj], null, 2).replace(/"/g, '')}</pre>
                  </>
                ))}
              </p>
            ))}
          </>
        )}
        {diffObj?.deleted && diffObj?.deleted.length > 0 && (
          <>
            <p>
              <b>Deleted</b>
            </p>
            {diffObj?.deleted.map((delKey, k) => {
              const woItemIndex = parseInt(delKey.match(/^Item\.(\d+)\./)?.[1], 10);
              const woItem = workOrder?.workOrderItems[woItemIndex];
              const ItemName = woItem?.itemName;
              return (
                <p key={k}>
                  <li>
                    {delKey.replace(
                      /^Item\.\d+\./,
                      `${
                        woItem
                          ? `${
                              browseNodes.find((node) => node?.id === woItem?.browseNodeId)?.title
                            }, ${ItemName} ${
                              woItem?.stateId && `, ${regionsMap[woItem?.stateId]?.name}`
                            } ${
                              woItem?.districtId &&
                              `, ${
                                regionsMap[woItem?.stateId]?.districts[woItem?.districtId]?.name
                              }`
                            }`
                          : ''
                      }.`
                    )}
                  </li>
                </p>
              );
            })}{' '}
          </>
        )}
      </>
    );
  };
  return (
    !isEmpty(diffs) && (
      <table border={1} cellPadding={10}>
        <thead style={{ color: '#000000', backgroundColor: '#f5f5f5' }}>
          <tr>
            <td>Date/Time</td>
            <td>User</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {diffs.map((change) => (
            <tr>
              <td>{toLocalDate(change.changedOn, 'DD-MMM-YYYY,h:mm a') || 'NA'}</td>
              <td>
                {userList?.some((user) => user?.id === change?.changedBy)
                  ? userList?.find((user) => user?.id === change?.changedBy)?.name
                  : serviceProviders?.some((user) => user?.id === change?.customerId)
                  ? serviceProviders?.find((user) => user?.id === change?.customerId)?.name
                  : clients?.find((user) => user?.id === change?.customerId)?.name ||
                    change?.changedByUserName}
              </td>
              <td>{toCapitalize(change.status)}</td>
              <td>{change.diff ? displayDifference(change.diff) : 'NA'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  );
}

export default WorkOrderChangeLog;
