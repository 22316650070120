import { createSlice } from '@reduxjs/toolkit';
import { fetchRegionStates } from './regionThunks';

const anyState = {
  id: 0,
  name: 'Any',
  code: 'Any',
  countryId: 1,
  gstinRegistered: false,
  districts: [],
};

const initialState = {
  data: [],
  isFetching: false,
  isUpdating: false,
  isCreating: false,
  fetchError: null,
  updateError: null,
  createError: null,
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRegionStates.pending]: (state) => {
      state.isFetching = true;
      state.fetchError = null;
    },
    [fetchRegionStates.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.fetchError = null;
      state.data = [anyState, ...action.payload];
    },
    [fetchRegionStates.rejected]: (state) => {
      state.isFetching = false;
      // state.fetchError = action.payload;
      state.fetchError = 500;
    },
  },
});

// export const {} = regionSlice.actions;

export default regionSlice.reducer;
