import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Divider, FormControlLabel, Paper, RadioGroup } from '@material-ui/core';
import StyledRadio from '../StyledRadio/StyledRadio';

import { useStyles } from './SortByModel.styles';
import { LOCATION_TYPE_LIST_NON_RECYCLABLE_PLASTIC } from '../../../Constants/Constants';

const SourceTypeModal = ({ open, onSelect, onClose, selection, setSelection, updateSelection }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <h3 id="transition-modal-title">Source Type</h3>
          <RadioGroup
            defaultValue="Latest on Top"
            aria-label="gender"
            name="customized-radios"
            value={selection}
            onChange={updateSelection}>
            {LOCATION_TYPE_LIST_NON_RECYCLABLE_PLASTIC?.map((label) => (
              <FormControlLabel
                value={label?.id}
                key={label?.id}
                control={<StyledRadio />}
                label={label?.id}
              />
            ))}
          </RadioGroup>
          <Divider className={classes.horizontalDivider} />
          <div className={classes.buttonGroup}>
            <Button className={classes.closeButton} onClick={onClose}>
              Close
            </Button>
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Button className={`${classes.closeButton} ${classes.selectButton}`} onClick={onSelect}>
              Select
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SourceTypeModal;
