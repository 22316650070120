import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as MarkerIcon } from '../../../../assets/img/_App/marker.svg';
import { Button, IconButton, InputBase, Paper, Typography } from '@material-ui/core';
import FormLabel from '../../../../shared/Components/_App/FormLabel';
import { useStyles } from './Map.styles';
import GooglePlaceAutocomplete from '../../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import { useRegions } from '../../../../hooks/useRegions';

const Map = ({
  onConfirmLocation,
  selectedAddress,
  setSelectedAddress,
  openMapModel,
  editLocationFromMap,
  ulb,
}) => {
  const classes = useStyles();
  const regions = useRegions();
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState();
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState(15);
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [search, setSearch] = useState();

  const setCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLng(position.coords.latitude, position.coords.longitude);
      });
    }
  };

  const setLatLng = (lat, lng) => {
    setCenter([lat, lng]);
    setLat(lat);
    setLng(lng);
  };
  useEffect(() => {
    if (!editLocationFromMap) {
      setSelectedAddress();
      setCurrentLocation();
      if (mapApi) {
        _generateAddress();
      }
    } else {
      setLatLng(selectedAddress?.latLng?.lat, selectedAddress?.latLng?.lng);
    }
  }, [mapApi, openMapModel]);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setLatLng(mouse.lat, mouse.lng);
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
    _generateAddress();
  };

  const _onChange = ({ center, zoom }) => {
    setCenter(center);
    setZoom(zoom);
  };

  const _onClick = (value) => {
    setLatLng(value.lat, value.lng);
    _generateAddress(value.lat, value.lng);
  };
  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  };

  const getCityState = (cityState) => {
    const state = regions?.find(
      (value) => value?.name?.toLowerCase().trim() === cityState?.stateName?.toLowerCase().trim()
    );
    const city = state?.districts.find(
      (value) => value?.name?.toLowerCase().trim() === cityState?.cityName?.toLowerCase().trim()
    );
    return { state, city };
  };

  const _generateAddress = (latt, lngg) => {
    if (mapApi) {
      const geocoder = new mapApi.Geocoder();

      geocoder.geocode(
        { location: { lat: latt ? latt : lat, lng: lngg ? lngg : lng } },
        (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const addr = {
                city: null,
                cityName: null,
                latLng: { lat: null, lng: null },
                sourceName: null,
                sourceType: null,
                state: null,
                street: null,
                zipCode: null,
              };
              const cityState = { cityName: null, stateName: null };
              addr.latLng.lat = lat;
              addr.latLng.lng = lng;
              results[0].address_components.forEach((item) => {
                if (item.types.includes('postal_code')) {
                  addr.zipCode = item.long_name;
                }
                if (item.types.includes('locality')) {
                  cityState.cityName = item.long_name;
                }
                if (item.types.includes('administrative_area_level_1')) {
                  cityState.stateName = item.long_name;
                }
              });
              addr.street = results[0]?.formatted_address;
              const cityStateGet = getCityState(cityState);
              addr.city = cityStateGet?.city?.id;
              addr.state = cityStateGet?.state;
              addr.cityName = cityStateGet?.city?.name;

              setZoom(15);
              setSelectedAddress(addr);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        }
      );
    }
  };

  const handleAddressSelect = (val) => {
    setLatLng(val.latLng.lat, val.latLng.lng);
    setSelectedAddress(val);
  };
  const createMapOptions = (map) => {
    return {
      zoomControl: false,
      fullscreenControl: false,
    };
  };

  return (
    <>
      {mapApiLoaded && (
        <div className={classes.searchBox}>
          <GooglePlaceAutocomplete
            value={search}
            handleChange={(val) => setSearch(val)}
            placeholder={ulb ? 'Search Location' : 'Search Pickup Location'}
            handleSelect={handleAddressSelect}
          />
        </div>
      )}
      <div className={classes.mapHeight}>
        <GoogleMapReact
          center={center}
          zoom={zoom}
          options={createMapOptions}
          draggable={draggable}
          onChange={_onChange}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionMouseUp}
          onChildMouseMove={onMarkerInteraction}
          onChildClick={() => console.log('child click')}
          onClick={_onClick}
          bootstrapURLKeys={{
            key: 'AIzaSyBCjZ5nnSS7CkkEE2Dwvkb0uCoCbKzTAY0',
            libraries: ['places', 'geometry'],
          }}
          // yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}>
          <MarkerIcon lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
      {selectedAddress && (
        <div className={classes.bottomCard}>
          <Paper elevation={0} className={classes.locationCard}>
            <FormLabel label="SELECT PICKUP LOCATION" />
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <MarkerIcon />
              <div className={classes.address}>
                <Typography variant="subtitle1">
                  {selectedAddress?.city?.formattedSuggestion?.mainText}
                </Typography>
                <p>{selectedAddress?.street}</p>
              </div>
            </div>
            <Button
              fullWidth
              disabled={!selectedAddress}
              variant="contained"
              color="primary"
              onClick={() => onConfirmLocation(selectedAddress)}>
              Confirm Location
            </Button>
          </Paper>
        </div>
      )}
    </>
  );
};

export default Map;
