import React, { useState } from 'react';
import PlanningCard from '../PlanningCard';
import PlanningHeader from '../PlanningHeader';
import MappingAccordian from '../MappingAccordian';
import { groupBy, sumBy, uniq } from 'lodash';
import {
  getCurrentYearStartEndDates,
  getNextYearStartEndDates,
} from '../../../shared/Utils/Helper';
import { Row, Col } from 'react-bootstrap';
import { useAsyncEffect } from '../../../hooks';
import { fetchMarketPlaceDeliveries } from './service';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import moment from 'moment';
import { useFormik } from 'formik';
import { convertToMt } from '../../../shared/Utils/Helper';
import { Loader } from '../../../shared/Components';
import { DELIVERY_STATUS } from '../../../shared/Constants/Constants';
import { getDisplayStatus } from '../../../shared/Utils/Utils';

export default function AvailableCreditsDetails() {
  const { currentYear, selectedFinancialYear } = useFinancialYear();
  const searchParams = new URLSearchParams(window.location.search);

  const [params, setParams] = useState({
    month: searchParams.get('month'),
    isMarketPlace: searchParams.get('isMarketPlace'),
  });
  const [mpDeliveries, setMpDeliveries] = useState([]);
  const [groupedByStateItem, setGroupedByStateItem] = useState([]);
  const [availableCreditDates, setAvailableCreditDates] = useState();
  const [apiCalled, setApiCalled] = useState(0);
  const [fetching, setFetching] = useState(true);

  useAsyncEffect(async () => {
    const deliveryParams = {
      dropOffUnloadDateFrom: datesValue()?.fromDate,
      dropOffUnloadDateTo: datesValue()?.toDate,
      direction: 'RECEIVED',
      'isMarketplaceOrder.equals': true,
      sort: 'createdOn,desc',
      fetchAll: true,
      'assignedToWorkOrder.equals': false,
      status: 'APPROVED',
    };
    const response = await fetchMarketPlaceDeliveries(deliveryParams);

    setMpDeliveries(response?.data?.received);
    setFetching(false);

    const groupedByStateAndItem = [];
    let id = 0;

    /**
     * To get the deliveries for which the WO has to be created
     */
    const deliveriesTogenerateWo = response?.data?.received.filter(
      (data) => !data?.assignedToWoItem
    );

    // const groupedByItemId = groupBy(response?.data?.received, 'itemId');
    const groupedByItemId = groupBy(response?.data?.received, 'itemId');

    for (let item of Object.keys(groupedByItemId)) {
      const groupedByStateId = groupBy(groupedByItemId[item], 'stateId');
      for (let state of Object.keys(groupedByStateId)) {
        const groupedByRecyclerId = groupBy(groupedByStateId[state], 'recyclerCustomerId');
        groupedByStateAndItem.push({
          itemId: item,
          itemName: groupedByStateId[state]?.[0]?.item,
          stateId: state,
          stateName: groupedByStateId[state]?.[0]?.state,
          itemList: groupedByStateId[state],
          recyclerList: Object?.values(groupedByRecyclerId)?.map((recyclerData, index) => ({
            accordionId: index,
            recyclerData: recyclerData,
            allocateToBrandData: recyclerData?.filter((data) => data?.assignedToWoItem),
            generateWoData: recyclerData
              ?.filter((data) => !data?.assignedToWoItem)
              .map((d) => {
                return { ...d, deliveryStatus: DELIVERY_STATUS[getDisplayStatus(d)]?.displayText };
              }),
          })),
          id: (id += 1),
        });
      }
    }
    setGroupedByStateItem(groupedByStateAndItem);
  }, [apiCalled]);

  const workOrderForm = useFormik(
    {
      initialValues: {
        deliveriesData: mpDeliveries,
        groupedByStateItemDeliveries: groupedByStateItem,
        selectedDeliveriesData: [],
        selectedClientData: [],
        demandData: [],
      },
    },
    [mpDeliveries]
  );

  const datesValue = () => {
    let dateUTC = {};
    if (Number(params.month) < 3) {
      dateUTC = getNextYearStartEndDates(selectedFinancialYear, params.month);
    } else if (params.month === 'all') {
      dateUTC = selectedFinancialYear;
    } else {
      dateUTC = getCurrentYearStartEndDates(selectedFinancialYear, params.month);
    }
    setAvailableCreditDates(dateUTC);
    return dateUTC;
  };

  const totalunallocatedQty = convertToMt(
    sumBy(mpDeliveries.map((delivery) => delivery?.unallocatedQty)),
    2
  );

  const totalQty = () => {
    const toGenerateWoDeliveries = mpDeliveries.filter(
      (delivery) => !('assignedToWoItem' in delivery)
    );
    const qty = convertToMt(sumBy(toGenerateWoDeliveries.map((delivery) => delivery?.qty)), 2);
    return qty;
  };

  const getUniqueItemDetails = () => {
    const toGenerateWoDeliveries = mpDeliveries.filter(
      (delivery) => !('assignedToWoItem' in delivery)
    );
    const itemCategories = uniq(toGenerateWoDeliveries.map((delivery) => delivery?.item));
    const itemWiseDetails = itemCategories.map((item) => {
      const itemDeliveries = toGenerateWoDeliveries.filter((delivery) => delivery?.item === item);
      const itemQty = convertToMt(
        sumBy(itemDeliveries.map((itemSpecificDlvry) => itemSpecificDlvry?.qty)),
        2
      );
      return {
        item: item,
        quantity: itemQty,
        noOfDeliveries: itemDeliveries.length,
      };
    });

    return itemWiseDetails;
  };

  const totalVendors = () => {
    let count = 0;
    groupedByStateItem.forEach((stateAndItemObj) => {
      const generateWoCount = stateAndItemObj?.recyclerList.filter(
        (vendor) => vendor?.generateWoData.length > 0
      );
      count = count + generateWoCount.length;
    });
    return count;
  };

  return (
    <div>
      <PlanningHeader
        label="Marketplace Credits"
        isBackNavigation={true}
        params={params}
        isCloseButton={true}>
        <p className="mpCreditsLabel">
          Marketplace Credits:
          <span class="text-style-1">
            {params?.month == 'all' ? (
              <>Total Credits</>
            ) : (
              moment((Number(params.month) + 1).toString()).format('MMMM')
            )}
          </span>
          <span class="text-style-2">({totalQty()} MT)</span>
        </p>
      </PlanningHeader>
      {fetching ? (
        <Loader />
      ) : (
        <>
          <Row>
            {getUniqueItemDetails()?.map((item) => {
              return (
                <Col xs="3" sm="3" md="3" lg="3">
                  <PlanningCard
                    label={item?.item}
                    value={item?.quantity}
                    count={item?.noOfDeliveries}
                    params={params}
                    isMpCard={true}
                    unit={'MT'}
                  />
                </Col>
              );
            })}
          </Row>

          <MappingAccordian
            deliveriesData={groupedByStateItem}
            availableCreditDates={availableCreditDates}
            params={params}
            totalVendors={totalVendors}
            workOrderForm={workOrderForm}
            setApiCalled={setApiCalled}
          />
        </>
      )}
    </div>
  );
}
