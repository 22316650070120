import React, { useState, useEffect } from 'react';
import { InputBase, Grid, ListItem, ListItemText, Paper, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useHistory } from 'react-router-dom';
import { useStyles } from './DeliveryPage.app.styles';
import { DeliveryStatus, Region } from '../../../shared/Components/GenericComponent';
import { Container } from '@material-ui/core';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { useDelivery, useLocations } from '../../../hooks/hooks';
import { getArialDistance, toLocalDate } from '../../../shared/Utils/Helper';
import { useAsyncEffect } from '../../../hooks';
import PageView from '../../../shared/Components/_App/PageView';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import { DELIVERY_STATUS } from '../../../shared/Constants/Constants';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { isEmpty } from 'lodash';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const IntransitDeliveryPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState();
  const { selectedFinancialYear } = useFinancialYear();
  const [deliverylist, setDeliveryList] = useState([]);
  const [masterdeliverylist, setMasterDeliveryList] = useState([]);
  const [deliveryLoading, setDeliveryLoading] = useState(false);

  useAsyncEffect(async () => {
    setDeliveryLoading(true);
    const response = await DeliveryService.fetchDeliveries({
      deliveryDateFrom: selectedFinancialYear?.fromDate,
      deliveryDateTo: selectedFinancialYear?.toDate,
      sort: 'createdOn,desc',
      direction: 'RECEIVED',
      itemIds: appMaterialItems,
      size: 1000,
      assignedStatus: 'IN_TRANSIT',
    });
    if (response?.deliveries) {
      setDeliveryList(response?.deliveries?.received);
      setMasterDeliveryList(response?.deliveries?.received);
      setDeliveryLoading(false);
    }
  }, []);

  const handleClick = (e) => {
    history.push(`/delivery-view/${e.id}`);
  };
  const fromTabView = props.location.pathname?.includes('/tabs/');
  const getDistance = (del) => {
    const {
      pickUpLatitude,
      pickUpLongitude,
      dropOffLatitude,
      dropOffLongitude,
    } = del?.deliveryDetails;
    return getArialDistance(
      { lat: pickUpLatitude, lng: pickUpLongitude },
      { lat: dropOffLatitude, lng: dropOffLongitude }
    );
  };

  function checkString(text) {
    var re = /^[A-Za-z]+$/;
    if (re.test(text)) return true;
    else return false;
  }

  const filterDelivery = (txt) => {
    const searchText = txt.toUpperCase();
    if (searchText.length > 3) {
      const newData = masterdeliverylist.filter((e) => {
        if (!checkString(searchText)) {
          return e.id.toUpperCase().indexOf(searchText) > -1;
        }
        return (
          e.district.toUpperCase().indexOf(searchText) > -1 ||
          e?.state?.toUpperCase().indexOf(searchText) > -1
        );
      });
      setDeliveryList(newData);
    } else {
      setDeliveryList(masterdeliverylist);
    }
  };

  return (
    <Container>
      <PageView loading={deliveryLoading} hasNoData={isEmpty(deliverylist)}>
        <Grid item xs={12} md={12} sm={6}>
          <Paper className={classes.root} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder="Search open deliveries"
              inputProps={{ 'aria-label': 'search open deliveries' }}
              onChange={(e) => filterDelivery(e.target.value)}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* <List> */}
        <Grid
          className={classes.demo}
          style={{ paddingBottom: fromTabView ? '150px' : 0 }}
          spacing={1}>
          {!deliveryLoading && deliverylist?.length === 0 ? (
            <Paper>
              <EmptyView
                hasNoData={isEmpty(deliverylist)}
                className={'p-5'}
                message={'No data for selected criteria'}
              />
            </Paper>
          ) : (
            deliverylist?.map((e) => (
              <Grid key={e.id} className={classes.greyBg} item xs={12} md={6} sm={6}>
                <ListItem className={classes.whiteBg} button onClick={() => handleClick(e)}>
                  <Grid container>
                    <ListItemText primary={e.id} secondary={toLocalDate(e.deliveryDate)} />
                  </Grid>
                  <Grid container>
                    <ListItemText
                      primary={`${Number(e?.qty) / 1000} MT's`}
                      secondary={
                        <DeliveryStatus
                          status={e.assignedDeliveries && e.assignedDeliveries[0]?.status}
                        />
                      }
                    />
                  </Grid>
                  <Grid container>
                    <ListItemText
                      primary={getDistance(e) + ' Km'}
                      secondary={<Region stateId={e?.stateId} districtId={e?.districtId} />}
                    />
                  </Grid>
                </ListItem>
              </Grid>
            ))
          )}
        </Grid>
      </PageView>
    </Container>
  );
};

export default IntransitDeliveryPage;
