import React, { useState } from 'react';
import axios from 'axios';
import Api from '../shared/Config/Api';
import { Paper, Button, makeStyles } from '@material-ui/core';
import ApiEndpoints from '../shared/Config/ApiEndpoints.const';
import { useDispatch } from 'react-redux';
import { groupBy, isEmpty, sortBy } from 'lodash';
import './Auth/Auth.scss';
import { DropdownSelect } from '../shared/Components';
import { useHistory } from 'react-router-dom';
import { useAsyncEffect } from '../hooks';
import { ChatService } from '../shared/Services/ChatService';
import PushNotification from '../shared/Config/push-notification';
import AppAnalytics from '../shared/Config/AppAnalytics';
import { getAccount } from '../state/Account/accountThunks';

const useStyles = makeStyles({
  paperContainer: {
    height: '400px',
  },
  headingStyle: {
    textAlign: 'center',
    paddingTop: '30px',
  },
  paragraphStyle: {
    fontSize: '0.84rem',
    fontFamily: 'font-medium',
    lineHeight: '1.5rem',
    letterSpacing: '1.8px',
    color: '#8797ad',
  },
  bootomContainerStyle: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  buttonContainerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
  },
  logoutButtonStyle: {
    fontSize: '15px',
    fontWeight: '300',
    marginLeft: '10px',
  },
  goButtonStyle: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginRight: '10px',
    color: '#fff',
    backgroundColor: '#005dff',
  },
});

function AppSelectCustomer(props) {
  const classes = useStyles();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(null);

  let dispatch = useDispatch();

  useAsyncEffect(async () => {
    const result = await Api.get(ApiEndpoints.GET_EPR_CUSTOMERS, null, {
      'eprCustomers.equals': 1,
      size: 1000,
    });
    let customers = result.data;
    customers = customers?.filter((data) => data?.appActor === 'SERVICE_PROVIDER');
    customers = sortBy(customers, 'lastName');
    setCustomers(customers);
    groupBy(customers, 'appActor');
  }, []);

  const go = async () => {
    setCustomerId(null);
    const query = { customerId: customerId };
    const result = await Api.get(ApiEndpoints.GET_CUSTOMER_SESSION, null, query);
    localStorage.setItem('authenticate_token', result.data.id_token);
    dispatch(getAccount());
    history.push('/tabs/dashboard');
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
      AppAnalytics.clearUserProperties();
      await PushNotification.deleteToken();
      await ChatService().clearUserData();
      history.push('/login');
    } catch (e) {
    } finally {
      window.location.reload();
    }
  };

  return (
    <>
      <Paper className={classes.paperContainer}>
        <h3 className={classes.headingStyle}>You logged in as Rakesh !</h3>
        <p className={classes.paragraphStyle} style={{ textAlign: 'center' }}>
          Select Service provider to proceed
        </p>
        <div className={classes.bootomContainerStyle}>
          <p className={classes.paragraphStyle}>SERVICE PROVIDER</p>
          <DropdownSelect
            value={customers?.find((data) => data?.id === customerId)}
            isSearchable={true}
            isClearable={true}
            onChange={(serviceProvider) => setCustomerId(serviceProvider?.id)}
            options={customers}
            noOptionsMessage={() =>
              isEmpty(customers) ? 'loading...' : 'Service provider not found!'
            }
            getOptionLabel={(option) => option?.lastName}
            getOptionValue={(option) => `${option?.id}`}
          />
        </div>
        <div className={classes.buttonContainerStyle}>
          <Button variant="contained" className={classes.logoutButtonStyle} onClick={handleLogout}>
            Logout
          </Button>
          <Button
            variant="contained"
            className={classes.goButtonStyle}
            disabled={customerId === null}
            onClick={go}>
            GO
          </Button>
        </div>
      </Paper>
    </>
  );
}

export default AppSelectCustomer;
