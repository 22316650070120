import { createSlice } from '@reduxjs/toolkit';
import { fetchServiceProviders } from './serviceProviderThunks';

const initialState = { listLight: [], isFetchingListLight: false, fetchListLightError: null };

export const serviceProviderSlice = createSlice({
  name: 'serviceProvider',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchServiceProviders.pending]: (state) => {
      state.isFetchingListLight = true;
      state.fetchListLightError = null;
    },
    [fetchServiceProviders.fulfilled]: (state, action) => {
      state.isFetchingListLight = false;
      state.fetchListLightError = null;
      state.listLight = action.payload;
    },
    [fetchServiceProviders.rejected]: (state) => {
      state.isFetchingListLight = false;
      state.fetchListLightError = 500;
    },
  },
});

export default serviceProviderSlice.reducer;
