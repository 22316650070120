import React, { Suspense, useState, useEffect } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../shared/Components/Loader/Loader';
import './Target.scss';
import { targetSelectors } from '../../state/Target';
import { TargetUtils } from '../../features/Target/TargetUtils';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { keyBy, pick, isEmpty } from 'lodash';
import { BulkWorkOrder } from '../WorkOrder/WorkOrderCreate/BulkWorkOrder';
import { useMaterialCategory } from '../../hooks/useMaterialCategory';
import { useRegions } from '../../hooks/useRegions';
import WorkOrderItemsEdit from '../../features/WorkOrder/WorkOrderItemsEdit/WorkOrderItemsEdit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { fetchTargets, createUpdateTargets } from './Service';
import { useAsyncEffect } from '../../hooks';
import { validateAndReturnInvalidRowIndexes } from '../WorkOrder/WorkOrderCreate/WorkOrderCreateFormValidators';
import { fetchTarget } from '../../state/Target/targetThunks';

function BulkWorkOrderTarget(props) {
  const account = useSelector(({ account }) => account);
  const dispatch = useDispatch();

  const history = useHistory();
  // local state for target data
  const target = useSelector(targetSelectors.getTarget);
  const browseNodes = useMaterialCategory();
  const regions = useRegions();
  const [targetData, setTargetData] = useState({});
  const [excelInProgress, setExcelInProgress] = useState(false);
  const [isExcelUploaded, setIsExcelUploaded] = useState(false);
  const [rowsToHighlight, setRowsHighlight] = useState([]);

  const NEW_ROW = {
    browseNodeId: null,
    itemId: null,
    stateId: null,
    districtId: null,
    qty: '',
  };

  useAsyncEffect(async () => {
    const response = await fetchTargets(
      `?targetFromDate=${account.selectedFinancialYear.fromDate}&targetToDate=${account.selectedFinancialYear.toDate}`
    );
    if (!isEmpty(response[0])) {
      setTargetData(response[0]);
    }
  }, [account.selectedFinancialYear.fromDate]);

  const workOrderForm = useFormik({
    initialValues: {
      isMandatoryDetailsPresent: false,
      workOrderItems: [NEW_ROW],
    },
    validationSchema: Yup.object({
      workOrderItems: Yup.array(),
    }),
    isInitialValid: false,
    onSubmit: async (values) => {
      if (!isEmpty(targetData?.workOrderItems)) {
        let cloneTargetWorkOrderData = [...targetData?.workOrderItems];
        for (let targetValue of values.workOrderItems) {
          const { browseNodeId, itemId, stateId, districtId, qty } = targetValue;
          let itemsByKey = keyBy(targetData?.workOrderItems, (x) =>
            JSON.stringify(pick(x, ['browseNodeId', 'itemId', 'stateId', 'districtId']))
          );

          let nodeKey = JSON.stringify({
            browseNodeId,
            itemId,
            stateId,
            districtId,
          });

          const currentItem = itemsByKey[nodeKey] || {
            id: null,
            browseNodeId,
            itemId,
            stateId,
            districtId,
            qty: qty,
          };
          if (currentItem?.id) {
            const indexNumber = cloneTargetWorkOrderData.indexOf(currentItem);
            currentItem.qty = qty;
            cloneTargetWorkOrderData[indexNumber] = currentItem;
          } else cloneTargetWorkOrderData.push(currentItem);
        }
        const updatedTarget = { ...targetData, workOrderItems: cloneTargetWorkOrderData };
        const updateCreateTargetWorkOrder = await createUpdateTargets(updatedTarget);
        if (updateCreateTargetWorkOrder === 200) {
          dispatch(
            fetchTarget({
              targetFromDate: account.selectedFinancialYear.fromDate,
              targetToDate: account.selectedFinancialYear.toDate,
            })
          );
          toast.success('Target created successfully');
          history.push('/target');
        } else toast.error('Failed to create target');
      } else toast.error('Category wise target is required');
    },
  });

  useEffect(() => {
    setRowsHighlight(validateAndReturnInvalidRowIndexes(workOrderForm.values));
  }, [workOrderForm?.values]);

  const constructWorkOrder = (data) => {
    setExcelInProgress(true);
    data.shift();
    const workOrderArray = [];
    let isMandatoryDetailsPresent = false;

    for (let workOrder of data) {
      if (workOrder[0] && workOrder?.length === 1) workOrder = [];
      if (!isEmpty(workOrder)) {
        const workOrderSkelton = {
          browseNodeId: null,
          districtId: null,
          itemId: null,
          qty: '',
          stateId: null,
          isSameDetailsExist: false,
        };

        if (workOrder[1]) {
          workOrderSkelton.browseNodeId = browseNodes?.find(
            (data) => data?.title?.toUpperCase()?.trim() === workOrder[1]?.toUpperCase()?.trim()
          )?.id;
        } else {
          if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
        }

        if (workOrder[2]) {
          const itemId = browseNodes
            ?.find((data) => data?.id === workOrderSkelton.browseNodeId)
            ?.items?.find(
              (data) => data?.name?.toUpperCase()?.trim() === workOrder[2]?.toUpperCase()?.trim()
            )?.id;
          if (itemId) workOrderSkelton.itemId = itemId;
          else {
            workOrderSkelton.itemId = undefined;
            if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
          }
        } else {
          if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
        }

        if (workOrder[3]) {
          if (workOrderSkelton.browseNodeId !== '3' && workOrderSkelton.browseNodeId !== '5') {
            workOrderSkelton.stateId = regions?.find(
              (data) => data?.name?.toUpperCase()?.trim() === workOrder[3]?.toUpperCase()?.trim()
            )?.id;
          }
        }

        if (workOrder[4]) {
          if (workOrderSkelton.browseNodeId !== '3' && workOrderSkelton.browseNodeId !== '5') {
            workOrderSkelton.districtId = regions
              ?.find((data) => data?.id === workOrderSkelton.stateId)
              ?.districts?.find(
                (data) => data?.name?.toUpperCase()?.trim() === workOrder[4]?.toUpperCase()?.trim()
              )?.id;

            const isSameStatePresent = workOrderArray?.filter(
              (data) =>
                data?.browseNodeId === workOrderSkelton?.browseNodeId &&
                data?.itemId === workOrderSkelton?.itemId &&
                data?.stateId === workOrderSkelton?.stateId
            );
            if (!isEmpty(isSameStatePresent) && workOrderSkelton.districtId) {
              for (let sameStatePresentItem of isSameStatePresent) {
                if (!sameStatePresentItem?.districtId) {
                  sameStatePresentItem.isSameDetailsExist = true;
                  if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
                }
              }
            }
          }
        } else {
          const isSameStatePresent = workOrderArray?.find(
            (data) =>
              data?.browseNodeId === workOrderSkelton?.browseNodeId &&
              data?.itemId === workOrderSkelton?.itemId &&
              data?.stateId === workOrderSkelton?.stateId
          );
          if (isSameStatePresent?.districtId) {
            workOrderSkelton.isSameDetailsExist = true;
            if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
          }
        }

        if (workOrder[5]) {
          workOrderSkelton.qty = workOrder[5];
        } else {
          if (!isMandatoryDetailsPresent) isMandatoryDetailsPresent = true;
        }
        workOrderArray.push(workOrderSkelton);
      }
    }
    workOrderForm.setFieldValue('workOrderItems', workOrderArray);
    workOrderForm.setFieldValue('isMandatoryDetailsPresent', isMandatoryDetailsPresent);
    setExcelInProgress(false);
    setIsExcelUploaded(true);
  };

  return (
    <div className={'animated fadeIn'}>
      <Suspense fallback={<Loader />}>
        <Card className="main-card border-0 ">
          <Form id="createWorkOrderForm" onSubmit={(e) => workOrderForm.handleSubmit(e)}>
            <Card.Header className="bg-white  border-0">
              <Row className="date-picker-bg-img-icon">
                <Col xs={'8'}>
                  <div className={'d-flex align-items-center'}>
                    <Button className={'mr-2'} onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <h4>Bulk Targets</h4>
                  </div>
                </Col>
                <Col className="pl-0 text-right justify-content-end">
                  <BulkWorkOrder
                    constructWorkOrder={constructWorkOrder}
                    excelInProgress={excelInProgress}
                    isBasicDetailsPresent={true}
                  />
                  <Button
                    className={'save-btn px-4'}
                    disabled={
                      !isExcelUploaded ||
                      workOrderForm?.values?.isMandatoryDetailsPresent ||
                      rowsToHighlight?.length !== 0
                    }
                    type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0 border-0">
              <Row>
                <Col style={{ marginLeft: '50px', marginRight: '50px' }}>
                  {isExcelUploaded ? (
                    <WorkOrderItemsEdit
                      basicDetails={workOrderForm.values}
                      deliveryExists={false}
                      rowsToHighlight={rowsToHighlight ? rowsToHighlight : []}
                      browseNodes={browseNodes}
                      regions={regions}
                      addRow={false}
                    />
                  ) : (
                    <h6 className="targetText">Please upload Bulk Order Targets excel</h6>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Form>
        </Card>
      </Suspense>
    </div>
  );
}

export default BulkWorkOrderTarget;
