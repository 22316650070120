import React from 'react';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    margin: '20px',
  },
  locationAvtar: {
    color: 'red',
    backgroundColor: '#fff5f5',
  },
}));

const LocationItem = ({ onClick, location, key, onEdit }) => {
  const classes = useStyles();
  return (
    <ListItem onClick={onClick} key={key}>
      <ListItemAvatar>
        <Avatar className={classes.locationAvtar}>
          <LocationOnIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={location?.name} secondary={location?.streetAddress} />

      {onEdit && (
        <ListItemSecondaryAction>
          <EditIcon fontSize={'small'} color={'primary'} onClick={onEdit} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default LocationItem;
