import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    margin: '20px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    marginBottom: '0px',
    backgroundColor: 'white',
  },
  iconButton: {
    padding: 10,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: '90vh',
    overflow: 'auto',
  },
  locationAvtar: {
    color: 'red',
    backgroundColor: '#fff5f5',
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  lastCol: {
    textAlign: 'right',
  },
  backArrowIcon: {
    margin: '10px',
  },
}));
