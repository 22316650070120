import React, { useEffect, useState } from 'react';
import { Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { uploadDocument } from '../../shared/Utils/FileUpload';
import DocumentCard from '../../features/Delivery/DeliveryDocumentManager/DocumentCard/DocumentCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './WorkOrderCreate/WorkOrderCreate.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';

const DocumentModal = (props) => {
  const [documents, setDocuments] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [key, setKey] = useState('first');
  const [inproDoc, setInProDoc] = useState([]);
  const [noOfDocUploaded, setNoOfDocUploaded] = useState(0);
  const purchaseOrder = props?.documents?.filter((e) => e.type === 'WO_PURCHASE_ORDER');
  const otherOrder = props?.documents?.filter((e) => e.type === 'WO_OTHERS');
  const { groupName, moduleName } = props;

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  useEffect(() => {
    if (!!inproDoc && inproDoc.length > 0 && inproDoc.length === noOfDocUploaded) {
      const docs = inproDoc.filter((e) => e.document.status === 'DONE');
      props.handleDocChange(docs);
      setInProDoc([]);
      setNoOfDocUploaded(0);
    }
  }, [inproDoc]);

  const uploadDocumentToS3 = (file, delDocId, type, count, index) => {
    const fileDetails = {
      active: true,
      entity: 'WORK_ORDER',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setDocuments((oldDocs) => [...(oldDocs || []), docTobeUploaded]);
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded.document?.filaeName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: 0,
        },
      });
    };

    const successCallback = (signedURLResponse) => {
      const uploadDetails = signedURLResponse.data;

      const oldUploadStatus = { ...uploadStatus };
      setUploadStatus({
        ...oldUploadStatus,
        [docTobeUploaded.document?.fileName]: {
          status: 'DONE',
          uploading: false,
        },
      });
      docTobeUploaded.document.status = 'DONE';
      setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);

      // props.handleDocChange(docTobeUploaded);
      // props.documentChanged(docTobeUploaded);
    };
    const progressCallback = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      const newStatus = {
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'IN_PROGRESS',
          uploading: true,
          progress: percentCompleted,
        },
      };
      setUploadStatus(newStatus);
    };

    const failureCallback = (error) => {
      // setUploadStatus({...uploadStatus, uploading: false});
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
      docTobeUploaded.document.status = 'FAIL';
      setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
      toast.error('Failed to upload the document');
    };

    uploadDocument(
      fileDetails,
      file,
      signedURLCallback,
      successCallback,
      failureCallback,
      progressCallback
    );
  };

  const handleFileSelected = (files, docId, type) => {
    setNoOfDocUploaded(files.length);
    files.forEach((file, index) => {
      uploadDocumentToS3(file, docId, type, files.length, index);
    });
  };

  return (
    <Modal
      className="modal modal-full-height"
      size="lg"
      show={props.displayDocumentModal}
      onHide={() => {
        props.setDisplayDocumentModal(false);
      }}
      centered>
      {' '}
      <Modal.Header closeButton className="">
        <h5 className="modal-title w-100 text-center text-regular">Work Order Documents</h5>
      </Modal.Header>
      <Modal.Body className="mb-5">
        <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(key) => setKey(key)}>
          <Row className="p-0" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <div>
                      Purchase Order
                      <FontAwesomeIcon
                        className="float-right"
                        style={key === 'first' ? { color: 'green' } : { color: 'lightgrey' }}
                        icon={faCheckCircle}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div>
                      Other Documents
                      <FontAwesomeIcon
                        className="float-right"
                        style={key === 'second' ? { color: 'green' } : { color: 'lightgrey' }}
                        icon={faCheckCircle}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <PerfectScrollbar style={{ height: '75vh' }} className="p-4">
                    <Row>
                      {!!purchaseOrder && purchaseOrder.length > 0
                        ? purchaseOrder.map((doc, idx) => {
                            return (
                              <Col xs={4} key={idx + 'WO_PO_' + doc.id}>
                                <DocumentCard
                                  className="pl-2"
                                  entity={'WORK_ORDER'}
                                  document={{
                                    status: '',
                                    document: doc,
                                    id: doc.id,
                                    createdOn: doc.createdAt,
                                  }}
                                  display={props.displayDocumentModal}
                                  uploadStatus={uploadStatus[doc?.fileName]}
                                  onDelete={(delDoc) => props.deletDoc(delDoc)}
                                  canUpdate={props.canUpdate}
                                  onFileSelected={(file, delDocId) =>
                                    handleFileSelected(file, delDocId, 'WO_PURCHASE_ORDER')
                                  }
                                  canDownload={true}
                                  groupName={groupName}
                                  moduleName={moduleName}
                                />
                              </Col>
                            );
                          })
                        : !props.canUpdate && (
                            <Col md={{ span: 6, offset: 3 }}>
                              <p className="p-4 text-muted" style={{ textAlign: 'center' }}>
                                No Documents
                              </p>
                            </Col>
                          )}
                      {props.canUpdate && (
                        <Col xs={4}>
                          <DocumentCard
                            entity={'WORK_ORDER'}
                            document={{}}
                            onFileSelected={(file, delDocId) =>
                              handleFileSelected(file, delDocId, 'WO_PURCHASE_ORDER')
                            }
                            canDownload={true}
                            groupName={groupName}
                            moduleName={moduleName}
                          />
                        </Col>
                      )}
                    </Row>
                  </PerfectScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <PerfectScrollbar style={{ height: '75vh' }} className="p-4">
                    <Row>
                      {!!otherOrder && otherOrder.length > 0
                        ? otherOrder.map((doc, idx) => {
                            return (
                              <Col xs={4} key={idx + 'WO_PO_' + doc.id}>
                                <DocumentCard
                                  entity={'WORK_ORDER'}
                                  document={{
                                    status: '',
                                    document: doc,
                                    id: doc.id,
                                    createdOn: doc.createdAt,
                                  }}
                                  display={props.displayDocumentModal}
                                  uploadStatus={uploadStatus[doc?.fileName]}
                                  onDelete={(delDoc) => props.deletDoc(delDoc)}
                                  canUpdate={props.canUpdate}
                                  onFileSelected={(file, delDocId) =>
                                    handleFileSelected(file, delDocId, 'WO_OTHERS')
                                  }
                                  canDownload={true}
                                  groupName={groupName}
                                  moduleName={moduleName}
                                />
                              </Col>
                            );
                          })
                        : !props.canUpdate && (
                            <Col md={{ span: 6, offset: 3 }}>
                              <p className="p-4 text-muted" style={{ textAlign: 'center' }}>
                                No Documents
                              </p>
                            </Col>
                          )}
                      {props.canUpdate && (
                        <Col xs={4}>
                          <DocumentCard
                            document={{}}
                            entity={'WORK_ORDER'}
                            onFileSelected={(file, delDocId) =>
                              handleFileSelected(file, delDocId, 'WO_OTHERS')
                            }
                            canDownload={true}
                            groupName={props?.groupName}
                            moduleName={props?.moduleName}
                          />
                        </Col>
                      )}
                    </Row>
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default DocumentModal;
