import { useRegions } from './index';
import { regionSelectors } from '../state/Region';

export const useDistricts = (stateId) => {
  const states = useRegions();
  if (states && stateId) {
    return regionSelectors.getDistrictByStateId(states, stateId);
  }
  return states.flatMap((s) => s.districts);
};
