import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import * as _ from 'lodash';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { useStyle } from './WoListPage.app.styles';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { useAccount, useDistricts, useRegions } from '../../../hooks';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import Filter from '../../../shared/Components/_App/Modal/Filter';
import PoStatusTab from '../../../shared/Components/_App/Modal/PoStatusTab';
import MaterialTab from '../../../shared/Components/_App/Modal/MaterialTab';
import PageView from '../../../shared/Components/_App/PageView';
import EmptyView from '../../../shared/Components/_App/EmptyView';
import { isEmpty } from 'lodash';
import { ReactComponent as WOIcon } from '../../../assets/img/_App/work_order_icon.svg';
import { WORK_ORDER_STATUS } from '../../../shared/Constants/Constants';
import { appMaterialItems } from '../../../shared/Constants/app.constant';

const WoListPage = () => {
  const classes = useStyle();
  const history = useHistory();
  const param = history?.location?.state;
  const regions = useRegions();
  const districtsMap = useDistricts(48);
  const [workOrders, setWorkOrders] = useState([]);
  const [masterWorkOrders, setMasterWorkOrders] = useState([]);
  const [fetchingWorkOrders, setFetchingWorkOrders] = useState();
  const { myCustomerId } = useAccount();
  const { selectedFinancialYear } = useFinancialYear();
  const [search, setSearch] = useState();
  const [openFilter, setOpenfilter] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [filter, setFilter] = useState({
    size: 500,
    page: 0,
    targetFromDate: selectedFinancialYear?.fromDate,
    targetToDate: selectedFinancialYear?.toDate,
    itemIds: appMaterialItems,
    'isNeeded.equals': true,
    'toCustomerId.equals': myCustomerId,
  });
  const [woFilter, setWofilter] = useState({
    workOrderStatus: null,
    material: null,
    LatestonTop: false,
    OlderonTop: false,
  });
  const [previousFilter, setPreviousFilter] = useState({
    workOrderStatus: null,
    material: null,
  });
  const handleOpen = () => {
    setOpenfilter(true);
  };
  const closeFilterModal = () => {
    setOpenfilter(false);
  };
  const handleClose = () => {
    setWofilter(previousFilter);
    closeFilterModal();
  };

  const clearAll = () => {
    setPreviousFilter({ ...previousFilter, workOrderStatus: null, material: null });
    setWofilter({ ...woFilter, workOrderStatus: null, material: null });
    setWorkOrders(masterWorkOrders);
    // setFilter({ ...filter, workOrderStatus: '' });
    // getAllWorkOrders({ ...filter, workOrderStatus: '' });
    closeFilterModal();
  };

  const applyFilter = () => {
    // getAllWorkOrders({ ...filter });
    if (woFilter?.workOrderStatus && woFilter?.material) {
      const filterData = masterWorkOrders.filter(
        (status) =>
          status?.status === woFilter?.workOrderStatus?.internalStatus &&
          status?.itemId === woFilter?.material?.itemId
      );
      setWorkOrders(filterData);
    } else if (woFilter?.workOrderStatus) {
      const filterData = masterWorkOrders.filter(
        (status) => status?.status === woFilter?.workOrderStatus?.internalStatus
      );
      setWorkOrders(filterData);
    } else if (woFilter?.material) {
      const filterData = masterWorkOrders.filter(
        (status) => status?.itemId === woFilter?.material?.itemId
      );
      setWorkOrders(filterData);
    } else {
      clearAll();
    }
    setPreviousFilter(woFilter);
    closeFilterModal();
  };

  const handleOpenSortBy = () => {
    setOpenSortBy(true);
  };
  const onSelect = () => {
    setWorkOrders(
      _.orderBy(
        workOrders,
        ['type', 'estimatedDelivery'],
        [woFilter?.LatestonTop ? 'asc' : 'desc', woFilter?.OlderonTop ? 'asc' : 'desc']
      )
    );
    setOpenSortBy(false);
  };
  const handleCloseSortBy = () => {
    setWofilter({ ...woFilter, LatestonTop: false, OlderonTop: false });
    setOpenSortBy(false);
  };

  useEffect(() => {
    getAllWorkOrders({ ...filter });
  }, []);

  const modifyWorkOrderData = (woData) => {
    let setArray = woData?.map((value) => {
      const dist = regions?.find((state) => state?.id === value?.stateId);
      const city = dist?.districts?.find((dist) => dist?.id === value?.districtId);
      return { ...value, cityName: city?.name };
    });
    setWorkOrders(setArray.filter((e) => e.stateId !== null));
    setMasterWorkOrders(setArray.filter((e) => e.stateId !== null));
  };

  const groupByMaterials = (woData) => {
    let uniqueItemId = _.uniqBy(woData, 'itemId');
    setMaterialList(
      uniqueItemId.map((item) => {
        return { itemId: item.itemId, itemName: item.itemName, select: false };
      })
    );
  };

  const getAllWorkOrders = async (params) => {
    setFetchingWorkOrders(true);
    try {
      const response = await WorkOrderService.fetchWorkOrderItems(params);
      var filterWorkOrderbyState = [];
      if (response?.data) {
        filterWorkOrderbyState = response?.data?.filter(
          (state) => state?.stateId === parseInt(param?.stateId)
        );
        modifyWorkOrderData(param ? filterWorkOrderbyState : response?.data);
        groupByMaterials(param ? filterWorkOrderbyState : response?.data);
        setFetchingWorkOrders(false);
      }
    } catch (e) {
      setFetchingWorkOrders(false);
    }
  };

  function checkString(text) {
    var re = /^[A-Za-z]+$/;
    if (re.test(text)) return true;
    else return false;
  }

  const searchFilterFunction = (text) => {
    if (text?.length >= 3) {
      const newData = masterWorkOrders.filter(function (value) {
        const itemData = !checkString(text)
          ? value.workOrderId
            ? value.workOrderId.toUpperCase()
            : ''.toUpperCase()
          : value?.cityName
          ? value?.cityName.toUpperCase()
          : ''.toUpperCase();

        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setWorkOrders(newData.filter((e) => e.stateId !== null));
      setSearch(text);
    } else {
      setWorkOrders(masterWorkOrders?.filter((e) => e.stateId !== null));
      setSearch(text);
    }
  };
  const statusStyle = ({ status }) => {
    if (['MET', 'CLOSED'].indexOf(status) >= 0) {
      return { fill: '#33ab2e', backgroundColor: '#f0ffed' };
    }
    if (['DELIVERY_INITIATED', 'PARTIALLY_FULFILLED'].indexOf(status) >= 0) {
      return { fill: '#ff8700', backgroundColor: '#fff6ed' };
    }
    if (['PENDING'].indexOf(status) >= 0) {
      return { fill: '#ff381a', backgroundColor: '#fff5f5' };
    }
    return { fill: '#33ab2e', backgroundColor: '#f0ffed' };
  };
  return (
    <PageView loading={fetchingWorkOrders}>
      <Paper component="form" className={classes.root} elevation={0}>
        <InputBase
          className={classes.input}
          placeholder="Search with District or WO Number"
          inputProps={{ 'aria-label': 'search District or WO Number' }}
          onChange={(e) => searchFilterFunction(e.target.value)}
          value={search}
        />
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <Grid className={classes.demo} style={{ paddingBottom: param ? 0 : '194px' }} container>
        {!fetchingWorkOrders ? (
          workOrders?.length === 0 ? (
            <EmptyView
              hasNoData={isEmpty(workOrders)}
              className={'p-5'}
              message={'No data for selected criteria'}
            />
          ) : (
            workOrders.map((woObj, index) => (
              <Grid item xs={12} md={6} sm={6} key={index}>
                <ListItem onClick={() => history.push('/create-delivery', woObj)}>
                  <ListItemAvatar>
                    <Avatar style={statusStyle(woObj)}>
                      <WOIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={woObj.workOrderId}
                    secondary={
                      <>
                        <Typography component={'span'}>{woObj.cityName || 'NA'}</Typography>
                      </>
                    }
                  />
                  <ListItemText
                    className={classes.lastCol}
                    primary={`${(woObj.qty - woObj.fulfilledQty) / 1000 || 0} MT, ${
                      woObj.itemName || 'NA'
                    }`}
                    secondary={`due by ${toLocalDate(woObj.estimatedDelivery) || 'NA'}`}
                  />
                </ListItem>
              </Grid>
            ))
          )
        ) : null}
      </Grid>
      {!param && (
        <Filter
          bottomMargin={{ bottom: param ? 0 : 68 }}
          openFilter={openFilter}
          openSortBy={openSortBy}
          onSelect={() => onSelect()}
          handleOpenSortBy={() => handleOpenSortBy()}
          handleCloseSortBy={() => handleCloseSortBy()}
          handleClose={handleClose}
          handleClear={() => clearAll()}
          onDone={() => applyFilter()}
          handleOpen={handleOpen}
          sortByFilter={woFilter}
          setSortByFilter={setWofilter}
          sortByDate={'Estimated Delivery'}
          Tabs={[
            {
              tabName: 'Material',
              component: (
                <MaterialTab
                  materialList={materialList}
                  setMaterialList={setMaterialList}
                  selectedStatus={woFilter}
                  setSelectedStatus={setWofilter}
                />
              ),
            },
            {
              /*
              tabName: 'Po Status',
              component: <PoStatusTab selectedStatus={woFilter} setSelectedStatus={setWofilter} />,
            */
            },
          ]}
        />
      )}
    </PageView>
  );
};

export default WoListPage;
