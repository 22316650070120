import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchRegionStates = async (payload) => {
  return await API.get(APIEndpoints.STATES, payload, {
    'wantsDistricts.equals': true,
  });
};

export const regionAPI = {
  fetchRegionStates,
};
