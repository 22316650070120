import React, { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ReactComponent as AccordianArrow } from '../../assets/img/AccordianArrow.svg';
import { convertToMt, getSearchParams } from '../../shared/Utils/Helper';
import ItemWiseAccordian from './ItemWiseAccordian';
import './MappingAccordian.scss';

function MappingAccordian(props) {
  const {
    deliveriesData,
    planData,
    demandColumns,
    supplyColumns,
    workOrderForm,
    stateById,
    vendorById,
    totalVendors,
    setApiCalled,
    availableCreditDates,
    setCallAPI,
    materialCategories,
    supplyTab,
    setSupplyTab,
  } = props;

  const paramsValue = {
    monthName: getSearchParams('month'),
    isMarketPlace: getSearchParams('isMarketPlace'),
  };

  const [brandwisePlanningTabType, setBrandwisePlanningTabType] = useState('Unplanned');
  const [isActive, setIsActive] = useState(true);
  const rotateArrow = () => {
    setIsActive((isActive) => !isActive);
  };

  const handleTabSelect = (eventKey) => {
    setBrandwisePlanningTabType(eventKey);
    workOrderForm.setValues({
      vendorData: [],
      clientData: workOrderForm?.values?.clientData?.map((data) => {
        if (data?.isRowSelected) {
          return {
            ...data,
            isRowSelected: false,
          };
        } else
          return {
            ...data,
          };
      }),
    });
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card className="Card Accordian">
        <div
          className="AccordianMain"
          style={{ height: !paramsValue?.isMarketPlace ? '120px' : 'none' }}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="Accordian-Header"
            onClick={rotateArrow}>
            <Row>
              {paramsValue?.isMarketPlace ? (
                <Col sm="10">
                  <p>Item and State wise Marketplace Deliveries</p>
                </Col>
              ) : (
                <Col sm="10" md="10">
                  <Row>
                    <span>
                      <b>{planData?.brandName}</b>
                    </span>
                  </Row>
                </Col>
              )}
              <Col sm="2">
                <div>
                  <AccordianArrow
                    className="AccordianArrow"
                    style={{ transform: isActive && `rotate(-180deg)` }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="subHeading">
              {paramsValue?.isMarketPlace ? (
                <p>Total no of vendors: {totalVendors()}</p>
              ) : (
                <div style={{ paddingTop: !paramsValue?.isMarketPlace ? '20px' : 'none' }}>
                  <Row>
                    Total Target: {convertToMt(planData?.totalTarget, 3)} MT | Fulfillment:{' '}
                    {planData?.fulfillment}
                  </Row>
                </div>
              )}
            </Row>
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {paramsValue?.isMarketPlace ? (
              <ItemWiseAccordian
                date={availableCreditDates}
                groupedData={deliveriesData}
                workOrderForm={workOrderForm}
                setApiCalled={setApiCalled}
              />
            ) : (
              <ItemWiseAccordian
                supplyTab={supplyTab}
                setSupplyTab={setSupplyTab}
                isBrandwiseUnplanned={true}
                brandwisePlanningTabType={brandwisePlanningTabType}
                setBrandwisePlanningTabType={setBrandwisePlanningTabType}
                handleTabSelect={handleTabSelect}
                planData={planData}
                demandColumns={demandColumns}
                supplyColumns={supplyColumns}
                workOrderForm={workOrderForm}
                stateById={stateById}
                vendorById={vendorById}
                setCallAPI={setCallAPI}
                materialCategories={materialCategories}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default MappingAccordian;
