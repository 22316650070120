import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Trash from '../../../assets/img/trash.svg';
import Tick from '../../../assets/img/SuccessCheck.svg';
import Files from '../../../assets/img/UploadFiles.svg';

function ProcessingDocs({
  file,
  uploadFn,
  uploaded,
  processFn,
  btnStatus,
  setIsProcessed,
  isProcessing,
}) {
  return (
    <>
      <Row>
        <Col xs={8}>
          <Form.Group as={Col}>
            <Form.Label className="label text-regular font-weight-bold mb-3">
              Upload Document
            </Form.Label>
            <div className="d-flex">
              <div className="uploadDocCardStyle">
                <div>
                  <img src={Files} alt="files_upload" className="mr-2" />
                  <span>{file?.content?.[0]?.name}</span>
                </div>
                {file?.content?.[0]?.name && <img src={Tick} alt="green_tick" />}
              </div>
              {!isProcessing && (
                <div
                  className="d-flex ml-2"
                  onClick={() => {
                    setIsProcessed(false);
                    processFn();
                  }}>
                  <img style={{ cursor: 'pointer' }} src={Trash} alt="trash_icon" />
                </div>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'flex-end' }} className="mb-2">
          <Form.Group as={Col}>
            <Button
              disabled={btnStatus || isProcessing || uploaded !== 2}
              onClick={uploadFn}
              style={{ display: 'block' }}
              variant="primary"
              className="text-center px-4">
              Process Document
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <hr />
    </>
  );
}

export default ProcessingDocs;
