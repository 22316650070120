import React from 'react';
import './DocumentHistoryFile.scss';
import { Button } from 'react-bootstrap';
import deleteicon from '../../../../assets/img/delete.svg';

const DocumentHistoryFile = (props) => {
  const contentTypeHash = {
    'application/pdf': 'PDF',
    'image/png': 'PNG',
    'image/jpeg': 'JPEG',
  };

  const sizeInMb = (kbSize) => {
    return `${(kbSize / 1024 / 1024).toFixed(2)} MB`;
  };

  const TYPE = props.fileName?.split('.');

  return (
    <div className="documenthistory-comment-file mb-3">
      {props.docType && props.docType}
      <div className="doc-file p-2">
        <div className="d-flex">
          <img crossOrigin="anonymous" src={props.fileURL} className="challan-doc" alt="file_url" />
          <div className="challen-jpg col-md-9">
            <div className="file-name-text">{props.fileName}</div>
            <div className="file-size">
              {`${
                contentTypeHash[props.fileType] || props.fileType || TYPE[TYPE.length - 1]
              } • ${sizeInMb(props.fileSize)}`}
            </div>
          </div>
          <Button
            variant={'outline-secondary ml-2 btn-icon download-bg'}
            onClick={(e) => window.open(props.fileURL)}
          />
          {props.canDelete && (
            <img
              alt="delete_icon"
              src={deleteicon}
              className="ml-4"
              onClick={() => {
                props.onDelete();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentHistoryFile;
