export const AuthViews = {
  LOGIN_REGISTER: 'view/auth/LOGIN_REGISTER',
  VALIDATE_EMAIL: 'view/auth/VALIDATE_EMAIL',
  SIGNUP: 'view/auth/SIGNUP',
  GSTIN_ADDRESS: 'view/auth/GSTIN_ADDRESS',
  CONTACT_PERSON: 'view/auth/CONTACT_PERSON',
  ADD_PRO_DETAILS: 'view/auth/ADD_PRO_DETAILS',
  RESET_PASSWORD: 'view/auth/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'view/auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FROM_LINK_SUCCESS: 'view/auth/RESET_PASSWORD_FROM_LINK_SUCCESS',
  RESET_PASSWORD_FROM_LINK: 'view/auth/RESET_PASSWORD_FROM_LINK',
  CREATE_PASSWORD_FROM_LINK_SUCCESS: 'view/auth/CREATE_PASSWORD_FROM_LINK_SUCCESS',
  CREATE_PASSWORD_FROM_LINK: 'view/auth/CREATE_PASSWORD_FROM_LINK',
};
