import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    height: '100%',
    width: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 2,
    height: '100%',
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
  },
  header: { backgroundColor: 'transparent', color: '#2e374d' },
  button: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    margin: '0 auto',
    bottom: 0,
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    paddingTop: '59px',
    paddingBottom: '51px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: 'inherit',
    width: '150px',
    backgroundColor: '#F1F4F5',
  },
  container: { height: 'inherit', padding: 0 },
}));
