import React, { useState } from 'react';
import { Typography, Paper } from '@material-ui/core';
import Section from '../../../../shared/Components/_App/Section';
import DocumentCard from '../DocumentCard';
import DocumentTypeModal from './DeliveryUntaggedDocuments/DocumentTypeModal';
import AppModal from '../../../../shared/Components/_App/AppModal';

const DeliverySVDocsDetails = (props) => {
  const { delivery, docTypeList, documents, doFetchDelivery } = { ...props };
  const [docTagModal, setDocTagModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState();
  const handleDocClick = (e) => {
    setDocTagModal(true);
    setSelectedDoc(e);
  };
  return (
    <Section title="UPLOADED BY SUPERVISOR">
      <Typography variant="body2">Please tag this images with respective document name</Typography>
      <DocumentCard onDocClick={handleDocClick} documents={documents} />
      <AppModal title={`Untagged Documents`} visible={docTagModal} setVisible={setDocTagModal}>
        <DocumentTypeModal
          docList={documents}
          selectedDocument={selectedDoc}
          docTypeList={docTypeList}
          doFetchDelivery={doFetchDelivery}
          delivery={delivery}
          setVisible={setDocTagModal}
        />
      </AppModal>
    </Section>
  );
};
export default DeliverySVDocsDetails;
