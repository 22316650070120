import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AUTHORITY2 } from '../../Constants/Constants';
import { usePermissions } from '../../../hooks/usePermissions';

/**
 * this component is authorise route and component as per role, permission and auth of user
 * @param props
 * @return {props.children || failure component}
 */

const usePermissionData = () => {
  const userPermissions = usePermissions();
  const { userAuthorities } = useSelector(({ account }) => {
    const { authorities: userAuthorities } = account?.user;
    return { userAuthorities };
  });

  return { userAuthorities, userPermissions };
};
const HasAuthority = (props) => {
  const { hasPermission, hasAuth } = useAuthority();
  const { authorities, noAuthorities, failureComponent } = props;
  const { permissionsAllowed } = props;
  return (
    <>
      {hasAuth(authorities, noAuthorities) && hasPermission({ permissionsAllowed })
        ? props.children
        : failureComponent || null}
    </>
  );
};

HasAuthority.propTypes = {
  authorities: PropTypes.array,
  noAuthorities: PropTypes.array,
  failureComponent: PropTypes.any,
  permissionsAllowed: PropTypes.array,
};

export default HasAuthority;

/**
 * Hook useful to check whether user has given permission, auth or roles
 * @return {{hasAuth: (function(*=, *=): boolean|boolean), hasPermission: (function(*=): boolean), hasPro: (function(): boolean), hasRoles: (function(*=): boolean)}}
 */
export const useAuthority = () => {
  const { userAuthorities, userPermissions } = usePermissionData();
  const hasPermission = ({ permissionsAllowed }) => {
    return (
      !permissionsAllowed ||
      permissionsAllowed.some(({ groupName, moduleName, name }) => {
        return userPermissions?.[`${groupName?.replace(' ', '_')}`]?.[
          `${moduleName?.replace(' ', '_')}`
        ]?.[`${name}`]?.at(0)?.hasAccess;
      })
    );
  };

  const hasAuth = (auth, noAuthorities) => {
    const isPrefix = (authority, role) => {
      if (typeof authority !== `string` || typeof role !== `string`) {
        throw new TypeError('Data Type Error while authenticating');
      }
      if (authority.length > role.length) return false;
      return role.startsWith(authority);
    };
    return (
      (isEmpty(auth) || userAuthorities?.some((ua) => auth?.some((aut) => isPrefix(aut, ua)))) &&
      (isEmpty(noAuthorities) ||
        !userAuthorities?.some((ua) => noAuthorities?.some((noAut) => isPrefix(noAut, ua))))
    );
  };

  const customerType = () => {
    const fin = Object.entries(AUTHORITY2)
      .filter(([ct, auth]) => hasAuth([auth]))
      .map((item) => item?.at(0));
    return fin;
  };

  const hasPro = () => {
    return hasAuth([AUTHORITY2.PRO]);
  };

  return { hasPermission, hasAuth, hasPro, customerType };
};
