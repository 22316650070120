/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Section from '../../../../shared/Components/_App/Section';
import SelectDropdown from '../../../../shared/Components/_App/SelectDropdown/SelectDropdown';
import Map from '../../../../shared/Components/_App/GoogleMap/Map';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import FormLabel from '../../../../shared/Components/_App/FormLabel';
import { locations } from '../../../../dummyData.json';
import LocationItem from './LocationItem';
import FactoryLocationItem from './FactoryLocationItem';
import AppModal from '../../../../shared/Components/_App/AppModal';
import DeliveryLocationsForm from './DeliveryLocationsForm';
import { useLocations } from '../../../../hooks/hooks';
import { isEquals } from '../../../../shared/Utils/Helper';
import { useRegions } from '../../../../hooks';
import { isEmpty } from 'lodash';
import WeightDetails from './WeightDetails';
// import { DeliveryService } from '../../../../features/Delivery/delivery.service';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#f7faff',
    borderColor: '#E7EDFE',
    padding: theme.spacing(1),
    marginBottom: 5,
  },
}));
const DeliveryLocations = (props) => {
  const {
    ItemLocation = true,
    formik,
    state,
    district,
    isWeighBridgeNumberPresent,
    setIsWeighBridgeNumberPresent,
    deliveryData,
    // maxQty,
  } = { ...props };
  const classes = useStyles();
  const regions = useRegions();
  const states = useRegions();
  const [editLocation, setEditLocation] = useState(false);
  const [visiblePickupLocation, setVisiblePickupLocation] = useState(false);
  const [visibleDropLocation, setVisibleDropLocation] = useState(false);
  const [addPickupLocation, setAddPickupLocation] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [editLocationFromMap, setEditLocationFromMap] = useState(false);
  const [pickUpLocationData, setPickUpLocationData] = useState([]);
  const [masterPickUpLocationData, setMasterPickUpLocationData] = useState([]);
  const [dropStateDistrictArray, setDropStateDistrictArray] = useState({
    stateArray: [],
    districtArray: [],
  });
  const [dropStateDistrictName, setDropStateDistrictName] = useState({
    stateName: '',
    districtName: '',
  });
  const [visibleDropState, setVisibleDropState] = useState(false);
  const [visibleDropDistrict, setVisibleDropDistrict] = useState(false);

  const [
    {
      fetchPickupLocationLoading,
      fetchPickupLocationValue,
      fetchDropLocationLoading,
      fetchDropLocationValue,
    },
    dofetchPickupLocation,
    dofetchDropLocation,
  ] = useLocations();

  useEffect(() => {
    if (!editLocation) {
      dofetchPickupLocation();
    }
  }, [editLocation]);

  useEffect(() => {
    if (formik?.values?.dropOffState) {
      let matchedstate = states?.find((data) => data?.id === formik?.values?.dropOffState);
      let matchedcity = matchedstate?.districts?.find(
        (data) => data?.id === formik?.values?.dropOffCity
      );
      setDropStateDistrictName({
        stateName: matchedstate?.id !== undefined ? matchedstate?.name : '',
        districtName: matchedcity?.id !== undefined ? matchedcity?.name : '',
      });
    }
  }, []);

  const isEqual = (a, b) => {
    return a?.trim()?.toLocaleString() === b?.trim()?.toLocaleString();
  };

  const stateIdHandler = (stateName) => {
    let stateObj = states?.find((s) => isEqual(s.name, stateName));
    return stateObj;
  };

  const cityIdHandler = (districts, districtName) => {
    let cityObj = districts?.find((d) => isEqual(d.name, districtName));
    return cityObj;
  };

  const onSelectLocation = (location, type) => {
    if (type === 'pickup') {
      setVisiblePickupLocation(false);
      const pickuplocation = {
        sourceName: location?.name,
        pickUpLocationType: location?.sourceType,
        pickUpPincode: parseInt(location?.zipCode),
        pickUpCity: location?.pickUpCity,
        pickUpState: location?.pickUpState,
        pickUpAddress: location?.streetAddress,
        pickUpLandMark: location?.landmark,
        pickUpLatitude: location?.lat,
        pickUpLongitude: location?.lng,
      };
      formik.setValues({ ...formik.values, ...pickuplocation });
    } else if (type === 'drop') {
      let matchedstate = stateIdHandler(location?.region?.parent?.name);
      let matchedcity =
        !isEmpty(matchedstate) && cityIdHandler(matchedstate?.districts, location?.region?.name);
      setVisibleDropLocation(false);
      setDropStateDistrictArray({
        stateArray: matchedstate?.id !== undefined ? [] : states,
        districtArray: matchedcity?.id !== undefined ? [] : matchedstate?.districts,
      });
      setDropStateDistrictName({
        stateName: matchedstate?.id !== undefined ? matchedstate?.name : '',
        districtName: matchedcity?.id !== undefined ? matchedcity?.name : '',
      });
      const dropLocation = {
        dropOffRecyclerName: location?.name,
        dropOffPincode: parseInt(location?.zipCode),
        dropOffState: matchedstate?.id !== undefined ? matchedstate?.id : null,
        dropOffCity: matchedcity?.id !== undefined ? matchedcity?.id : null,
        dropOffAddress: location?.streetAddress,
        dropOffLatitude: location?.lat,
        dropOffLongitude: location?.lng,
        recyclerId: location?.recyklerId,
      };
      formik.setValues({ ...formik.values, ...dropLocation });
    }
  };
  const onSelectDropStateDistrict = (addr, type) => {
    if (type === 'state') {
      setDropStateDistrictName({
        stateName: addr?.name?.trim(),
        districtName: '',
      });
      setDropStateDistrictArray({
        stateArray: states,
        districtArray: addr?.districts,
      });
      formik.setFieldValue('dropOffState', addr?.id);
      formik.setFieldValue('dropOffCity', null);
      setVisibleDropState(false);
    } else if (type === 'city') {
      setDropStateDistrictName({
        stateName: dropStateDistrictName?.stateName,
        districtName: addr?.name?.trim(),
      });
      formik.setFieldValue('dropOffCity', addr?.id);
      setVisibleDropDistrict(false);
    }
  };
  const addLocation = (addr) => {
    setSelectedAddress(addr);
    setAddPickupLocation(true);
    setOpenMap(false);
    setEditLocation(true);
  };
  const editPickUpLocation = (location) => {
    const addr = {
      id: location?.id,
      latLng: { lat: location?.lat, lng: location?.lng },
      sourceName: location?.name,
      sourceType: location?.sourceType,
      street: location?.streetAddress,
      zipCode: location?.zipCode,
    };
    const cityState = { cityName: location?.city, stateName: location?.state };
    const cityStateGet = getCityState(cityState);
    addr.city = cityStateGet?.city?.id;
    addr.state = cityStateGet?.state;
    addr.cityName = cityStateGet?.city?.name;

    setSelectedAddress(addr);
    setAddPickupLocation(false);
    setEditLocation(true);
  };

  const getCityState = (cityState) => {
    const state = regions?.find(
      (value) => value?.name?.toLowerCase().trim() === cityState?.stateName?.toLowerCase().trim()
    );
    const city = state?.districts.find(
      (value) => value?.name?.toLowerCase().trim() === cityState?.cityName?.toLowerCase().trim()
    );
    return { state, city };
  };

  useEffect(() => {
    if (fetchPickupLocationValue) {
      const pickUpData =
        state && district
          ? fetchPickupLocationValue?.filter(
              (e) => isEquals(e.state, state) && e.pickUpCity === district
            )
          : state
          ? fetchPickupLocationValue?.filter((e) => isEquals(e.state, state))
          : fetchPickupLocationValue;
      setPickUpLocationData(pickUpData);
      setMasterPickUpLocationData(pickUpData);
    }
  }, [state, district, fetchPickupLocationValue]);

  useEffect(() => {
    if (
      !deliveryData?.id &&
      formik?.values?.pickUpGrossWeight &&
      formik?.values?.pickUpTareWeight &&
      formik?.values?.dropOffGrossWeight &&
      formik?.values?.dropOffTareWeight
    )
      formik.setFieldValue('qty', formik?.values?.dropOffNetWeight);
  }, [
    formik?.values?.pickUpGrossWeight,
    formik?.values?.pickUpTareWeight,
    formik?.values?.dropOffGrossWeight,
    formik?.values?.dropOffTareWeight,
  ]);

  const filterData = (value) => {
    const newData = masterPickUpLocationData.filter(function (data) {
      return (
        data?.name?.toUpperCase()?.trim()?.includes(value?.toUpperCase()) ||
        data?.streetAddress?.toUpperCase()?.trim()?.includes(value?.toUpperCase())
      );
    });
    setPickUpLocationData(newData);
  };

  // const handleqtyChange = (value, fieldType, type) => {
  //   if (type === 'pickup') {
  //     let pickUpNetWeight = null;
  //     if (fieldType === 'pickUpGrossWeight')
  //       pickUpNetWeight =
  //         formik?.values?.pickUpTareWeight === null
  //           ? value
  //           : value - formik?.values?.pickUpTareWeight;
  //     else
  //       pickUpNetWeight =
  //         value === null
  //           ? formik?.values?.pickUpGrossWeight
  //           : formik?.values?.pickUpGrossWeight - value;
  //     formik.setFieldValue(fieldType, value);
  //     formik.setFieldValue('pickUpNetWeight', pickUpNetWeight);
  //     if (!deliveryData?.id && pickUpNetWeight === null) formik.setFieldValue('qty', maxQty);
  //   } else {
  //     let dropOffNetWeight = null;
  //     if (fieldType === 'dropOffGrossWeight')
  //       dropOffNetWeight =
  //         formik?.values?.dropOffTareWeight === null
  //           ? value
  //           : value - formik?.values?.dropOffTareWeight;
  //     else
  //       dropOffNetWeight =
  //         value === null
  //           ? formik?.values?.dropOffGrossWeight
  //           : formik?.values?.dropOffGrossWeight - value;
  //     formik.setFieldValue(fieldType, value);
  //     formik.setFieldValue('dropOffNetWeight', dropOffNetWeight);
  //     if (!deliveryData?.id && dropOffNetWeight === null) formik.setFieldValue('qty', maxQty);
  //   }
  // };

  // const handleWeighBridgeCheck = async (value, type, fieldType) => {
  //   const isSameDetailsPresent = !isEmpty(deliveryData)
  //     ? deliveryData?.deliveryDetails[fieldType]?.trim() === value?.trim()
  //     : false;
  //   if (type === 'PICKUP')
  //     setIsWeighBridgeNumberPresent({
  //       ...isWeighBridgeNumberPresent,
  //       pickUpWeighBridgeCheckLoading: true,
  //     });
  //   else
  //     setIsWeighBridgeNumberPresent({
  //       ...isWeighBridgeNumberPresent,
  //       dropOffWeighBridgeCheckLoading: true,
  //     });
  //   formik.setFieldValue(fieldType, value);
  //   if (!isEmpty(value) && !isSameDetailsPresent) {
  //     const response = await DeliveryService.checkWeighBridgeNumber(type, value);
  //     if (type === 'PICKUP')
  //       setIsWeighBridgeNumberPresent({
  //         ...isWeighBridgeNumberPresent,
  //         isPickUpWeighBridgePresent: response?.data === true ? true : false,
  //         pickUpWeighBridgeCheckLoading: false,
  //       });
  //     else
  //       setIsWeighBridgeNumberPresent({
  //         ...isWeighBridgeNumberPresent,
  //         isDropOffWeighBridgePresent: response?.data === true ? true : false,
  //         dropOffWeighBridgeCheckLoading: false,
  //       });
  //   } else {
  //     if (type === 'PICKUP')
  //       setIsWeighBridgeNumberPresent({
  //         ...isWeighBridgeNumberPresent,
  //         isPickUpWeighBridgePresent: false,
  //       });
  //     else
  //       setIsWeighBridgeNumberPresent({
  //         ...isWeighBridgeNumberPresent,
  //         isDropOffWeighBridgePresent: false,
  //       });
  //   }
  // };

  // const handleKeyDown = (event) => {
  //   const invalidCharecters = ['-', '+', 'e'];
  //   if (invalidCharecters.includes(event?.key)) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <Section title={'Choose Pickup & Delivery Location'}>
      <div>
        <SelectDropdown
          visible={visiblePickupLocation}
          value={formik?.values?.sourceName}
          setVisible={setVisiblePickupLocation}
          loading={fetchPickupLocationLoading}
          filterData={filterData}
          isSeachBox={true}
          onClick={() => {
            dofetchPickupLocation();
            setVisiblePickupLocation(true);
          }}
          setVisibleModel={visiblePickupLocation}
          listData={pickUpLocationData}
          renderItem={(loc, idx) => (
            <LocationItem
              onClick={() => onSelectLocation(loc, 'pickup')}
              onEdit={() => editPickUpLocation(loc)}
              key={idx}
              location={loc}
            />
          )}
          label={'Pickup From'}
          modalTitle={'Pickup From'}
          addHandler={() => {
            setSelectedAddress();
            setEditLocationFromMap(false);
            setOpenMap(true);
          }}
          actions>
          <Paper elevation={0} variant={'outlined'} className={classes.paper}>
            <FormLabel label={'Address'} size={'small'} />
            <Typography color={'secondary'} variant={'h6'}>
              {formik.values.pickUpAddress ? formik?.values?.pickUpAddress : 'N/A'}
            </Typography>
          </Paper>
        </SelectDropdown>
        <WeightDetails
          formik={formik}
          isWeighBridgeNumberPresent={isWeighBridgeNumberPresent}
          setIsWeighBridgeNumberPresent={setIsWeighBridgeNumberPresent}
          isPickup={true}
          isCreate={true}
          deliveryData={deliveryData}
          isQtyEditable={
            isEmpty(deliveryData) ? true : deliveryData?.deliveryDetails?.dropOffNetWeight === null
          }
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <SelectDropdown
          visible={visibleDropLocation}
          value={formik?.values?.dropOffRecyclerName}
          setVisible={setVisibleDropLocation}
          onClick={() => {
            dofetchDropLocation();
            setVisibleDropLocation(true);
          }}
          loading={fetchDropLocationLoading}
          listData={fetchDropLocationValue}
          label={'Delivery To'}
          ItemLocation={ItemLocation}
          renderItem={(loc, idx) => (
            <LocationItem onClick={() => onSelectLocation(loc, 'drop')} key={idx} location={loc} />
          )}
          children2={<FactoryLocationItem listData={locations} />}
          modalTitle={'Delivery To'}>
          <Paper elevation={0} variant={'outlined'} className={classes.paper}>
            <FormLabel size={'small'} label={'Address'} />
            <Typography color={'secondary'} variant={'h6'}>
              {formik.values.dropOffAddress ? formik?.values?.dropOffAddress : 'N/A'}
            </Typography>
          </Paper>
        </SelectDropdown>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <SelectDropdown
              visible={visibleDropState}
              value={dropStateDistrictName?.stateName}
              setVisible={setVisibleDropState}
              onClick={() => {
                setVisibleDropState(
                  formik?.values?.recyclerId
                    ? isEmpty(dropStateDistrictArray?.stateArray)
                      ? false
                      : true
                    : false
                );
              }}
              listData={dropStateDistrictArray?.stateArray}
              placeholder={'State'}
              renderItem={(loc, idx) => {
                return (
                  <LocationItem
                    onClick={() => onSelectDropStateDistrict(loc, 'state')}
                    key={idx}
                    location={loc}
                  />
                );
              }}
              modalTitle={'Drop State'}></SelectDropdown>
            {formik?.values?.recyclerId && formik?.values?.dropOffState === null && (
              <p style={{ color: 'red' }}>Drop state is missing</p>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectDropdown
              visible={visibleDropDistrict}
              value={dropStateDistrictName?.districtName}
              setVisible={setVisibleDropDistrict}
              onClick={() => {
                setVisibleDropDistrict(
                  formik?.values?.recyclerId
                    ? isEmpty(dropStateDistrictArray?.districtArray)
                      ? false
                      : true
                    : false
                );
              }}
              listData={dropStateDistrictArray?.districtArray}
              placeholder={'District'}
              renderItem={(loc, idx) => {
                return (
                  <LocationItem
                    onClick={() => onSelectDropStateDistrict(loc, 'city')}
                    key={idx}
                    location={loc}
                  />
                );
              }}
              modalTitle={'Drop District'}></SelectDropdown>
            {formik?.values?.recyclerId && formik?.values?.dropOffCity === null && (
              <p style={{ color: 'red' }}>Drop district is missing</p>
            )}
          </Grid>
        </Grid>
      </div>

      <AppModal
        title={addPickupLocation ? `Add Pickup Location` : `Edit PickupLocation`}
        visible={editLocation}
        setVisible={setEditLocation}>
        <DeliveryLocationsForm
          setOpenMap={setOpenMap}
          editLocation={editLocation}
          addPickupLocation={addPickupLocation}
          setEditLocation={setEditLocation}
          setEditLocationFromMap={setEditLocationFromMap}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setVisiblePickupLocation={setVisiblePickupLocation}
          formik={formik}
          states={states}
        />
      </AppModal>
      <AppModal title={`Current Location`} visible={openMap} setVisible={setOpenMap}>
        <Map
          onConfirmLocation={(selectedAddress) => addLocation(selectedAddress)}
          openMapModel={openMap}
          editLocationFromMap={editLocationFromMap}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      </AppModal>
    </Section>
  );
};

export default DeliveryLocations;
