import React from 'react';

function DeleteICon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}>
      <path
        d="M9.66667 3.49992V2.96659C9.66667 2.21985 9.66667 1.84648 9.52134 1.56126C9.39351 1.31038 9.18954 1.10641 8.93865 0.978577C8.65344 0.833252 8.28007 0.833252 7.53333 0.833252H6.46667C5.71993 0.833252 5.34656 0.833252 5.06135 0.978577C4.81046 1.10641 4.60649 1.31038 4.47866 1.56126C4.33333 1.84648 4.33333 2.21985 4.33333 2.96659V3.49992M1 3.49992H13M11.6667 3.49992V10.9666C11.6667 12.0867 11.6667 12.6467 11.4487 13.0746C11.2569 13.4509 10.951 13.7569 10.5746 13.9486C10.1468 14.1666 9.58677 14.1666 8.46667 14.1666H5.53333C4.41323 14.1666 3.85318 14.1666 3.42535 13.9486C3.04903 13.7569 2.74307 13.4509 2.55132 13.0746C2.33333 12.6467 2.33333 12.0867 2.33333 10.9666V3.49992"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DeleteICon;
