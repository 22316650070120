import jwt_decode from 'jwt-decode';
const JwtUtils = () => {
  function _decodeToken(token) {
    const _token = token || getToken();
    return jwt_decode(_token);
  }

  function getToken() {
    return localStorage.getItem('authenticate_token');
  }

  function getTokenData(token) {
    return _decodeToken(token);
  }

  return {
    getToken: getToken,
    getTokenData: getTokenData,
  };
};

export default JwtUtils;
