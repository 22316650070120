import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingButton = (props) => {
  return (
    <Button
      className={props.className}
      fullWidth
      color="primary"
      name={props.name}
      id={props.id}
      variant={props.variant || 'contained'}
      disabled={props.disabled}
      type={props.type}
      {...props}>
      {props.loading ? (
        <div>
          <CircularProgress as="span" size="sm" role="status" aria-hidden="true" />
          <span> {props.loadingtext ? props.loadingtext : 'Loading...'}</span>
        </div>
      ) : (
        props.children
      )}
    </Button>
  );
};

export default LoadingButton;
