import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Typography,
  Link,
  ListItemIcon,
  makeStyles,
  Paper,
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  mainHead: {
    color: '#2e384d',
    fontFamily: 'font-semibold',
  },
  alignRight: {
    textAlign: 'end',
  },
  linkStyl: {
    color: '#33ab2e',
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DeliveryLocationsDetail = (props) => {
  const { expand, data, showEditChange, isEditable, isPRO } = { ...props };
  const classes = useStyles();
  const [showLocationCard, setLocationCard] = useState(!expand);
  const [show, setShow] = useState({ pickUp: false, drop: false });
  const onExpand = () => {
    if (expand) setLocationCard(!showLocationCard);
  };
  return (
    <Paper elevation={0} className="mb-3">
      <div onClick={() => onExpand()}>
        <List style={{ paddingBottom: showLocationCard ? 8 : 0 }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  className={`${classes.mainHead} mb-2`}
                  variant="body1"
                  color="textPrimary">
                  Pickup / Delivery Locations
                </Typography>
              }
            />
            {!isPRO && (
              <ListItemText
                primary={
                  isEditable && (
                    <Typography className={`${classes.alignRight} mb-2`}>
                      <Link href="#" className={classes.linkStyl} onClick={showEditChange}>
                        Change
                      </Link>
                    </Typography>
                  )
                }
              />
            )}
            {/*<ListItemSecondaryAction>
              {expand ? (
                showLocationCard ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : (
                <ListItemText
                  primary={
                    <Typography className={`${classes.alignRight} mb-2`}>
                      <Link href="#" className={classes.linkStyl} onClick={showEditChange}>
                        Change
                      </Link>
                    </Typography>
                  }
                />
              )}
                </ListItemSecondaryAction>*/}
          </ListItem>
          <Divider />
        </List>
      </div>

      {showLocationCard && (
        <Timeline align="left" style={{ alignItems: 'baseline', padding: 0 }}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ flex: 'auto' }}>
              <>
                <div className={classes.display}>
                  <Typography className="mb-2" variant="body1" color="textPrimary">
                    Pickup From
                  </Typography>
                  {/* <Typography onClick={showEditChange} style={{ color: '#33ab2e' }} variant="body1">
                    Change
      </Typography>*/}
                </div>
                <Grid container>
                  <Grid item>
                    <div
                      style={{ width: '220px', whiteSpace: !show?.pickUp ? 'nowrap' : 'normal' }}>
                      <Box
                        style={{ marginTop: '0px' }}
                        component="div"
                        my={2}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        bgcolor="background.paper">
                        {data?.pickUpAddress || 'NA'}
                      </Box>
                    </div>
                  </Grid>
                  <Grid item>
                    <p
                      onClick={() => setShow({ ...show, pickUp: !show.pickUp })}
                      style={{ color: 'green', float: 'right' }}>
                      {!show?.pickUp ? 'View more' : 'View less'}
                    </p>
                  </Grid>
                </Grid>
                <Divider />
              </>
            </TimelineContent>
          </TimelineItem>
          <Divider />

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#33ab2e' }} />
            </TimelineSeparator>
            <TimelineContent style={{ flex: 'auto' }}>
              <>
                <div className={classes.display}>
                  <Typography className="mb-2" variant="body1" color="textPrimary">
                    Delivery To
                  </Typography>
                  {/*<Typography variant="body1" onClick={showEditChange} style={{ color: '#33ab2e' }}>
                    Change
    </Typography>*/}
                </div>
                <Grid container>
                  <Grid item>
                    <div style={{ width: '220px', whiteSpace: !show?.drop ? 'nowrap' : 'normal' }}>
                      <Box
                        style={{ marginTop: '0px' }}
                        component="div"
                        my={2}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        bgcolor="background.paper">
                        {data?.dropOffAddress || 'NA'}
                      </Box>
                    </div>
                  </Grid>
                  <Grid item>
                    <p
                      onClick={() => setShow({ ...show, drop: !show.drop })}
                      style={{ color: 'green', float: 'right' }}>
                      {!show?.drop ? 'View more' : 'View less'}
                    </p>
                  </Grid>
                </Grid>
              </>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      )}
    </Paper>
  );
};

export default DeliveryLocationsDetail;
