import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import React from 'react';
import downloadFile from '../../../../features/Delivery/DeliveryDocumentManager/DocumentCard/DocumentDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTracking } from '../../../../hooks/hooks';
import { faArrowDown, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useAsyncEffect } from '../../../../hooks';

const DeliveryDownloadDocs = ({ id, docList, documents }) => {
  const documentList = [
    { id: 'PICKUP_INVOICE', name: 'E-Invoice' },
    { id: 'PICKUP_E_WAY_BILL', name: 'Ewaybill' },
  ];
  const [{ value }, { doFetchTracking }] = useTracking();

  const ewayEinvoiceDocuments = () => {
    const docArray = documents?.filter(
      (doc) =>
        doc?.document?.type.includes('PICKUP_INVOICE') ||
        doc?.document?.type.includes('PICKUP_E_WAY_BILL')
    );
    return docArray;
  };
  useAsyncEffect(async () => {
    const response = await doFetchTracking(id);
  }, []);
  return (
    <Paper elevation={0}>
      <List>
        {ewayEinvoiceDocuments()?.map((e) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'transparent' }}>
                <FontAwesomeIcon color="red" icon={faFilePdf} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              {documentList.find((doc) => doc.id === e.document.type)?.name}
            </ListItemText>
            <ListItemAvatar onClick={() => downloadFile(e.document, e.document.fileName)}>
              <Avatar style={{ backgroundColor: '#f0ffed' }}>
                <FontAwesomeIcon color="green" icon={faArrowDown} />
              </Avatar>
            </ListItemAvatar>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
export default DeliveryDownloadDocs;
