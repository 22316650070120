import React from 'react';
import { ListItemText, ListItem } from '@material-ui/core';
import { toCapitalize } from '../../../../shared/Utils/Helper';

const UlbListView = ({ onClick, ulbObj, key }) => {
  return (
    <ListItem key={key}>
      <ListItemText onClick={onClick} primary={toCapitalize(ulbObj.name)} secondary={ulbObj.addr} />
    </ListItem>
  );
};

export { UlbListView };
