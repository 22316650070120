import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    marginBottom: '0px',
    backgroundColor: 'white',
  },
  iconButton: {
    padding: 10,
  },
  listUi: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  demo: {
    maxHeight: '73vh',
    overflow: 'auto',
  },
  greyBg: {
    backgroundColor: '#eff0f5',
  },
  whiteBg: {
    backgroundColor: 'white',
  },
  logoutModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    width: '75%',
    position: 'absolute',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
  modalText: {
    textAlign: 'center',
    padding: '10px',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  modalButton: {
    height: '40px',
    margin: '5px',
  },
}));
