import React from 'react';
import './DashboardDataCard.scss';
import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';

export const DashboardDataSubCard = ({ categoryData, ...props }) => {
  const history = useHistory();

  return (
    <Card className="dashboard-data-sub-card">
      <div className="d-flex">
        {categoryData.map((data, index) => {
          return index === 0 ? (
            <div
              className="d-flex flex-column"
              onClick={() => {
                history.push(data?.path);
              }}>
              <h6>{data['title']}</h6>
              <h3>
                <CountUp start={0} separator={','} end={data['value']} duration={1} />
                <span className="text-small"> kg</span>
              </h3>
            </div>
          ) : (
            <>
              <Divider orientation="vertical" style={{ height: '3rem', marginTop: '0.75rem' }} />
              <div
                className="d-flex flex-column"
                onClick={() => {
                  history.push(data?.path);
                }}>
                <h6>{data['title']}</h6>
                <h3>
                  <CountUp start={0} separator={','} end={data['value']} duration={1} />
                  <span className="text-small"> kg</span>
                </h3>
              </div>
            </>
          );
        })}
      </div>
    </Card>
  );
};

const DashboardDataCard = ({ title, value, ...props }) => {
  return (
    <Card className="dashboard-data-card">
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <h6>{title}</h6>
          <h3>
            <CountUp start={0} separator={','} end={value} duration={1} />
            <span className="text-small"> kg</span>
          </h3>
        </div>
        {props.children}
      </div>
    </Card>
  );
};

export default DashboardDataCard;
