import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

export const fetchTargets = async (financialYear) => {
  try {
    const response = await API.get(APIEndpoints.TARGETS + financialYear);
    if (response?.status === 200) return Promise.resolve(response?.data);
    else return Promise.resolve('');
  } catch (error) {
    console.log('fetchTargets', error);
  }
};

export const createUpdateTargets = async (payload) => {
  try {
    const response = await API.put(APIEndpoints.TARGETS, payload);
    if (response?.status === 200) return Promise.resolve(response?.status);
    else return Promise.resolve('');
  } catch (error) {
    console.log('fetchCreateUpdateTargets', error);
  }
};
